import axios from "axios";
import { getBackendUrl } from "./getBackendUrl";
import { middleField } from "./middlefield";

class CronService {
  constructor(baseURL) {
    this.baseURL = baseURL;
    this.api = axios.create({
      baseURL,
    });
    middleField(this.api);
  }

  vacancyChecker() {
    return this.api.get(`/vacancyChecker`);
  }
  
}

export default new CronService(`${getBackendUrl()}/cron`);
