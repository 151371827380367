import { message } from "antd";
import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import CalendlyService from "../../../service/CalendlyService";

const Calendly = () => {
  let [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const code = searchParams.get("code");
    if (!code) return;

    CalendlyService.requestToken({
      code,
      redirect_uri: `${window.location.origin.replace(
        "http://",
        "https://"
      )}/calendly`,
    })
      .then(() => {
        message.success(
          "Your Calendly account has been successfully connected"
        );
        document.dispatchEvent(new CustomEvent("CHECK.CALENDLY.EVENT.TYPES"));
        navigate("/dashboard/settings#integrations");
      })
      .catch(() => navigate("/dashboard/settings#integrations"));
  }, [searchParams, navigate]);
  return <></>;
};

export default Calendly;
