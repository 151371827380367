import React from "react";
import ZoomService from "../../../service/ZoomService";

const ConnectZoom = () => {
  return (
    <>
      <h2 className="font-semibold text-lg">
        Connect Your Zoom to Get Started
      </h2>

      <div>
        <p></p>
      </div>

      <div className="mt-5">
        <button
          className="px-2 py-1 text-sm bg-indigo-500 text-white rounded"
          onClick={async () => {
            const res = await ZoomService.getAuthURI();
            window.location.href = res.data.authUri;
          }}
        >
          Click here to connect
        </button>
      </div>
    </>
  );
};

export default ConnectZoom;
