import Cookies from "js-cookie";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { logout } from "../../redux/auth/actions";
import { store } from "../../redux/store";
import AuthService from "../../service/AuthService";
import { Button } from "./components/Button";
import { CheckBox } from "./components/CheckBox";
import { Heading } from "./components/Heading";
import { Img } from "./components/Img";
import { Text } from "./components/Text";

import { useSelector } from "react-redux";
import { getPartner, selectLoading } from "../../redux/auth/selectors";
import ShowPassword from "../Dashboard/PartnerSettings/ShowPassword";

const PasswordReset = () => {
  const [minutes, setMinutes] = useState(5);
  const [seconds, setSeconds] = useState(0);
  const [isActive, setIsActive] = useState(true);
  const navigate = useNavigate();
  const loading = useSelector(selectLoading);
  const [passwordShow, setPasswordShow] = useState(false);

  useEffect(() => {
    let interval;

    if (isActive && (minutes > 0 || seconds > 0)) {
      interval = setInterval(() => {
        if (seconds === 0) {
          if (minutes === 0) {
            setIsActive(false);
            clearInterval(interval);
          } else {
            setMinutes((prevMinutes) => prevMinutes - 1);
            setSeconds(59);
          }
        } else {
          setSeconds((prevSeconds) => prevSeconds - 1);
        }

        if (seconds === 1 && minutes === 0) {
          setIsActive(false);
          clearInterval(interval);
        }
      }, 1000);
    }

    return () => {
      clearInterval(interval);
    };
  }, [isActive, minutes, seconds]);

  const resetTimer = () => {
    setIsActive(true);
    setMinutes(1);
    setSeconds(0);
  };

  const formRef = useRef(null);
  const inputRef1 = useRef(null);
  const inputRef2 = useRef(null);
  const inputRef3 = useRef(null);
  const inputRef4 = useRef(null);

  const focusNextInput = (currentRef, nextRef) => {
    if (currentRef.current.value.length >= currentRef.current.maxLength) {
      nextRef.current && nextRef.current.focus();
    }
  };
  const focusPrevInput = (currentRef, prevRef) => {
    if (currentRef.current.value.length === 0) {
      prevRef.current && prevRef.current.focus();
    }
  };

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      if (!localStorage.forgotEmail) return;
      await AuthService.resetPassword({
        OTP: `${e.target[0].value}${e.target[1].value}${e.target[2].value}${e.target[3].value}`,
        email: localStorage.forgotEmail,
        newPassword: e.target[4].value,
      });
      navigate("/dashboard/home");
    },
    [navigate]
  );

  const handleResend = useCallback(async () => {
    if (!localStorage.forgotEmail) return;

    await AuthService.requestPasswordReset({ email: localStorage.forgotEmail });

    if (isActive) return;
    resetTimer();
  }, [isActive]);

  return (
    <>
      <div className=" bg-[#f8f8f8] min-h-[100vh] min-w-[100vw] overflow-hidden ">
        <div className="flex flex-col flex-1 md:flex-row min-h-[100vh] min-w-[100vw]">
          <div className="w-[50vw] flex  flex-col items-start hidden md:block  min-h-[90vh] max-h-[100vh]">
            <div className="flex  flex-col items-center gap-10 rounded-br-[12px] rounded-tr-[12px] border border-solid border-[#eaecf0] bg-[#ffffff] md:w-full  min-h-[80vh]  my-[12px]">
              <div className="ml-[60px] mr-[74px] mt-36 flex flex-col gap-3 self-stretch md:mx-0">
                <div className="flex">
                  <Heading
                    size="headingxl"
                    as="h1"
                    className="mx-8 text-3xl font-semibold tracking-[-0.60px] text-[#0f1728] md:text-[28px] sm:text-[26px]"
                  >
                    HireHeroes
                  </Heading>
                </div>
                <Heading
                  as="h2"
                  className=" mx-8 text-base font-normal leading-[22px] text-[#475467] "
                >
                  Conversational voice driven ai recruitment assistants.
                  Interviews applicants through live interview calls, takes care
                  of all admin tasks, have the AI evaluate and shortlist the top
                  pools.
                </Heading>
              </div>
              <div className="relative h-[500px] md:h-[637px] w-[90%] content-center self-end rounded-[10px] md:h-auto">
                <Img
                  src="/images/ScreenMockup.png"
                  alt="Shadow Image"
                  className="mx-auto h-[300px] lg:h-[484px] flex-1 object-cover"
                />
              </div>
            </div>
          </div>
          <div className="mt-8 flex flex-col md:w-[50vw] w-full items-center gap-[30px] px-5 md:px-5">
            <div className="flex flex-col justify-center gap-3 px-5 md:px-5">
              <Img
                src="/images/lock.svg"
                alt="Logo Icon"
                className="mx-auto h-[48px] w-[48px] rounded-[10px] "
              />
              <a href="#" className="md:text-[28px] sm:text-[26px]">
                <Heading
                  size="headingxl"
                  as="h3"
                  className="text-[30px] text-center font-semibold text-[#0f1728]"
                >
                  Confirm OTP
                </Heading>
              </a>
            </div>
            <form
              onSubmit={handleSubmit}
              ref={formRef}
              onKeyDown={(e) => {
                if (e.key !== "Enter") return;
                e.target.submit();
              }}
              className="flex w-full md:w-[70%] flex-col justify-center gap-6 "
            >
              <div className="flex flex-col gap-6 justify-center ">
                <div className="flex flex-col justify-center gap-5">
                  <div className="flex flex-col items-center justify-center gap-1.5 mx-5">
                    <div className="flex flex-row items-center justify-between mx-auto w-full max-w-xs">
                      <div className="w-16 h-16 ">
                        <input
                          ref={inputRef1}
                          className="w-full h-full flex flex-col items-center justify-center text-center px-5 outline-none rounded-xl border border-gray-200 dark:border-gray-600  text-lg bg-white dark:bg-gray-900 focus:bg-gray-50 focus:ring-1 ring-blue-700"
                          type="text"
                          autoFocus
                          maxLength={1}
                          onChange={() => focusNextInput(inputRef1, inputRef2)}
                        />
                      </div>
                      <div className="w-16 h-16 ">
                        <input
                          ref={inputRef2}
                          className="w-full h-full flex flex-col items-center justify-center text-center px-5 outline-none rounded-xl border border-gray-200 dark:border-gray-600  text-lg bg-white dark:bg-gray-900 focus:bg-gray-50 focus:ring-1 ring-blue-700"
                          type="text"
                          maxLength={1}
                          onKeyDown={(e) => {
                            if (e.key === "Backspace")
                              focusPrevInput(inputRef2, inputRef1);
                          }}
                          onChange={() => focusNextInput(inputRef2, inputRef3)}
                        />
                      </div>
                      <div className="w-16 h-16 ">
                        <input
                          ref={inputRef3}
                          className="w-full h-full flex flex-col items-center justify-center text-center px-5 outline-none rounded-xl border border-gray-200 dark:border-gray-600  text-lg bg-white dark:bg-gray-900 focus:bg-gray-50 focus:ring-1 ring-blue-700"
                          type="text"
                          maxLength={1}
                          onKeyDown={(e) => {
                            if (e.key === "Backspace")
                              focusPrevInput(inputRef3, inputRef2);
                          }}
                          onChange={() => focusNextInput(inputRef3, inputRef4)}
                        />
                      </div>
                      <div className="w-16 h-16 ">
                        <input
                          ref={inputRef4}
                          className="w-full h-full flex flex-col items-center justify-center text-center px-5 outline-none rounded-xl border border-gray-200 dark:border-gray-600  text-lg bg-white dark:bg-gray-900 focus:bg-gray-50 focus:ring-1 ring-blue-700"
                          type="text"
                          maxLength={1}
                          onKeyDown={(e) => {
                            if (e.key === "Backspace")
                              focusPrevInput(inputRef4, inputRef3);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-col items-center justify-center gap-1.5 mx-5">
                    <Heading
                      size="texts"
                      as="h6"
                      className="w-full self-start text-[14px] font-medium text-[#344054]"
                    >
                      Enter your new password
                    </Heading>
                    <ShowPassword
                      shape="round"
                      name="Password"
                      label="Password"
                      required
                      className="w-full rounded-lg border border-solid border-[#cfd4dc] px-3.5"
                    />
                  </div>
                </div>
              </div>
              <Button
                className="justify-center continuebutton_border lg:min-w-[400px] rounded-lg bg-gradient-to-r from-[#0538ff] to-[#6b56f4] px-[33px] font-semibold text-[#ffffff] shadow-[0_1px_2px_0_#1018280c] sm:px-5 mx-5"
                size="2xl"
                shape="round"
                variant={null}
                type="submit"
              >
                Submit
              </Button>
            </form>
            <div className="flex items-center justify-center px-14 md:px-5 ">
              <a href="#">
                <Text as="p" className="text-[14px] font-normal text-[#667084]">
                  Didn't receive the code?&nbsp;
                </Text>
              </a>
              <div className="flex">
                <a
                  href="#"
                  onClick={handleResend}
                  className="bg-gradient-to-r from-[#0538ff] to-[#6b56f4] bg-clip-text"
                >
                  <Heading
                    size="headings"
                    as="p"
                    className="text-[14px] font-semibold text-transparent"
                  >
                    Resend
                  </Heading>
                </a>
              </div>
            </div>
            <Button
              className="justify-center lg:min-w-[400px] rounded-lg  px-[33px] font-semibold  shadow-[0_1px_2px_0_#1018280c] sm:px-5"
              size="2xl"
              shape="round"
              onClick={() => {
                Cookies.remove("accessToken");
                Cookies.remove("refreshToken");
                store.dispatch(logout());
                window.location.href = "/";
              }}
            >
              Sign out
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default PasswordReset;
