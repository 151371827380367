import { useState } from 'react'
import ColumnForm from './components/ColumnForm'

function ColumnAdder({ onConfirm }) {
  const [isAddingColumn, setAddingColumn] = useState(false)

  function confirmColumn(title) {
    onConfirm(title)
    setAddingColumn(false)
  }

  return isAddingColumn ? (
    <ColumnForm onConfirm={confirmColumn} onCancel={() => setAddingColumn(false)} />
  ) : (
    <div
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '70px',height: "100px",
        borderRadius: "10px", margin: "10px", borderTop: `10px solid #0538FF` }}
      className='react-kanban-column bg-[#ffffff]'
      onClick={() => setAddingColumn(true)}
      role='button'
    >
      ➕
    </div>
  )
}

export default ColumnAdder

// style={{
//   height: "100%",
//   minHeight: "28px",
//   background: "transparent",
//   display: "inline-block",
//   gap: "5px",
//   verticalAlign: "top",
//   ...columnProvided.draggableProps.style,
// }}

