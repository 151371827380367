import axios from "axios";
import { getBackendUrl } from "./getBackendUrl";
import { middleField } from "./middlefield";

class ApolloService {
  constructor(baseURL) {
    this.baseURL = baseURL;
    this.api = axios.create({
      baseURL,
    });
    middleField(this.api);
  }

  /**
      person_titles: ["sales director", "engineer manager"] An array of the person's title. Apollo will return results matching ANY of the titles passed in
      q_keywords: "Tim" A string of words over which we want to filter the results
      person_locations: ["California, US", "Minnesota, US"] An array of strings denoting allowed locations of the person
      organization_locations: ["California, US", "Minnesota, US"] An array of strings denoting allowed locations of organization headquarters of the person
      person_seniorities: ["senior", "manager"] An array of strings denoting the seniorities or levels	
      contact_email_status: ["verified", "guessed", "unavailable", "bounced", "pending_manual_fulfillment"] An array of strings to look for people having a set of email statuses	
      q_organization_domains: "google.com\nfacebook.com" An array of the company domains to search for, joined by the new line character.	
      organization_num_employees_ranges: ["1,10", "101,200" ] An array of intervals to include people belonging in an organization having number of employees in a range
   */
  searchPeople({
    page = 1,
    per_page = 50,
    person_titles,
    q_keywords,
    person_locations,
    person_seniorities,
    contact_email_status,
    q_organization_domains,
    organization_locations,
    organization_num_employees_ranges,
  }) {
    return this.api.post("/searchPeople", {
      page,
      per_page,
      person_titles,
      q_keywords,
      person_locations,
      person_seniorities,
      contact_email_status,
      q_organization_domains,
      organization_locations,
      organization_num_employees_ranges,
    });
  }
  /**
      reveal_personal_emails: true Flag to reveal personal emails	
   */
  matchPeople({
    first_name,
    last_name,
    email,
    organization_name,
    domain,
    id,
    linkedin_url,
    reveal_personal_emails,
  }) {
    return this.api.post("/matchPeople", {
      first_name,
      last_name,
      email,
      organization_name,
      domain,
      id,
      linkedin_url,
      reveal_personal_emails,
    });
  }
  bulkMatchPeople({ details, reveal_personal_emails }) {
    return this.api.post("/bulkMatchPeople", {
      details,
      reveal_personal_emails,
    });
  }

  messageProspect({
    recipientData,
    subject,
    message,
    includeBCC = false,
    isSMS = false,
  }) {
    return this.api.post(`/messageProspect`, {
      baseURL: window.location.origin,
      recipientData,
      subject,
      message,
      includeBCC,
      isSMS,
    });
  }
}

export default new ApolloService(`${getBackendUrl()}/apollo`);
