import {
  VideoCameraIcon,
  VideoCameraSlashIcon,
} from "@heroicons/react/24/outline";
import { Popconfirm, Tooltip } from "antd";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { CiMicrophoneOff, CiMicrophoneOn } from "react-icons/ci";
import { MdCallEnd, MdOutlineCallEnd } from "react-icons/md";
import ATSService from "../service/ATSService";
import PublicService from "../service/PublicService";
import UploadService from "../service/UploadService";

const Camera = ({
  handleStartInterview,
  transcript,
  vapi,
  hero,
  user,
  endinterview,
  setendInterview,
  training,
}) => {
  const videoRef = useRef(null);

  const [mediaRecorder, setMediaRecorder] = useState(null);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [isMicMuted, setIsMicMuted] = useState(false);
  const [isCameraMuted, setIsCameraMuted] = useState(false);
  const [videoUrl, setVideoUrl] = useState(null);
  const [recording, setRecording] = useState(false);

  useEffect(() => {
    if (!endinterview && !isCameraMuted) {
      const startCamera = async () => {
        try {
          const stream = await navigator.mediaDevices.getUserMedia({
            video: true,
          });
          videoRef.current.srcObject = stream;
          videoRef.current.play();
        } catch (error) {
          console.error("Error accessing media devices.", error);
        }
      };

      startCamera();
    }
  }, [endinterview]);

  const handleStartRecording = useCallback(async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
      });
      console.log("stream", stream);
      videoRef.current.srcObject = stream;

      videoRef.current.play();
      console.log("videoRef", videoRef);
      console.log("user", user);
      const recorder = new MediaRecorder(stream);
      recorder.ondataavailable = (event) => {
        console.log("event", event);
        if (event.data.size > 0) {
          setRecordedChunks((prev) => [...prev, event.data]);
        }
      };

      recorder.start();
      setMediaRecorder(recorder);
      setRecording(true);
    } catch (error) {
      console.error("Error accessing media devices.", error);
    }
  }, [videoRef, endinterview]);

  useEffect(() => {
    document
      .getElementsByClassName("transcript-container")?.[0]
      ?.scroll?.({ top: 1000000 * transcript?.length, behavior: "smooth" });
  }, [transcript]);

  // const handleStopRecording = async() => {
  //   if (mediaRecorder) {
  //     console.log("mediarecorder", mediaRecorder)
  //     mediaRecorder.stop();
  //     const tracks = videoRef.current.srcObject.getTracks();
  //     tracks.forEach(track => track.stop());
  //   }
  //   setendInterview(true)
  //   // mediaRecorder.stop();
  //   setRecording(false);
  //   vapi?.current?.stop?.();

  //   console.log("chunk", recordedChunks)
  //   const videoBlob = new Blob(recordedChunks, { type: 'video/webm' });
  //   console.log(videoBlob)

  //   try {
  //     const uploadResult = await UploadService.upload(videoBlob, 600);
  //     console.log('Upload result:', uploadResult);

  //     setVideoUrl(uploadResult.data.secure_url);
  //   } catch (error) {
  //     console.error("Error uploading video to Cloudinary:", error);
  //   }
  // };
  // const handleStopRecording = async () => {
  //   if (mediaRecorder) {
  //     return new Promise((resolve) => {
  //       // Escuchar el evento `onstop` del MediaRecorder
  //       mediaRecorder.onstop = async () => {
  //         const tracks = videoRef.current.srcObject.getTracks();
  //         tracks.forEach(track => track.stop());

  //         setRecording(false);
  //         vapi?.current?.stop?.();

  //         // Espera a que todos los chunks estén disponibles
  //         console.log("Final chunks: ", recordedChunks);

  //         const videoBlob = new Blob(recordedChunks, { type: 'video/webm' });
  //         console.log("videoBlob", videoBlob);

  //         try {
  //           const uploadResult = await UploadService.upload(videoBlob, 600);
  //           console.log('Upload result:', uploadResult);

  //           setVideoUrl(uploadResult.data.secure_url);
  //           resolve(); // Resolviendo la promesa después de la carga
  //         } catch (error) {
  //           console.error("Error uploading video to Cloudinary:", error);
  //           resolve(); // También resolver si hay un error
  //         }
  //       };

  //       // Llama a `mediaRecorder.stop()` y espera a que se detenga
  //       mediaRecorder.stop();
  //     });
  //   }
  // };
  const handleStopRecording = async () => {
    if (training) {
      if (mediaRecorder) {
        mediaRecorder.stop();
        setRecording(false);
      }
      if (videoRef.current) {
        videoRef.current.srcObject.getTracks().forEach((track) => track.stop());
        videoRef.current.srcObject = null;
      }
      vapi?.current?.stop?.();
      setendInterview(true);
    } else {
      if (mediaRecorder) {
        return new Promise((resolve) => {
          const chunks = [];
          mediaRecorder.ondataavailable = (event) => {
            if (event.data.size > 0) {
              chunks.push(event.data);
            }
          };

          mediaRecorder.onstop = async () => {
            const tracks = videoRef.current.srcObject.getTracks();
            tracks.forEach((track) => track.stop());

            videoRef.current.srcObject = null;

            setRecording(false);
            vapi?.current?.stop?.();
            setendInterview(true);

            const videoBlob = new Blob(chunks, { type: "video/webm" });
            console.log("user", user);

            try {
              const uploadResult = await UploadService.upload(videoBlob, 600);
              console.log("Upload result:", uploadResult);

              await PublicService.addVideoInterview(
                user._id,
                uploadResult.data.secure_url
              );

              console.log("user", user);
              resolve();
            } catch (error) {
              console.error("Error uploading video to Cloudinary:", error);
              resolve();
            }
          };

          mediaRecorder.stop();
        });
      }
    }
  };

  // const handleStopRecording = async () => {
  //   if (mediaRecorder) {
  //     mediaRecorder.requestData(); // Asegura que todos los datos se recopilen antes de detener.
  //     mediaRecorder.stop();

  //     const tracks = videoRef.current.srcObject.getTracks();
  //     tracks.forEach(track => track.stop());
  //   }
  //   vapi?.current?.stop?.();
  //   setendInterview(true);
  //   setRecording(false);

  //   console.log(recordedChunks)
  //   const videoBlob = new Blob(recordedChunks, { type: 'video/webm' });
  //   console.log(videoBlob);

  //   try {
  //     const uploadResult = await UploadService.upload(videoBlob, 600);
  //     console.log('Upload result:', uploadResult);

  //     setVideoUrl(uploadResult.data.secure_url);
  //   } catch (error) {
  //     console.error("Error uploading video to Cloudinary:", error);
  //   }
  // };

  // const handleFullScreen = () => {
  //   if (videoRef.current.requestFullscreen) {
  //     videoRef.current.requestFullscreen();
  //   } else if (videoRef.current.mozRequestFullScreen) {
  //     /* Firefox */
  //     videoRef.current.mozRequestFullScreen();
  //   } else if (videoRef.current.webkitRequestFullscreen) {
  //     /* Chrome, Safari & Opera */
  //     videoRef.current.webkitRequestFullscreen();
  //   } else if (videoRef.current.msRequestFullscreen) {
  //     /* IE/Edge */
  //     videoRef.current.msRequestFullscreen();
  //   }
  // };

  const toggleMuteMic = useCallback(() => {
    setIsMicMuted((e) => {
      return !e;
    });
    vapi?.current?.setMuted?.(!isMicMuted);
  }, [vapi, isMicMuted]);

  const toggleMuteCamera = () => {
    const stream = videoRef.current.srcObject;
    const videoTrack = stream.getVideoTracks()[0];
    videoTrack.enabled = !videoTrack.enabled;
    setIsCameraMuted(!videoTrack.enabled);
  };

  return (
    <div className="h-screen flex flex-col">
      {/* {!recording && (
        <div className={``}>
          <div className="mb-[55px] mdx:mb-1  flex flex-1 flex-col items-start gap-3  mdx:self-stretch smx:pt-0 pt-12  relative cursor-pointer">
            <div className="flex w-[67%]  mx-auto justify-center rounded-[24px]  mdx:w-full smx:p-1 ">
              <div className=" relative h-[320px] mdx:h-[125px] w-full rounded-lg  flex items-center justify-center p-1  mdx:h-auto">
                <img
                  onClick={() => {
                    handleStartInterview();
                    handleStartRecording();
                  }}
                  src="/dhimages/playbtn.png"
                  alt="screenmockup"
                />
              </div>
            </div>

  
        </div>
        </div>
      )} */}

      <div
        className="flex-1 flex flex-col"
        // style={
        //   recording
        //     ? {}
        //     : {
        //         opacity: 0,
        //       }
        // }
      >
        <div className="flex justify-between">
          <div className="video-container">
            <div className="controls"></div>

            <video ref={videoRef} className="video-element" />

            {!recording && (
              <div className="absolute z-[5] inset-0 flex justify-center items-center">
                <Tooltip
                  className="z-[5]"
                  title="Click the microphone to start the interview"
                >
                  <button
                    onClick={() => {
                      handleStartInterview();
                      handleStartRecording();
                    }}
                  >
                    <img src="/images/Microphone.png" width={120} />
                  </button>
                </Tooltip>
              </div>
            )}

            <div className="absolute z-[5] right-2 top-2 md:bottom-2 md:top-auto">
              <div>
                <img
                  src={hero?.aiImage ?? "/images/hirehero_f.png"}
                  height={120}
                  width={120}
                  className="z-[5] rounded-full "
                />
              </div>
              <div className="relative -mt-2 z-[6] text-white font-bold w-full text-center bg-[#0538FFCC] opacity-80% rounded-md">
                {hero?.name ?? "Taylor"}
              </div>
            </div>

            <div className="absolute z-[5] left-4 top-4 ">
              <div className="relative text-white font-bold w-full text-center bg-[#0538FFCC] opacity-80% rounded-md p-1">
                {user?.formData.firstname ?? "Applicant"}{" "}
                {user?.formData.lastname ?? ""}
              </div>
            </div>

            <div className="absolute left-0 right-0 bottom-0 z-[5] h-[70px] p-2 flex justify-center gap-5 backdrop-blur-md md:backdrop-blur-none">
              <button onClick={toggleMuteCamera}>
                {isCameraMuted ? (
                  <div className="rounded-full p-3 bg-[#000000] opacity-50 ">
                    <VideoCameraSlashIcon color="white" width={30} />
                  </div>
                ) : (
                  <div className="rounded-full p-3 bg-[#000000] opacity-50">
                    <VideoCameraIcon color="white" width={30} />
                  </div>
                )}
              </button>
              {/*<button onClick={handleFullScreen}>
                <RiFullscreenFill color="white" size={30} />
              </button> */}
              <Popconfirm
                title="Are you sure you want to stop the interview?"
                onConfirm={handleStopRecording}
                okText="Yes"
                cancelText="No"
              >
                <button>
                  <div className="rounded-full p-3 bg-[#D92D20]">
                    <MdOutlineCallEnd size={30} className="text-white" />
                  </div>
                </button>
              </Popconfirm>
              <button onClick={toggleMuteMic}>
                {isMicMuted ? (
                  <div className="rounded-full p-3 bg-[#000000] opacity-50">
                    <CiMicrophoneOff color="white" size={30} />
                  </div>
                ) : (
                  <div className="rounded-full p-3 bg-[#000000] opacity-50">
                    <CiMicrophoneOn color="white" size={30} />
                  </div>
                )}
              </button>
            </div>
          </div>

          <div
            className="transcript-container hidden md:block"
            style={{ maxWidth: 450 }}
          >
            <h2 className="font-bold mb-4">Transcript</h2>

            <div className="transcript">
              {transcript.map((message, index) => (
                <div key={index} className={`message ${message.role} mt-3`}>
                  <span
                    className={`capitalize mt-2 flex text-sm ${
                      message.role == "assistant"
                        ? "text-[#317FD4]"
                        : "text-[#344054]"
                    }`}
                  >
                    {message.role == "assistant" && (
                      <img
                        src={hero?.aiImage ?? "/images/hirehero_f.png"}
                        height={30}
                        width={30}
                        className="rounded-full mr-2"
                      />
                    )}
                    {message.role == "assistant"
                      ? hero?.name
                      : user?.formData.firstname ?? "Applicant"}
                  </span>{" "}
                  <div
                    className={`flex rounded-md  px-3 py-1 text-sm ${
                      message.role === "assistant"
                        ? "bg-[#F2F4F7] -mt-2 ml-9 text-left self-start rounded-tl-none max-w-[280px]"
                        : " max-w-[320px] bg-[#317FD4] text-white text-right self-end rounded-tr-none"
                    }`}
                  >
                    {message.text}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Camera;
