import { Button, List, Modal, Popconfirm, Tabs, Upload, message } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { IoDocumentOutline, IoVideocamOutline } from "react-icons/io5";
import { useSelector } from "react-redux";
import { login } from "../../../redux/auth/actions";
import { selectLoading, selectUser } from "../../../redux/auth/selectors";
import { store } from "../../../redux/store";
import AuthService from "../../../service/AuthService";
import CrudService from "../../../service/CrudService";
import { Text } from "./components";

export default function KnowledgeBase() {
  const [modal, setModal] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const user = useSelector(selectUser);
  const loadingB = useSelector(selectLoading);

  const refresh = useCallback(async () => {
    if (!user) return;

    setLoading(true);
    try {
      const filesResponse = await CrudService.search("File", 10000, 1, {
        filters: { user_id: user._id },
      });
      console.log(filesResponse.data.items);
      setFileList(filesResponse.data.items);
    } catch (error) {
      console.error("Error fetching data:", error);
      message.error("Failed to fetch data");
    }
    setLoading(false);
  }, [user]);

  useEffect(() => {
    refresh();
  }, [refresh]);

  const handleOk = async () => {
    try {
      refresh();
      setModal(null);
    } catch (error) {
      console.error("Error updating knowledge base:", error);
      message.error("Failed to update knowledge base");
    }
  };

  const handleUpload = useCallback(
    async (file) => {
      setLoading(true);
      try {
        const form = new FormData();
        form.append("file", file);

        const options = {
          method: "POST",
          headers: {
            Authorization: "Bearer 5d4a1b12-6b73-41e5-b915-7986538959ac",
          },
          body: form,
        };

        const response = await fetch("https://api.vapi.ai/file", options);
        const result = await response.json();
        console.log(result);
        console.log(modal);
        if (result.message) return message.error(result.message);

        await CrudService.create("File", {
          user_id: user._id,
          type: modal?.replace?.("s", ""),
          originalName: result.name,
          vapiId: result.id,
          bytes: result.bytes,
          mimetype: result.mimetype,
        });
        setModal(null);
        message.success("File uploaded successfully");
        refresh();

        if (!user.onboarded) {
          Promise.all([
            CrudService.search("Hero", 4, 1, {
              filters: { user_id: user._id },
            }),
            CrudService.search("Vacancy", 2, 1, {
              filters: { user_id: user._id },
            }),
          ]).then(([heroesRes, vacanciesRes]) => {
            const campaigns = vacanciesRes.data.items;
            const heroes = heroesRes.data.items;

            if (campaigns.length > 0 && heroes.length > 0) {
              AuthService.setOnboarded().then(async () => {
                const me = await AuthService.me();
                store.dispatch(login(me.data.me));
                document.dispatchEvent(new CustomEvent("BOOK.MEETING"));
              });
            }
          });
        }
      } catch (error) {
        console.error("Error uploading file:", error);
        message.error("Failed to upload file");
      } finally {
        setLoading(false);
      }
    },
    [modal, user]
  );

  const handleDelete = async (file) => {
    setLoading(true);
    try {
      await CrudService.delete("File", file._id);

      const options = {
        method: "DELETE",
        headers: {
          Authorization: "Bearer 5d4a1b12-6b73-41e5-b915-7986538959ac",
        },
      };
      await fetch("https://api.vapi.ai/file/" + file.vapiId, options);

      message.success("File deleted successfully");
      refresh();
    } catch (error) {
      console.error("Error deleting file:", error);
      message.error("Failed to delete file");
    } finally {
      setLoading(false);
    }
  };

  const knowledgeItems = [
    // {
    //   key: "websites",
    //   icon: <CgWebsite className="text-2xl" />,
    //   title: "Add URLs",
    //   description: "Add various URLs to train the knowledge base with the data",
    // },
    // {
    //   key: "socials",
    //   icon: <IoShareSocialOutline className="text-2xl" />,
    //   title: "Add social media profiles",
    //   description:
    //     "Add social media links to train the knowledge base with the data",
    // },
    // {
    //   key: "videos",
    //   icon: <IoVideocamOutline className="text-2xl" />,
    //   title: "Add videos",
    //   description: "Upload videos to train the knowledge base with the data",
    // },
    {
      key: "documents",
      icon: <IoDocumentOutline className="text-2xl" />,
      title: "Add documents",
      description: "Upload documents to train the knowledge base with the data",
    },
  ];

  const renderFileList = (fileType) => (
    <List
      loading={loading}
      dataSource={fileList.filter((file) => file.type.startsWith(fileType))}
      renderItem={(file) => (
        <List.Item
          actions={[
            <Popconfirm
              onConfirm={() => handleDelete(file)}
              title="Are you sure?"
            >
              <Button key="delete" loading={loadingB} danger>
                Delete
              </Button>
            </Popconfirm>,
          ]}
        >
          <List.Item.Meta
            title={file.originalName}
            description={`Size: ${(file.bytes / 1024).toFixed(2)} KB | Type: ${
              file.mimetype
            }`}
          />
        </List.Item>
      )}
    />
  );

  return (
    <div className="flex flex-col min-h-screen bg-gray-50">
      <main className="flex-grow p-6 sm:p-10">
        <div className="max-w-4xl mx-auto">
          <h1 className="text-3xl font-bold text-gray-900 mb-4">
            Knowledge Base
          </h1>
          <Text as="p" className="text-lg text-gray-600 mb-8">
            Let HireHeroes learn about your company. Add websites or upload
            valuable information to let us train your agent to become the
            recruiter you need it to be.
          </Text>

          <div className="grid md:grid-cols-3 gap-10 grid-col-1">
            {knowledgeItems.map((item) => (
              <div
                key={item.key}
                className="cursor-pointer rounded-lg p-2 bg-white border border-1 border-[#D0D5DD]"
                onClick={() => setModal(item.key)}
              >
                <div className="p-1 rounded-lg border border-1 border-[#D0D5DD] inline-block">
                  {item.icon}
                </div>
                <h6 className="text-md font-semibold py-2">{item.title}</h6>
                <p className="pb-2">{item.description}</p>
              </div>
            ))}
          </div>

          <div className="bg-white rounded-lg shadow-md p-6 mt-10">
            <h2 className="text-2xl font-semibold mb-4">Uploaded Files</h2>
            <Tabs defaultActiveKey="documents">
              <Tabs.TabPane tab="Documents" key="documents">
                {renderFileList("document")}
              </Tabs.TabPane>
            </Tabs>
          </div>
        </div>
      </main>

      <Modal
        open={!!modal}
        onCancel={() => setModal(null)}
        onOk={handleOk}
        okText="Submit"
        destroyOnClose
        title={`Add ${modal}`}
        footer={[]}
      >
        {modal === "documents" && (
          <Upload
            beforeUpload={(file) => {
              handleUpload(file);
              return false;
            }}
            accept="text/markdown,application/pdf,text/plain,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
          >
            <Button type="primary" size="middle" loading={loading || loadingB}>
              Click to Upload
            </Button>
          </Upload>
        )}
        {modal === "videos" && (
          <Upload
            accept="video/*"
            beforeUpload={(file) => {
              if (file.type.startsWith("video/")) {
                handleUpload(file);
                return false;
              }
              message.error("You can only upload video files!");
              return Upload.LIST_IGNORE;
            }}
          >
            <Button type="primary" size="middle" loading={loading || loadingB}>
              Click to Upload Video
            </Button>
          </Upload>
        )}
      </Modal>
    </div>
  );
}
