import Cookies from "js-cookie";
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { login } from "../../redux/auth/actions";
import { store } from "../../redux/store";
import AuthService from "../../service/AuthService";
import { Button } from "./components/Button";
import { CheckBox } from "./components/CheckBox";
import { Heading } from "./components/Heading";
import { Img } from "./components/Img";
import { Text } from "./components/Text";

import { useSelector } from "react-redux";
import { getPartner, selectLoading } from "../../redux/auth/selectors";
import ShowPassword from "../Dashboard/PartnerSettings/ShowPassword";

const Login = () => {
  const partner = useSelector(getPartner);
  const navigate = useNavigate();
  const loading = useSelector(selectLoading);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const result = await AuthService.login({
      email: e.target[0].value,
      password: e.target[1].value,
    });
    if (!result?.data?.accessToken) return;

    Cookies.set("accessToken", result?.data?.accessToken);
    Cookies.set("refreshToken", result?.data?.refreshToken);

    const me = await AuthService.me();
    if (!me?.data) return message.error("Could not load user data");

    store.dispatch(login(me.data));

    navigate("/dashboard/home");
  };

  return (
    <>
      <div className=" bg-[#f8f8f8] min-h-[100vh] min-w-[100vw] overflow-hidden ">
        <div className="flex flex-col flex-1 md:flex-row w-[100vw]">
          <div className="  w-[50vw] flex  flex-col items-start hidden md:block h-[100vh]">
            <div className=" flex  flex-col items-center justify-between gap-10 rounded-br-[12px] rounded-tr-[12px] border border-solid border-[#eaecf0] bg-[#ffffff] md:w-full h-full my-[12px]">
              <div className="ml-[60px] mr-[74px] mt-36 flex flex-col gap-3 self-stretch md:mx-0">
                <div className="flex">
                  <Heading
                    size="headingxl"
                    as="h1"
                    className="mx-8 text-3xl font-semibold tracking-[-0.60px] text-[#0f1728] md:text-[28px] sm:text-[26px]"
                  >
                    HireHeroes
                  </Heading>
                </div>
                <Heading
                  as="h2"
                  className=" mx-8 text-base font-normal leading-[22px] text-[#475467] "
                >
                  Conversational voice driven ai recruitment assistants.
                  Interviews applicants through live interview calls, takes care
                  of all admin tasks, have the AI evaluate and shortlist the top
                  pools.
                </Heading>
              </div>
              <div className="  w-[90%] content-center self-end rounded-[10px] md:h-auto">
                <Img
                  src="/images/ScreenMockup.png"
                  alt="Shadow Image"
                  className=" w-full  flex-1 object-cover"
                />
              </div>
            </div>
          </div>
          <div className="mt-8 flex flex-col md:w-[50vw] w-full items-center gap-[30px] px-5 md:px-5">
            <div className="flex flex-col justify-center gap-3 px-5 md:px-5">
              <Img
                src="/logo.svg"
                alt="Logo Icon"
                className="mx-auto h-[48px] w-[48px] rounded-[10px] "
              />
              <a href="#" className="md:text-[28px] sm:text-[26px]">
                <Heading
                  size="headingxl"
                  as="h3"
                  className="text-3xl text-center font-semibold text-[#101828]"
                >
                  Log In
                </Heading>
              </a>
              <Heading
                as="h4"
                className="text-[16px] font-base text-[#475467] text-center"
              >
                Welcome back! Please enter your details.
              </Heading>
            </div>
            <form
              onSubmit={handleSubmit}
              className="flex w-full md:w-[80%] flex-col justify-center gap-6 "
            >
              <div className="flex flex-col gap-6 justify-center ">
                <div className="flex flex-col justify-center gap-5">
                  <div className="flex flex-col items-center justify-center gap-1.5 mx-5">
                    <Heading
                      size="texts"
                      as="h5"
                      className="w-full self-start text-sm font-medium text-[#344054]"
                    >
                      Email*
                    </Heading>
                    <input
                      shape="round"
                      type="email"
                      name="Email"
                      label="Email address"
                      required
                      placeholder={`Enter your email`}
                      className="w-full rounded-lg border border-solid border-[#cfd4dc] px-3.5 py-2.5 text-base font-normal"
                    />
                  </div>
                  <div className="flex flex-col items-center justify-center gap-1.5 mx-5">
                    <Heading
                      size="texts"
                      as="h6"
                      className="w-full self-start text-sm font-medium text-[#344054]"
                    >
                      Password*
                    </Heading>
                    <ShowPassword
                      shape="round"
                      name="Password"
                      label="Password"
                      required
                      placeholder={`Enter your password`}
                      className="w-full rounded-lg border border-solid border-[#cfd4dc] px-3.5 py-2.5 text-base font-normal"
                    />
                  </div>
                </div>
                <div className="flex justify-between mx-5 flex-col md:flex-row gap-3">
                  <CheckBox
                    name="Remember Checkbox"
                    label="Remember for 30 days"
                    id="RememberCheckbox"
                    className="gap-2 text-sm font-medium text-[#344054]"
                  />
                  <div className="flex">
                    <a
                      href="/auth/forgot"
                      className="bg-gradient-to-r from-[#0538ff] to-[#6b56f4] bg-clip-text"
                    >
                      <Heading
                        size="headings"
                        as="p"
                        className="text-sm font-medium text-transparent"
                      >
                        Forgot password
                      </Heading>
                    </a>
                  </div>
                </div>
              </div>
              <Button
                className=" text-base justify-center continuebutton_border lg:min-w-[400px] rounded-lg bg-gradient-to-r from-[#0538ff] to-[#6b56f4] px-[33px] font-semibold text-[#ffffff] shadow-[0_1px_2px_0_#1018280c] sm:px-5 mx-5"
                size="2xl"
                shape="round"
                variant={null}
                type="submit"
              >
                Log In
              </Button>
            </form>
            <div className="flex items-center justify-center px-14 md:px-5 ">
              <a href="#">
                <Text as="p" className="text-sm font-normal text-[#667084]">
                  Don’t have an account?&nbsp;
                </Text>
              </a>
              <div className="flex">
                <a
                  href="/auth/register"
                  className="bg-gradient-to-r from-[#0538ff] to-[#6b56f4] bg-clip-text"
                >
                  <Heading
                    size="headings"
                    as="p"
                    className="text-[14px] font-semibold text-transparent"
                  >
                    Sign up
                  </Heading>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
