import React, { useState } from "react";

const ShowPassword = (props) => {
  const [passwordShow, setPasswordShow] = useState(false);
  return (
    <>
      <div className="relative w-full">
        <input type={passwordShow ? "text" : "password"} {...props} />
        <img
          src="/images/img_eye.svg"
          width={18}
          height={14}
          alt={"Eye"}
          onClick={() => setPasswordShow((x) => !x)}
          className="h-[14px] w-[18px] cursor-pointer absolute top-[15px] right-[15px]"
        />
      </div>
    </>
  );
};

export default ShowPassword;
