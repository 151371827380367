import {
  ChartBarIcon,
  ChartPieIcon,
  Cog8ToothIcon,
  HomeIcon,
  LifebuoyIcon,
  RectangleGroupIcon,
  SwatchIcon,
  UsersIcon,
} from "@heroicons/react/24/outline";
import { Badge, Modal, Popconfirm, Skeleton, message } from "antd";
import Color from "color";
import Cookies from "js-cookie";
import { useCallback, useEffect, useState } from "react";
import { BsFillSearchHeartFill } from "react-icons/bs";
import { FaMask } from "react-icons/fa";
import { GiPortal } from "react-icons/gi";
import { LiaMaskSolid } from "react-icons/lia";
import { MdDelete } from "react-icons/md";
import { RiTeamLine } from "react-icons/ri";
import { SiCrowdsource, SiKnowledgebase } from "react-icons/si";
import { useSelector } from "react-redux";
import {
  Link,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { DarkModeSwitch } from "react-toggle-dark-mode";
import { ReactComponent as HeroMaskIcon } from "../../assets/HeroMaskIcon.svg";
import { ZOOM_ENABLED, brandColor } from "../../data/constants";
import { logout, setDarkMode, setPartner } from "../../redux/auth/actions";
import {
  getPartner,
  selectDarkMode,
  selectLoading,
  selectUser,
} from "../../redux/auth/selectors";
import { store } from "../../redux/store";
import AuthService from "../../service/AuthService";
import CalendlyService from "../../service/CalendlyService";
import ChatService from "../../service/ChatService";
import CrudService from "../../service/CrudService";
import PartnerService from "../../service/PartnerService";
import PublicService from "../../service/PublicService";
import UserService from "../../service/UserService";
import AgencyOnboarding from "./AgencyOnboarding";
import Billing from "./Billing";
import CalendlyEventSelector from "./CalendlyEventSelector";
import CandidateSourcing from "./CandidateSourcing";
import DemoCard from "./DemoCard";
import { Footer } from "./Footer";
import GrowthAccelerator from "./GrowthAccelerator";
import KnowledgeBase from "./KnowledgeBase";
import LeadAutomations from "./LeadAutomations";
import MyInterviews from "./MyInterviews";
import PartnerActivation from "./PartnerActivation";
import PartnerSettings from "./PartnerSettings";
import PartnerStats from "./PartnerStats";
import PartnerUsers from "./PartnerUsers";
import PhoneWidget from "./PhoneWidget";
import RCCredits from "./RCCredits";
import RecruiterTeamMembers from "./RecruiterTeamMembers";
import SearchCandidates from "./SearchCandidates";
import Settings, { JOB_PORTAL_ACTIVATED } from "./Settings";
import StatsDashboard from "./StatsDashboard";
import Support from "./Support";
import SupportTickets from "./SupportTickets";
import ThemeFive from "./ThemeFive";
import ThemeOne from "./ThemeOne";
import ThemeTwo from "./ThemeTwo";
import Tutorials from "./Tutorials";
import UserSettings from "./UserSettings";
import Warnings from "./Warnings";
import Legal from "./legal";
import ATS from "./vacancies/ATS";
import CreateVacancy from "./vacancies/CreateVacancy";
import HeroEdit from "./vacancies/HeroEdit";
import MyHeroes from "./vacancies/MyHeroes";
import MyVacancies from "./vacancies/MyVacancies";
import VacancyDetails from "./vacancies/VacancyDetails";
import VacancyEdit from "./vacancies/VacancyEdit";
import VacancyEditForm from "./vacancies/VacancyEditForm";
import VacancyForm from "./vacancies/VacancyForm";
import VacancyPrepublish from "./vacancies/VacancyPrepublish";
import VacancyPublish from "./vacancies/VacancyPublish";
import VacancyStats from "./vacancies/VacancyStats";

const getItemCurrent = (location, elem) => {
  if (elem.href.includes("/dashboard/heroes")) {
    if (location.pathname.includes("/dashboard/heroedit")) return true;
  }
  if (elem.href.includes("/dashboard/vacancy")) {
    if (location.pathname.includes("/dashboard/vacancydetails")) return true;
    if (location.pathname.includes("/dashboard/vacancyedit")) return true;
  }
  return location.pathname === elem.href;
};

export const THEME_OPTIONS = [
  { value: 1, label: "Default" },
  { value: 2, label: "Minimalistic" },
  // { value: 5, label: "Stacked" },
];

export const changeIndigoShades = (newShades) => {
  Object.keys(newShades).forEach((shade) => {
    document.documentElement.style.setProperty(
      `--indigo-${shade}`,
      newShades[shade]
    );
    document.documentElement.style.setProperty(
      `--blue-${shade}`,
      newShades[shade]
    );
  });
};

export const generateTailwindPalette = (baseColor) => {
  const base = Color(baseColor);
  let palette = {};

  // Generate lighter shades for 50 to 400
  for (let i = 50; i <= 400; i += 50) {
    palette[i] = base.lighten((4 - i / 100) * 0.2).hex();
  }

  // Base color for 500
  palette[500] = baseColor;

  // Generate darker shades for 600 to 950
  for (let i = 600; i <= 950; i += 50) {
    palette[i] = base.darken((i - 500) / 1000).hex();
  }

  return palette;
};

const Dashboard = () => {
  const partner = useSelector(getPartner);
  const [theme, setTheme] = useState(null);
  const [me, setMe] = useState(null);
  const [featureRequest, setFeatureRequest] = useState(false);
  const [bugReport, setBugReport] = useState(false);
  const [numberNewAccelerator, setNumberNewAccelerator] = useState(null);
  const [bookMeetingModal, setBookMeetingModal] = useState(false);
  const [numberTickets, setNumberTickets] = useState(null);
  const [needsToSelectCalendlyType, setNeedsToSelectCalendlyType] =
    useState(false);
  const [subMenus, setSubMenus] = useState([]);
  const location = useLocation();
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const darkMode = useSelector(selectDarkMode);
  const loading = useSelector(selectLoading);

  const Theme = useCallback(
    (props) => {
      if (theme === 1) return <ThemeOne {...props} />;
      if (theme === 2) return <ThemeTwo {...props} />;
      if (theme === 5) return <ThemeFive {...props} />;
    },
    [theme]
  );

  const handleRefresh = useCallback(async () => {
    const res = await PublicService.getPartnerConfig();
    store.dispatch(setPartner(res.data.partner));
  }, []);
  useEffect(() => {
    document.addEventListener("REFRESH.PROFILE", handleRefresh);
    return () => document.removeEventListener("REFRESH.PROFILE", handleRefresh);
  }, [handleRefresh]);
  useEffect(() => {
    if (!partner) return;
    changeIndigoShades(
      generateTailwindPalette(partner?.themeColor ?? brandColor)
    );
    setTheme(partner.theme);
  }, [partner]);

  useEffect(() => {
    if (numberNewAccelerator === null && user?.role === "partner") {
      PartnerService.getNumberOfNew().then(({ data }) => {
        setNumberNewAccelerator(data.totalNew);
      });
    }
  }, [user]);

  useEffect(() => {
    const refresh = () =>
      ChatService.getNumberTickets().then(({ data }) =>
        setNumberTickets(data.numberTickets)
      );
    refresh();
    setBookMeetingModal(false);

    document.addEventListener("REFRESH.TICKETNUM", refresh);
    return () => document.removeEventListener("REFRESH.TICKETNUM", refresh);
  }, [location]);

  useEffect(() => {
    const checkUser = async () => {
      if (!Cookies.get("accessToken")) {
        localStorage.lastVisit = window.location.href;
        return navigate("/auth/login");
      }
      const res = await AuthService.me();
      setMe(res.data.me);

      const onboardingStatus = res.data.onboardingStatus;

      if (onboardingStatus.actionRequired) {
        // User is not onboarded

        if (
          onboardingStatus.step === "isEmailVerified" &&
          !location.pathname.includes("/auth/otpemail")
        )
          navigate("/auth/otpemail");
        if (
          onboardingStatus.step === "isPartnerOnboarded" &&
          !location.pathname.includes("/auth/partneronboarding")
        )
          navigate("/auth/partneronboarding");
        if (
          onboardingStatus.step === "isPartnerActivated" &&
          !location.pathname.includes("/dashboard/partnerActivation") &&
          !location.pathname.includes("dashboard/partnerSettings")
        )
          navigate("/dashboard/partnerActivation");
        if (
          onboardingStatus.step === "subscription" &&
          !location.pathname.includes("/auth/subscription")
        )
          navigate("/auth/subscription");
        if (
          onboardingStatus.step === "isPhoneVerified" &&
          !location.pathname.includes("/auth/otpphone")
        )
          navigate("/auth/otpphone");
        if (
          onboardingStatus.step === "kycVerified" &&
          !location.pathname.includes("/auth/kyc")
        )
          navigate("/auth/kyc");

        if (
          onboardingStatus.step === "profileCompletion" &&
          !location.pathname.includes("/dashboard/settings")
        ) {
          message.info("Please complete your profile");
          navigate("/dashboard/settings");
        }
        if (
          onboardingStatus.step === "userOnboarding" &&
          !location.pathname.includes("/auth/userOnboarding")
        ) {
          navigate("/auth/userOnboarding");
        }
        if (
          onboardingStatus.step === "onboardAnimation" &&
          !location.pathname.includes("/auth/onboarding")
        ) {
          navigate("/auth/onboarding");
        }
        if (
          onboardingStatus.step === "connectCalendly" &&
          !location.pathname.includes("/auth/connectCalendly")
        ) {
          navigate("/auth/connectCalendly");
        }
        if (
          onboardingStatus.step === "partnerCompletion" &&
          !location.pathname.includes("/dashboard/partnerSettings")
        ) {
          message.info("Please setup your SaaS");
          navigate("/dashboard/partnerSettings");
        }
      } else if (localStorage.lastVisit) {
        if (window.location.href !== localStorage.lastVisit)
          window.location.href = localStorage.lastVisit;
        localStorage.removeItem("lastVisit");
      } else if (localStorage.demoUUID) {
        if (window.location.pathname !== "/dashboard/demoCard")
          window.location.href = "/dashboard/demoCard";
      }
    };
    checkUser();
  }, [location, navigate]);

  const checkCalendlyEventTypes = useCallback(async () => {
    if (!partner?.calendlyclientId) return;
    CalendlyService.getNeedsToSelectEventType().then(({ data }) => {
      if (data.needsToSelectEventType) setNeedsToSelectCalendlyType(true);
    });
  }, [partner]);

  useEffect(() => {
    checkCalendlyEventTypes();

    document.addEventListener(
      "CHECK.CALENDLY.EVENT.TYPES",
      checkCalendlyEventTypes
    );
    return () =>
      document.removeEventListener(
        "CHECK.CALENDLY.EVENT.TYPES",
        checkCalendlyEventTypes
      );
  }, []);

  const bookMeetingSuggestion = () => {
    setBookMeetingModal(true);
    const script = document.createElement("script");
    script.src = "https://assets.calendly.com/assets/external/widget.js";
    script.async = true;
    document.body.appendChild(script);
  };
  useEffect(() => {
    document.addEventListener("BOOK.MEETING", bookMeetingSuggestion);
    return () =>
      document.removeEventListener("BOOK.MEETING", bookMeetingSuggestion);
  }, [bookMeetingSuggestion]);

  const navigation = [
    {
      name: "Dashboard",
      component: <StatsDashboard />,
      href: "/dashboard/home",
      icon: ChartBarIcon,
    },
    {
      name: "My Heroes",
      component: <MyHeroes />,
      href: "/dashboard/heroes",
      // icon: () => <LiaMaskSolid className="w-6 h-6" />,
      icon: () => <HeroMaskIcon className="w-6 h-6" />,
    },
    {
      name: "My Campaigns",
      component: <MyVacancies />,
      href: "/dashboard/vacancy",
      icon: RectangleGroupIcon,
    },
    // {
    //   name: "ATS",
    //   component: <ATS/>,
    //   href: "/dashboard/ats",
    //   icon: UsersIcon,
    // },
    {
      name: "My Interviews",
      component: <MyInterviews />,
      href: "/dashboard/myinterviews",
      icon: () => (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="m15.75 10.5 4.72-4.72a.75.75 0 0 1 1.28.53v11.38a.75.75 0 0 1-1.28.53l-4.72-4.72M4.5 18.75h9a2.25 2.25 0 0 0 2.25-2.25v-9a2.25 2.25 0 0 0-2.25-2.25h-9A2.25 2.25 0 0 0 2.25 7.5v9a2.25 2.25 0 0 0 2.25 2.25Z"
          />
        </svg>
      ),
      hide: !ZOOM_ENABLED || !partner?.zoomClientId,
    },

    // {
    //   name: "Search Candidates",
    //   href: "/dashboard/searchCandidates",
    //   component: <SearchCandidates />,
    //   icon: BsFillSearchHeartFill,
    // },
    // {
    //   name: "Team",
    //   href: "/dashboard/teamMembers",
    //   component: <RecruiterTeamMembers />,
    //   icon: RiTeamLine,
    //   hide: !["admin", "partner", "recruiter"].includes(me?.role),
    // },
    {
      name: "Knowledge Base",
      href: "/dashboard/knowledge",
      component: <KnowledgeBase />,
      icon: SwatchIcon,
    },

    {
      name: "Job Portal",
      href: `/jobportal/${user?._id ?? ""}`,
      target: "_blank",
      icon: GiPortal,
      hide: !user?._id || !JOB_PORTAL_ACTIVATED,
    },
    // {
    //   name: "Candidate Sourcing",
    //   href: `/dashboard/sourcing`,
    //   icon: SiCrowdsource,
    //   component: <CandidateSourcing />,
    // },
    {
      name: "Lead Automations",
      href: `/dashboard/leadAutomations`,
      icon: SiCrowdsource,
      component: <LeadAutomations />,
      hide: !user?.leadModuleAccess,
    },

    {
      name: "SaaS Partners",
      component: <PartnerUsers />,
      href: "/dashboard/adminPartners",
      icon: () => (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          className="w-6 h-6"
        >
          <path
            fillRule="evenodd"
            d="M7.5 5.25a3 3 0 0 1 3-3h3a3 3 0 0 1 3 3v.205c.933.085 1.857.197 2.774.334 1.454.218 2.476 1.483 2.476 2.917v3.033c0 1.211-.734 2.352-1.936 2.752A24.726 24.726 0 0 1 12 15.75c-2.73 0-5.357-.442-7.814-1.259-1.202-.4-1.936-1.541-1.936-2.752V8.706c0-1.434 1.022-2.7 2.476-2.917A48.814 48.814 0 0 1 7.5 5.455V5.25Zm7.5 0v.09a49.488 49.488 0 0 0-6 0v-.09a1.5 1.5 0 0 1 1.5-1.5h3a1.5 1.5 0 0 1 1.5 1.5Zm-3 8.25a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Z"
            clipRule="evenodd"
          />
          <path d="M3 18.4v-2.796a4.3 4.3 0 0 0 .713.31A26.226 26.226 0 0 0 12 17.25c2.892 0 5.68-.468 8.287-1.335.252-.084.49-.189.713-.311V18.4c0 1.452-1.047 2.728-2.523 2.923-2.12.282-4.282.427-6.477.427a49.19 49.19 0 0 1-6.477-.427C4.047 21.128 3 19.852 3 18.4Z" />
        </svg>
      ),
      hide: me?.role !== "admin",
    },
    {
      name: "SaaS Users",
      component: <PartnerUsers />,
      href: "/dashboard/adminUsers",
      icon: UsersIcon,
      hide: me?.role !== "admin",
    },

    {
      name: "My Users",
      href: "/dashboard/partnerUsers",
      component: <PartnerUsers />,
      icon: UsersIcon,
      hide: me?.role !== "partner",
    },
    {
      name: "My Stats",
      href: "/dashboard/partnerStats",
      component: <PartnerStats />,
      icon: ChartPieIcon,
      hide: me?.role !== "partner",
    },

    {
      name: (
        <>
          Support Tickets
          <Badge count={numberTickets} offset={[0, 0]}></Badge>
        </>
      ),
      component: <SupportTickets />,
      href: "/dashboard/tickets",
      icon: () => (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          fill="currentColor"
          className="w-6 h-6"
        >
          <path
            fillRule="evenodd"
            d="M19.449 8.448 16.388 11a4.52 4.52 0 0 1 0 2.002l3.061 2.55a8.275 8.275 0 0 0 0-7.103ZM15.552 19.45 13 16.388a4.52 4.52 0 0 1-2.002 0l-2.55 3.061a8.275 8.275 0 0 0 7.103 0ZM4.55 15.552 7.612 13a4.52 4.52 0 0 1 0-2.002L4.551 8.45a8.275 8.275 0 0 0 0 7.103ZM8.448 4.55 11 7.612a4.52 4.52 0 0 1 2.002 0l2.55-3.061a8.275 8.275 0 0 0-7.103 0Zm8.657-.86a9.776 9.776 0 0 1 1.79 1.415 9.776 9.776 0 0 1 1.414 1.788 9.764 9.764 0 0 1 0 10.211 9.777 9.777 0 0 1-1.415 1.79 9.777 9.777 0 0 1-1.788 1.414 9.764 9.764 0 0 1-10.212 0 9.776 9.776 0 0 1-1.788-1.415 9.776 9.776 0 0 1-1.415-1.788 9.764 9.764 0 0 1 0-10.212 9.774 9.774 0 0 1 1.415-1.788A9.774 9.774 0 0 1 6.894 3.69a9.764 9.764 0 0 1 10.211 0ZM14.121 9.88a2.985 2.985 0 0 0-1.11-.704 3.015 3.015 0 0 0-2.022 0 2.985 2.985 0 0 0-1.11.704c-.326.325-.56.705-.704 1.11a3.015 3.015 0 0 0 0 2.022c.144.405.378.785.704 1.11.325.326.705.56 1.11.704.652.233 1.37.233 2.022 0a2.985 2.985 0 0 0 1.11-.704c.326-.325.56-.705.704-1.11a3.016 3.016 0 0 0 0-2.022 2.985 2.985 0 0 0-.704-1.11Z"
            clipRule="evenodd"
          />
        </svg>
      ),
      hide: !["partner", "admin"].includes(me?.role),
    },

    // {
    //   name: (
    //     <>
    //       Growth Accelerator
    //       <Badge count={numberNewAccelerator} offset={[0, 0]}></Badge>
    //     </>
    //   ),
    //   href: "/dashboard/growthAccelerator",
    //   component: <GrowthAccelerator />,
    //   icon: () => (
    //     <svg
    //       xmlns="http://www.w3.org/2000/svg"
    //       fill="none"
    //       viewBox="0 0 24 24"
    //       strokeWidth={1.5}
    //       stroke="currentColor"
    //       className="w-6 h-6"
    //     >
    //       <path
    //         strokeLinecap="round"
    //         strokeLinejoin="round"
    //         d="M2.25 18 9 11.25l4.306 4.306a11.95 11.95 0 0 1 5.814-5.518l2.74-1.22m0 0-5.94-2.281m5.94 2.28-2.28 5.941"
    //       />
    //     </svg>
    //   ),
    //   hide:
    //     !["partner", "admin"].includes(me?.role) ||
    //     !["US"].includes(me?.country),
    // },
  ]
    .map((elem) => ({
      ...elem,
      current: getItemCurrent(location, elem),
      path: elem.href.replace("/dashboard", ""),
    }))
    .filter((a) => !a?.hide);

  const IS_DEVELOPMENT =
    !process.env.NODE_ENV || process.env.NODE_ENV === "development";

  const generalNavigation = [
    {
      name: "Support",
      component: <Support />,
      href: "/dashboard/support",
      icon: LifebuoyIcon,
    },
    {
      name: "Settings",
      component: <Settings />,
      href: "/dashboard/settings",
      icon: Cog8ToothIcon,
    },
  ]
    .map((elem) => ({
      ...elem,
      current: getItemCurrent(location, elem),
      path: elem.href.replace("/dashboard", ""),
    }))
    .filter((a) => !a?.hide);

  const userNavigation = [
    // {
    //   name: "Billing",
    //   href: "/dashboard/billing",
    //   component: <Billing />,
    //   hide: me?.role !== "recruiter",
    // },
    {
      name: "SaaS Configuration",
      href: "/dashboard/partnerSettings",
      component: <PartnerSettings />,
      hide: me?.role !== "partner",
    },
    // {
    //   name: "SaaS Activation",
    //   href: "/dashboard/partnerActivation",
    //   component: <PartnerActivation />,
    //   hide: me?.role !== "partner" || !IS_DEVELOPMENT,
    // },
    {
      name: "Book Demo",
      href: "#",
      onClick: (e) => {
        e.preventDefault();
        window.open(partner?.calendlySchedulingURL);
      },
      hide: !partner?.calendlySchedulingURL,
    },
    // {
    //   name: "Partner Consultation",
    //   href: "#",
    //   onClick: (e) => {
    //     e.preventDefault();
    //     window.open("https://calendly.com/gorkemsinirlioglu/coffee");
    //   },
    //   hide: me?.role !== "partner",
    // },
    // {
    //   name: "RC Credits",
    //   href: "/dashboard/credits",
    //   component: <RCCredits />,
    //   hide: !["admin", "partner", "recruiter"].includes(me?.role),
    // },
    // {
    //   name: "Tutorials",
    //   href: "/dashboard/tutorials",
    //   component: <Tutorials />,
    // },
    {
      name: "Billing",
      href: "#",
      onClick: async () => {
        const res = await AuthService.userActivation({
          return_url: window.location.href,
        });
        window.location.href = res.data.link;
      },
    },
    {
      name: "Feature Request",
      href: "#",
      onClick: (e) => {
        e.preventDefault();
        setFeatureRequest(true);
      },
    },
    {
      name: "Bug Report",
      href: "#",
      onClick: (e) => {
        e.preventDefault();
        setBugReport(true);
      },
    },
    // {
    //   name: (
    //     <div
    //       className="w-full flex justify-left"
    //       onClick={() => store.dispatch(setDarkMode(!darkMode))}
    //     >
    //       <DarkModeSwitch
    //         checked={darkMode}
    //         onChange={(e) => store.dispatch(setDarkMode(e))}
    //         size={20}
    //       />
    //     </div>
    //   ),
    //   href: "#",
    //   onClick: (e) => {
    //     e.preventDefault();
    //   },
    // },
    {
      name: "Sign out",
      href: "/dashboard/home",
      onClick: () => {
        Cookies.remove("accessToken");
        Cookies.remove("refreshToken");
        store.dispatch(logout());
        window.location.href = "/";
      },
    },
  ]
    .map((elem) => ({
      ...elem,
      current: getItemCurrent(location, elem),
      path: elem.href.replace("/dashboard", ""),
    }))
    .filter((a) => !a?.hide);

  if (!theme) return <Skeleton active />;
  if (needsToSelectCalendlyType)
    return (
      <CalendlyEventSelector
        refresh={() => {
          CalendlyService.getNeedsToSelectEventType().then(({ data }) => {
            setNeedsToSelectCalendlyType(data.needsToSelectEventType);
          });
        }}
      />
    );
  return (
    <div className="max-h-[100vh] max-w-[100vw] py-3 px-6">
      <div className=" max-h-[100vh] max-w-[100vw] dark:bg-gray-900 dark:text-gray-400">
        <Theme
          navigation={navigation}
          subMenus={subMenus}
          userNavigation={userNavigation}
          generalNavigation={generalNavigation}
        >
          {bookMeetingModal ? (
            <div
              className="calendly-inline-widget"
              data-url="https://calendly.com/hirelab/hireheroes?primary_color=2d47fc"
              style={{ width: "100%", minHeight: "700px" }}
            />
          ) : (
            <>
              <Warnings />
              <Routes>
                {navigation.map((nav) => (
                  <Route path={nav.path} element={nav.component} />
                ))}
                {subMenus
                  .map((s) => [...s.items])
                  .flat()
                  .map((nav) => (
                    <Route path={nav.path} element={nav.component} />
                  ))}
                {userNavigation.map((nav) => (
                  <Route path={nav.path} element={nav.component} />
                ))}
                {generalNavigation.map((nav) => (
                  <Route path={nav.path} element={nav.component} />
                ))}

                <Route path={"/vacancypublish"} element={<VacancyPublish />} />
                <Route path={"/vacancystats"} element={<VacancyStats />} />
                <Route path={"/vacancydetails"} element={<VacancyDetails />} />
                <Route
                  path={"/agency-onboarding"}
                  element={<AgencyOnboarding />}
                />
                <Route
                  path={"/vacancyprepublish"}
                  element={<VacancyPrepublish />}
                />
                <Route path={"/vacancyform"} element={<VacancyForm />} />
                <Route path={"/vacancyedit"} element={<VacancyEdit />} />
                <Route path={"/heroedit"} element={<HeroEdit />} />
                <Route
                  path={"/vacancyeditForm"}
                  element={<VacancyEditForm />}
                />
                <Route path={"/demoCard"} element={<DemoCard />} />

                <Route path="/legal/*" element={<Legal />} />
              </Routes>
            </>
          )}

          <Footer />
          {/* <SupportWidget /> */}
          <PhoneWidget />
        </Theme>
      </div>

      <Modal
        wrapClassName={`${darkMode ? "dark" : ""}`}
        open={!!featureRequest}
        onCancel={() => setTimeout(() => setFeatureRequest(false), 750)}
        destroyOnClose
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <form
          className="mt-5"
          onSubmit={async (e) => {
            e.preventDefault();
            await UserService.contactDeveloper("Feature Request", {
              wantTo: e.target[0].value,
              soThat: e.target[1].value,
              thisHelpsMeWith: e.target[2].value,
            });

            setFeatureRequest(false);
          }}
        >
          <label className="font-semibold">
            As a user, I want to be able to...
          </label>
          <textarea
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-gray-400  shadow-sm dark:shadow-gray-400/50  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-900 "
            rows={3}
          />

          <br />
          <label className="font-semibold">so that I can...</label>
          <textarea
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-gray-400  shadow-sm dark:shadow-gray-400/50  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-900 "
            rows={3}
          />

          <br />
          <label className="font-semibold">
            This feature will particularly help me with...
          </label>
          <textarea
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-gray-400  shadow-sm dark:shadow-gray-400/50  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-900 "
            rows={3}
          />

          <div className="w-full flex justify-end">
            <button
              disabled={loading}
              type="submit"
              className="mt-10 px-2 py-1 text-sm bg-indigo-500 text-white rounded"
            >
              Send
            </button>
          </div>
        </form>
      </Modal>

      <Modal
        wrapClassName={`${darkMode ? "dark" : ""}`}
        open={!!bugReport}
        onCancel={() => setTimeout(() => setBugReport(false), 750)}
        destroyOnClose
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <form
          className="mt-5"
          onSubmit={async (e) => {
            e.preventDefault();
            await UserService.contactDeveloper("Bug Report", {
              wasTryingTo: e.target[0].value,
              reproduction: e.target[1].value,
              details: e.target[2].value,
            });

            setBugReport(false);
          }}
        >
          <label className="font-semibold">I was trying to...</label>
          <textarea
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-gray-400  shadow-sm dark:shadow-gray-400/50  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-900 "
            rows={3}
          />

          <br />
          <label className="font-semibold">
            Here is a step-by-step process to reproduce the error I am facing...
          </label>
          <textarea
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-gray-400  shadow-sm dark:shadow-gray-400/50  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-900 "
            rows={6}
          />

          <br />
          <label className="font-semibold">
            Here are additional details on what happened...
          </label>
          <textarea
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-gray-400  shadow-sm dark:shadow-gray-400/50  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-900 "
            rows={3}
          />

          <div className="w-full flex justify-end">
            <button
              disabled={loading}
              type="submit"
              className="mt-10 px-2 py-1 text-sm bg-indigo-500 text-white rounded"
            >
              Send
            </button>
          </div>
        </form>
      </Modal>

      {/* <StripeChecker /> */}
    </div>
  );
};

export default Dashboard;
