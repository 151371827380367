import {
  BoltIcon,
  LightBulbIcon,
  SpeakerWaveIcon,
  WifiIcon,
} from "@heroicons/react/24/outline";
import { vapi } from "@vapi-ai/web";
import {
  Alert,
  Button,
  DatePicker,
  Divider,
  Skeleton,
  TimePicker,
  message,
} from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useSearchParams } from "react-router-dom";
import { selectLoading } from "../redux/auth/selectors";
import CVService from "../service/CVService";
import PublicService from "../service/PublicService";
import { Footer } from "./Landing/Footer";

const InterviewBook = () => {
  let [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [finished, setFinished] = useState(false);
  const [candidateData, setCandidateData] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [schedule, setSchedule] = useState(
    searchParams.get("schedule") == "true" ? true : false
  );
  const tips = [
    {
      icon: <BoltIcon width={20} />,
      title: "Take Your Time!",
      description:
        "You have all the time you need to highlight your skills and experiences. The interviewer is patient and eager to learn more about you.",
    },
    {
      icon: <SpeakerWaveIcon width={20} />,
      title: "Speak Naturally",
      description: `Feel free to speak at a comfortable pace and speak over ${
        candidateData?.vacancy?.hero?.name ?? "Taylor"
      } if it ever interrupts you. ${
        candidateData?.vacancy?.hero?.name ?? "Taylor"
      } is designed to understand you.`,
    },
    {
      icon: <LightBulbIcon width={20} />,
      title: "Be Confident",
      description: `Just be yourself. ${
        candidateData?.vacancy?.hero?.name ?? "Taylor"
      } is unbiased and understands. Plus, you can always hang up and pick up the interview where you left off.`,
    },
    {
      icon: <WifiIcon width={20} />,
      title: "Connectivity Basics",
      description:
        "Do it later if your internet is bad or if you are in a noisy setting. We want to ensure a seamless conversation.",
    },
  ];

  const backendLoading = useSelector(selectLoading);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const handleTimeChange = (date) => {
    setSelectedTime(date);
  };

  // const disabledHoursMinutes = () => {
  //   if (selectedDate && selectedDate.isSame(moment(), "day")) {
  //     const currentHour = moment().hour();
  //     const currentMinute = moment().minute();
  //     const disabledHours = Array.from({ length: currentHour }, (_, i) => i);
  //     const disabledMinutes = Array.from({ length: 60 }, (_, i) => i).filter(
  //       (minute) => minute <= currentMinute + 5
  //     );

  //     return {
  //       disabledHours: () => disabledHours,
  //       disabledMinutes: () => disabledMinutes,
  //     };
  //   }
  //   // If the selected day is not today, return no restrictions
  //   return {
  //     disabledHours: () => [],
  //     disabledMinutes: () => [],
  //   };
  // };

  // const { disabledHours, disabledMinutes } = disabledHoursMinutes();

  const getDisabledTime = (selectedDate) => {
    return (now) => {
      if (selectedDate && selectedDate.isSame(moment(), "day")) {
        const currentHour = moment().hour();
        const currentMinute = moment().minute();

        return {
          disabledHours: () => Array.from({ length: currentHour }, (_, i) => i),
          disabledMinutes: (selectedHour) => {
            if (selectedHour === currentHour) {
              return Array.from({ length: currentMinute + 5 }, (_, i) => i);
            }
            return [];
          },
        };
      }
      // Si el día seleccionado no es hoy, no hay restricciones
      return {
        disabledHours: () => [],
        disabledMinutes: () => [],
      };
    };
  };

  useEffect(() => {
    const token = searchParams.get("token");
    if (!token) return;

    setLoading(true);

    CVService.getCVData(token)
      .then(({ data }) => {
        setCandidateData(data);
      })
      .finally(() => setLoading(false));
  }, [searchParams]);

  if (!candidateData && loading) return <Skeleton active />;
  if (!candidateData && !loading)
    return (
      <div className="m-4">
        <Alert type="error" message="Invalid CV link" />
      </div>
    );

  return (
    <>
      {finished ? (
        <>
          <div
            className="w-full border-box pt-5 pl-8 pr-8 pb-2"
            style={{
              background: "center center / cover no-repeat transparent",
            }}
          >
            <div className="mx-auto max-w-md sm:max-w-xl w-full md:max-w-3xl lg:max-w-4xl">
              <div className="transition-wrapper" style={{}}>
                <div className="wrapper break-words text-center responsive font-semibold text-lg">
                  <h4>
                    Thanks for scheduling your interview,{" "}
                    {candidateData?.candidate?.formData?.firstname ?? ""}!
                  </h4>
                  <h4>
                    {candidateData?.vacancy?.hero?.name ?? "Taylor"} will call
                    you on{" "}
                    {moment(
                      selectedDate.toISOString().slice(0, 10) +
                        selectedTime.toISOString().slice(10)
                    ).format("Do MMM")}{" "}
                    at{" "}
                    {moment(
                      selectedDate.toISOString().slice(0, 10) +
                        selectedTime.toISOString().slice(10)
                    ).format("hh:mm A")}
                    .
                  </h4>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : !schedule ? (
        <div className="w-[80vw] flex m-auto flex-col">
          <div
            className="w-full border-box pt-5 pl-8 pr-8 pb-2"
            style={{
              background: "center center / cover no-repeat transparent",
            }}
          >
            <div className="mx-auto max-w-md sm:max-w-xl w-full md:max-w-3xl lg:max-w-4xl">
              <div className="transition-wrapper" style={{}}>
                <div className="wrapper break-words text-center responsive font-bold text-2xl">
                  <h2>
                    Welcome{" "}
                    {candidateData?.candidate?.formData?.firstname ?? ""}! As
                    earlier communicated, you will be talking to{" "}
                    {candidateData?.vacancy?.hero?.name ?? "Taylor"}, our AI
                    driven recruiter.
                  </h2>
                </div>
              </div>
            </div>
          </div>

          <div className="py-6 flex flex-col gap-4 container-sm">
            {tips.map((tip, index) => (
              <div
                key={index}
                className="flex items-center gap-4 p-4 bg-white shadow rounded-lg "
              >
                <div className="shrink-0 border border-[#EAECF0] p-2 rounded-md">
                  {tip.icon}
                </div>
                <div>
                  <h2 className="text-md font-semibold">{tip.title}</h2>
                  <p className="text-gray-600">{tip.description}</p>
                </div>
              </div>
            ))}
          </div>

          <div className="w-full flex justify-center items-center mt-5">
            <Link
              className="w-full"
              to={`/interview-call?token=${searchParams.get("token")}`}
            >
              <Button
                type="primary"
                disabled={backendLoading}
                className="w-full"
                // onClick={() => {
                //   const token = searchParams.get("token");
                //   if (!token) return;
                //   PublicService.startCall(token);
                // }}
              >
                Start Your Interview
              </Button>
            </Link>
          </div>
        </div>
      ) : (
        <div
          className="w-full h-max-[40vh] border-box pt-5 pl-8 pr-8 pb-2"
          style={{
            background: "center center / cover no-repeat transparent",
          }}
        >
          <div className="mx-auto max-w-md sm:max-w-xl w-full md:max-w-2xl lg:max-w-2xl mb-5">
            <h2 className="wrapper break-words text-center responsive font-bold text-2xl">
              Welcome {candidateData?.candidate?.formData?.firstname ?? ""}!
            </h2>
            <h5 className="wrapper break-words text-center responsive text-lg">
              Please pick a date and time to specify when we should call you and
              start the interview process.
            </h5>
          </div>

          <div className="mx-auto max-w-md sm:max-w-xl w-full md:max-w-2xl lg:max-w-2xl">
            <div className="transition-wrapper" style={{}}>
              <div className="wrapper break-words text-center responsive font-semibold text-lg">
                <DatePicker
                  className="mr-2"
                  disabledDate={(current) =>
                    current && current <= moment().startOf("day")
                  }
                  onChange={handleDateChange}
                />

                <TimePicker
                  className="ml-2"
                  disabledTime={getDisabledTime(selectedDate)}
                  format="HH:mm"
                  onChange={handleTimeChange}
                />
              </div>
              <div className="w-full flex justify-center items-center mt-5">
                <Button
                  type="primary"
                  className="w-full"
                  onClick={async () => {
                    if (!selectedDate)
                      return message.info("Please select a date");
                    if (!selectedTime)
                      return message.info("Please select a time");

                    const token = searchParams.get("token");
                    if (!token) return;

                    if (backendLoading) return;

                    await PublicService.scheduleInterview({
                      token,
                      start_time: `${
                        selectedDate.toISOString().slice(0, 10) +
                        selectedTime.toISOString().slice(10)
                      }`,
                      end_time: moment(
                        selectedDate.toISOString().slice(0, 10) +
                          selectedTime.toISOString().slice(10)
                      )
                        .add(1, "days")
                        .toISOString(),
                    });

                    setFinished(true);
                  }}
                >
                  Schedule Your Interview
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default InterviewBook;
