import { Alert, Button, Divider, Skeleton, Spin } from "antd";
import { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { getPartner, selectLoading } from "../redux/auth/selectors";
import PublicService from "../service/PublicService";

const JobOpenings = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [pagination, setPagination] = useState({
    total: 0,
    currentPage: 1,
    limit: 5,
  });
  const [jobs, setJobs] = useState(null);

  const loading = useSelector(selectLoading);
  const partner = useSelector(getPartner);

  const loadMore = useCallback(
    async ({ refresh = false, page = 1, text = "" }) => {
      const response = await PublicService.queryJobs({
        page,
        limit: 5,
        text,
      });

      const newJobs = response.data.result;
      setJobs((prevJobs) => [...(refresh ? [] : prevJobs ?? []), ...newJobs]);
      setPagination({
        total: response.data.total,
        currentPage: response.data.page,
        limit: response.data.limit,
      });
    },
    []
  );

  // Function to perform the actual search
  const performSearch = useCallback(
    (text) => {
      loadMore({
        text: text ? text : undefined,
        refresh: true,
        page: 1,
      });
    },
    [loadMore]
  );
  // Function to handle the input change with debounce
  const searchTimer = useRef();
  const handleInputChange = (event) => {
    const newValue = event.target.value;
    setSearchTerm(newValue);

    // Delay the execution of the search function by 300 milliseconds (adjust as needed)
    if (searchTimer.current) clearTimeout(searchTimer.current);
    searchTimer.current = setTimeout(() => {
      performSearch(newValue);
    }, 1000);
  };

  useEffect(() => {
    loadMore({});
  }, [loadMore]);

  if (!partner) return <Skeleton active />;
  if (!jobs) return <Skeleton active />;

  return (
    <>
      <Divider />
      <div className="w-full lg:max-w-xl mx-auto mb-10">
        <h3 className="text-xl font-bold mb-3">Job openings</h3>

        <div className="mt-5">
          <input
            type="text"
            placeholder="Search Jobs"
            className="block w-full rounded-md border-0 py-1.5 pr-14 text-gray-900 dark:text-gray-400  shadow-sm dark:shadow-gray-400/50  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-900 "
            value={searchTerm}
            onChange={handleInputChange}
          />
        </div>

        {loading && (
          <div className="absolute text-center mt-5 w-full lg:max-w-xl mx-auto mb-10">
            <Spin />
          </div>
        )}
        {jobs?.length === 0 ? (
          <Alert
            type="info"
            className="-my-8 divide-y divide-gray-100 mt-5"
            message={`At this time, we do not have any vacant positions available${
              searchTerm ? " for " + searchTerm + "." : "."
            }`}
          />
        ) : (
          <ul
            className="-my-8 divide-y divide-gray-100 text-left mt-5"
            id="jobs"
          >
            {jobs?.map?.((opening) => (
              <li key={opening._id} className="py-8">
                <dl className="relative flex flex-wrap gap-x-3">
                  <dt className="sr-only">Role</dt>
                  <dd className="w-full flex-none text-lg font-semibold tracking-tight text-gray-900 dark:text-gray-400 ">
                    <a href={`/funnel?id=${opening._id}`} target="_blank">
                      {opening.name}
                      <span className="absolute inset-0" aria-hidden="true" />
                    </a>
                  </dd>
                  <dt className="sr-only">Description</dt>
                  <dd className="mt-2 w-full flex-none text-base leading-7 text-gray-600">
                    {opening.valueProposition}
                  </dd>
                  <dt className="sr-only">Contract Type</dt>
                  <dd className="mt-4 text-base font-semibold leading-7 text-gray-900 dark:text-gray-400 ">
                    {opening.contractType}
                  </dd>
                  <dt className="sr-only">Location</dt>
                  <dd className="mt-4 flex items-center gap-x-3 text-base leading-7 text-gray-500">
                    <svg
                      viewBox="0 0 2 2"
                      className="h-0.5 w-0.5 flex-none fill-gray-300"
                      aria-hidden="true"
                    >
                      <circle cx={1} cy={1} r={1} />
                    </svg>
                    {opening.location}
                  </dd>
                </dl>
              </li>
            ))}
          </ul>
        )}
      </div>

      {pagination.total >= 5 * pagination.currentPage && (
        <div className="flex justify-center mt-10">
          <Button
            className="rounded-md bg-indigo-500 px-3.5  text-sm font-semibold text-white shadow-sm dark:shadow-gray-400/50  hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 !hover:text-white"
            style={{
              background: partner?.themeColor,
            }}
            loading={loading}
            onClick={() => loadMore({ page: pagination.currentPage + 1 })}
          >
            Load more
          </Button>
        </div>
      )}

      <Divider />
    </>
  );
};

export default JobOpenings;
