import { Skeleton, Typography } from "antd";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
// import { SketchPicker } from "react-color";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import MultiStepComponent from "../components/MultiStepComponent";
import Onboarding2 from "../pages/auth/Onboarding";
import { getPartner } from "../redux/auth/selectors";
import { Button, Heading, Img, Input, Text } from "./components";
import { Textarea } from "./components/Textarea";

const data = [
  { icon: "😊", formtitle: "Friendly & Conversational" },
  { icon: "💼", formtitle: "Professional & Authoritative" },
  { icon: "💡", formtitle: "Innovative & Inspirational" },
  { icon: "😄", formtitle: "Quirky & Humorous" },
];

const Onboarding = () => {
  const [step, setStep] = useState(1);
  const [values, setValues] = useState({});
  const [result, setResult] = useState(null);
  const [loading, setLoading] = useState(false);
  const [setupFinished, setSetupFinished] = useState(false);
  const partner = useSelector(getPartner);
  const navigate = useNavigate();

  const socket = useRef();
  const socketPing = useRef();

  useEffect(() => {
    setValues((e) => ({ ...e, themeColor: partner.themeColor }));
  }, [partner]);

  if (loading && step === 10) return <Skeleton active />;
  return (
    <div
      style={{
        height: "100vh",
        padding: "20px",
      }}
    >
      {/* onboarding step two section */}
      <div className="flex w-full flex-col gap-5 bg-white smx:gap-8">
        {/* navigation section */}
        <div className=" py-4">
          <div className="container-xs flex items-center justify-between gap-5 mdx:p-5">
            <Img
              src="/images/img_arrow_left.svg"
              alt="arrowleft"
              className="h-[24px] w-[24px] cursor-pointer"
              onClick={() => setStep((s) => Math.max(1, s - 1))}
            />
            <Heading
              size="xs"
              as="h1"
              className="self-end tracking-[-0.50px] !text-[#e30047]"
            >
              Step {step} of 10
            </Heading>
          </div>
        </div>

        {step === 1 && (
          <>
            <div className="container-xs mt-[39px] flex flex-col gap-[11px] mdx:p-5">
              <div className="flex flex-col items-start">
                <Text as="p" className="!font-normal tracking-[-0.16px]">
                  <span className="font-medium text-[#4b5563]">
                    What is the name of your business?
                  </span>
                </Text>
              </div>
              <Textarea
                rows={4}
                shape="round"
                type="text"
                name="name"
                placeholder={`Sales AI`}
                value={values.brandName}
                onChange={(e) => {
                  setValues((x) => ({
                    ...x,
                    brandName: e.target?.value,
                  }));
                }}
              />
            </div>
          </>
        )}
        {step === 2 && (
          <>
            <div className="container-xs mt-[39px] flex flex-col gap-[11px] mdx:p-5">
              <div className="flex flex-col items-start">
                <Text as="p" className="!font-normal tracking-[-0.16px]">
                  <span className="font-medium text-[#4b5563]">
                    Please describe your product or service
                  </span>
                </Text>
              </div>
              <Textarea
                rows={4}
                shape="round"
                type="text"
                name="name"
                placeholder={`We provide our customers an AI system to automate their lead generation and sales.`}
                value={values.serviceDetails}
                onChange={(e) => {
                  setValues((x) => ({
                    ...x,
                    serviceDetails: e.target?.value,
                  }));
                }}
              />
            </div>
          </>
        )}
        {step === 3 && (
          <>
            <div className="container-xs mt-[39px] flex flex-col gap-[11px] mdx:p-5">
              <div className="flex flex-col items-start">
                <Text as="p" className="!font-normal tracking-[-0.16px]">
                  <span className="font-medium text-[#4b5563]">
                    What are the customer pain points who would like to use your
                    service?
                  </span>
                </Text>
              </div>
              <Textarea
                rows={4}
                shape="round"
                type="text"
                name="name"
                placeholder={`The main features are: Creating customized sales automation funnels for clients to streamline their sales process and increase conversions.`}
                value={values.customerPersonaInterestDetails}
                onChange={(e) => {
                  setValues((x) => ({
                    ...x,
                    customerPersonaInterestDetails: e.target?.value,
                  }));
                }}
              />
            </div>
          </>
        )}
        {step === 4 && (
          <>
            <div className="container-xs mt-[39px] flex flex-col gap-[11px] mdx:p-5">
              <div className="flex flex-col items-start">
                <Text as="p" className="!font-normal tracking-[-0.16px]">
                  <span className="font-medium text-[#4b5563]">
                    What are your pricing plans?
                  </span>
                </Text>
              </div>
              <Textarea
                rows={4}
                shape="round"
                type="text"
                name="name"
                value={values.pricingDetails}
                onChange={(e) => {
                  setValues((x) => ({
                    ...x,
                    pricingDetails: e.target?.value,
                  }));
                }}
              />
            </div>
          </>
        )}
        {step === 5 && (
          <>
            <div className="container-xs mt-[39px] flex flex-col gap-[11px] mdx:p-5">
              <div className="flex flex-col items-start">
                <Text as="p" className="!font-normal tracking-[-0.16px]">
                  <span className="font-medium text-[#4b5563]">
                    What are your benefits?
                  </span>
                </Text>
              </div>
              <Textarea
                rows={4}
                shape="round"
                type="text"
                name="name"
                value={values.benefitDetails}
                onChange={(e) => {
                  setValues((x) => ({
                    ...x,
                    benefitDetails: e.target?.value,
                  }));
                }}
              />
            </div>
          </>
        )}
        {step === 6 && (
          <>
            <div className="container-xs mt-[39px] flex flex-col gap-[11px] mdx:p-5">
              <div className="flex flex-col items-start">
                <Text as="p" className="!font-normal tracking-[-0.16px]">
                  <span className="font-medium text-[#4b5563]">
                    Who is your audience?
                  </span>
                </Text>
              </div>
              <Textarea
                rows={4}
                shape="round"
                type="text"
                name="name"
                value={values.customerAudienceDetails}
                onChange={(e) => {
                  setValues((x) => ({
                    ...x,
                    customerAudienceDetails: e.target?.value,
                  }));
                }}
              />
            </div>
          </>
        )}
        {step === 7 && (
          <>
            <div className="container-xs mt-[39px] flex flex-col gap-[11px] mdx:p-5">
              <div className="flex flex-col items-start">
                <Text as="p" className="!font-normal tracking-[-0.16px]">
                  <span className="font-medium text-[#4b5563]">
                    What is your brand's tone and voice?
                  </span>
                </Text>
              </div>
              <div className="flex w-[28%] gap-4 mdx:w-full mdx:flex-row smx:flex-col">
                {data.map((d, index) => (
                  <div
                    key={"listproduct" + index}
                    className={`flex w-full flex-col items-center gap-[11px] smx:w-full border border-1 p-2 rounded-lg cursor-pointer ${
                      d.formtitle === values?.brandToneVoice
                        ? "border-gray-900"
                        : ""
                    }`}
                    onClick={() => {
                      setValues((x) => ({
                        ...x,
                        brandToneVoice: d.formtitle,
                      }));
                    }}
                  >
                    <div
                      className={`w-full rounded-[12px] object-cover flex justify-center`}
                    >
                      {d.icon}
                    </div>
                    <Text
                      size="xs"
                      as="p"
                      className="tracking-[-0.13px] text-center"
                    >
                      {d.formtitle}
                    </Text>
                  </div>
                ))}
              </div>
            </div>
          </>
        )}
        {step === 8 && (
          <>
            <div className="container-xs mt-[39px] flex flex-col gap-[11px] mdx:p-5">
              <div className="flex flex-col items-start">
                <Text as="p" className="!font-normal tracking-[-0.16px]">
                  <span className="font-medium text-[#4b5563]">
                    Select your brand color
                  </span>
                </Text>
                <input
                  type="color"
                  value={values.themeColor}
                  onChange={(e) => {
                    if (e.target?.value)
                      setValues((x) => ({
                        ...x,
                        themeColor: e?.target?.value,
                      }));
                  }}
                />
              </div>
            </div>
          </>
        )}
        {step === 10 && (
          <>
            <div className="container-xs mt-[39px] flex flex-col gap-[11px] mdx:p-5">
              <div className="flex flex-col items-start">
                <Text as="p" className="!font-normal tracking-[-0.16px]">
                  <span className="font-medium text-[#4b5563]">
                    Does it look right?
                  </span>
                </Text>
                <Text as="p" className="!font-normal mt-10">
                  <span className="font-medium text-sm text-[#4b5563] ">
                    Company Description
                  </span>
                </Text>
                <div>
                  <Typography.Paragraph
                    className="w-full"
                    editable={{
                      onChange: (x) => {
                        setResult((e) => ({
                          ...e,
                          shortCompanyDescription: x,
                        }));
                      },
                    }}
                  >
                    {result?.shortCompanyDescription}
                  </Typography.Paragraph>
                </div>
                <Text as="p" className="!font-normal mt-10">
                  <span className="font-medium text-sm text-[#4b5563]">
                    Benefits
                  </span>
                </Text>
                <div>
                  {result?.benefits?.map?.((item, i) => (
                    <div>
                      <Typography.Paragraph
                        className="w-full"
                        editable={{
                          onChange: (e) => {
                            const benefits = result.benefits;
                            benefits[i] = e;
                            setResult((e) => ({
                              ...e,
                              benefits,
                            }));
                          },
                        }}
                      >
                        • {item}
                      </Typography.Paragraph>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </>
        )}
        {step === 9 && (
          <>
            <div className="grid grid-cols-1 md:grid-cols-2">
              <Onboarding2 onFinish={() => setSetupFinished(true)} />

              <div>
                <div className="flex flex-col items-start">
                  <Text as="p" className="!font-normal tracking-[-0.16px]">
                    <span className="font-medium text-[#4b5563] mt-5">
                      While we work on creating a customized sales strategy for
                      your business in {new Date().getFullYear()}, could you
                      take your time to answer a few questions? We are curious
                      to know more about you to personalize your experience even
                      more!
                    </span>
                  </Text>
                </div>
                <MultiStepComponent
                  additionalClassNames={"min-h-[55vh]"}
                  hideFinish
                  steps={[
                    {
                      id: "step1",
                      name: "Your Purpose",
                      form: [
                        {
                          fieldName: "websitePurpose",
                          label: "Who will you be building websites for?",
                          type: "radio",
                          options: [
                            {
                              value: "freelancer",
                              label: "Clients as a freelancer",
                            },
                            {
                              value: "agency",
                              label: "Clients as part of an agency",
                            },
                            {
                              value: "personal",
                              label: "Myself (personal brand or portfolio)",
                            },
                            {
                              value: "ownBusiness",
                              label: "My company or business",
                            },
                            {
                              value: "employer",
                              label: "The company I work for",
                            },
                            { value: "others", label: "Others" },
                          ],
                        },
                      ],
                    },
                    {
                      id: "step2",
                      name: "Discovery Method",
                      form: [
                        {
                          fieldName: "discoverySource",
                          label: "How did you hear about SalesAI?",
                          type: "radio",
                          options: [
                            { value: "friend", label: "Friend / Colleague" },
                            { value: "twitter", label: "Twitter" },
                            { value: "youtube", label: "Youtube" },
                            { value: "instagram", label: "Instagram" },
                            { value: "tiktok", label: "Tiktok" },
                            {
                              value: "searchEngine",
                              label: "Search engine (e.g., Google, Bing)",
                            },
                            { value: "others", label: "Others" },
                          ],
                        },
                      ],
                    },
                    {
                      id: "step3",
                      name: "Role Description",
                      form: [
                        {
                          fieldName: "role",
                          label: "What best describes your role?",
                          type: "radio",
                          options: [
                            { value: "webDesigner", label: "Web designer" },
                            {
                              value: "productDesigner",
                              label: "Product designer",
                            },
                            { value: "developer", label: "Developer" },
                            { value: "marketer", label: "Marketer" },
                            { value: "entrepreneur", label: "Entrepreneur" },
                            { value: "student", label: "Student" },
                            { value: "others", label: "Others" },
                          ],
                        },
                      ],
                    },
                  ]}
                />
              </div>
            </div>
          </>
        )}

        {/* interest selection section */}
        <div className="flex flex-col pb-16 mdx:pb-5">
          {/* navigation next section */}
          <div className="container-xs mt-[90px]  mdx:p-5 mdx:px-5">
            <Button
              onClick={() => {
                if (step === 9 && !setupFinished) return;

                if (step === 8) {
                  setLoading(true);

                  socket.current = new WebSocket(
                    `wss://booklified-chat-socket.herokuapp.com`
                  );

                  socket.current.addEventListener("open", async () => {
                    socketPing.current = setInterval(
                      () => socket.current.send(JSON.stringify({ id: "PING" })),
                      30000
                    );
                    const content = `Here is the details of a business: ${values}
                          
                          According to the details provided, please generate the following data, and in your answer only send a JSON object as specified below that can be parsed using Javascript JSON.parse. It's imperative that you do not add anything else to your response. Send me only the string that can be safely parsed with JSON.parse.
                          It's imperative that you provide some meaningful results
              
                          {
                              serviceDetails: String,
                              customerPersonaAge: [String],
                              customerPersonaGender: [String],
                              customerPersonaInterest: [String],
                              customerPersonaIndustry: [String],
                              customerPersonaKeywords: [String],
                              shortCompanyDescription: String,
                              benefits: [String],
                          }
                          `;

                    socket.current.send(
                      JSON.stringify({
                        id: "OPEN_AI_PROMPT",
                        payload: {
                          content,
                          model: "gpt-3.5-turbo-16k",
                          partner: partner?._id,
                          app_id: "hidec",
                          max_tokens: 4096,
                        },
                      })
                    );
                  });

                  socket.current.addEventListener("message", async (event) => {
                    const message = JSON.parse(event.data);
                    const response = message.payload?.response
                      ?.replace?.(/```json/g, "")
                      ?.replace?.(/```/g, "");

                    setLoading(false);
                    const result = JSON.parse(response);
                    setResult(result);

                    if (socketPing.current) clearInterval(socketPing.current);
                  });
                }
                setStep((s) => Math.min(10, s + 1));

                if (step === 10) {
                  socket.current = new WebSocket(
                    `wss://booklified-chat-socket.herokuapp.com`
                  );

                  socket.current.addEventListener("open", async () => {
                    socketPing.current = setInterval(
                      () => socket.current.send(JSON.stringify({ id: "PING" })),
                      30000
                    );
                    const content = `
                        ${partner?.landingPagePrompt}
                  
                        Target Audience:
                        Persona Age: ${result?.customerPersonaAge ?? ""}
                        Persona Gender: ${result?.customerPersonaGender ?? ""}
                        Persona Interest: ${
                          result?.customerPersonaInterest ?? ""
                        }
                        Persona Industry: ${
                          result?.customerPersonaIndustry ?? ""
                        }
                        Persona Keywords: ${
                          result?.customerPersonaKeywords ?? ""
                        }
                  
                        Also consider this information to create the content: ${
                          localStorage.describeLandingpage
                        }
                  
                        Your answer should be in JSON format. Do not include anything else in your answer. I should be able to process your answer with JSON.parse function in Javascript. This structure should be followed (change the content):
                        {
                          name: "A meaningful name for the landing page",
                          pageContent: [
                            {
                              "id": "Hero",
                              "data": {
                                "headline": "A great headline to capture interest",
                                "subline": "5-10 word subline",
                                "description": "A detailed description of our services / products",
                                "image": "/dhimages/img_screen_mockup_replace.png",
                                "video": "https://www.youtube.com/watch?v=uOJ6J__L-fo",
                                "benefits": [
                                  {
                                    "text": "5-10 word benefit"
                                  },
                                  {
                                    "text": "5-10 word benefit"
                                  },
                                  {
                                    "text": "5-10 word benefit"
                                  }
                                ]
                              }
                            },
                            {
                              "id": "CallToAction",
                              "data": {
                                "headline": "Our core value proposition and what it entails for the customer",
                                "description": "A more detailed description of why they should start today",
                                "secondaryButtonText": "Contact Us",
                                "secondaryButtonLink": "#contact",
                                "primaryButtonText": "Book Demo",
                                "primaryButtonLink": "${"https://calendly.com/"}"
                              }
                            },
                            {
                              "id": "FeatureSlider",
                              "data": {
                                "slides": [
                                  {
                                    "image": "/dhimages/img_screen_mockup_replace_221x333.png",
                                    "title": "Feature title of our product / service",
                                    "description": "Feature descritpion of our product / service"
                                  },
                                  {
                                    "image": "/dhimages/img_screen_mockup_replace_221x333.png",
                                    "title": "Feature title of our product / service",
                                    "description": "Feature descritpion of our product / service"
                                  }
                                ]
                              }
                            },
                            {
                              "id": "TestemonialSectionSlider",
                              "data": {
                                "testimonials": [
                                  {
                                    "title": "Testimonial section title",
                                    "description": "Summary of what customers are saying about us",
                                    "rate": "5.0",
                                    "totalReviewers": 200
                                  },
                                  {
                                    "title": "Testimonial section title",
                                    "description": "Summary of what customers are saying about us",
                                    "rate": "5.0",
                                    "totalReviewers": 58
                                  }
                                ]
                              }
                            },
                            {
                              "id": "AdminFeatures",
                              "data": {
                                "title": "Feature Category Title",
                                "features": [
                                  {
                                    "description": "Feature description (5-10 words)"
                                  },
                                  {
                                    "description": "Feature description (5-10 words)"
                                  },
                                  {
                                    "description": "Feature description (5-10 words)"
                                  }
                                ],
                                "image": "/dhimages/img_image_1.png"
                              }
                            },
                            {
                              "id": "AdminFeatures",
                              "data": {
                                "title": "Feature Category Title",
                                "features": [
                                  {
                                    "description": "Feature description (5-10 words)"
                                  },
                                  {
                                    "description": "Feature description (5-10 words)"
                                  },
                                  {
                                    "description": "Feature description (5-10 words)"
                                  }
                                ],
                                "imageLeft": "/dhimages/img_image_500x594.png"
                              }
                            },
                            {
                              "id": "AdminFeatures",
                              "data": {
                                "title": "Feature Category Title",
                                "features": [
                                  {
                                    "description": "Feature description (5-10 words)"
                                  },
                                  {
                                    "description": "Feature description (5-10 words)"
                                  },
                                  {
                                    "description": "Feature description (5-10 words)"
                                  }
                                ],
                                "image": "/dhimages/img_image_1.png"
                              }
                            },
                            {
                              "id": "LeadForm",
                              "data": {
                                "headline": "Sign Up for Updates",
                                "description": "Stay informed with the latest news from our team.",
                                "privacyPolicyLink": "#"
                              }
                            },
                            {
                              "id": "DivideLine"
                            },
                            {
                              "id": "Benefits",
                              "data": {
                                "title": "A meaningful title for the entire benefits section",
                                "description": "Description of what our core benefits are",
                                "benefits": [
                                  {
                                    "emoji": "🚀",
                                    "title": "Benefit title (2-5 words)",
                                    "description": "Benefit description (10-20 words)"
                                  },
                                  {
                                    "emoji": "🚀",
                                    "title": "Benefit title (2-5 words)",
                                    "description": "Benefit description (10-20 words)"
                                  },
                                  {
                                    "emoji": "🚀",
                                    "title": "Benefit title (2-5 words)",
                                    "description": "Benefit description (10-20 words)"
                                  },
                                  {
                                    "emoji": "🚀",
                                    "title": "Benefit title (2-5 words)",
                                    "description": "Benefit description (10-20 words)"
                                  }
                                ]
                              }
                            },
                            {
                              "id": "DivideLine"
                            },
                            {
                              "id": "ValueProp",
                              "data": {
                                "title": "Title for the section of our promise",
                                "description": "Description of our promise",
                                "image": "/dhimages/img_image.png"
                              }
                            },
                            {
                              "id": "DivideLine"
                            },
                            {
                              "id": "LeadForm",
                              "data": {
                                "headline": "Sign Up for Updates",
                                "description": "Stay informed with the latest news from our team.",
                                "privacyPolicyLink": "#"
                              }
                            },
                            {
                              "id": "DivideLine"
                            }
                          ]
                        }
                  
                        The "id" field can never be anything other than:
                        Hero
                        CallToAction
                        FeatureSlider
                        TestemonialSectionSlider
                        AdminFeatures
                        DivideLine
                        LeadForm
                        Benefits
                        ValueProp
                  
                        It is imminent to have all of the fields in the specified order included in your answer:
                        Hero
                        CallToAction
                        FeatureSlider
                        TestemonialSectionSlider
                        AdminFeatures
                        AdminFeatures
                        AdminFeatures
                        LeadForm
                        DivideLine
                        Benefits
                        DivideLine
                        ValueProp
                        DivideLine
                        LeadForm
                        DivideLine
                  
                        Do not change any links, images, and buttons from the provided example. Those should be exactly same.
                        `;

                    setLoading(true);
                    socket.current.send(
                      JSON.stringify({
                        id: "OPEN_AI_PROMPT",
                        payload: {
                          content,
                          model: "gpt-3.5-turbo-16k",
                          partner: partner?._id,
                          app_id: "hidec",
                          max_tokens: 10000,
                        },
                      })
                    );
                  });

                  socket.current.addEventListener("message", async (event) => {
                    const message = JSON.parse(event.data);
                    const response = message.payload?.response;

                    const data = JSON.parse(response);
                    if (!Array.isArray(data.pageContent)) return;

                    localStorage.demoVacancy = JSON.stringify({
                      name:
                        data?.name ?? moment().format(STANDARD_MOMENT_FORMAT),
                      pageContent: data.pageContent,
                      result,
                    });

                    navigate(`/page/demo`);

                    if (socketPing.current) clearInterval(socketPing.current);
                    setLoading(false);
                  });
                }
              }}
              color="indigo-500"
              size="sm"
              className={`w-full rounded-[29px] font-bold tracking-[-0.20px] smx:px-5 ${
                step === 9 && !setupFinished ? "opacity-50" : ""
              }`}
            >
              Next
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Onboarding;
