import React from "react";

const Privacy = () => {
  return (
    <div className="p-9">
      <div className="font-bold text-lg">Privacy Policy</div>

      <div className="mt-5">Last Updated: December, 2023</div>
      <div>1. Overview</div>
      <div>
        At HireHeroes.ai (“HireHeroes,” “we,” “us,” or “our”), we prioritize the
        privacy and security of our users (“you” or “your”). Our commitment is
        to uphold the highest standards of privacy and data protection, ensuring
        that your sensitive data and communications are protected while you
        leverage our services to enhance your professional opportunities.
      </div>
      <div>1.1. Privacy Commitment</div>
      <div>
        Data Integrity: We only access and store the minimum amount of
        information necessary for our services to function effectively.
      </div>
      <div>
        Data Ownership: You retain full ownership of your data. It is treated
        with the utmost confidentiality and is protected under this policy and
        by law.
      </div>
      <div>
        Access Control: No unauthorized personnel will access or manage your
        data.
      </div>
      <div>
        For any questions regarding our privacy practices, please contact us at
        privacy@HireHeroes.ai.
      </div>
      <div>1.2. Policy Updates</div>
      <div>
        We may update this Privacy Policy periodically to reflect changes in our
        practices. If material changes are made, we will notify you through our
        website or services, or by other means, to offer you the opportunity to
        review the changes before they become effective.
      </div>
      <div>2. Data Use and Collection</div>
      <div>2.1. Services Offered</div>
      <div>
        HireHeroes.ai provides tools and platforms for career advancement, job
        matching, and professional networking. Our services allow you to:
      </div>
      <div>Create and manage professional profiles.</div>
      <div>Connect with potential employers or candidates.</div>
      <div>Receive tailored job recommendations and career advice.</div>
      <div>2.2. Personal Information</div>
      <div>We collect personal information when you:</div>
      <div>Register an account.</div>
      <div>
        Engage with our services, like posting job listings or applying for
        jobs.
      </div>
      <div>
        Participate in interactive features, surveys, promotions, and customer
        communication.
      </div>
      <div>
        This information may include your name, email address, professional
        history, education details, and employment preferences.
      </div>
      <div>2.3. Sensitive Information</div>
      <div>
        We do not intentionally collect sensitive personal information such as
        racial or ethnic origin, political opinions, religious beliefs, health
        information, biometric data, or sexual orientation.
      </div>
      <div>3. Data Management</div>
      <div>3.1. Use of Information</div>
      <div>Your personal information is used to:</div>
      <div>Operate and improve our services.</div>
      <div>
        Communicate with you about your account and service-related issues.
      </div>
      <div>Conduct research and analysis to understand workforce trends.</div>
      <div>3.2. Disclosure</div>
      <div>Personal information may be shared with:</div>
      <div>
        Third-party service providers who assist us in operating our services,
        conducting our business, or serving our users, under confidentiality
        agreements.
      </div>
      <div>
        Regulatory authorities, when required by law or to protect the rights
        and safety of our users and ourselves.
      </div>
      <div>3.3. Data Retention and Deletion</div>
      <div>
        We retain your personal information for as long as your account is
        active or as needed to provide you services, comply with our legal
        obligations, resolve disputes, and enforce our agreements. When it is no
        longer necessary to retain your information, we will securely delete or
        anonymize it.
      </div>
      <div>3.4. International Data Transfers</div>
      <div>
        HireHeroes.ai operates globally, and we may transfer your personal
        information to countries outside of your country of residence for
        processing and storage. By using our services, you consent to the
        transfer of information to countries outside of your country, which may
        have different data protection rules than your country.
      </div>
      <div>4. User Rights and Choices</div>
      <div>4.1. Access and Control of Personal Information</div>
      <div>
        You have the right to access, correct, update, or request deletion of
        your personal information. HireHeroes.ai provides tools and settings to
        access, modify, or delete the information you provided to us and
        associated with your account.
      </div>
      <div>4.2. Communication Preferences</div>
      <div>
        You can manage your subscription and communication preferences at any
        time from your account settings, including opting out of receiving
        marketing material from us or our partners.
      </div>
      <div>4.3. Data Portability</div>
      <div>
        You have the right to be provided with a copy of the information we have
        on you in a structured, machine-readable, and commonly used format.
      </div>
      <div>5. Security Measures</div>
      <div>
        We implement a variety of security measures to maintain the safety of
        your personal information when you enter, submit, or access your
        personal information. These include using encryption protocols and
        software, maintaining physical security measures to guard against
        unauthorized access to systems, and engaging third-party cybersecurity
        services.
      </div>
      <div>6. Third-party Websites</div>
      <div>
        Our services may include links to third-party websites whose privacy
        practices may differ from those of HireHeroes.ai. If you submit personal
        information to any of those sites, your information is governed by their
        privacy policies. We encourage you to carefully read the privacy policy
        of any website you visit.
      </div>
      <div>7. Compliance and Cooperation with Regulatory Authorities</div>
      <div>
        We regularly review our compliance with this Privacy Policy. When we
        receive formal written complaints, we respond by contacting the person
        who made the complaint to follow up. We will cooperate with the
        appropriate regulatory authorities, including local data protection
        authorities, to resolve any complaints regarding the transfer of
        personal data that cannot be resolved between HireHeroes.ai and an
        individual.
      </div>
      <div>8. GDPR and DSA Compliance</div>
      <div>
        As a Europe-based company, HireHeroes.ai adheres strictly to the
        regulations set forth by the General Data Protection Regulation (GDPR)
        and the Digital Services Act (DSA) to ensure the highest standards of
        data privacy and security:
      </div>
      <div>8.1. Lawful Basis for Processing</div>
      <div>
        We process your personal information under the following lawful bases:
      </div>
      <div>
        Consent: We may use your data based on the consent you provide at the
        time of collection.
      </div>
      <div>
        Contractual Necessity: Processing is necessary for the performance of a
        contract to which you are a party or in order to take steps at your
        request prior to entering into a contract.
      </div>
      <div>
        Legal Obligation: Processing is necessary for compliance with a legal
        obligation to which we are subject.
      </div>
      <div>
        Legitimate Interests: Processing is necessary for the purposes of the
        legitimate interests pursued by us or by a third party, except where
        such interests are overridden by your interests or fundamental rights
        and freedoms.
      </div>
      <div>8.2. Data Subject Rights</div>
      <div>Under the GDPR, you have the following rights:</div>
      <div>
        Right to Access: You have the right to access your personal data that we
        hold.
      </div>
      <div>
        Right to Rectify: You can request correction of your personal data if it
        is inaccurate or incomplete.
      </div>
      <div>
        Right to Erase: You have the right to request the deletion or removal of
        your personal data where there is no compelling reason for its continued
        processing.
      </div>
      <div>
        Right to Restrict Processing: You have the right to 'block' or suppress
        further use of your data under certain circumstances.
      </div>
      <div>
        Right to Data Portability: You have the right to receive your personal
        data in a structured, commonly used, and machine-readable format and
        have the right to transmit that data to another controller.
      </div>
      <div>
        Right to Object: You have the right to object to the processing of your
        personal data based on grounds relating to your particular situation.
      </div>
      <div>
        Rights in relation to automated decision making and profiling: You have
        the right not to be subject to a decision based solely on automated
        processing, including profiling, which produces legal effects concerning
        you or similarly significantly affects you.
      </div>
      <div>8.3. Data Protection Officer</div>
      <div>
        HireHeroes.ai has appointed a Data Protection Officer (DPO) to oversee
        compliance with this policy. If you have any questions or concerns about
        HireHeroes.ai's privacy practices or your rights under the GDPR, you may
        contact our DPO at dpo@HireHeroes.ai.
      </div>
      <div>8.4. Reporting and Compliance</div>
      <div>
        We undertake to report any data breaches in compliance with GDPR
        guidelines to the relevant supervisory authority and data subjects, as
        applicable, within 72 hours of becoming aware of the breach.
      </div>
      <div>9. Contact Information</div>
      <div>
        If you have any questions or concerns about our Privacy Policy, our data
        practices, or our compliance with applicable law, please contact us at
        privacy@HireHeroes.ai.
      </div>
      <div>10. Changes to This Privacy Policy</div>
      <div>
        HireHeroes.ai reserves the right to update or change this Privacy Policy
        at any time. We will post the updated policy on our website, and we will
        notify you of any changes by posting the new Privacy Policy on this
        page. You are advised to review this Privacy Policy periodically for any
        changes.
      </div>
    </div>
  );
};

export default Privacy;
