import Cookies from "js-cookie";
import React, { Suspense, useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { login } from "../../redux/auth/actions";
import { getPartner, selectLoading } from "../../redux/auth/selectors";
import { store } from "../../redux/store";
import AuthService from "../../service/AuthService";
import UserService from "../../service/UserService";
import { Button } from "./components/Button";
import { Img } from "./components/Img";
import UserDetail from "./components/UserDetail";
import Details from "./Details";
import InviteTeam from "./InviteTeam";
import Password from "./setPassword";

const Login = () => {
  const partner = useSelector(getPartner);

  const navigate = useNavigate();
  //const loading = useSelector(selectLoading);

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [step, setStep] = useState(1);
  const [members, setMembers] = useState([
    { key: 1, email: "" },
    { key: 2, email: "" },
  ]);
  const [loading, setLoading] = useState(false);

  const handleAddMember = (e) => {
    e.preventDefault();
    let newmember = { key: members.length + 1, email: "" };
    setMembers([...members, newmember]);
  };

  const handleSubmitTeam = useCallback(
    async (e) => {
      e.preventDefault();

      setLoading(true);

      const newUser = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        password: password,
      };

      await AuthService.register(newUser);

      const result = await AuthService.login({
        email,
        password,
      });
      if (!result?.data?.accessToken)
        return message.error("Could not load user data");

      Cookies.set("accessToken", result?.data?.accessToken);
      Cookies.set("refreshToken", result?.data?.refreshToken);

      const me = await AuthService.me();
      if (!me?.data) return message.error("Could not load user data");

      store.dispatch(login(me.data));

      for (const member of members) {
        if (member.email) {
          let inviteData = { email: member.email };
          try {
            await UserService.inviteUser(inviteData);
            console.log(`Invitation sent to: ${member.email}`);
          } catch (error) {
            console.error(`Error sending to: ${member.email}`, error);
          }
        }
      }

      console.log("All invitations sent.");

      navigate("/auth/otpemail");
    },
    [navigate, firstName, lastName, email, password, members]
  );

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      setLoading(true);
      const newUser = {
        firstName: firstName,
        lastName: lastName,
        email: email,
        password: password,
      };

      await AuthService.register(newUser);

      const result = await AuthService.login({
        email,
        password,
      });
      if (!result?.data?.accessToken)
        return message.error("Could not load user data");

      Cookies.set("accessToken", result?.data?.accessToken);
      Cookies.set("refreshToken", result?.data?.refreshToken);

      const me = await AuthService.me();
      if (!me?.data) return message.error("Could not load user data");

      store.dispatch(login(me.data));

      navigate("/auth/otpemail");
    },
    [navigate, firstName, lastName, email, password]
  );

  useEffect(() => {
    const uuid = window.location.href.split("?uuid=")?.[1];
    if (uuid) {
      localStorage.demoUUID = uuid;
    }
  }, []);

  const steps = [
    {
      step: 0,
      name: "Your details",
      desc: "Please provide your personal details",
    },
    {
      step: 1,
      name: "Choose a password",
      desc: "Must be at least 8 characters",
    },
    {
      step: 2,
      name: "Invite your team",
      desc: "Start collaborating with your team",
    },
  ];

  const data = [
    {
      userDetailsHeading: "Your details",
      userDetailsDescription: "Please provide your personal details",
    },
    {
      userDetailsHeading: "Choose a password",
      userDetailsDescription: "Must be at least 8 characters",
    },
    {
      userDetailsHeading: "Invite your team",
      userDetailsDescription: "Start collaborating with your team",
    },
  ];

  return (
    <>
      <div className="w-full h-full bg-[#f8f8f8] flex">
        <div className="flex w-[30%] min-h-[100vh] flex-col items-start hidden md:block">
          <div className="flex w-full h-full flex-col rounded-[12px] border border-solid border-[#eaecf0] bg-[#ffffff] p-6  md:gap-[330px] sm:gap-[220px] sm:p-5 justify-between">
            <div className="flex flex-col gap-12 ">
              <div className="flex justify-center">
                <Img src="/logo_hh.png" alt="Logo Icon" className="h-[32px]" />
              </div>
              <div className="flex flex-col gap-7">
                <Suspense fallback={<div>Loading feed...</div>}>
                  {data.map((d, index) => (
                    <UserDetail {...d} key={"orderList" + index} />
                  ))}
                </Suspense>
              </div>
              <Link
                size="lg"
                shape="round"
                className="flex justify-center py-3 rounded-lg border border-solid border-[#cfd4dc] px-[33px] font-semibold sm:px-5 mt-10"
                to="/auth/login"
              >
                Already have an account?
              </Link>
            </div>
          </div>
        </div>
        <div className="w-full">
          {step === 1 && (
            <Details
              email={email}
              lastName={lastName}
              firstName={firstName}
              setEmail={setEmail}
              setFirstName={setFirstName}
              setLastName={setLastName}
              step={step}
              setStep={setStep}
            />
          )}
          {step === 2 && (
            <Password
              password={password}
              setPassword={setPassword}
              step={step}
              setStep={setStep}
            />
          )}
          {step === 3 && (
            <InviteTeam
              handleSubmit={handleSubmit}
              handleSubmitTeam={handleSubmitTeam}
              handleAddMember={handleAddMember}
              members={members}
              setMembers={setMembers}
              loading={loading}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Login;
