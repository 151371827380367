import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import { getPartner } from "../../../redux/auth/selectors";
import AcceptableUse from "./AcceptableUse";
import Cookie from "./Cookie";
import Disclaimer from "./Disclaimer";
import Privacy from "./Privacy";
import Terms from "./Terms";

const Legal = () => {
  const partner = useSelector(getPartner);
  return (
    <>
      <Routes>
        {(partner?.termlyPrivacyDataID || partner?.PrivacyText) && (
          <Route path="/privacy" element={<Privacy />} />
        )}
        {(partner?.termlyTermsDataID || partner?.TermsText) && (
          <Route path="/terms" element={<Terms />} />
        )}
        {(partner?.termlyAcceptableUseDataID || partner?.AcceptableUseText) && (
          <Route path="/acceptableUse" element={<AcceptableUse />} />
        )}
        {(partner?.termlyCookieDataID || partner?.CookieText) && (
          <Route path="/cookie" element={<Cookie />} />
        )}
        {(partner?.termlyDisclaimerDataID || partner?.DisclaimerText) && (
          <Route path="/disclaimer" element={<Disclaimer />} />
        )}
        <Route path="/*" element={<Navigate to="/" />} />
      </Routes>
    </>
  );
};

export default Legal;
