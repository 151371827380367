import { Menu, Transition } from "@headlessui/react";
import {
  Button,
  Divider,
  InputNumber,
  Modal,
  Popconfirm,
  Select,
  Spin,
} from "antd";
import classNames from "classnames";
import moment from "moment";
import React, {
  Fragment,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import "react-datepicker/dist/react-datepicker.css";
import { FaFilter } from "react-icons/fa";
import { GrValidate } from "react-icons/gr";
import { MdDelete } from "react-icons/md";
import { RiSortAsc } from "react-icons/ri";
import { useSelector } from "react-redux";
import { STANDARD_MOMENT_FORMAT } from "../../../data/constants";
import {
  getPartner,
  selectDarkMode,
  selectLoading,
} from "../../../redux/auth/selectors";
import AdminLeadsService from "../../../service/AdminLeadsService";
import CrudService from "../../../service/CrudService";
import { capitalizeInitials } from "../CandidateSourcing/ManageCandidates";

const PAGE_LIMIT = 18;
const UserSearch = () => {
  const partner = useSelector(getPartner);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [userModal, setUserModal] = useState(null);
  const [lastScroll, setLastScroll] = useState(0);
  const [sortId, setSortId] = useState("recent_created");
  const [queryFilter, setQueryFilter] = useState("ALL");
  const [page, setPage] = useState(1);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedVacancy, setSelectedVacancy] = useState(null);
  const [selectedVacancyStage, setSelectedVacancyStage] = useState(null);
  const [total, setTotal] = useState(0);
  const [leadAmount, setLeadAmount] = useState(0);
  const [leadType, setLeadType] = useState("B2B");
  const [vacancies, setVacancies] = useState([]);
  const [automations, setAutomations] = useState([]);
  const [negativeKeywords, setNegativeKeywords] = useState([]);
  const [positiveKeywords, setPositiveKeywords] = useState([]);
  const [vacancyStages, setVacancyStages] = useState([]);
  const [possibleTags, setPossibleTags] = useState([]);

  const darkMode = useSelector(selectDarkMode);
  const backendLoading = useSelector(selectLoading);

  const sortQuery = {
    recent_created: { createdAt: -1 },
    oldest_created: { createdAt: 1 },
    recent_activity: { lastActive: -1 },
    oldest_activity: { lastActive: 1 },
  };

  const loadMoreUsers = useCallback(
    async ({ text = undefined, refresh = false, page = 1 }) => {
      setLoading(true);
      try {
        const response = await CrudService.search("User", PAGE_LIMIT, page, {
          sort: sortId ? sortQuery[sortId] : {},
          text,
          filters: {
            ALL: {},
            PARTNER_USER_ONLY: { role: "partner" },
            RECRUITER_USER_ONLY: { role: "recruiter" },
          }[queryFilter],
        });

        const newUsers = response.data.items;
        setUsers((prevUsers) => [
          ...(refresh ? [] : prevUsers),
          ...newUsers.filter((a) => !prevUsers.some((b) => b._id === a._id)),
        ]);
        setPage((prevPage) => prevPage + 1);
        setTotal(response.data.total);
      } catch (e) {
        console.error(e);
      } finally {
        setLoading(false);
      }
    },
    [queryFilter, sortId, partner]
  );

  useEffect(() => {
    loadMoreUsers({});
  }, [loadMoreUsers]);

  useEffect(() => {
    if (userModal) {
      CrudService.search("Vacancy", 10000, 1, {
        filters: { user_id: userModal },
        sort: { createdAt: -1 },
      }).then(({ data }) => {
        setVacancies(data.items);
      });
      CrudService.search("LeadAutomations", 10000, 1, {
        filters: { userId: userModal },
        populate: "stageId",
        sort: { createdAt: -1 },
      }).then(({ data }) => {
        setAutomations(data.items);
      });
    } else {
      setSelectedVacancy(null);
      setVacancies([]);
      setAutomations([]);
      setVacancyStages([]);
      setLeadAmount(0);
      setNegativeKeywords([]);
      setPositiveKeywords([]);
      setLeadType("B2B");
    }
  }, [userModal]);

  useEffect(() => {
    CrudService.search("LeadTags", 10000, 1, {}).then(({ data }) => {
      setPossibleTags(data.items);
    });
  }, []);

  useEffect(() => {
    if (selectedVacancy)
      CrudService.search("VacancyStage", 10000, 1, {
        filters: { vacancyId: selectedVacancy },
        sort: { createdAt: -1 },
      }).then(({ data }) => {
        setVacancyStages(data.items);
      });
    else setSelectedVacancyStage(null);
  }, [selectedVacancy]);

  useEffect(() => {
    if (loading) return;
    const handleScroll = () => {
      const container = document.getElementById("myContainer");

      if (
        container &&
        window.innerHeight + window.scrollY >= container.scrollHeight - 100
      ) {
        loadMoreUsers({ page });
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [page, loading]);

  // Function to perform the actual search
  const performSearch = useCallback((text) => {
    setPage(1);
    loadMoreUsers({
      text: text ? text : undefined,
      refresh: true,
      page: 1,
    });
  }, []);

  // Function to handle the input change with debounce
  const searchTimer = useRef();
  const handleInputChange = (event) => {
    const newValue = event.target.value;
    setSearchTerm(newValue);

    // Delay the execution of the search function by 300 milliseconds (adjust as needed)
    if (searchTimer.current) clearTimeout(searchTimer.current);
    searchTimer.current = setTimeout(() => {
      performSearch(newValue);
    }, 1000);
  };

  return (
    <>
      <div className="relative mt-2 flex items-center">
        <input
          type="text"
          placeholder="Search Users"
          className="block w-full rounded-md border-0 py-1.5 pr-14 text-gray-900 dark:text-gray-400  shadow-sm dark:shadow-gray-400/50  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-900 "
          value={searchTerm}
          onChange={handleInputChange}
        />

        <Menu as="div" className="relative ml-3">
          <div>
            <Menu.Button
              type="button"
              className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 dark:text-gray-400  ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              <RiSortAsc />
              Sort
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white dark:bg-gray-900 py-1 shadow-lg dark:shadow-gray-400/50 hover:shadow-gray-600/50  ring-1 ring-black ring-opacity-5 focus:outline-none">
              {[
                { _id: "recent_created", name: "Newest first" },
                { _id: "oldest_created", name: "Oldest first" },
                { _id: "recent_activity", name: "Recent Activity" },
                { _id: "oldest_activity", name: "Oldest Activity" },
              ].map((item) => (
                <Menu.Item key={item._id}>
                  {({ active }) => (
                    <div
                      className={classNames(
                        active || sortId === item._id
                          ? "bg-gray-100 dark:bg-gray-400 dark:bg-gray-600"
                          : "",
                        "block px-4 py-2 text-sm text-gray-700 dark:text-gray-300  cursor-pointer"
                      )}
                      onClick={() => {
                        setPage(1);
                        setUsers([]);
                        setSortId(item._id);
                      }}
                    >
                      {item.name}
                    </div>
                  )}
                </Menu.Item>
              ))}
            </Menu.Items>
          </Transition>
        </Menu>

        <Menu as="div" className="relative ml-3">
          <div>
            <Menu.Button
              type="button"
              className="relative -ml-px inline-flex items-center gap-x-1.5 rounded-r-md px-3 py-2 text-sm font-semibold text-gray-900 dark:text-gray-400  ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            >
              <FaFilter />
              Filter
            </Menu.Button>
          </div>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-200"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white dark:bg-gray-900 py-1 shadow-lg dark:shadow-gray-400/50 hover:shadow-gray-600/50  ring-1 ring-black ring-opacity-5 focus:outline-none">
              {[
                { _id: "ALL", name: "All users" },
                { _id: "PARTNER_USER_ONLY", name: "Only partners" },
                { _id: "RECRUITER_USER_ONLY", name: "Only recruiters" },
              ].map((item) => (
                <Menu.Item key={item._id}>
                  {({ active }) => (
                    <div
                      className={classNames(
                        active || queryFilter === item._id
                          ? "bg-gray-100 dark:bg-gray-400 dark:bg-gray-600"
                          : "",
                        "block px-4 py-2 text-sm text-gray-700 dark:text-gray-300  cursor-pointer"
                      )}
                      onClick={() => {
                        setPage(1);
                        setUsers([]);
                        setQueryFilter(item._id);
                      }}
                    >
                      {item.name}
                    </div>
                  )}
                </Menu.Item>
              ))}
            </Menu.Items>
          </Transition>
        </Menu>
      </div>

      <div className="container mx-auto p-4" id="myContainer">
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4">
          {users.map((user) => (
            <div
              key={user._id}
              className="max-w-sm rounded-xl overflow-hidden shadow-lg dark:shadow-gray-400/50 hover:shadow-gray-600/50  hover:shadow-2xl transition duration-300 ease-in-out"
            >
              <div className="flex justify-center">
                <img
                  className="h-40 p-4 rounded-full"
                  src={user.avatar}
                  alt=""
                />
              </div>
              <div className="px-6 py-4">
                <div className="flex justify-between">
                  <div className="font-bold text-xl mb-2">
                    {user.firstName} {user.lastName}
                  </div>
                  <div className="flex items-center gap-1">
                    {new Date().getTime() -
                      new Date(user.lastActive).getTime() <=
                      5 * 60 * 1000 && (
                      <span class="rounded-full bg-green-100 px-2.5 py-1 text-xs font-semibold leading-5 text-green-800">
                        Online
                      </span>
                    )}
                    <p className="rounded-full bg-indigo-600 px-2.5 py-1 text-xs font-semibold leading-5 text-white">
                      {capitalizeInitials(user.role)}
                    </p>
                  </div>
                </div>
                <div className="mb-4">
                  <div>
                    <a
                      href={`mailto:${user.email}`}
                      className="text-gray-700 dark:text-gray-300  text-base"
                    >
                      {user.email}
                    </a>
                  </div>
                  <div>
                    <a
                      href={`tel:${user.phone}`}
                      className="text-gray-700 dark:text-gray-300  text-base"
                    >
                      {user.phone}
                    </a>
                  </div>
                </div>

                <div className="text-sm my-2 flex items-center justify-between">
                  <GrValidate
                    className="cursor-pointer text-indigo-500"
                    size={23}
                    onClick={() => {
                      setLastScroll(window.scrollY);
                      setUserModal(user._id);
                    }}
                  />
                </div>

                <p className="text-gray-400 text-[10px]">
                  Joined: {moment(user.createdAt).format("Do MMM, YYYY, HH:mm")}
                </p>
                <p className="text-gray-400 text-[10px]">
                  Activity:{" "}
                  {moment(user.lastActive).format("Do MMM, YYYY, HH:mm")}
                </p>
              </div>
            </div>
          ))}
        </div>

        {total >= PAGE_LIMIT * (page - 1) && (
          <div className="flex justify-center mt-5">
            <Button loading={loading} onClick={() => loadMoreUsers({ page })}>
              Load more
            </Button>
          </div>
        )}
      </div>

      <Modal
        wrapClassName={`${darkMode ? "dark" : ""}`}
        open={!!userModal}
        onCancel={() => setUserModal(null)}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        destroyOnClose
        afterOpenChange={(e) => {
          if (!e) window.scrollTo(0, lastScroll);
        }}
      >
        <div className="mt-5">
          {selectedVacancyStage ? (
            <>
              <Button
                className="mb-5 px-2 py-1 text-sm bg-indigo-500 text-white rounded"
                onClick={() => setSelectedVacancyStage(null)}
              >
                Back
              </Button>

              <div>
                <label className="font-bold">Negative Keywords</label>
                <Select
                  className="mt-2"
                  mode="tags"
                  style={{ width: "100%" }}
                  placeholder="Enter Tags"
                  onChange={(value) => setNegativeKeywords(value)}
                  value={negativeKeywords}
                  options={possibleTags.map((tag) => ({
                    value: tag.text,
                    label: tag.text,
                  }))}
                ></Select>
              </div>
              <div className="mt-5">
                <label className="font-bold">Positive Keywords</label>
                <Select
                  className="mt-2"
                  mode="tags"
                  style={{ width: "100%" }}
                  placeholder="Enter Tags"
                  onChange={(value) => setPositiveKeywords(value)}
                  value={positiveKeywords}
                  options={possibleTags.map((tag) => ({
                    value: tag.text,
                    label: tag.text,
                  }))}
                ></Select>
              </div>
              <div className="mt-5">
                <label className="font-bold">Number of Leads</label>
                <InputNumber
                  className="mt-2 block"
                  placeholder="Enter Number of Leads"
                  onChange={(value) => setLeadAmount(value)}
                  value={leadAmount}
                ></InputNumber>
              </div>
              <div className="mt-5">
                <label className="font-bold">Lead Type</label>
                <Select
                  className="mt-2 block"
                  placeholder="Enter Lead Type"
                  onChange={(value) => setLeadType(value)}
                  value={leadType}
                  options={[
                    { value: "B2B", label: "B2B" },
                    { value: "B2C", label: "B2C" },
                  ]}
                ></Select>
              </div>

              <div className="w-full mt-5 flex justify-end gap-3">
                <Button
                  className="px-2 py-1 text-sm bg-transparent border border-indigo-500 text-indigo-500 rounded"
                  disabled={backendLoading}
                  onClick={async () => {
                    await CrudService.create("LeadAutomations", {
                      userId: userModal,
                      stageId: selectedVacancyStage,
                      positiveKeywords,
                      negativeKeywords,
                      type: leadType,
                      dailyLeads: leadAmount,
                    });
                    await CrudService.search("LeadAutomations", 10000, 1, {
                      filters: { userId: userModal },
                      populate: "stageId",
                      sort: { createdAt: -1 },
                    }).then(({ data }) => {
                      setAutomations(data.items);
                    });
                  }}
                >
                  Schedule
                </Button>
                <Button
                  className="px-2 py-1 text-sm bg-indigo-500 text-white rounded"
                  disabled={backendLoading}
                  onClick={() => {
                    AdminLeadsService.manuallyTransferLeads({
                      stageId: selectedVacancyStage,
                      amount: leadAmount,
                      positiveKeywords,
                      negativeKeywords,
                      leadType,
                    });
                  }}
                >
                  Insert
                </Button>
              </div>
            </>
          ) : selectedVacancy ? (
            <>
              <Button
                className="mb-5 px-2 py-1 text-sm bg-indigo-500 text-white rounded"
                onClick={() => setSelectedVacancy(null)}
              >
                Back
              </Button>

              {vacancyStages.map((vacancyStage) => (
                <div
                  key={vacancyStage._id}
                  className="mt-5 cursor-pointer bg-white px-4 py-5 sm:px-6  overflow-hidden shadow-lg dark:shadow-gray-400/50 hover:shadow-gray-600/50  hover:shadow-2xl"
                  onClick={() => setSelectedVacancyStage(vacancyStage._id)}
                >
                  <div className="flex space-x-3">
                    <div className="flex-shrink-0"></div>
                    <div className="min-w-0 flex-1">
                      <p className="text-sm font-semibold text-gray-900">
                        <div>{vacancyStage?.name}</div>
                      </p>
                      <p className="text-sm text-gray-500">
                        <div>
                          {moment(vacancyStage.createdAt).format(
                            STANDARD_MOMENT_FORMAT
                          )}
                        </div>
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </>
          ) : (
            <>
              <label className="font-bold">Campaigns</label>
              {vacancies.map((vacancy) => (
                <div
                  key={vacancy._id}
                  className="mt-5 mb-5 cursor-pointer bg-white px-4 py-5 sm:px-6  overflow-hidden shadow-lg dark:shadow-gray-400/50 hover:shadow-gray-600/50  hover:shadow-2xl"
                  onClick={() => setSelectedVacancy(vacancy._id)}
                >
                  <div className="flex space-x-3">
                    <div className="flex-shrink-0"></div>
                    <div className="min-w-0 flex-1">
                      <p className="text-sm font-semibold text-gray-900">
                        <div>{vacancy?.alternativeName || vacancy?.name}</div>
                      </p>
                      <p className="text-sm text-gray-500">
                        <div>
                          {moment(vacancy.createdAt).format(
                            STANDARD_MOMENT_FORMAT
                          )}
                        </div>
                      </p>
                    </div>
                  </div>
                </div>
              ))}

              <Divider />
              <label className="font-bold">Active Automations</label>
              {automations.map((automation) => (
                <div
                  key={automation._id}
                  className="mt-5 mb-5 cursor-pointer bg-white px-4 py-5 sm:px-6  overflow-hidden shadow-lg dark:shadow-gray-400/50 hover:shadow-gray-600/50  hover:shadow-2xl"
                >
                  <div className="flex space-x-3">
                    <div className="flex-shrink-0"></div>
                    <div className="min-w-0 flex-1">
                      <p className="text-sm font-semibold text-gray-900">
                        <div>
                          {automation?.dailyLeads ?? 0} per day,{" "}
                          {automation?.type ?? ""},{" "}
                          {automation?.stageId?.name ?? ""}
                        </div>
                        {automation?.positiveKeywords?.length > 0 && (
                          <div>
                            Positive: {`${automation?.positiveKeywords}`}
                          </div>
                        )}
                        {automation?.negativeKeywords?.length > 0 && (
                          <div>
                            Negative: {`${automation?.negativeKeywords}`}
                          </div>
                        )}
                      </p>
                      <p className="text-sm text-gray-500">
                        <div>
                          {moment(automation.createdAt).format(
                            STANDARD_MOMENT_FORMAT
                          )}
                        </div>
                      </p>
                    </div>

                    <Popconfirm
                      title="Are you sure to delete the automation?"
                      onConfirm={async () => {
                        await CrudService.delete(
                          "LeadAutomations",
                          automation._id
                        );
                        await CrudService.search("LeadAutomations", 10000, 1, {
                          filters: { userId: userModal },
                          populate: "stageId",
                          sort: { createdAt: -1 },
                        }).then(({ data }) => {
                          setAutomations(data.items);
                        });
                      }}
                    >
                      <MdDelete
                        size={18}
                        className="cursor-pointer text-red-500 relative"
                      />
                    </Popconfirm>
                  </div>
                </div>
              ))}
            </>
          )}
        </div>
        <div className="mt-5 w-full flex justify-center items-center">
          {backendLoading && <Spin />}
        </div>
      </Modal>
    </>
  );
};

export default UserSearch;
