import { Dialog, Menu, Transition } from "@headlessui/react";
import {
  ChevronDownIcon,
  MagnifyingGlassIcon,
  UsersIcon,
} from "@heroicons/react/20/solid";
import {
  Bars3Icon,
  BellIcon,
  ChevronDoubleRightIcon,
  Cog6ToothIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { Spin } from "antd";
import { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  getPartner,
  selectLoading,
  selectUser,
} from "../../redux/auth/selectors";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Example({
  navigation,
  subMenus,
  userNavigation,
  generalNavigation,
  children,
}) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  const user = useSelector(selectUser);
  const partner = useSelector(getPartner);
  const loading = useSelector(selectLoading);

  const handleSideBar = (e) => {
    e.preventDefault();
    setCollapsed(!collapsed);
  };

  return (
    <div className="flex flex-col h-full flex-grow max-w-[100vw]">
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50 lg:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-900/80" />
          </Transition.Child>

          <div className="fixed inset-0 flex">
            <Transition.Child
              as={Fragment}
              enter="transition ease-in-out duration-300 transform"
              enterFrom="-translate-x-full"
              enterTo="translate-x-0"
              leave="transition ease-in-out duration-300 transform"
              leaveFrom="translate-x-0"
              leaveTo="-translate-x-full"
            >
              <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                <Transition.Child
                  as={Fragment}
                  enter="ease-in-out duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="ease-in-out duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                    <button
                      type="button"
                      className="-m-2.5 p-2.5"
                      onClick={() => setSidebarOpen(false)}
                    >
                      <span className="sr-only">Close sidebar</span>
                      <XMarkIcon
                        className="h-6 w-6 text-white"
                        aria-hidden="true"
                      />
                    </button>
                  </div>
                </Transition.Child>
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <div className="flex flex-col gap-y-5 bg-white dark:bg-gray-900 px-6 pb-4">
                  <div className="flex h-16 shrink-0 items-center">
                    <img
                      className="h-8 w-auto"
                      src={partner?.dashboardLogo || partner?.logo}
                      alt=" "
                    />
                  </div>
                  <nav className="flex flex-1 flex-col">
                    <ul role="list" className="flex flex-1 flex-col gap-y-7">
                      <li>
                        <ul role="list" className="-mx-2 space-y-1">
                          {navigation.map((item) => (
                            <li key={item.name}>
                              <Link
                                to={item.href}
                                target={item.target}
                                className={classNames(
                                  item.current
                                    ? "bg-gradient text-white"
                                    : "text-gray-700 dark:text-gray-300  hover:text-indigo-600 hover:bg-gray-50",
                                  "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                )}
                              >
                                <item.icon
                                  className={classNames(
                                    item.current
                                      ? "text-"
                                      : "text-gray-400 group-hover:text-indigo-600",
                                    "h-6 w-6 shrink-0"
                                  )}
                                  aria-hidden="true"
                                />
                                {item.name}
                              </Link>
                            </li>
                          ))}
                        </ul>
                      </li>
                      {subMenus.map((subMenu, i) => (
                        <li key={i}>
                          <div className="text-xs font-semibold leading-6 text-gray-400">
                            {subMenu.title}
                          </div>
                          <ul role="list" className="-mx-2 mt-2 space-y-1">
                            {subMenu.items.map((team) => (
                              <li key={team.name}>
                                <Link
                                  to={team.href}
                                  target={team.target}
                                  className={classNames(
                                    team.current
                                      ? "bg-gray-50 text-indigo-600"
                                      : "text-gray-700 dark:text-gray-300  hover:text-indigo-600 hover:bg-gray-50",
                                    "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                  )}
                                >
                                  <span
                                    className={classNames(
                                      team.current
                                        ? "text-indigo-600 border-indigo-600"
                                        : "text-gray-400 border-gray-200 dark:border-gray-600  group-hover:border-indigo-600 group-hover:text-indigo-600",
                                      "flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white"
                                    )}
                                  >
                                    {team.initial}
                                  </span>
                                  <span className="truncate">{team.name}</span>
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </li>
                      ))}
                    </ul>
                  </nav>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>

      <div className=" flex flex-col h-full flex-grow min-h-[95vh] gap-5">
        <div
          className=" sticky flex  h-20 shrink-0 items-center gap-x-2 md:gap-x-4 border-b border-gray-200 dark:border-gray-600  dark:border-gray-600 bg-white dark:bg-gray-900 px-2 md:px-4 shadow-sm dark:shadow-gray-400/50  lg:px-8"
          style={{
            borderRadius: 20,
            zIndex: 999,
          }}
        >
          {/* {loading && <Spin />} */}
          <button
            type="button"
            className="-m-1 md:-m-2.5 p-1 text-gray-700 dark:text-gray-300  lg:hidden"
            onClick={() => setSidebarOpen(true)}
          >
            <span className="sr-only">Open sidebar</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>

          {/* Separator */}
          {/* <div
              className="h-6 bg-gray-200 lg:hidden"
              aria-hidden="true"
            /> */}

          <div className="flex flex-1 justify-between m-0">
            <div className="flex items-center ">
              <img
                alt="Logo HireHeroes"
                className="h-8 w-auto hidden md:block"
                src="/logo_hh.png"
              />
              <img
                alt="Logo HireHeroes"
                className="h-8 w-auto block md:hidden "
                src="/logo.png"
              />
            </div>

            <form
              className="flex justify-start md:px-2 my-2 mr-2 rounded-full border-0 md:w-[320px]"
              style={{ backgroundColor: "#F8F8F8" }}
              action="#"
              method="GET"
            >
              <label htmlFor="search-field" className="sr-only">
                Search
              </label>
              <MagnifyingGlassIcon
                className="hidden lg:block pointer-events-none  inset-y-0 left-0  h-full w-5 text-gray-400"
                aria-hidden="true"
              />
              <input
                id="search-field"
                className=" rounded-full bg-[#F8F8F8] border-0 md:py-1 pl-2 pr-0 text-gray-900 dark:text-gray-400  placeholder:text-gray-400 focus:ring-0 sm:text-sm dark:bg-gray-900"
                placeholder="Search..."
                type="search"
              />
            </form>
            <div className="flex items-center my-2 gap-x-2 lg:gap-x-6 lg:flex-none">
              <button
                type="button"
                className="flex item-center -m-2.5 p-1.5 rounded-full border border-gray-100 border-2 text-gray-400 hover:text-gray-500 "
              >
                <span className="sr-only">View notifications</span>
                <BellIcon
                  className="h-4 w-4 md:h-6 md:w-6"
                  aria-hidden="true"
                />
              </button>

              {/* Separator */}
              {/* <div
                  className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-200 dark:lg:bg-gray-600"
                  aria-hidden="true"
                /> */}

              {/* Profile dropdown */}
              <Menu as="div" className="flex z-100 relative">
                <Menu.Button className="md:-m-1.5 flex items-center p-1.5">
                  <span className="sr-only">Open user menu</span>
                  {!user?.avatar && (
                    <UsersIcon className="h-8 w-8 rounded-full bg-gray-50" />
                  )}
                  {user?.avatar && (
                    <img
                      className="h-8 w-8 rounded-full bg-gray-50"
                      src={user?.avatar}
                      alt=""
                    />
                  )}
                  <span className="hidden lg:flex lg:items-center">
                    {/* <span
                        className="ml-4 text-sm font-semibold leading-6 text-gray-900 dark:text-gray-400 "
                        aria-hidden="true"
                      >
                        {user?.firstName} {user?.lastName}
                      </span> */}
                    <ChevronDownIcon
                      className="ml-2 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                  </span>
                </Menu.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0  mt-2.5 w-48 origin-top-right rounded-md bg-white dark:bg-gray-900 py-2 shadow-lg dark:shadow-gray-400/50 hover:shadow-gray-600/50  ring-1 ring-gray-900/5 focus:outline-none">
                    {userNavigation.map((item) => (
                      <Menu.Item key={item.name}>
                        {({ active }) => (
                          <Link
                            to={item.href}
                            target={item.target}
                            onClick={item.onClick}
                            className={classNames(
                              active ? "bg-gray-50 dark:bg-gray-700" : "",
                              "block px-3 py-1 text-sm leading-6 text-gray-900 dark:text-gray-400 "
                            )}
                          >
                            {item.name}
                          </Link>
                        )}
                      </Menu.Item>
                    ))}
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </div>
        </div>
        <div className="flex w-full h-full gap-6 flex-1 ">
          {/* Static sidebar for desktop */}
          <div className="hidden  lg:z-50  lg:flex lg:flex-col lg:flex-y-grow lg:sticky">
            {/* Sidebar component, swap this element with another sidebar if you like */}
            <div
              className="flex flex-1 flex-col h-full bg-white px-4  rounded-xl transition-all duration-200 dark:bg-gray-500 justify-start"
              style={{
                // zIndex: 2,
                borderRadius: 20,
                width: collapsed ? 90 : 260,
                paddingInline: collapsed ? 10 : 16,
              }}
            >
              <nav className="flex  flex-col ">
                <ul role="list" className="flex flex-col gap-y-7 py-2">
                  <li>
                    <ul role="list" className="space-y-1">
                      <h5
                        className={classNames(
                          `my-3 text-[#667085] font-inter text-xs leading-5 font-semibold ${
                            collapsed ? "flex justify-center" : ""
                          }`
                        )}
                      >
                        MENU
                      </h5>
                      {navigation.map((item) => (
                        <li key={item.name}>
                          <Link
                            to={item.href}
                            target={item.target}
                            className={classNames(
                              item.current
                                ? "bg-gradient text-white current dark:bg-gray-600 dark:text-gray-400"
                                : "text-[#344054] dark:text-gray-300  hover:text-indigo-600 hover:bg-gray-50",
                              `
                            group flex rounded-md p-2 text-base font-medium
                            transition-all duration-200 ${
                              collapsed ? "flex justify-center" : ""
                            }`
                            )}
                          >
                            <div className="flex items-center gap-x-3">
                              <item.icon
                                className={classNames(
                                  item.current
                                    ? "text-white"
                                    : "text-[#344054] group-hover:text-indigo-600",
                                  "h-6 w-6 shrink-0"
                                )}
                                aria-hidden="true"
                              />
                              {!collapsed && item.name}
                            </div>
                          </Link>
                        </li>
                      ))}
                      {/* </ul>
                  <ul role="list" className="space-y-1"> */}
                      <h5
                        className={classNames(
                          `my-3 text-[#667085] font-inter text-xs leading-5 font-semibold ${
                            collapsed ? "flex justify-center" : ""
                          }`
                        )}
                      >
                        GENERAL
                      </h5>
                      {generalNavigation.map((item) => (
                        <li key={item.name}>
                          <Link
                            to={item.href}
                            target={item.target}
                            className={classNames(
                              item.current
                                ? "bg-gradient text-white current dark:bg-gray-600 dark:text-gray-400"
                                : "text-[#344054] dark:text-gray-300  hover:text-indigo-600 hover:bg-gray-50",
                              `
                            group flex rounded-md p-2 text-base font-medium
                            transition-all duration-200 ${
                              collapsed ? "flex justify-center" : ""
                            }`
                            )}
                          >
                            <div className="flex items-center gap-x-3">
                              <item.icon
                                className={classNames(
                                  item.current
                                    ? "text-white"
                                    : "text-[#344054] group-hover:text-indigo-600",
                                  "h-6 w-6 shrink-0"
                                )}
                                aria-hidden="true"
                              />
                              {!collapsed && item.name}
                            </div>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </li>
                  {subMenus.map((subMenu, i) => (
                    <li key={i}>
                      <div className="text-xs font-semibold leading-6 text-gray-400">
                        {subMenu.title}
                      </div>
                      <ul role="list" className="-mx-2 mt-2 space-y-1">
                        {subMenu.items.map((team) => (
                          <li key={team.name}>
                            <Link
                              to={team.href}
                              target={team.target}
                              className={classNames(
                                team.current
                                  ? "bg-gray-50 text-indigo-600"
                                  : "text-gray-700 dark:text-gray-300  hover:text-indigo-600 hover:bg-gray-50",
                                "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                              )}
                            >
                              <span
                                className={classNames(
                                  team.current
                                    ? "text-indigo-600 border-indigo-600"
                                    : "text-gray-400 border-gray-200 dark:border-gray-600  group-hover:border-indigo-600 group-hover:text-indigo-600",
                                  "flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white"
                                )}
                              >
                                {team.initial}
                              </span>
                              <span className="truncate">{team.name}</span>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </li>
                  ))}
                </ul>
              </nav>
              <div className="w-full flex justify-center items-center h-10 mb-5 cursor-pointer">
                <div
                  className="bg-gradient text-white dark:bg-gray-600 dark:text-gray-400 rounded-md "
                  style={{ transform: collapsed ? "" : "rotate(180deg)" }}
                  onClick={handleSideBar}
                >
                  <ChevronDoubleRightIcon
                    className="text-white h-3 w-6 shrink-0 my-2 mx-3"
                    aria-hidden="true"
                  />
                </div>
              </div>
            </div>
          </div>
          <main className="flex flex-1 ">
            <div className="flex flex-1">{children}</div>
          </main>
        </div>
      </div>
    </div>
  );
}
