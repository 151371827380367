import { Skeleton } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import MultiStepComponent from "../../components/MultiStepComponent";
import { selectLoading, selectUser } from "../../redux/auth/selectors";
import AuthService from "../../service/AuthService";
import FormMultiStep from "./vacancies/FormMultiStep";

const AgencyOnboarding = () => {
  const loading = useSelector(selectLoading);
  const user = useSelector(selectUser);
  const navigate = useNavigate();

  if (!user) return <Skeleton active />;
  return (
    <>
      <FormMultiStep
        loading={loading}
        defaultFormData={user?.onboardingForm}
        displayUndoRedo
        displaySteps={false}
        wrapperClassName="h-[60vh]"
        steps={[
          {
            id: "step1",
            name: "Sign-Up Information",
            form: [
              {
                fieldName: "signupCapacity",
                label:
                  "In what capacity are you signing up for our beta launch?",
                type: "select",
                options: [
                  { value: "ceoFounder", label: "CEO/Founder" },
                  { value: "leaderManager", label: "Leader/Manager" },
                  { value: "ctoCio", label: "CTO/CIO" },
                  { value: "hrManagerDirector", label: "HR Manager/Director" },
                  {
                    value: "talentAcquisitionSpecialist",
                    label: "Talent Acquisition Specialist",
                  },
                  { value: "recruiter", label: "Recruiter" },
                  { value: "hiringManager", label: "Hiring Manager" },
                  { value: "agencyOwner", label: "Agency Owner" },
                  { value: "other", label: "Other" },
                ],
              },
            ],
          },
          {
            id: "step2",
            name: "Company Information",
            form: [
              {
                fieldName: "companyType",
                label: "What type of company do you work for?",
                type: "select",
                options: [
                  { value: "recruitmentAgency", label: "Recruitment Agency" },
                  {
                    value: "company0_50",
                    label: "Company with 0-50 employees",
                  },
                  {
                    value: "company50_250",
                    label: "Company with 50-250 employees",
                  },
                  {
                    value: "company250_1000",
                    label: "Company with 250-1000 employees",
                  },
                  {
                    value: "company1000Plus",
                    label: "Company with 1000+ employees",
                  },
                ],
              },
              {
                fieldName: "vacantRoles",
                label:
                  "How many roles do you have vacant on average (your best guess)?",
                type: "select",
                options: [
                  { value: "1_10", label: "1-10" },
                  { value: "11_50", label: "11-50" },
                  { value: "51_100", label: "51-100" },
                  { value: "101_500", label: "101-500" },
                  { value: "moreThan500", label: "More than 500" },
                ],
              },
              {
                fieldName: "industry",
                label: "What industry is your company active in?",
                type: "select",
                options: [
                  { value: "technology", label: "Technology" },
                  { value: "healthcare", label: "Healthcare" },
                  { value: "finance", label: "Finance" },
                  { value: "manufacturing", label: "Manufacturing" },
                  { value: "retail", label: "Retail" },
                  { value: "education", label: "Education" },
                  { value: "other", label: "Other" },
                ],
              },
              {
                fieldName: "companyLocation",
                label: "Where is your company located?",
                type: "select",
                options: [
                  { value: "northAmerica", label: "North America" },
                  { value: "europe", label: "Europe" },
                  { value: "asia", label: "Asia" },
                  { value: "southAmerica", label: "South America" },
                  { value: "africa", label: "Africa" },
                  { value: "australia", label: "Australia" },
                  { value: "other", label: "Other" },
                ],
              },
            ],
          },
          {
            id: "step3",
            name: "Product Fit and Challenges",
            form: [
              {
                fieldName: "roleFit",
                label:
                  "For what type of role would you see this AI and automation having the best fit?",
                type: "select",
                options: [
                  { value: "entryLevel", label: "Entry-level" },
                  { value: "midLevel", label: "Mid-level" },
                  { value: "seniorLevel", label: "Senior-level" },
                  { value: "technicalRoles", label: "Technical roles" },
                  { value: "nonTechnicalRoles", label: "Non-technical roles" },
                  { value: "executivePositions", label: "Executive positions" },
                ],
              },
              {
                fieldName: "biggestChallenge",
                label: "What is your biggest challenge in recruitment?",
                type: "select",
                options: [
                  {
                    value: "sourcingQualityCandidates",
                    label: "Sourcing quality candidates",
                  },
                  {
                    value: "screeningEvaluatingCandidates",
                    label: "Screening and evaluating candidates",
                  },
                  {
                    value: "reducingTimeToHire",
                    label: "Reducing time-to-hire",
                  },
                  {
                    value: "reducingCostPerHire",
                    label: "Reducing cost-per-hire",
                  },
                  {
                    value: "ensuringDiversityInclusion",
                    label: "Ensuring diversity and inclusion",
                  },
                  { value: "other", label: "Other" },
                ],
              },
            ],
          },
          {
            id: "step4",
            name: "Open-ended (Optional)",
            form: [
              {
                fieldName: "additionalComments",
                label:
                  "Do you have any additional comments or feedback for the team?",
                type: "textarea",
                placeholder: "Any additional comments or feedback",
              },
            ],
          },
        ]}
        onFinish={async (formData) => {
          await AuthService.updateMe({
            onboardingForm: formData,
            silent: true,
          });

          navigate("/dashboard/home");
        }}
        onNext={async (formData) => {
          await AuthService.updateMe({
            onboardingForm: formData,
            silent: true,
          });
        }}
      />
    </>
  );
};

export default AgencyOnboarding;
