import {
  Avatar,
  Button,
  Dropdown,
  FloatButton,
  Modal,
  Space,
  Spin,
  Table,
  Tag,
  Tooltip,
  message,
} from "antd";
import React, { useEffect, useRef, useState } from "react";
import { CgUnavailable } from "react-icons/cg";
import {
  FaAngellist,
  FaFacebook,
  FaGithub,
  FaLinkedin,
  FaPhone,
  FaTwitter,
} from "react-icons/fa";
import { IoMdWarning } from "react-icons/io";
import {
  MdOutgoingMail,
  MdOutlineGppMaybe,
  MdOutlinePending,
  MdOutlineVerifiedUser,
} from "react-icons/md";
import { VscWorkspaceUnknown } from "react-icons/vsc";
import { useSelector } from "react-redux";
import {
  getPartner,
  selectDarkMode,
  selectLoading,
} from "../../../redux/auth/selectors";
import CrudService from "../../../service/CrudService";
import SharedComponent from "./SharedComponent";

const DEFAULT_PAGE_SIZE = 25;

const stringToColour = (str) => {
  if (!str) return undefined;
  let hash = 0;
  str.split("").forEach((char) => {
    hash = char.charCodeAt(0) + ((hash << 5) - hash);
  });
  let colour = "#";
  for (let i = 0; i < 3; i++) {
    const value = (hash >> (i * 8)) & 0xff;
    colour += value.toString(16).padStart(2, "0");
  }
  return colour;
};

export const capitalizeInitials = (inputString) => {
  if (!inputString) return "";
  return inputString
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

const ManageCandidates = () => {
  const [searchParams, setSearchParams] = useState({
    page: 1,
    per_page: DEFAULT_PAGE_SIZE,
  });
  const [data, setData] = useState([]);
  const [phoneCall, setPhoneCall] = useState(null);
  const [emailProspect, setEmailProspect] = useState(null);
  const [importProspect, setImportProspect] = useState(null);
  const [smsProspect, setSmsProspect] = useState(null);
  const [vacancies, setVacancies] = useState([]);
  const [addManualEmail, setAddManualEmail] = useState(null);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: DEFAULT_PAGE_SIZE,
    showSizeChanger: false,
  });

  const partner = useSelector(getPartner);
  const darkMode = useSelector(selectDarkMode);
  const loading = useSelector(selectLoading);

  const fetchData = async (params) => {
    try {
      const response = await CrudService.search(
        "ApolloPeople",
        params.per_page,
        params.page,
        {
          sort: { createdAt: -1, first_name: 1, last_name: 1 },
          text: params?.text ?? undefined,
        }
      );
      if (!response.data?.items) return;
      setSelectedRowKeys([]);
      setData(response.data.items);
      setPagination({
        ...pagination,
        current: params.page,
        total: response.data.total,
      });
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData({
      page: pagination.current,
      per_page: pagination.pageSize,
    });
  }, []);

  // Function to perform the actual search
  const performSearch = (text) => {
    fetchData({
      page: 1,
      per_page: DEFAULT_PAGE_SIZE,
      text,
    });
  };

  // Function to handle the input change with debounce
  const searchTimer = useRef();
  const handleInputChange = (event) => {
    const newValue = event.target.value;
    setSearchTerm(newValue);

    // Delay the execution of the search function by 300 milliseconds (adjust as needed)
    if (searchTimer.current) clearTimeout(searchTimer.current);
    searchTimer.current = setTimeout(() => {
      performSearch(newValue);
    }, 700);
  };

  const handleTableChange = (pagination) => {
    setSearchParams({
      ...searchParams,
      page: pagination.current,
      per_page: pagination.pageSize,
    });
  };

  const renderLink = (url, Icon) =>
    url ? (
      <a href={url} target="_blank">
        <Icon />
      </a>
    ) : (
      <></>
    );

  const columns = [
    {
      title: "Full name",
      dataIndex: "name",
      key: "name",
      render: (a, record) => (
        <Space>
          <Avatar src={record.photo_url} />
          <div>{a}</div>
          {record?.phone_numbers?.map?.((num) => (
            <>
              {partner?.twilioAccountSID && partner?.twilioTwimlAppSID ? (
                <a
                  title={`${capitalizeInitials(
                    num?.type?.replace?.(/\_/g, " ")
                  )}: ${num?.raw_number ?? ""}`}
                  onClick={() => {
                    setPhoneCall({
                      ...record,
                      phone: num?.sanitized_number,
                    });
                  }}
                >
                  <FaPhone />
                </a>
              ) : (
                <a
                  title={`${capitalizeInitials(
                    num?.type?.replace?.(/\_/g, " ")
                  )}: ${num?.raw_number ?? ""}`}
                  href={`tel:${num?.sanitized_number}`}
                >
                  <FaPhone />
                </a>
              )}
            </>
          ))}
        </Space>
      ),
    },
    { title: "Title", dataIndex: "title", key: "title" },
    {
      title: "Headline",
      dataIndex: "headline",
      key: "headline",
      render: (a) => (
        <div
          title={a}
          className="whitespace-nowrap max-w-[150px] text-ellipsis truncate"
        >
          {a}
        </div>
      ),
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      render: (a, record) => (
        <div className="flex gap-1">
          {record?.personal_emails?.length > 0 ? (
            record?.personal_emails?.map?.((email, i) => (
              <Dropdown
                menu={{
                  items: [
                    {
                      key: "1",
                      label: <div>Import into funnel</div>,
                      onClick: () => {
                        setImportProspect({
                          ...record,
                          email,
                          phone: record?.phone_numbers?.[0]?.sanitized_number,
                        });
                        CrudService.search("Vacancy", 20, 1, {}).then(
                          ({ data }) => {
                            setVacancies(data.items);
                          }
                        );
                      },
                    },
                    {
                      key: "2",
                      label: <div>Send email</div>,
                      onClick: () => setEmailProspect({ ...record, email }),
                    },
                    {
                      key: "3",
                      label: <div>Copy email: {email}</div>,
                      onClick: () => {
                        navigator.clipboard.writeText(email);
                        message.success("Copied to clipboard");
                      },
                    },
                  ],
                }}
              >
                <MdOutgoingMail
                  className="cursor-pointer"
                  key={i}
                  color="green"
                />
              </Dropdown>
            ))
          ) : (
            <Tooltip
              title="Click to add email manually"
              className="cursor-pointer"
              onClick={() => setAddManualEmail(record)}
            >
              <MdOutgoingMail />
            </Tooltip>
          )}
        </div>
      ),
    },
    {
      title: "Email Status",
      dataIndex: "email_status",
      key: "email_status",
      render: (a) => (
        <>
          {{
            verified: (
              <div title="Verified">
                <MdOutlineVerifiedUser color="green" />
              </div>
            ),
            guessed: (
              <div title="Guessed">
                <MdOutlineGppMaybe />
              </div>
            ),
            unavailable: (
              <div title="Unavailable">
                <CgUnavailable />
              </div>
            ),
            bounced: (
              <div title="Bounced">
                <IoMdWarning color="red" />
              </div>
            ),
            pending_manual_fulfillment: (
              <div title="Pending Manual Fulfillment">
                <MdOutlinePending />
              </div>
            ),
          }?.[a] ?? <VscWorkspaceUnknown />}
        </>
      ),
    },
    {
      title: "Likely to Reply",
      dataIndex: "is_likely_to_engage",
      key: "is_likely_to_engage",
      render: (a) => (
        <>{a ? <Tag color="green">Yes</Tag> : <Tag color="red">No</Tag>}</>
      ),
    },
    {
      title: "Social Links",
      key: "links",
      render: (text, record) => (
        <Space>
          {renderLink(record?.linkedin_url ?? null, FaLinkedin)}
          {renderLink(record?.twitter_url ?? null, FaTwitter)}
          {renderLink(record?.facebook_url ?? null, FaFacebook)}
          {renderLink(record?.github_url ?? null, FaGithub)}
          {renderLink(record?.angellist_url ?? null, FaAngellist)}
        </Space>
      ),
    },
    { title: "City", dataIndex: "city", key: "city" },
    { title: "State", dataIndex: "state", key: "state" },
    { title: "Country", dataIndex: "country", key: "country" },
    {
      title: "Company",
      dataIndex: "organization",
      key: "organization_name",
      render: (a) => (
        <Space>
          <Avatar src={a?.logo_url} />
          <a href={a?.website_url} target="_blank">
            {a?.name ?? ""}
          </a>
          {renderLink(a?.linkedin_url ?? null, FaLinkedin)}
          {renderLink(a?.twitter_url ?? null, FaTwitter)}
          {renderLink(a?.facebook_url ?? null, FaFacebook)}
          {renderLink(a?.github_url ?? null, FaGithub)}
          {renderLink(a?.angellist_url ?? null, FaAngellist)}
        </Space>
      ),
    },
    {
      title: "Departments",
      dataIndex: "departments",
      key: "departments",
      render: (a) => (
        <Space direction="vertical">
          {a?.map?.((x) => (
            <Tag color={stringToColour(x)}>
              {capitalizeInitials(x?.replace?.(/\_/g, " "))}
            </Tag>
          ))}
        </Space>
      ),
    },
    {
      title: "Sub Departments",
      dataIndex: "subdepartments",
      key: "subdepartments",
      render: (a) => (
        <Space direction="vertical">
          {a?.map?.((x) => (
            <Tag color={stringToColour(x)}>
              {capitalizeInitials(x?.replace?.(/\_/g, " "))}
            </Tag>
          ))}
        </Space>
      ),
    },
    {
      title: "Functions",
      dataIndex: "functions",
      key: "functions",
      render: (a) => (
        <Space direction="vertical">
          {a?.map?.((x) => (
            <Tag color={stringToColour(x)}>
              {capitalizeInitials(x?.replace?.(/\_/g, " "))}
            </Tag>
          ))}
        </Space>
      ),
    },
    {
      title: "Seniority",
      dataIndex: "seniority",
      key: "seniority",
      render: (a) => (
        <Space direction="vertical">
          <Tag color={stringToColour(a)}>
            {capitalizeInitials(a?.replace(/\_/g, " "))}
          </Tag>
        </Space>
      ),
    },
  ];

  return (
    <div>
      {selectedRowKeys?.length > 0 && (
        <FloatButton
          shape="square"
          type="primary"
          style={{ right: 94, bottom: 10, width: 150, margin: 10 }}
          icon={
            loading ? (
              <Spin>
                <div>Delete ({selectedRowKeys.length})</div>
              </Spin>
            ) : (
              <div>Delete ({selectedRowKeys.length})</div>
            )
          }
          className="text-sm text-indigo-500 text-white rounded"
          loading={loading}
          onClick={async () => {
            if (loading) return;
            await Promise.all(
              selectedRowKeys
                .map((_id) => data.find((d) => d._id === _id))
                .filter((a) => !!a)
                .map(
                  async (data) =>
                    await CrudService.delete("ApolloPeople", data._id)
                )
            );
            document.dispatchEvent(new CustomEvent("APOLLO_PEOPLE_UPDATE"));
            setData((d) => [
              ...d.filter((x) => !selectedRowKeys.includes(x._id)),
            ]);
            setSelectedRowKeys([]);
          }}
        />
      )}

      <input
        type="text"
        placeholder="Search Users"
        className="block w-full rounded-md border-0 py-1.5 pr-14 text-gray-900 dark:text-gray-400  shadow-sm dark:shadow-gray-400/50  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-900 "
        value={searchTerm}
        onChange={handleInputChange}
      />

      <div className="overflow-auto">
        <Table
          className="mt-2"
          columns={columns}
          dataSource={data}
          rowSelection={{
            selectedRowKeys,
            onChange: (newSelectedRowKeys, selectedRows) => {
              setSelectedRowKeys(newSelectedRowKeys);
            },
          }}
          rowKey={"_id"}
          pagination={{
            ...pagination,
            onChange: (e) => {
              const newSearchParams = {
                ...searchParams,
                page: e,
                per_page: pagination.pageSize,
              };
              setSearchParams(newSearchParams);
              fetchData(newSearchParams);
            },
          }}
          onChange={handleTableChange}
        />
      </div>

      <Modal
        wrapClassName={`${darkMode ? "dark" : ""}`}
        open={!!addManualEmail}
        onCancel={() => setAddManualEmail(null)}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        destroyOnClose
        title="Manual Email Entry"
      >
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            const email = e.target[0].value;
            const personal_emails = [
              ...(addManualEmail?.personal_emails ?? []),
              email,
            ];

            await CrudService.update("ApolloPeople", addManualEmail._id, {
              personal_emails,
            });
            setData((data) => {
              const current = [...data];
              const thisPerson = current.find(
                (a) => a._id === addManualEmail._id
              );
              thisPerson.personal_emails = personal_emails;

              return current;
            });
            setAddManualEmail(null);
          }}
        >
          <div className="mb-2 mt-5">
            <input
              type="email"
              className="w-full mt-2 dark:bg-gray-900"
              placeholder="Email"
              defaultValue={importProspect?.email}
            />
          </div>
          <div className="w-full justify-end flex mt-2">
            <Button
              className="text-sm bg-indigo-500 text-white rounded"
              loading={loading}
              htmlType="submit"
            >
              Add
            </Button>
          </div>
        </form>
      </Modal>
      <SharedComponent
        importProspect={importProspect}
        setImportProspect={setImportProspect}
        emailProspect={emailProspect}
        setEmailProspect={setEmailProspect}
        setVacancies={setVacancies}
        vacancies={vacancies}
        phoneCall={phoneCall}
        setPhoneCall={setPhoneCall}
        smsProspect={smsProspect}
        setSmsProspect={setSmsProspect}
      />
    </div>
  );
};

export default ManageCandidates;
