import axios from "axios";
import { getBackendUrl } from "./getBackendUrl";
import { middleField } from "./middlefield";

class ZoomService {
  constructor(baseURL) {
    this.api = axios.create({
      baseURL,
    });
    middleField(this.api);
  }

  getAuthURI() {
    return this.api.get(
      `/getAuthURI?redirect_uri=${window.location.origin.replace(
        "http://",
        "https://"
      )}/zoomAuth`
    );
  }
  requestToken({ code, redirect_uri }) {
    return this.api.post("/requestToken", { code, redirect_uri });
  }
  getCurrentToken() {
    return this.api.get("/getCurrentToken");
  }
  disconnectZoom() {
    return this.api.delete("/disconnectZoom");
  }
  getMeetings() {
    return this.api.get("/getMeetings");
  }
}

export default new ZoomService(`${getBackendUrl()}/zoom`);
