import { Alert, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import MultiStepComponent from "../../components/MultiStepComponent";
import { eeoForm, personalDataCollection } from "../../data/constants";
import PublicService from "../../service/PublicService";
import { Footer } from "../Landing/Footer";
import Finished from "./Finished";
import LinkedinSearch from "./LinkedinSearch";

const ApplyForm = ({ reset }) => {
  let [searchParams] = useSearchParams();
  const [vacancyData, setVacancyData] = useState(null);
  const [finished, setFinished] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const id = searchParams.get("id");
    if (!id) return;
    setVacancyData(null);

    PublicService.getVacancyData(id).then((res) => {
      if (!res.data) return;
      setVacancyData(res.data);
    });
  }, [searchParams]);

  if (finished) return <Finished reset={reset} />;
  if (!vacancyData) return <Skeleton active />;
  if (!vacancyData.enabled)
    return (
      <div className="p-5">
        <Alert
          type="info"
          message="The vacancy you are looking for is no longer available."
        />
      </div>
    );

  if (!vacancyData?.form)
    return (
      <Alert
        type="warning"
        message="This funnel is not setup"
        className="m-4"
      />
    );

  if (loading) return <Skeleton active />;
  return (
    <>
      <div className="content">
        <div
          className="w-full border-box pt-4 pl-8 pr-8 pb-4"
          style={{
            background: "center center / cover no-repeat transparent",
          }}
        >
          <div className="mx-auto max-w-md sm:max-w-xl w-full md:max-w-3xl lg:max-w-4xl">
            <div className="transition-wrapper" style={{}}>
              <div className="wrapper break-words text-left flex items-center flex-col responsive">
                <h2 className="font-bold text-3xl">Application</h2>
              </div>
            </div>
          </div>
        </div>

        <MultiStepComponent
          loading={loading}
          displaySteps={vacancyData.displaySteps}
          AIEnhancements={vacancyData.AIEnhancements}
          steps={[
            ...(Array.isArray(vacancyData.form)
              ? vacancyData.form
              : typeof vacancyData.form === "object"
              ? Object.values(vacancyData.form)
              : []),
            vacancyData?.eeodc
              ? {
                  id: "eeodc",
                  name: "EEODC",
                  form: [
                    {
                      type: "custom",
                      CustomInputComponent: () => (
                        <>
                          <h2 className="font-bold text-lg">
                            EEO Data Collection
                          </h2>
                          <Alert
                            type="info"
                            message="The following questions are part of our commitment to ensuring equal opportunities. Participation in this data collection is entirely voluntary, and all questions are optional. Your responses will not influence any individual hiring decisions. Instead, the data collected will be aggregated across multiple participants. This process is designed exclusively for our internal use, to monitor our progress towards achieving specific goals related to ensuring equal employment opportunities. Should you prefer not to respond to any query, please feel free to proceed to the next section of the form."
                          />
                        </>
                      ),
                    },
                    ...eeoForm,
                  ],
                }
              : null,
            {
              id: "contact",
              name: "Contact Information",
              form: [
                ...personalDataCollection,
                {
                  type: "custom",
                  fieldName: "linkedInUrl",
                  CustomInputComponent: ({ onChange, value }) => (
                    <>
                      <LinkedinSearch onChange={onChange} value={value} />
                    </>
                  ),
                },
              ],
            },
          ].filter((a) => !!a)}
          onFinish={async (formData) => {
            const id = searchParams.get("id");
            if (!id) return;
            if (loading) return;

            setLoading(true);
            PublicService.createVacancySubmission({
              VacancyId: id,
              formData,
              funnelUUID: localStorage?.[`funnelUUID_${id}`],
              tracking: {
                utm_campaign: searchParams.get("utm_campaign") ?? null,
                utm_source: searchParams.get("utm_source") ?? null,
                utm_medium: searchParams.get("utm_medium") ?? null,
                utm_content: searchParams.get("utm_content") ?? null,
                utm_term: searchParams.get("utm_term") ?? null,
                salesforce_uuid: searchParams.get("salesforce_uuid") ?? null,
              },
            })
              .then((res) => {
                if (!res.data) return;
                setVacancyData(res.data);
                localStorage[`vacancyApplied_${id}`] = "true";
                setFinished(true);
              })
              .catch((e) => reset())
              .finally(() => {
                setLoading(false);
              });
          }}
        />
      </div>
      <Footer />
    </>
  );
};

export default ApplyForm;
