import { Divider, Drawer, Rate, Space, Tooltip } from "antd";
import moment from "moment";
import { useSelector } from "react-redux";
import { getPartner } from "../../../../redux/auth/selectors";
import { useState } from "react";
import {
  EnvelopeIcon,
  PencilSquareIcon,
  NoSymbolIcon,
  CheckIcon,
  CalendarIcon,
  PhoneIcon,
  ChatBubbleLeftEllipsisIcon,
  DocumentIcon,
  ChartBarIcon,
} from "@heroicons/react/24/outline";
import { FaLinkedin } from "react-icons/fa";

export default function ({
  children: card,
  dragging,
  allowRemoveCard,
  onCardRemove,
  onCardOptionClick,
}) {
  const partner = useSelector(getPartner);
  const isPhoneDisabled = !card.phone || !partner?.twilioAccountSID || true;
  const isSMSDisabled =
    !card.phone ||
    !partner?.twilioAccountSID ||
    partner?.twilioTwimlAppSID ||
    true;
  const tooltipMessage = "Configure Twilio account to enable";

  return (
    <>
      <div
        className={`react-kanban-card dark:bg-gray-700 rounded-lg ${
          card.rejected ? "bg-red-100 dark:bg-red-800" : ""
        } ${dragging ? "react-kanban-card--dragging" : ""}`}
      >
        <div className="react-kanban-card__title flex flex-col ">
          <div className="flex justify-between">
            <div>Name:</div>
            <div className="font-bold">{card.fullname}</div>
          </div>
          <div className="flex justify-between">
            <div>Date:</div>
            <div className="font-bold">
              {moment(card.createdAt).calendar(null, {
                sameDay: "[Today]",
                lastDay: "[Yesterday]",
                lastWeek: "dddd",
                sameElse: "MMM D, YYYY",
              })}
            </div>
          </div>
          <div className="flex justify-between">
            <div>Rate:</div>
            <div className="font-bold">{card.stars ?? 0}/5</div>
          </div>
          {allowRemoveCard && (
            <span
              style={{ cursor: "pointer" }}
              onClick={() => onCardRemove(card)}
            >
              ×
            </span>
          )}
          <Divider className="my-2" />
          <div className="grid grid-cols-4 gap-x-2 gap-y-2">
            <div className="border border-solid p-2 rounded-lg flex items-center justify-center">
              <Tooltip title={isSMSDisabled ? tooltipMessage : ""}>
                <a
                  href="#"
                  title="Send SMS"
                  onClick={() =>
                    !isSMSDisabled && onCardOptionClick(card.id, "sms")
                  }
                  style={{
                    opacity: isSMSDisabled ? 0.5 : 1,
                  }}
                >
                  <ChatBubbleLeftEllipsisIcon width={20} />
                </a>
              </Tooltip>
            </div>
            <div className="border border-solid p-2 rounded-lg flex items-center justify-center">
              <a
                title="Send Email"
                onClick={() => onCardOptionClick(card.id, "email")}
              >
                <EnvelopeIcon width={20} />
              </a>
            </div>
            <div className="border border-solid p-2 rounded-lg flex items-center justify-center">
              {partner?.calendlyclientId && !card.scheduledAt && (
                <a
                  href="#"
                  title="Schedule"
                  onClick={() => onCardOptionClick(card.id, "schedule")}
                >
                  <CalendarIcon width={20} />
                </a>
              )}
            </div>
            <div className="border border-solid p-2 rounded-lg flex items-center justify-center">
              <Tooltip title={isPhoneDisabled ? tooltipMessage : ""}>
                <a
                  href="#"
                  title="Call"
                  onClick={() =>
                    !isPhoneDisabled && onCardOptionClick(card.id, "phone")
                  }
                  style={{
                    opacity: isPhoneDisabled ? 0.5 : 1,
                  }}
                >
                  <PhoneIcon width={20} />
                </a>
              </Tooltip>
            </div>
            <div className="border border-solid p-2 rounded-lg flex items-center justify-center">
              <a
                href="#"
                title="Details"
                onClick={(e) => {
                  onCardOptionClick(card.id, "details-modal");
                }}
              >
                <ChartBarIcon width={20} />
              </a>
            </div>
            <div className="border border-solid p-2 rounded-lg flex items-center justify-center">
              <a
                href="#"
                title="Candidate Notes"
                onClick={(e) => onCardOptionClick(card.id, "open-note")}
              >
                <DocumentIcon width={20} />
              </a>
            </div>
            <div className="border border-solid p-2 rounded-lg flex items-center justify-center">
              {!card.rejected && (
                <a
                  href="#"
                  title="Reject"
                  onClick={() => onCardOptionClick(card.id, "reject")}
                >
                  <NoSymbolIcon width={20} />
                </a>
              )}
              {card.rejected && (
                <a
                  href="#"
                  title="Undo Reject"
                  onClick={() => onCardOptionClick(card.id, "undo-reject")}
                >
                  <CheckIcon width={20} />
                </a>
              )}
            </div>
            <div className="border border-solid p-2 rounded-lg flex items-center justify-center">
              <a
                href="#"
                title="LinkedIn"
                onClick={(e) => {
                  window.open(
                    card?.linkedInUrl?.includes?.("https://")
                      ? `${
                          card?.linkedInUrl?.includes?.("https")
                            ? ""
                            : "https://"
                        }${card?.linkedInUrl}`
                      : `https://www.linkedin.com/search/results/all/?keywords=${card.fullname}`
                  );
                }}
              >
                <FaLinkedin />
              </a>
            </div>
          </div>

          {/* <Space> */}
          {/* <a
              href="#"
              title="Forward Resume"
              onClick={(e) => onCardOptionClick(card.id, "forward-resume")}
            >
              <RiShareForwardBoxFill />
            </a> */}

          {/* </Space> */}
          {/* <Space>
            <FaBusinessTime />
            <div>
              {moment(card.createdAt).calendar(null, {
                sameDay: "[Today]",
                lastDay: "[Yesterday]",
                lastWeek: "dddd",
                sameElse: "MMM D, YYYY",
              })}
            </div>
          </Space> */}
          {card?.scheduledAt && new Date(card.scheduledAt) > new Date() && (
            <div>
              <Space>
                <CalendarIcon />
                <div>
                  {moment(card.scheduledAt).calendar(null, {
                    sameDay: "[Today at] LT",
                    lastDay: "[Yesterday]",
                    lastWeek: "dddd",
                    sameElse: "MMM D, YYYY",
                  })}
                </div>
              </Space>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
