import Cookies from "js-cookie";
import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";

import { Checkbox, Skeleton } from "antd";
import jwtDecode from "jwt-decode";
import { useSelector } from "react-redux";
import ShowPassword from "./pages/Dashboard/PartnerSettings/ShowPassword";
import { Button } from "./pages/Landing/Button";
import { TextField } from "./pages/Landing/Fields";
import { Logo } from "./pages/Landing/Logo";
import { SlimLayout } from "./pages/Landing/SlimLayout";
import { login } from "./redux/auth/actions";
import { getPartner, selectLoading } from "./redux/auth/selectors";
import { store } from "./redux/store";
import AuthService from "./service/AuthService";

const JoinTeamMember = () => {
  const partner = useSelector(getPartner);
  let [searchParams] = useSearchParams();
  const [tokenData, setTokenData] = useState(null);

  const navigate = useNavigate();
  const loading = useSelector(selectLoading);

  const handleSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      if (!tokenData) return;

      const password = e.target[3].value;

      await AuthService.registerTeam({
        ...tokenData,
        password,
      });

      const result = await AuthService.login({
        ...tokenData,
        password,
      });
      if (!result?.data?.accessToken)
        return message.error("Could not load user data");

      Cookies.set("accessToken", result?.data?.accessToken);
      Cookies.set("refreshToken", result?.data?.refreshToken);

      const me = await AuthService.me();
      if (!me?.data) return message.error("Could not load user data");

      store.dispatch(login(me.data.me));

      navigate("/dashboard/home");
    },
    [navigate, tokenData]
  );

  useEffect(() => {
    const token = searchParams.get("token");
    if (!token) return;

    const tokenData = jwtDecode(token);
    if (tokenData) setTokenData(tokenData);
  }, [searchParams]);

  if (!tokenData) return <Skeleton active />;
  return (
    <>
      <div className="flex h-[100vh] flex-col">
        <SlimLayout>
          <div className="h-[99vh]">
            <div className="flex">
              <Link href="/" aria-label="Home">
                <Logo className="h-10 w-auto" />
              </Link>
            </div>
            <h2 className="mt-5 text-lg font-semibold text-gray-900 dark:text-gray-400 ">
              Get started {partner?.trialDays > 0 ? "for free" : ""}
            </h2>
            <p className="mt-2 text-sm text-gray-700 dark:text-gray-300 ">
              Already registered?{" "}
              <Link
                to="/auth/login"
                className="font-medium text-blue-600 hover:underline"
              >
                Sign in
              </Link>{" "}
              to your account.
            </p>
            <form
              action="#"
              className="mt-5 grid grid-cols-1 gap-y-5"
              onSubmit={handleSubmit}
            >
              <TextField
                label="First name"
                name="first_name"
                type="text"
                autoComplete="given-name"
                value={tokenData?.firstName ?? ""}
                readOnly
              />
              <TextField
                label="Last name"
                name="last_name"
                type="text"
                autoComplete="family-name"
                value={tokenData?.lastName ?? ""}
                readOnly
              />
              <TextField
                className="col-span-full"
                label="Email address"
                name="email"
                type="email"
                autoComplete="email"
                value={tokenData?.email ?? ""}
                readOnly
              />
              <TextField name="password" autoComplete="new-password" required />

              <div className="col-span-full">
                <label
                  htmlFor={"passworasdfasdf"}
                  className="mb-3 block text-sm font-medium text-gray-700 dark:text-gray-300 "
                >
                  Password
                </label>
                <ShowPassword
                  id={"passworasdfasdf"}
                  {...props}
                  className={
                    "block w-full appearance-none rounded-md border border-gray-200 dark:border-gray-600  bg-gray-50 px-3 py-2 text-gray-900 dark:text-gray-400  placeholder-gray-400 focus:border-blue-500 focus:bg-white dark:bg-gray-900 focus:outline-none focus:ring-blue-500 sm:text-sm dark:bg-gray-900"
                  }
                />
              </div>

              {
                <div className="flex gap-2">
                  <Checkbox className="col-span-full" required />
                  <label>
                    I accept the{" "}
                    <a href="/legal/privacy" target="_blank">
                      privacy policy
                    </a>{" "}
                    and{" "}
                    <a href="/legal/terms" target="_blank">
                      terms of service
                    </a>
                  </label>
                </div>
              }
              <div>
                <Button
                  type="submit"
                  className="w-full bg-gradient"
                  loading={loading}
                >
                  <span>
                    Sign up <span aria-hidden="true">&rarr;</span>
                  </span>
                </Button>
              </div>
            </form>
          </div>
        </SlimLayout>
      </div>
    </>
  );
};

export default JoinTeamMember;
