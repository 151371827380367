import { Alert, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import PublicService from "../../service/PublicService";
import { Footer } from "../Landing/Footer";

const Finished = () => {
  let [searchParams] = useSearchParams();
  const [surveyData, setSurveyData] = useState(null);

  useEffect(() => {
    const token = searchParams.get("token");
    if (!token) return;
    setSurveyData(null);

    PublicService.getSurvey(token).then((res) => {
      if (!res.data) return;
      setSurveyData(res.data);
    });
  }, [searchParams]);

  if (!surveyData) return <Skeleton active />;

  return (
    <>
      <div className="content">
        <div
          className="w-full border-box pt-4 pl-8 pr-8 pb-4"
          style={{
            background: "center center / cover no-repeat transparent",
          }}
        >
          <div className="mx-auto max-w-md sm:max-w-xl w-full md:max-w-3xl lg:max-w-4xl">
            <div className="transition-wrapper" style={{}}>
              <div className="wrapper break-words text-left flex items-center flex-col responsive">
                <h2 className="font-bold text-3xl">
                  Your Participation in the Survey is Highly Valued!
                </h2>
              </div>
            </div>
          </div>
        </div>

        <div
          className="w-full border-box pt-4 pl-6 pr-6 pb-2"
          style={{
            background: "center center / cover no-repeat transparent",
          }}
        >
          <div className="mx-auto max-w-md sm:max-w-xl w-full md:max-w-3xl lg:max-w-4xl">
            <div className="transition-wrapper" style={{}}>
              <div
                className="w-full border-box pb-6"
                style={{
                  background: "center center / cover no-repeat transparent",
                }}
              >
                <div className="mx-auto max-w-md sm:max-w-xl w-full md:max-w-3xl lg:max-w-4xl">
                  <div className="transition-wrapper" style={{}}>
                    <div className="wrapper break-words text-left flex items-center flex-col responsive">
                      <h4>
                        We sincerely appreciate your feedback and contribution
                        to our survey. Your insights are of great importance to
                        us, and they will help us improve. Thank you for taking
                        the time to share your thoughts and opinions.
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Finished;
