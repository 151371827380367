import axios from "axios";
import { getBackendUrl } from "./getBackendUrl";
import { middleField } from "./middlefield";

class GarbageService {
  constructor(baseURL) {
    this.baseURL = baseURL;
    this.api = axios.create({
      baseURL,
    });
    middleField(this.api);
  }

  messageCandidate({
    candidateId,
    subject,
    message,
    includeBCC = false,
    isSMS = false,
  }) {
    return this.api.post(`/messageCandidate?id=${candidateId}`, {
      subject,
      includeBCC,
      message,
      baseURL: window.location.origin,
      isSMS,
    });
  }
  forwardCandidateToHiringManagers({
    candidateId,
    subject,
    message,
    includeBCC = false,
    hiringManagers,
  }) {
    return this.api.post(
      `/forwardCandidateToHiringManagers?id=${candidateId}`,
      {
        subject,
        includeBCC,
        message,
        baseURL: window.location.origin,
        hiringManagers,
      }
    );
  }
}

export default new GarbageService(`${getBackendUrl()}/messaging`);
