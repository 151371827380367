export const countryLanguages = [
  {
    language: "English (United States)",
    codeLanguage: "en",
    codeCountry: "US",
  },

  { language: "Hawaiian", codeLanguage: "haw", codeCountry: "US" },
  { language: "Lakota", codeLanguage: "lkt", codeCountry: "US" },
  { language: "Spanish", codeLanguage: "es", codeCountry: "US" },
  { language: "English", codeLanguage: "en", codeCountry: "US" },
  { language: "English", codeLanguage: "en", codeCountry: "GB" },
  { language: "English", codeLanguage: "en", codeCountry: "AL" },
  { language: "English", codeLanguage: "en", codeCountry: "AS" },
  { language: "English", codeLanguage: "en", codeCountry: "AD" },
  { language: "English", codeLanguage: "en", codeCountry: "AI" },
  { language: "English", codeLanguage: "en", codeCountry: "AG" },
  { language: "English", codeLanguage: "en", codeCountry: "AR" },
  { language: "English", codeLanguage: "en", codeCountry: "AU" },
  { language: "English", codeLanguage: "en", codeCountry: "AT" },
  { language: "English", codeLanguage: "en", codeCountry: "BS" },
  { language: "English", codeLanguage: "en", codeCountry: "BD" },
  { language: "English", codeLanguage: "en", codeCountry: "BB" },
  { language: "English", codeLanguage: "en", codeCountry: "BE" },
  { language: "English", codeLanguage: "en", codeCountry: "BZ" },
  { language: "English", codeLanguage: "en", codeCountry: "BM" },
  { language: "English", codeLanguage: "en", codeCountry: "BA" },
  { language: "English", codeLanguage: "en", codeCountry: "BW" },
  { language: "English", codeLanguage: "en", codeCountry: "BR" },
  { language: "English", codeLanguage: "en", codeCountry: "IO" },
  { language: "English", codeLanguage: "en", codeCountry: "VG" },
  { language: "English", codeLanguage: "en", codeCountry: "BG" },
  { language: "English", codeLanguage: "en", codeCountry: "BI" },
  { language: "English", codeLanguage: "en", codeCountry: "CM" },
  { language: "English", codeLanguage: "en", codeCountry: "CA" },
  { language: "English", codeLanguage: "en", codeCountry: "KY" },
  { language: "English", codeLanguage: "en", codeCountry: "CL" },
  { language: "English", codeLanguage: "en", codeCountry: "CN" },
  { language: "English", codeLanguage: "en", codeCountry: "CX" },
  { language: "English", codeLanguage: "en", codeCountry: "CC" },
  { language: "English", codeLanguage: "en", codeCountry: "CO" },
  { language: "English", codeLanguage: "en", codeCountry: "CK" },
  { language: "English", codeLanguage: "en", codeCountry: "HR" },
  { language: "English", codeLanguage: "en", codeCountry: "CY" },
  { language: "English", codeLanguage: "en", codeCountry: "CZ" },
  { language: "English", codeLanguage: "en", codeCountry: "DK" },
  { language: "English", codeLanguage: "en", codeCountry: "DG" },
  { language: "English", codeLanguage: "en", codeCountry: "DM" },
  { language: "English", codeLanguage: "en", codeCountry: "ER" },
  { language: "English", codeLanguage: "en", codeCountry: "EE" },
  { language: "English", codeLanguage: "en", codeCountry: "SZ" },
  { language: "English", codeLanguage: "en", codeCountry: "150" },
  { language: "English", codeLanguage: "en", codeCountry: "FK" },
  { language: "English", codeLanguage: "en", codeCountry: "FJ" },
  { language: "English", codeLanguage: "en", codeCountry: "FI" },
  { language: "English", codeLanguage: "en", codeCountry: "FR" },
  { language: "English", codeLanguage: "en", codeCountry: "GM" },
  { language: "English", codeLanguage: "en", codeCountry: "DE" },
  { language: "English", codeLanguage: "en", codeCountry: "GH" },
  { language: "English", codeLanguage: "en", codeCountry: "GI" },
  { language: "English", codeLanguage: "en", codeCountry: "GR" },
  { language: "English", codeLanguage: "en", codeCountry: "GD" },
  { language: "English", codeLanguage: "en", codeCountry: "GU" },
  { language: "English", codeLanguage: "en", codeCountry: "GG" },
  { language: "English", codeLanguage: "en", codeCountry: "GY" },
  { language: "English", codeLanguage: "en", codeCountry: "HK" },
  { language: "English", codeLanguage: "en", codeCountry: "HU" },
  { language: "English", codeLanguage: "en", codeCountry: "IS" },
  { language: "English", codeLanguage: "en", codeCountry: "IN" },
  { language: "English", codeLanguage: "en", codeCountry: "ID" },
  { language: "English", codeLanguage: "en", codeCountry: "IE" },
  { language: "English", codeLanguage: "en", codeCountry: "IM" },
  { language: "English", codeLanguage: "en", codeCountry: "IL" },
  { language: "English", codeLanguage: "en", codeCountry: "IT" },
  { language: "English", codeLanguage: "en", codeCountry: "JM" },
  { language: "English", codeLanguage: "en", codeCountry: "JP" },
  { language: "English", codeLanguage: "en", codeCountry: "JE" },
  { language: "English", codeLanguage: "en", codeCountry: "KE" },
  { language: "English", codeLanguage: "en", codeCountry: "KI" },
  { language: "English", codeLanguage: "en", codeCountry: "LV" },
  { language: "English", codeLanguage: "en", codeCountry: "LS" },
  { language: "English", codeLanguage: "en", codeCountry: "LR" },
  { language: "English", codeLanguage: "en", codeCountry: "LT" },
  { language: "English", codeLanguage: "en", codeCountry: "LU" },
  { language: "English", codeLanguage: "en", codeCountry: "MO" },
  { language: "English", codeLanguage: "en", codeCountry: "MG" },
  { language: "English", codeLanguage: "en", codeCountry: "MW" },
  { language: "English", codeLanguage: "en", codeCountry: "MY" },
  { language: "English", codeLanguage: "en", codeCountry: "MV" },
  { language: "English", codeLanguage: "en", codeCountry: "MT" },
  { language: "English", codeLanguage: "en", codeCountry: "MH" },
  { language: "English", codeLanguage: "en", codeCountry: "MU" },
  { language: "English", codeLanguage: "en", codeCountry: "MX" },
  { language: "English", codeLanguage: "en", codeCountry: "FM" },
  { language: "English", codeLanguage: "en", codeCountry: "ME" },
  { language: "English", codeLanguage: "en", codeCountry: "MS" },
  { language: "English", codeLanguage: "en", codeCountry: "MM" },
  { language: "English", codeLanguage: "en", codeCountry: "NA" },
  { language: "English", codeLanguage: "en", codeCountry: "NR" },
  { language: "English", codeLanguage: "en", codeCountry: "NL" },
  { language: "English", codeLanguage: "en", codeCountry: "NZ" },
  { language: "English", codeLanguage: "en", codeCountry: "NG" },
  { language: "English", codeLanguage: "en", codeCountry: "NU" },
  { language: "English", codeLanguage: "en", codeCountry: "NF" },
  { language: "English", codeLanguage: "en", codeCountry: "MP" },
  { language: "English", codeLanguage: "en", codeCountry: "NO" },
  { language: "English", codeLanguage: "en", codeCountry: "PK" },
  { language: "English", codeLanguage: "en", codeCountry: "PW" },
  { language: "English", codeLanguage: "en", codeCountry: "PG" },
  { language: "English", codeLanguage: "en", codeCountry: "PH" },
  { language: "English", codeLanguage: "en", codeCountry: "PN" },
  { language: "English", codeLanguage: "en", codeCountry: "PL" },
  { language: "English", codeLanguage: "en", codeCountry: "PT" },
  { language: "English", codeLanguage: "en", codeCountry: "PR" },
  { language: "English", codeLanguage: "en", codeCountry: "RO" },
  { language: "English", codeLanguage: "en", codeCountry: "RU" },
  { language: "English", codeLanguage: "en", codeCountry: "RW" },
  { language: "English", codeLanguage: "en", codeCountry: "WS" },
  { language: "English", codeLanguage: "en", codeCountry: "SA" },
  { language: "English", codeLanguage: "en", codeCountry: "RS" },
  { language: "English", codeLanguage: "en", codeCountry: "SC" },
  { language: "English", codeLanguage: "en", codeCountry: "SL" },
  { language: "English", codeLanguage: "en", codeCountry: "SG" },
  { language: "English", codeLanguage: "en", codeCountry: "SX" },
  { language: "English", codeLanguage: "en", codeCountry: "SK" },
  { language: "English", codeLanguage: "en", codeCountry: "SI" },
  { language: "English", codeLanguage: "en", codeCountry: "SB" },
  { language: "English", codeLanguage: "en", codeCountry: "ZA" },
  { language: "English", codeLanguage: "en", codeCountry: "KR" },
  { language: "English", codeLanguage: "en", codeCountry: "SS" },
  { language: "English", codeLanguage: "en", codeCountry: "ES" },
  { language: "English", codeLanguage: "en", codeCountry: "SH" },
  { language: "English", codeLanguage: "en", codeCountry: "KN" },
  { language: "English", codeLanguage: "en", codeCountry: "LC" },
  { language: "English", codeLanguage: "en", codeCountry: "VC" },
  { language: "English", codeLanguage: "en", codeCountry: "SD" },
  { language: "English", codeLanguage: "en", codeCountry: "SE" },
  { language: "English", codeLanguage: "en", codeCountry: "CH" },
  { language: "English", codeLanguage: "en", codeCountry: "TW" },
  { language: "English", codeLanguage: "en", codeCountry: "TZ" },
  { language: "English", codeLanguage: "en", codeCountry: "TH" },
  { language: "English", codeLanguage: "en", codeCountry: "TK" },
  { language: "English", codeLanguage: "en", codeCountry: "TO" },
  { language: "English", codeLanguage: "en", codeCountry: "TT" },
  { language: "English", codeLanguage: "en", codeCountry: "TR" },
  { language: "English", codeLanguage: "en", codeCountry: "TC" },
  { language: "English", codeLanguage: "en", codeCountry: "TV" },
  { language: "English", codeLanguage: "en", codeCountry: "UM" },
  { language: "English", codeLanguage: "en", codeCountry: "VI" },
  { language: "English", codeLanguage: "en", codeCountry: "UG" },
  { language: "English", codeLanguage: "en", codeCountry: "UA" },
  { language: "English", codeLanguage: "en", codeCountry: "AE" },

  { language: "English", codeLanguage: "en", codeCountry: "VU" },
  { language: "English", codeLanguage: "en", codeCountry: "001" },
  { language: "English", codeLanguage: "en", codeCountry: "ZM" },
  { language: "English", codeLanguage: "en", codeCountry: "ZW" },

  { language: "Cherokee", codeLanguage: "chr", codeCountry: "US" },
  { language: "Pashto", codeLanguage: "ps", codeCountry: "AF" },
  { language: "Persian", codeLanguage: "fa", codeCountry: "AF" },
  { language: "Uzbek", codeLanguage: "uz", codeCountry: "AF" },
  { language: "Swedish", codeLanguage: "sv", codeCountry: "AX" },
  { language: "Albanian", codeLanguage: "sq", codeCountry: "AL" },
  { language: "Arabic", codeLanguage: "ar", codeCountry: "DZ" },
  { language: "French", codeLanguage: "fr", codeCountry: "DZ" },
  { language: "Kabyle", codeLanguage: "kab", codeCountry: "DZ" },
  { language: "Catalan", codeLanguage: "ca", codeCountry: "AD" },
  { language: "Lingala", codeLanguage: "ln", codeCountry: "AO" },
  { language: "Portuguese", codeLanguage: "pt", codeCountry: "AO" },
  { language: "Spanish", codeLanguage: "es", codeCountry: "AI" },
  { language: "Spanish", codeLanguage: "es", codeCountry: "AG" },
  { language: "Spanish", codeLanguage: "es", codeCountry: "AR" },
  { language: "Armenian", codeLanguage: "hy", codeCountry: "AM" },
  { language: "Dutch", codeLanguage: "nl", codeCountry: "AW" },
  { language: "Spanish", codeLanguage: "es", codeCountry: "AW" },
  { language: "German", codeLanguage: "de", codeCountry: "AT" },
  { language: "Azerbaijani", codeLanguage: "az", codeCountry: "AZ" },
  { language: "Azerbaijani", codeLanguage: "az", codeCountry: "AZ" },
  { language: "Spanish", codeLanguage: "es", codeCountry: "BS" },
  { language: "Arabic", codeLanguage: "ar", codeCountry: "BH" },
  { language: "Bangla", codeLanguage: "bn", codeCountry: "BD" },
  { language: "Chakma", codeLanguage: "ccp", codeCountry: "BD" },
  { language: "Spanish", codeLanguage: "es", codeCountry: "BB" },
  { language: "Belarusian", codeLanguage: "be", codeCountry: "BY" },
  { language: "Russian", codeLanguage: "ru", codeCountry: "BY" },
  { language: "Dutch", codeLanguage: "nl", codeCountry: "BE" },
  { language: "French", codeLanguage: "fr", codeCountry: "BE" },
  { language: "German", codeLanguage: "de", codeCountry: "BE" },
  { language: "Walloon", codeLanguage: "wa", codeCountry: "BE" },
  { language: "Spanish", codeLanguage: "es", codeCountry: "BZ" },
  { language: "French", codeLanguage: "fr", codeCountry: "BJ" },
  { language: "Yoruba", codeLanguage: "yo", codeCountry: "BJ" },
  { language: "Spanish", codeLanguage: "es", codeCountry: "BM" },
  { language: "Dzongkha", codeLanguage: "dz", codeCountry: "BT" },
  { language: "Quechua", codeLanguage: "qu", codeCountry: "BO" },
  { language: "Spanish", codeLanguage: "es", codeCountry: "BO" },
  { language: "Bosnian", codeLanguage: "bs", codeCountry: "BA" },
  { language: "Bosnian", codeLanguage: "bs", codeCountry: "BA" },
  { language: "Croatian", codeLanguage: "hr", codeCountry: "BA" },
  { language: "Serbian", codeLanguage: "sr", codeCountry: "BA" },
  { language: "Serbian", codeLanguage: "sr", codeCountry: "BA" },
  { language: "Tswana", codeLanguage: "tn", codeCountry: "BW" },
  { language: "Portuguese", codeLanguage: "pt", codeCountry: "BR" },
  { language: "Spanish", codeLanguage: "es", codeCountry: "BR" },
  { language: "Spanish", codeLanguage: "es", codeCountry: "VG" },
  { language: "Malay", codeLanguage: "ms", codeCountry: "BN" },
  { language: "Malay", codeLanguage: "ms", codeCountry: "BN" },
  { language: "Bulgarian", codeLanguage: "bg", codeCountry: "BG" },
  { language: "French", codeLanguage: "fr", codeCountry: "BF" },
  { language: "Fulah", codeLanguage: "ff", codeCountry: "BF" },
  { language: "French", codeLanguage: "fr", codeCountry: "BI" },
  { language: "Rundi", codeLanguage: "rn", codeCountry: "BI" },
  { language: "Khmer", codeLanguage: "km", codeCountry: "KH" },
  { language: "Aghem", codeLanguage: "agq", codeCountry: "CM" },
  { language: "Bafia", codeLanguage: "ksf", codeCountry: "CM" },
  { language: "Basaa", codeLanguage: "bas", codeCountry: "CM" },
  { language: "Duala", codeLanguage: "dua", codeCountry: "CM" },
  { language: "Ewondo", codeLanguage: "ewo", codeCountry: "CM" },
  { language: "French", codeLanguage: "fr", codeCountry: "CM" },
  { language: "Fulah", codeLanguage: "ff", codeCountry: "CM" },
  { language: "Kako", codeLanguage: "kkj", codeCountry: "CM" },
  { language: "Kwasio", codeLanguage: "nmg", codeCountry: "CM" },
  { language: "Metaʼ", codeLanguage: "mgo", codeCountry: "CM" },
  { language: "Mundang", codeLanguage: "mua", codeCountry: "CM" },
  { language: "Ngiemboon", codeLanguage: "nnh", codeCountry: "CM" },
  { language: "Ngomba", codeLanguage: "jgo", codeCountry: "CM" },
  { language: "Yangben", codeLanguage: "yav", codeCountry: "CM" },
  { language: "French", codeLanguage: "fr", codeCountry: "CA" },
  { language: "Inuktitut", codeLanguage: "iu", codeCountry: "CA" },
  { language: "Mohawk", codeLanguage: "moh", codeCountry: "CA" },
  { language: "Spanish", codeLanguage: "es", codeCountry: "CA" },
  { language: "Spanish", codeLanguage: "es", codeCountry: "IC" },
  { language: "Kabuverdianu", codeLanguage: "kea", codeCountry: "CV" },
  { language: "Portuguese", codeLanguage: "pt", codeCountry: "CV" },
  { language: "Dutch", codeLanguage: "nl", codeCountry: "BQ" },
  { language: "Spanish", codeLanguage: "es", codeCountry: "BQ" },
  { language: "Spanish", codeLanguage: "es", codeCountry: "KY" },
  { language: "French", codeLanguage: "fr", codeCountry: "CF" },
  { language: "Lingala", codeLanguage: "ln", codeCountry: "CF" },
  { language: "Sango", codeLanguage: "sg", codeCountry: "CF" },
  { language: "Spanish", codeLanguage: "es", codeCountry: "EA" },
  { language: "Arabic", codeLanguage: "ar", codeCountry: "TD" },
  { language: "French", codeLanguage: "fr", codeCountry: "TD" },
  { language: "Mapuche", codeLanguage: "arn", codeCountry: "CL" },
  { language: "Spanish", codeLanguage: "es", codeCountry: "CL" },
  { language: "Cantonese", codeLanguage: "yue", codeCountry: "CN" },
  { language: "Chinese", codeLanguage: "zh", codeCountry: "CN" },
  { language: "Chinese", codeLanguage: "zh", codeCountry: "CN" },
  { language: "Sichuan Yi", codeLanguage: "ii", codeCountry: "CN" },
  { language: "Tibetan", codeLanguage: "bo", codeCountry: "CN" },
  { language: "Uyghur", codeLanguage: "ug", codeCountry: "CN" },
  { language: "Spanish", codeLanguage: "es", codeCountry: "CO" },
  { language: "Arabic", codeLanguage: "ar", codeCountry: "KM" },
  { language: "French", codeLanguage: "fr", codeCountry: "KM" },
  { language: "French", codeLanguage: "fr", codeCountry: "CG" },
  { language: "Lingala", codeLanguage: "ln", codeCountry: "CG" },
  { language: "French", codeLanguage: "fr", codeCountry: "CD" },
  { language: "Lingala", codeLanguage: "ln", codeCountry: "CD" },
  { language: "Luba-Katanga", codeLanguage: "lu", codeCountry: "CD" },
  { language: "Swahili", codeLanguage: "sw", codeCountry: "CD" },
  { language: "Spanish", codeLanguage: "es", codeCountry: "CR" },
  { language: "French", codeLanguage: "fr", codeCountry: "CI" },
  { language: "Croatian", codeLanguage: "hr", codeCountry: "HR" },
  { language: "Spanish", codeLanguage: "es", codeCountry: "CU" },
  { language: "Dutch", codeLanguage: "nl", codeCountry: "CW" },
  { language: "Spanish", codeLanguage: "es", codeCountry: "CW" },
  { language: "Greek", codeLanguage: "el", codeCountry: "CY" },
  { language: "Turkish", codeLanguage: "tr", codeCountry: "CY" },
  { language: "Czech", codeLanguage: "cs", codeCountry: "CZ" },
  { language: "Danish", codeLanguage: "da", codeCountry: "DK" },
  { language: "Faroese", codeLanguage: "fo", codeCountry: "DK" },
  { language: "Arabic", codeLanguage: "ar", codeCountry: "DJ" },
  { language: "French", codeLanguage: "fr", codeCountry: "DJ" },
  { language: "Somali", codeLanguage: "so", codeCountry: "DJ" },
  { language: "Spanish", codeLanguage: "es", codeCountry: "DM" },
  { language: "Spanish", codeLanguage: "es", codeCountry: "DO" },
  { language: "Quechua", codeLanguage: "qu", codeCountry: "EC" },
  { language: "Spanish", codeLanguage: "es", codeCountry: "EC" },
  { language: "Arabic", codeLanguage: "ar", codeCountry: "EG" },
  { language: "Spanish", codeLanguage: "es", codeCountry: "SV" },
  { language: "French", codeLanguage: "fr", codeCountry: "GQ" },
  { language: "Portuguese", codeLanguage: "pt", codeCountry: "GQ" },
  { language: "Spanish", codeLanguage: "es", codeCountry: "GQ" },
  { language: "Arabic", codeLanguage: "ar", codeCountry: "ER" },
  { language: "Blin", codeLanguage: "byn", codeCountry: "ER" },
  { language: "Geez", codeLanguage: "gez", codeCountry: "ER" },
  { language: "Tigre", codeLanguage: "tig", codeCountry: "ER" },
  { language: "Tigrinya", codeLanguage: "ti", codeCountry: "ER" },
  { language: "Estonian", codeLanguage: "et", codeCountry: "EE" },
  { language: "Swati", codeLanguage: "ss", codeCountry: "SZ" },
  { language: "Amharic", codeLanguage: "am", codeCountry: "ET" },
  { language: "Geez", codeLanguage: "gez", codeCountry: "ET" },
  { language: "Oromo", codeLanguage: "om", codeCountry: "ET" },
  { language: "Somali", codeLanguage: "so", codeCountry: "ET" },
  { language: "Tigrinya", codeLanguage: "ti", codeCountry: "ET" },
  { language: "Wolaytta", codeLanguage: "wal", codeCountry: "ET" },
  { language: "Spanish", codeLanguage: "es", codeCountry: "FK" },
  { language: "Faroese", codeLanguage: "fo", codeCountry: "FO" },
  { language: "Finnish", codeLanguage: "fi", codeCountry: "FI" },
  { language: "Inari Sami", codeLanguage: "smn", codeCountry: "FI" },
  { language: "Northern Sami", codeLanguage: "se", codeCountry: "FI" },
  { language: "Swedish", codeLanguage: "sv", codeCountry: "FI" },
  { language: "Breton", codeLanguage: "br", codeCountry: "FR" },
  { language: "Catalan", codeLanguage: "ca", codeCountry: "FR" },
  { language: "Corsican", codeLanguage: "co", codeCountry: "FR" },
  { language: "French", codeLanguage: "fr", codeCountry: "FR" },
  { language: "Occitan", codeLanguage: "oc", codeCountry: "FR" },
  { language: "Portuguese", codeLanguage: "pt", codeCountry: "FR" },
  { language: "Swiss German", codeLanguage: "gsw", codeCountry: "FR" },
  { language: "French", codeLanguage: "fr", codeCountry: "GF" },
  { language: "Spanish", codeLanguage: "es", codeCountry: "GF" },
  { language: "French", codeLanguage: "fr", codeCountry: "PF" },
  { language: "French", codeLanguage: "fr", codeCountry: "GA" },
  { language: "Fulah", codeLanguage: "ff", codeCountry: "GM" },
  { language: "Georgian", codeLanguage: "ka", codeCountry: "GE" },
  { language: "Ossetic", codeLanguage: "os", codeCountry: "GE" },
  { language: "Colognian", codeLanguage: "ksh", codeCountry: "DE" },
  { language: "German", codeLanguage: "de", codeCountry: "DE" },
  { language: "Low German", codeLanguage: "nds", codeCountry: "DE" },
  { language: "Lower Sorbian", codeLanguage: "dsb", codeCountry: "DE" },
  { language: "Upper Sorbian", codeLanguage: "hsb", codeCountry: "DE" },
  { language: "Akan", codeLanguage: "ak", codeCountry: "GH" },
  { language: "Ewe", codeLanguage: "ee", codeCountry: "GH" },
  { language: "Fulah", codeLanguage: "ff", codeCountry: "GH" },
  { language: "Ga", codeLanguage: "gaa", codeCountry: "GH" },
  { language: "Hausa", codeLanguage: "ha", codeCountry: "GH" },
  { language: "Greek", codeLanguage: "el", codeCountry: "GR" },
  { language: "Danish", codeLanguage: "da", codeCountry: "GL" },
  { language: "Kalaallisut", codeLanguage: "kl", codeCountry: "GL" },
  { language: "Spanish", codeLanguage: "es", codeCountry: "GL" },
  { language: "Spanish", codeLanguage: "es", codeCountry: "GD" },
  { language: "French", codeLanguage: "fr", codeCountry: "GP" },
  { language: "Spanish", codeLanguage: "es", codeCountry: "GP" },
  { language: "Spanish", codeLanguage: "es", codeCountry: "GT" },
  { language: "Fulah", codeLanguage: "ff", codeCountry: "GW" },
  { language: "Portuguese", codeLanguage: "pt", codeCountry: "GW" },
  { language: "French", codeLanguage: "fr", codeCountry: "GN" },
  { language: "Fulah", codeLanguage: "ff", codeCountry: "GN" },
  { language: "Kpelle", codeLanguage: "kpe", codeCountry: "GN" },
  { language: "N’Ko", codeLanguage: "nqo", codeCountry: "GN" },
  { language: "Spanish", codeLanguage: "es", codeCountry: "GY" },
  { language: "French", codeLanguage: "fr", codeCountry: "HT" },
  { language: "Spanish", codeLanguage: "es", codeCountry: "HT" },
  { language: "Spanish", codeLanguage: "es", codeCountry: "HN" },
  { language: "Cantonese", codeLanguage: "yue", codeCountry: "HK" },
  { language: "Chinese", codeLanguage: "zh", codeCountry: "HK" },
  { language: "Chinese", codeLanguage: "zh", codeCountry: "HK" },
  { language: "Hungarian", codeLanguage: "hu", codeCountry: "HU" },
  { language: "Icelandic", codeLanguage: "is", codeCountry: "IS" },
  { language: "Assamese", codeLanguage: "as", codeCountry: "IN" },
  { language: "Bangla", codeLanguage: "bn", codeCountry: "IN" },
  { language: "Bodo", codeLanguage: "brx", codeCountry: "IN" },
  { language: "Chakma", codeLanguage: "ccp", codeCountry: "IN" },
  { language: "Gujarati", codeLanguage: "gu", codeCountry: "IN" },
  { language: "Hindi", codeLanguage: "hi", codeCountry: "IN" },
  { language: "Kannada", codeLanguage: "kn", codeCountry: "IN" },
  { language: "Kashmiri", codeLanguage: "ks", codeCountry: "IN" },
  { language: "Kashmiri", codeLanguage: "ks", codeCountry: "IN" },
  { language: "Kashmiri", codeLanguage: "ks", codeCountry: "IN" },
  { language: "Konkani", codeLanguage: "kok", codeCountry: "IN" },
  { language: "Malayalam", codeLanguage: "ml", codeCountry: "IN" },
  { language: "Manipuri", codeLanguage: "mni", codeCountry: "IN" },
  { language: "Manipuri", codeLanguage: "mni", codeCountry: "IN" },
  { language: "Marathi", codeLanguage: "mr", codeCountry: "IN" },
  { language: "Nepali", codeLanguage: "ne", codeCountry: "IN" },
  { language: "Odia", codeLanguage: "or", codeCountry: "IN" },
  { language: "Punjabi", codeLanguage: "pa", codeCountry: "IN" },
  { language: "Sanskrit", codeLanguage: "sa", codeCountry: "IN" },
  { language: "Santali", codeLanguage: "sat", codeCountry: "IN" },
  { language: "Santali", codeLanguage: "sat", codeCountry: "IN" },
  { language: "Tamil", codeLanguage: "ta", codeCountry: "IN" },
  { language: "Telugu", codeLanguage: "te", codeCountry: "IN" },
  { language: "Tibetan", codeLanguage: "bo", codeCountry: "IN" },
  { language: "Urdu", codeLanguage: "ur", codeCountry: "IN" },
  { language: "Urdu", codeLanguage: "ur", codeCountry: "IN" },
  { language: "Urdu", codeLanguage: "ur", codeCountry: "IN" },
  { language: "Indonesian", codeLanguage: "id", codeCountry: "ID" },
  { language: "Javanese", codeLanguage: "jv", codeCountry: "ID" },
  { language: "Kurdish, Sorani", codeLanguage: "ckb", codeCountry: "IR" },
  { language: "Mazanderani", codeLanguage: "mzn", codeCountry: "IR" },
  { language: "Northern Luri", codeLanguage: "lrc", codeCountry: "IR" },
  { language: "Persian", codeLanguage: "fa", codeCountry: "IR" },
  { language: "Arabic", codeLanguage: "ar", codeCountry: "IQ" },
  { language: "Kurdish, Sorani", codeLanguage: "ckb", codeCountry: "IQ" },
  { language: "Northern Luri", codeLanguage: "lrc", codeCountry: "IQ" },
  { language: "Syriac", codeLanguage: "syr", codeCountry: "IQ" },
  { language: "Irish", codeLanguage: "ga", codeCountry: "IE" },
  { language: "Manx", codeLanguage: "gv", codeCountry: "IM" },
  { language: "Arabic", codeLanguage: "ar", codeCountry: "IL" },
  { language: "Hebrew", codeLanguage: "he", codeCountry: "IL" },
  { language: "Catalan", codeLanguage: "ca", codeCountry: "IT" },
  { language: "Friulian", codeLanguage: "fur", codeCountry: "IT" },
  { language: "German", codeLanguage: "de", codeCountry: "IT" },
  { language: "Italian", codeLanguage: "it", codeCountry: "IT" },
  { language: "Sardinian", codeLanguage: "sc", codeCountry: "IT" },
  { language: "Sicilian", codeLanguage: "scn", codeCountry: "IT" },
  { language: "Japanese", codeLanguage: "ja", codeCountry: "JP" },
  { language: "Arabic", codeLanguage: "ar", codeCountry: "JO" },
  { language: "Kazakh", codeLanguage: "kk", codeCountry: "KZ" },
  { language: "Russian", codeLanguage: "ru", codeCountry: "KZ" },
  { language: "Embu", codeLanguage: "ebu", codeCountry: "KE" },
  { language: "Gusii", codeLanguage: "guz", codeCountry: "KE" },
  { language: "Kalenjin", codeLanguage: "kln", codeCountry: "KE" },
  { language: "Kamba", codeLanguage: "kam", codeCountry: "KE" },
  { language: "Kikuyu", codeLanguage: "ki", codeCountry: "KE" },
  { language: "Luo", codeLanguage: "luo", codeCountry: "KE" },
  { language: "Luyia", codeLanguage: "luy", codeCountry: "KE" },
  { language: "Masai", codeLanguage: "mas", codeCountry: "KE" },
  { language: "Meru", codeLanguage: "mer", codeCountry: "KE" },
  { language: "Oromo", codeLanguage: "om", codeCountry: "KE" },
  { language: "Samburu", codeLanguage: "saq", codeCountry: "KE" },
  { language: "Somali", codeLanguage: "so", codeCountry: "KE" },
  { language: "Swahili", codeLanguage: "sw", codeCountry: "KE" },
  { language: "Taita", codeLanguage: "dav", codeCountry: "KE" },
  { language: "Teso", codeLanguage: "teo", codeCountry: "KE" },
  { language: "Albanian", codeLanguage: "sq", codeCountry: "XK" },
  { language: "Serbian", codeLanguage: "sr", codeCountry: "XK" },
  { language: "Serbian", codeLanguage: "sr", codeCountry: "XK" },
  { language: "Arabic", codeLanguage: "ar", codeCountry: "KW" },
  { language: "Kyrgyz", codeLanguage: "ky", codeCountry: "KG" },
  { language: "Russian", codeLanguage: "ru", codeCountry: "KG" },
  { language: "Lao", codeLanguage: "lo", codeCountry: "LA" },
  { language: "Spanish", codeLanguage: "es", codeCountry: "419" },
  { language: "Latvian", codeLanguage: "lv", codeCountry: "LV" },
  { language: "Arabic", codeLanguage: "ar", codeCountry: "LB" },
  { language: "Southern Sotho", codeLanguage: "st", codeCountry: "LS" },
  { language: "Fulah", codeLanguage: "ff", codeCountry: "LR" },
  { language: "Kpelle", codeLanguage: "kpe", codeCountry: "LR" },
  { language: "Vai", codeLanguage: "vai", codeCountry: "LR" },
  { language: "Vai", codeLanguage: "vai", codeCountry: "LR" },
  { language: "Arabic", codeLanguage: "ar", codeCountry: "LY" },
  { language: "German", codeLanguage: "de", codeCountry: "LI" },
  { language: "Swiss German", codeLanguage: "gsw", codeCountry: "LI" },
  { language: "Lithuanian", codeLanguage: "lt", codeCountry: "LT" },
  { language: "French", codeLanguage: "fr", codeCountry: "LU" },
  { language: "German", codeLanguage: "de", codeCountry: "LU" },
  { language: "Luxembourgish", codeLanguage: "lb", codeCountry: "LU" },
  { language: "Portuguese", codeLanguage: "pt", codeCountry: "LU" },
  { language: "Chinese", codeLanguage: "zh", codeCountry: "MO" },
  { language: "Chinese", codeLanguage: "zh", codeCountry: "MO" },
  { language: "Portuguese", codeLanguage: "pt", codeCountry: "MO" },
  { language: "French", codeLanguage: "fr", codeCountry: "MG" },
  { language: "Malagasy", codeLanguage: "mg", codeCountry: "MG" },
  { language: "Nyanja", codeLanguage: "ny", codeCountry: "MW" },
  { language: "Malay", codeLanguage: "ms", codeCountry: "MY" },
  { language: "Malay", codeLanguage: "ms", codeCountry: "MY" },
  { language: "Tamil", codeLanguage: "ta", codeCountry: "MY" },
  { language: "Dhivehi", codeLanguage: "dv", codeCountry: "MV" },
  { language: "Bambara", codeLanguage: "bm", codeCountry: "ML" },
  { language: "French", codeLanguage: "fr", codeCountry: "ML" },
  { language: "Koyra Chiini", codeLanguage: "khq", codeCountry: "ML" },
  { language: "Koyraboro Senni", codeLanguage: "ses", codeCountry: "ML" },
  { language: "Maltese", codeLanguage: "mt", codeCountry: "MT" },
  { language: "French", codeLanguage: "fr", codeCountry: "MQ" },
  { language: "Spanish", codeLanguage: "es", codeCountry: "MQ" },
  { language: "Arabic", codeLanguage: "ar", codeCountry: "MR" },
  { language: "French", codeLanguage: "fr", codeCountry: "MR" },
  { language: "Fulah", codeLanguage: "ff", codeCountry: "MR" },
  { language: "French", codeLanguage: "fr", codeCountry: "MU" },
  { language: "Morisyen", codeLanguage: "mfe", codeCountry: "MU" },
  { language: "French", codeLanguage: "fr", codeCountry: "YT" },
  { language: "Spanish", codeLanguage: "es", codeCountry: "MX" },
  { language: "Romanian", codeLanguage: "ro", codeCountry: "MD" },
  { language: "Russian", codeLanguage: "ru", codeCountry: "MD" },
  { language: "French", codeLanguage: "fr", codeCountry: "MC" },
  { language: "Mongolian", codeLanguage: "mn", codeCountry: "MN" },
  { language: "Serbian", codeLanguage: "sr", codeCountry: "ME" },
  { language: "Serbian", codeLanguage: "sr", codeCountry: "ME" },
  { language: "Spanish", codeLanguage: "es", codeCountry: "MS" },
  { language: "Arabic", codeLanguage: "ar", codeCountry: "MA" },
  {
    language: "Central Atlas Tamazight",
    codeLanguage: "tzm",
    codeCountry: "MA",
  },
  { language: "French", codeLanguage: "fr", codeCountry: "MA" },
  {
    language: "Standard Moroccan Tamazight",
    codeLanguage: "zgh",
    codeCountry: "MA",
  },
  { language: "Tachelhit", codeLanguage: "shi", codeCountry: "MA" },
  { language: "Tachelhit", codeLanguage: "shi", codeCountry: "MA" },
  { language: "Makhuwa-Meetto", codeLanguage: "mgh", codeCountry: "MZ" },
  { language: "Portuguese", codeLanguage: "pt", codeCountry: "MZ" },
  { language: "Sena", codeLanguage: "seh", codeCountry: "MZ" },
  { language: "Burmese", codeLanguage: "my", codeCountry: "MM" },
  { language: "Afrikaans", codeLanguage: "af", codeCountry: "NA" },
  { language: "Nama", codeLanguage: "naq", codeCountry: "NA" },
  { language: "Nepali", codeLanguage: "ne", codeCountry: "NP" },
  { language: "Dutch", codeLanguage: "nl", codeCountry: "NL" },
  { language: "Low German", codeLanguage: "nds", codeCountry: "NL" },
  { language: "Western Frisian", codeLanguage: "fy", codeCountry: "NL" },
  { language: "French", codeLanguage: "fr", codeCountry: "NC" },
  { language: "Maori", codeLanguage: "mi", codeCountry: "NZ" },
  { language: "Spanish", codeLanguage: "es", codeCountry: "NI" },
  { language: "French", codeLanguage: "fr", codeCountry: "NE" },
  { language: "Fulah", codeLanguage: "ff", codeCountry: "NE" },
  { language: "Hausa", codeLanguage: "ha", codeCountry: "NE" },
  { language: "Tasawaq", codeLanguage: "twq", codeCountry: "NE" },
  { language: "Zarma", codeLanguage: "dje", codeCountry: "NE" },
  { language: "Fulah", codeLanguage: "ff", codeCountry: "NG" },
  { language: "Hausa", codeLanguage: "ha", codeCountry: "NG" },
  { language: "Igbo", codeLanguage: "ig", codeCountry: "NG" },
  { language: "Jju", codeLanguage: "kaj", codeCountry: "NG" },
  { language: "Tyap", codeLanguage: "kcg", codeCountry: "NG" },
  { language: "Yoruba", codeLanguage: "yo", codeCountry: "NG" },
  { language: "Korean", codeLanguage: "ko", codeCountry: "KP" },
  { language: "Albanian", codeLanguage: "sq", codeCountry: "MK" },
  { language: "Macedonian", codeLanguage: "mk", codeCountry: "MK" },
  { language: "Northern Sami", codeLanguage: "se", codeCountry: "NO" },
  { language: "Norwegian Bokmål", codeLanguage: "nb", codeCountry: "NO" },
  { language: "Norwegian Nynorsk", codeLanguage: "nn", codeCountry: "NO" },
  { language: "Arabic", codeLanguage: "ar", codeCountry: "OM" },
  { language: "Pashto", codeLanguage: "ps", codeCountry: "PK" },
  { language: "Punjabi", codeLanguage: "pa", codeCountry: "PK" },
  { language: "Punjabi", codeLanguage: "pa", codeCountry: "PK" },
  { language: "Sindhi", codeLanguage: "sd", codeCountry: "PK" },
  { language: "Urdu", codeLanguage: "ur", codeCountry: "PK" },
  { language: "Urdu", codeLanguage: "ur", codeCountry: "PK" },
  { language: "Urdu", codeLanguage: "ur", codeCountry: "PK" },
  { language: "Arabic", codeLanguage: "ar", codeCountry: "PS" },
  { language: "Spanish", codeLanguage: "es", codeCountry: "PA" },
  { language: "Guarani", codeLanguage: "gn", codeCountry: "PY" },
  { language: "Spanish", codeLanguage: "es", codeCountry: "PY" },
  { language: "Quechua", codeLanguage: "qu", codeCountry: "PE" },
  { language: "Spanish", codeLanguage: "es", codeCountry: "PE" },
  { language: "Cebuano", codeLanguage: "ceb", codeCountry: "PH" },
  { language: "Filipino", codeLanguage: "fil", codeCountry: "PH" },
  { language: "Spanish", codeLanguage: "es", codeCountry: "PH" },
  { language: "Polish", codeLanguage: "pl", codeCountry: "PL" },
  { language: "Portuguese", codeLanguage: "pt", codeCountry: "PT" },
  { language: "Spanish", codeLanguage: "es", codeCountry: "PR" },
  { language: "Arabic", codeLanguage: "ar", codeCountry: "QA" },
  { language: "French", codeLanguage: "fr", codeCountry: "RE" },
  { language: "Romanian", codeLanguage: "ro", codeCountry: "RO" },
  { language: "Bashkir", codeLanguage: "ba", codeCountry: "RU" },
  { language: "Chechen", codeLanguage: "ce", codeCountry: "RU" },
  { language: "Chuvash", codeLanguage: "cv", codeCountry: "RU" },
  { language: "Erzya", codeLanguage: "myv", codeCountry: "RU" },
  { language: "Ossetic", codeLanguage: "os", codeCountry: "RU" },
  { language: "Russian", codeLanguage: "ru", codeCountry: "RU" },
  { language: "Sakha", codeLanguage: "sah", codeCountry: "RU" },
  { language: "Tatar", codeLanguage: "tt", codeCountry: "RU" },
  { language: "French", codeLanguage: "fr", codeCountry: "RW" },
  { language: "Kinyarwanda", codeLanguage: "rw", codeCountry: "RW" },
  { language: "Italian", codeLanguage: "it", codeCountry: "SM" },
  { language: "Portuguese", codeLanguage: "pt", codeCountry: "ST" },
  { language: "Arabic", codeLanguage: "ar", codeCountry: "SA" },
  { language: "French", codeLanguage: "fr", codeCountry: "SN" },
  { language: "Fulah", codeLanguage: "ff", codeCountry: "SN" },
  { language: "Jola-Fonyi", codeLanguage: "dyo", codeCountry: "SN" },
  { language: "Wolof", codeLanguage: "wo", codeCountry: "SN" },
  { language: "Serbian", codeLanguage: "sr", codeCountry: "RS" },
  { language: "Serbian", codeLanguage: "sr", codeCountry: "RS" },
  { language: "French", codeLanguage: "fr", codeCountry: "SC" },
  { language: "Fulah", codeLanguage: "ff", codeCountry: "SL" },
  { language: "Chinese", codeLanguage: "zh", codeCountry: "SG" },
  { language: "Malay", codeLanguage: "ms", codeCountry: "SG" },
  { language: "Tamil", codeLanguage: "ta", codeCountry: "SG" },
  { language: "Dutch", codeLanguage: "nl", codeCountry: "SX" },
  { language: "Spanish", codeLanguage: "es", codeCountry: "SX" },
  { language: "Slovak", codeLanguage: "sk", codeCountry: "SK" },
  { language: "Slovenian", codeLanguage: "sl", codeCountry: "SI" },
  { language: "Arabic", codeLanguage: "ar", codeCountry: "SO" },
  { language: "Somali", codeLanguage: "so", codeCountry: "SO" },
  { language: "Afrikaans", codeLanguage: "af", codeCountry: "ZA" },
  { language: "Northern Sotho", codeLanguage: "nso", codeCountry: "ZA" },
  { language: "South Ndebele", codeLanguage: "nr", codeCountry: "ZA" },
  { language: "Southern Sotho", codeLanguage: "st", codeCountry: "ZA" },
  { language: "Swati", codeLanguage: "ss", codeCountry: "ZA" },
  { language: "Tsonga", codeLanguage: "ts", codeCountry: "ZA" },
  { language: "Tswana", codeLanguage: "tn", codeCountry: "ZA" },
  { language: "Venda", codeLanguage: "ve", codeCountry: "ZA" },
  { language: "Xhosa", codeLanguage: "xh", codeCountry: "ZA" },
  { language: "Zulu", codeLanguage: "zu", codeCountry: "ZA" },
  { language: "Korean", codeLanguage: "ko", codeCountry: "KR" },
  { language: "Arabic", codeLanguage: "ar", codeCountry: "SS" },
  { language: "Nuer", codeLanguage: "nus", codeCountry: "SS" },
  { language: "Asturian", codeLanguage: "ast", codeCountry: "ES" },
  { language: "Basque", codeLanguage: "eu", codeCountry: "ES" },
  { language: "Catalan", codeLanguage: "ca", codeCountry: "ES" },
  { language: "Galician", codeLanguage: "gl", codeCountry: "ES" },
  { language: "Spanish", codeLanguage: "es", codeCountry: "ES" },
  { language: "Sinhala", codeLanguage: "si", codeCountry: "LK" },
  { language: "Tamil", codeLanguage: "ta", codeCountry: "LK" },
  { language: "French", codeLanguage: "fr", codeCountry: "BL" },
  { language: "Spanish", codeLanguage: "es", codeCountry: "BL" },
  { language: "Spanish", codeLanguage: "es", codeCountry: "KN" },
  { language: "Spanish", codeLanguage: "es", codeCountry: "LC" },
  { language: "French", codeLanguage: "fr", codeCountry: "MF" },
  { language: "Spanish", codeLanguage: "es", codeCountry: "MF" },
  { language: "French", codeLanguage: "fr", codeCountry: "PM" },
  { language: "Spanish", codeLanguage: "es", codeCountry: "PM" },
  { language: "Spanish", codeLanguage: "es", codeCountry: "VC" },
  { language: "Arabic", codeLanguage: "ar", codeCountry: "SD" },
  { language: "Dutch", codeLanguage: "nl", codeCountry: "SR" },
  { language: "Spanish", codeLanguage: "es", codeCountry: "SR" },
  { language: "Norwegian Bokmål", codeLanguage: "nb", codeCountry: "SJ" },
  { language: "Northern Sami", codeLanguage: "se", codeCountry: "SE" },
  { language: "Swedish", codeLanguage: "sv", codeCountry: "SE" },
  { language: "French", codeLanguage: "fr", codeCountry: "CH" },
  { language: "German", codeLanguage: "de", codeCountry: "CH" },
  { language: "Italian", codeLanguage: "it", codeCountry: "CH" },
  { language: "Portuguese", codeLanguage: "pt", codeCountry: "CH" },
  { language: "Romansh", codeLanguage: "rm", codeCountry: "CH" },
  { language: "Swiss German", codeLanguage: "gsw", codeCountry: "CH" },
  { language: "Walser", codeLanguage: "wae", codeCountry: "CH" },
  { language: "Arabic", codeLanguage: "ar", codeCountry: "SY" },
  { language: "French", codeLanguage: "fr", codeCountry: "SY" },
  { language: "Syriac", codeLanguage: "syr", codeCountry: "SY" },
  { language: "Chinese", codeLanguage: "zh", codeCountry: "TW" },
  { language: "Taroko", codeLanguage: "trv", codeCountry: "TW" },
  { language: "Tajik", codeLanguage: "tg", codeCountry: "TJ" },
  { language: "Asu", codeLanguage: "asa", codeCountry: "TZ" },
  { language: "Bena", codeLanguage: "bez", codeCountry: "TZ" },
  { language: "Langi", codeLanguage: "lag", codeCountry: "TZ" },
  { language: "Machame", codeLanguage: "jmc", codeCountry: "TZ" },
  { language: "Makonde", codeLanguage: "kde", codeCountry: "TZ" },
  { language: "Masai", codeLanguage: "mas", codeCountry: "TZ" },
  { language: "Rombo", codeLanguage: "rof", codeCountry: "TZ" },
  { language: "Rwa", codeLanguage: "rwk", codeCountry: "TZ" },
  { language: "Sangu", codeLanguage: "sbp", codeCountry: "TZ" },
  { language: "Shambala", codeLanguage: "ksb", codeCountry: "TZ" },
  { language: "Swahili", codeLanguage: "sw", codeCountry: "TZ" },
  { language: "Vunjo", codeLanguage: "vun", codeCountry: "TZ" },
  { language: "Thai", codeLanguage: "th", codeCountry: "TH" },
  { language: "Portuguese", codeLanguage: "pt", codeCountry: "TL" },
  { language: "Ewe", codeLanguage: "ee", codeCountry: "TG" },
  { language: "French", codeLanguage: "fr", codeCountry: "TG" },
  { language: "Tongan", codeLanguage: "to", codeCountry: "TO" },
  { language: "Spanish", codeLanguage: "es", codeCountry: "TT" },
  { language: "Arabic", codeLanguage: "ar", codeCountry: "TN" },
  { language: "French", codeLanguage: "fr", codeCountry: "TN" },
  { language: "Kurdish", codeLanguage: "ku", codeCountry: "TR" },
  { language: "Turkish", codeLanguage: "tr", codeCountry: "TR" },
  { language: "Turkmen", codeLanguage: "tk", codeCountry: "TM" },
  { language: "Spanish", codeLanguage: "es", codeCountry: "TC" },
  { language: "Spanish", codeLanguage: "es", codeCountry: "VI" },
  { language: "Chiga", codeLanguage: "cgg", codeCountry: "UG" },
  { language: "Ganda", codeLanguage: "lg", codeCountry: "UG" },
  { language: "Nyankole", codeLanguage: "nyn", codeCountry: "UG" },
  { language: "Soga", codeLanguage: "xog", codeCountry: "UG" },
  { language: "Swahili", codeLanguage: "sw", codeCountry: "UG" },
  { language: "Teso", codeLanguage: "teo", codeCountry: "UG" },
  { language: "Russian", codeLanguage: "ru", codeCountry: "UA" },
  { language: "Ukrainian", codeLanguage: "uk", codeCountry: "UA" },
  { language: "Arabic", codeLanguage: "ar", codeCountry: "AE" },
  { language: "Cornish", codeLanguage: "kw", codeCountry: "GB" },
  { language: "Scottish Gaelic", codeLanguage: "gd", codeCountry: "GB" },
  { language: "Welsh", codeLanguage: "cy", codeCountry: "GB" },

  { language: "Spanish", codeLanguage: "es", codeCountry: "UY" },
  { language: "Uzbek", codeLanguage: "uz", codeCountry: "UZ" },
  { language: "Uzbek", codeLanguage: "uz", codeCountry: "UZ" },
  { language: "French", codeLanguage: "fr", codeCountry: "VU" },
  { language: "Italian", codeLanguage: "it", codeCountry: "VA" },
  { language: "Spanish", codeLanguage: "es", codeCountry: "VE" },
  { language: "Vietnamese", codeLanguage: "vi", codeCountry: "VN" },
  { language: "French", codeLanguage: "fr", codeCountry: "WF" },
  { language: "Arabic", codeLanguage: "ar", codeCountry: "EH" },
  { language: "Arabic", codeLanguage: "ar", codeCountry: "001" },
  { language: "Esperanto", codeLanguage: "eo", codeCountry: "001" },
  { language: "Ido", codeLanguage: "io", codeCountry: "001" },
  { language: "Interlingua", codeLanguage: "ia", codeCountry: "001" },
  { language: "Lojban", codeLanguage: "jbo", codeCountry: "001" },
  { language: "Yiddish", codeLanguage: "yi", codeCountry: "001" },
  { language: "Arabic", codeLanguage: "ar", codeCountry: "YE" },
  { language: "Bemba", codeLanguage: "bem", codeCountry: "ZM" },
  { language: "North Ndebele", codeLanguage: "nd", codeCountry: "ZW" },
  { language: "Shona", codeLanguage: "sn", codeCountry: "ZW" },
];
