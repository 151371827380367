import { Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { AiOutlineFall, AiOutlineMail, AiOutlineSend } from "react-icons/ai";
import { BsStars } from "react-icons/bs";
import PublicService from "../../service/PublicService";
import CallStatsComponent from "./vacancies/DetailsModal/CallStatsComponent";

const DemoCard = () => {
  const [data, setData] = useState(null);
  useEffect(() => {
    if (localStorage.demoUUID)
      PublicService.getDemo(localStorage.demoUUID).then((res) => {
        setData(res.data?.result);
      });
    return () => localStorage.removeItem("demoUUID");
  }, []);

  if (!data) return <Skeleton active />;
  return (
    <>
      <h1 className="font-bold text-xl mb-2">
        Here is how you did during your demo interview:
      </h1>

      <CallStatsComponent callData={data} />

      <div className="h-[100%] w-[100%] flex flex-col gap-2 p-2">
        <div className="flex gap-2 items-center">
          {" "}
          <BsStars
            color="#FBB623"
            fontSize={"25px"}
            style={{ fontWeight: "bolder" }}
          />{" "}
          <span className="text-gray-500">Candidate Highligth</span>
        </div>
        <ol>
          {data?.aiValidation?.highlight?.map?.((ele, index) => (
            <li className="flex gap-2 justify-start items-center font-bold capitalize">
              <div
                style={{
                  height: "5px",
                  width: "5px",
                  background: "black",
                  borderRadius: "50%",
                }}
              ></div>{" "}
              {ele}
            </li>
          ))}
        </ol>
        <div className="flex gap-2 items-center">
          {" "}
          <AiOutlineFall
            color="#F46652"
            fontSize={"30px"}
            style={{ fontWeight: "bolder" }}
          />{" "}
          <span className="text-gray-500">Cadidate Lowlight</span>
        </div>
        <ol>
          {data?.aiValidation?.lowlight?.map?.((ele, index) => (
            <li className="flex gap-2 justify-start items-center font-bold capitalize">
              <div
                style={{
                  height: "5px",
                  width: "5px",
                  background: "black",
                  borderRadius: "50%",
                }}
              ></div>{" "}
              {ele}
            </li>
          ))}
        </ol>
        <h1 className="text-gray-500">Candidate Skills</h1>
        <div className="flex flex-wrap gap-3">
          {data?.aiValidation?.skills?.map?.((ele) => (
            <div className="h-[30px] w-min-[100px] flex justify-center items-center border capitalize p-2 border-black rounded-lg bg-gray-200 font-bold ">
              {ele}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default DemoCard;
