import { Button, Upload, message } from "antd";
import axios from "axios";
import React from "react";

const CloudinaryUpload = ({ onChange, customText }) => {
  const customRequest = async ({ file, onSuccess, onError }) => {
    if (!localStorage?.cloudinaryCloudName)
      return message.error("Uploads are disabled");
    if (!localStorage?.cloudinaryPreset)
      return message.error("Uploads are disabled");

    try {
      const cloudinaryUploadUrl = `https://api.cloudinary.com/v1_1/${localStorage?.cloudinaryCloudName}/auto/upload`;
      const formData = new FormData();
      formData.append("file", file);
      formData.append("upload_preset", localStorage?.cloudinaryPreset);
      const response = await axios.post(cloudinaryUploadUrl, formData);
      const secureUrl = response.data.secure_url;
      onChange(secureUrl);
      onSuccess(response, file);
    } catch (error) {
      console.error("Error uploading file to Cloudinary", error);
      onError(error);
    }
  };

  return (
    <Upload customRequest={customRequest}>
      <button className="px-2 py-1 text-sm bg-indigo-500 text-white rounded">
        {customText ?? "Upload"}
      </button>
    </Upload>
  );
};

export default CloudinaryUpload;
