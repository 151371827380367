import Vapi from "@vapi-ai/web";
import { Alert, Skeleton } from "antd";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { selectUser } from "../redux/auth/selectors";
import CVService from "../service/CVService";
import PublicService from "../service/PublicService";
import Camera from "./Camera";

export const voices = {
  "en-US": {
    male: "s3://voice-cloning-zero-shot/a540a448-a9ca-446c-9538-d1bae6c506f1/original/manifest.json",
    female: "jennifer",
    label: "American English",
    provider: "playht",
    language: "en-US",
  },
  "en-GB": {
    male: "s3://voice-cloning-zero-shot/2879ab87-3775-4992-a228-7e4f551658c2/fredericksaad2/manifest.json",
    female:
      "s3://voice-cloning-zero-shot/32b943f6-87cf-4e15-8e7a-d4cb848e3689/original/manifest.json",
    label: "British English",
    provider: "playht",
    language: "en-GB",
  },
  "en-CA": {
    male: "en-CA-LiamNeural",
    female: "en-CA-ClaraNeural",
    label: "Canadian English",
    provider: "azure",
    language: "en",
  },
  "en-AU": {
    male: "en-AU-DarrenNeural",
    female: "en-AU-ElsieNeural",
    label: "Australian English",
    provider: "azure",
    language: "en-AU",
  },
  "en-HK": {
    male: "en-HK-SamNeural",
    female: "en-HK-YanNeural",
    label: "Hong Kong English",
    provider: "azure",
    language: "en",
  },
  "en-IE": {
    male: "en-IE-ConnorNeural",
    female: "en-IE-EmilyNeural",
    label: "Irish English",
    provider: "azure",
    language: "en",
  },
  "en-KE": {
    male: "en-KE-ChilembaNeural",
    female: "en-KE-AsiliaNeural",
    label: "Kenyan English",
    provider: "azure",
    language: "en",
  },
  "en-NG": {
    male: "en-NG-AbeoNeural",
    female: "en-NG-EzinneNeural",
    label: "Nigerian English",
    provider: "azure",
    language: "en",
  },
  "en-NZ": {
    male: "en-NZ-MitchNeural",
    female: "en-NZ-LunaNeural",
    label: "New Zealand English",
    provider: "azure",
    language: "en-NZ",
  },
  "en-PH": {
    male: "en-PH-JamesNeural",
    female: "en-PH-RosaNeural",
    label: "Philippine English",
    provider: "azure",
    language: "en",
  },
  "en-SG": {
    male: "en-SG-WayneNeural",
    female: "en-SG-LunaNeural",
    label: "Singapore English",
    provider: "azure",
    language: "en",
  },
  "en-TZ": {
    male: "en-TZ-ElimuNeural",
    female: "en-TZ-ImaniNeural",
    label: "Tanzanian English",
    provider: "azure",
    language: "en",
  },
  "en-ZA": {
    male: "en-ZA-LukeNeural",
    female: "en-ZA-LeahNeural",
    label: "South African English",
    provider: "azure",
    language: "en",
  },
  "es-AR": {
    male: "es-AR-EmilioNeural",
    female: "es-AR-ElenaNeural",
    label: "Argentinian Spanish",
    provider: "azure",
    language: "es",
  },
  "es-BO": {
    male: "es-BO-MarceloNeural",
    female: "es-BO-SofiaNeural",
    label: "Bolivian Spanish",
    provider: "azure",
    language: "es",
  },
  "es-CL": {
    male: "es-CL-LorenzoNeural",
    female: "es-CL-CatalinaNeural",
    label: "Chilean Spanish",
    provider: "azure",
    language: "es",
  },
  "es-CO": {
    male: "es-CO-GonzaloNeural",
    female: "es-CO-SalomeNeural",
    label: "Colombian Spanish",
    provider: "azure",
    language: "es",
  },
  "es-CR": {
    male: "es-CR-JuanNeural",
    female: "es-CR-MariaNeural",
    label: "Costa Rican Spanish",
    provider: "azure",
    language: "es",
  },
  "es-CU": {
    male: "es-CU-ManuelNeural",
    female: "es-CU-BelkysNeural",
    label: "Cuban Spanish",
    provider: "azure",
    language: "es",
  },
  "es-DO": {
    male: "es-DO-RobertoNeural",
    female: "es-DO-RamonaNeural",
    label: "Dominican Spanish",
    provider: "azure",
    language: "es",
  },
  "es-EC": {
    male: "es-EC-LuisNeural",
    female: "es-EC-AndreaNeural",
    label: "Ecuadorian Spanish",
    provider: "azure",
    language: "es",
  },
  "es-ES": {
    male: "es-ES-NilNeural",
    female: "es-ES-XimenaNeural",
    label: "Spanish",
    provider: "azure",
    language: "es",
  },
  "es-GQ": {
    male: "es-GQ-JavierNeural",
    female: "es-GQ-TeresaNeural",
    label: "Equatorial Guinea Spanish",
    provider: "azure",
    language: "es",
  },
  "es-GT": {
    male: "es-GT-AndresNeural",
    female: "es-GT-MartaNeural",
    label: "Guatemalan Spanish",
    provider: "azure",
    language: "es",
  },
  "es-HN": {
    male: "es-HN-CarlosNeural",
    female: "es-HN-KarlaNeural",
    label: "Honduran Spanish",
    provider: "azure",
    language: "es",
  },
  "es-MX": {
    male: "es-MX-GerardoNeural",
    female: "es-MX-LarissaNeural",
    label: "Mexican Spanish",
    provider: "azure",
    language: "es",
  },
  "es-NI": {
    male: "es-NI-VictorNeural",
    female: "es-NI-YolandaNeural",
    label: "Nicaraguan Spanish",
    provider: "azure",
    language: "es",
  },
  "es-PA": {
    male: "es-PA-RobertoNeural",
    female: "es-PA-DaliaNeural",
    label: "Panamanian Spanish",
    provider: "azure",
    language: "es",
  },
  "es-PE": {
    male: "es-PE-AlexNeural",
    female: "es-PE-CamilaNeural",
    label: "Peruvian Spanish",
    provider: "azure",
    language: "es",
  },
  "es-PR": {
    male: "es-PR-VictorNeural",
    female: "es-PR-KarinaNeural",
    label: "Puerto Rican Spanish",
    provider: "azure",
    language: "es",
  },
  "es-PY": {
    male: "es-PY-MarioNeural",
    female: "es-PY-TaniaNeural",
    label: "Paraguayan Spanish",
    provider: "azure",
    language: "es",
  },
  "es-SV": {
    male: "es-SV-RodrigoNeural",
    female: "es-SV-LorenaNeural",
    label: "Salvadoran Spanish",
    provider: "azure",
    language: "es",
  },
  "es-US": {
    male: "es-US-AlvaroNeural",
    female: "es-US-PalomaNeural",
    label: "American Spanish",
    provider: "azure",
    language: "es",
  },
  "es-UY": {
    male: "es-UY-MateoNeural",
    female: "es-UY-ValentinaNeural",
    label: "Uruguayan Spanish",
    provider: "azure",
    language: "es",
  },
  "es-VE": {
    male: "es-VE-SebastianNeural",
    female: "es-VE-PaolaNeural",
    label: "Venezuelan Spanish",
    provider: "azure",
    language: "es",
  },
  "nl-NL": {
    male: "nl-NL-MaartenNeural",
    female: "nl-NL-FennaNeural",
    label: "Dutch",
    provider: "azure",
    language: "nl",
  },
  "nl-BE": {
    male: "nl-BE-ArnaudNeural",
    female: "nl-BE-DenaNeural",
    label: "Belgian Dutch",
    provider: "azure",
    language: "nl-BE",
  },
  "de-DE": {
    male: "de-DE-KasperNeural",
    female: "de-DE-KlarissaNeural",
    label: "German",
    provider: "azure",
    language: "de",
  },
  "de-AT": {
    male: "de-AT-JonasNeural",
    female: "de-AT-IngridNeural",
    label: "Austrian German",
    provider: "azure",
    language: "de",
  },
  "de-CH": {
    male: "de-CH-JanNeural",
    female: "de-CH-LeniNeural",
    label: "Swiss German",
    provider: "azure",
    language: "de-CH",
  },
  "fr-FR": {
    male: "fr-FR-RemyMultilingualNeural",
    female: "fr-FR-YvetteNeural",
    label: "French",
    provider: "azure",
    language: "fr",
  },
  "fr-BE": {
    male: "fr-BE-GerardNeural",
    female: "fr-BE-CharlineNeural",
    label: "Belgian French",
    provider: "azure",
    language: "fr",
  },
  "fr-CA": {
    male: "fr-CA-ThierryNeural",
    female: "fr-CA-SylvieNeural",
    label: "Canadian French",
    provider: "azure",
    language: "fr-CA",
  },
  "fr-CH": {
    male: "fr-CH-FabriceNeural",
    female: "fr-CH-ArianeNeural",
    label: "Swiss French",
    provider: "azure",
    language: "fr-CH",
  },
  "zh-CN": {
    male: "zh-CN-YunxiNeural",
    female: "zh-CN-XiaochenNeural",
    label: "Mandarin Chinese",
    provider: "azure",
    language: "zh-CN",
  },
  "zh-HK": {
    male: "zh-HK-YanNeural",
    female: "zh-HK-HiuMaanNeural",
    label: "Cantonese Chinese",
    provider: "azure",
    language: "zh",
  },
  "zh-TW": {
    male: "zh-TW-YunfengNeural",
    female: "zh-TW-HsiaoYuNeural",
    label: "Taiwanese Mandarin",
    provider: "azure",
    language: "zh-TW",
  },
  "zu-ZA": {
    male: "zu-ZA-ThembaNeural",
    female: "zu-ZA-ThandoNeural",
    label: "Zulu",
    provider: "azure",
    language: "zu",
  },
  "en-IN": {
    male: "en-IN-RehaanNeural",
    female: "en-IN-AnanyaNeural",
    label: "Indian English",
    provider: "azure",
    language: "en-IN",
  },
  "as-IN": {
    male: "as-IN-PriyomNeural",
    female: "as-IN-YashicaNeural",
    label: "Assamese",
    provider: "azure",
    language: "multi",
  },
  "bn-IN": {
    male: "bn-IN-BashkarNeural",
    female: "bn-IN-DhwaniNeural",
    label: "Bengali (India)",
    provider: "azure",
    language: "bn-IN",
  },
  "gu-IN": {
    male: "gu-IN-NiranjanNeural",
    female: "gu-IN-DhwaniNeural",
    label: "Gujarati",
    provider: "azure",
    language: "multi",
  },
  "hi-IN": {
    male: "hi-IN-AaravNeural",
    female: "hi-IN-AnanyaNeural",
    label: "Hindi",
    provider: "azure",
    language: "hi",
  },
  "kn-IN": {
    male: "kn-IN-GaganNeural",
    female: "kn-IN-SapnaNeural",
    label: "Kannada",
    provider: "azure",
    language: "multi",
  },
  "ml-IN": {
    male: "ml-IN-ManoharNeural",
    female: "ml-IN-SobhanaNeural",
    label: "Malayalam",
    provider: "azure",
    language: "multi",
  },
  "mr-IN": {
    male: "mr-IN-ManoharNeural",
    female: "mr-IN-AarohiNeural",
    label: "Marathi",
    provider: "azure",
    language: "multi",
  },
  "or-IN": {
    male: "or-IN-SukantNeural",
    female: "or-IN-SubhasiniNeural",
    label: "Odia",
    provider: "azure",
    language: "multi",
  },
  "pa-IN": {
    male: "pa-IN-OjasNeural",
    female: "pa-IN-VaaniNeural",
    label: "Punjabi",
    provider: "azure",
    language: "multi",
  },
  "ta-IN": {
    male: "ta-IN-ValluvarNeural",
    female: "ta-IN-PallaviNeural",
    label: "Tamil",
    provider: "azure",
    language: "ta-IN",
  },
  "te-IN": {
    male: "te-IN-MohanNeural",
    female: "te-IN-ShrutiNeural",
    label: "Telugu",
    provider: "azure",
    language: "te-IN",
  },
  "ur-IN": {
    male: "ur-IN-SalmanNeural",
    female: "ur-IN-UzmaNeural",
    label: "Urdu (Pakistan)",
    provider: "azure",
    language: "ur-PK",
  },
  "af-ZA": {
    male: "af-ZA-WillemNeural",
    female: "af-ZA-AdriNeural",
    label: "Afrikaans",
    provider: "azure",
    language: "af-ZA",
  },
  "am-ET": {
    male: "am-ET-AmehaNeural",
    female: "am-ET-MekdesNeural",
    label: "Amharic",
    provider: "azure",
    language: "multi",
  },
  "ar-AE": {
    male: "ar-AE-HamdanNeural",
    female: "ar-AE-FatimaNeural",
    label: "Arabic (UAE)",
    provider: "azure",
    language: "ar-AE",
  },
  "ar-BH": {
    male: "ar-BH-AliNeural",
    female: "ar-BH-LailaNeural",
    label: "Arabic (Bahrain)",
    provider: "azure",
    language: "ar-BH",
  },
  "ar-DZ": {
    male: "ar-DZ-IsmaelNeural",
    female: "ar-DZ-AminaNeural",
    label: "Arabic (Algeria)",
    provider: "azure",
    language: "ar-DZ",
  },
  "ar-EG": {
    male: "ar-EG-ShakirNeural",
    female: "ar-EG-SalmaNeural",
    label: "Arabic (Egypt)",
    provider: "azure",
    language: "ar-EG",
  },
  "ar-IQ": {
    male: "ar-IQ-BasselNeural",
    female: "ar-JO-SanaNeural",
    label: "Arabic (Iraq)",
    provider: "azure",
    language: "ar-IQ",
  },
  "ar-JO": {
    male: "ar-JO-TaimNeural",
    female: "ar-JO-SanaNeural",
    label: "Arabic (Jordan)",
    provider: "azure",
    language: "ar-JO",
  },
  "ar-KW": {
    male: "ar-KW-FahedNeural",
    female: "ar-KW-NouraNeural",
    label: "Arabic (Kuwait)",
    provider: "azure",
    language: "ar-KW",
  },
  "ar-LB": {
    male: "ar-LB-RamiNeural",
    female: "ar-LB-LaylaNeural",
    label: "Arabic (Lebanon)",
    provider: "azure",
    language: "ar-LB",
  },
  "ar-LY": {
    male: "ar-LY-OmarNeural",
    female: "ar-LY-ImanNeural",
    label: "Arabic (Libya)",
    provider: "azure",
    language: "ar-LY",
  },
  "ar-MA": {
    male: "ar-MA-JamalNeural",
    female: "ar-MA-AminaNeural",
    label: "Arabic (Morocco)",
    provider: "azure",
    language: "ar-MA",
  },
  "ar-OM": {
    male: "ar-OM-AbdullahNeural",
    female: "ar-OM-MayaNeural",
    label: "Arabic (Oman)",
    provider: "azure",
    language: "ar-OM",
  },
  "ar-QA": {
    male: "ar-QA-MoazNeural",
    female: "ar-QA-AmalNeural",
    label: "Arabic (Qatar)",
    provider: "azure",
    language: "ar-QA",
  },
  "ar-SA": {
    male: "ar-SA-HamedNeural",
    female: "ar-SA-ZariyahNeural",
    label: "Arabic (Saudi Arabia)",
    provider: "azure",
    language: "ar-SA",
  },
  "ar-SY": {
    male: "ar-SY-LaithNeural",
    female: "ar-SY-AmanyNeural",
    label: "Arabic (Syria)",
    provider: "azure",
    language: "ar-SY",
  },
  "ar-TN": {
    male: "ar-TN-HediNeural",
    female: "ar-TN-ReemNeural",
    label: "Arabic (Tunisia)",
    provider: "azure",
    language: "ar-TN",
  },
  "ar-YE": {
    male: "ar-YE-RamiNeural",
    female: "ar-YE-SanaNeural",
    label: "Arabic (Yemen)",
    provider: "azure",
    language: "ar-YE",
  },
  "az-AZ": {
    male: "az-AZ-BabekNeural",
    female: "az-AZ-BanuNeural",
    label: "Azerbaijani",
    provider: "azure",
    language: "az-AZ",
  },
  "bg-BG": {
    male: "bg-BG-BorislavNeural",
    female: "bg-BG-KalinaNeural",
    label: "Bulgarian",
    provider: "azure",
    language: "bg",
  },
  "bn-BD": {
    male: "bn-BD-PradeepNeural",
    female: "bn-BD-NabanitaNeural",
    label: "Bengali (Bangladesh)",
    provider: "azure",
    language: "bn-BD",
  },
  "bn-IN": {
    male: "bn-IN-BashkarNeural",
    female: "bn-IN-DhwaniNeural",
    label: "Bengali (India)",
    provider: "azure",
    language: "bn-IN",
  },
  "bs-BA": {
    male: "bs-BA-GoranNeural",
    female: "bs-BA-VesnaNeural",
    label: "Bosnian",
    provider: "azure",
    language: "bs-BA",
  },
  "ca-ES": {
    male: "ca-ES-EnricNeural",
    female: "ca-ES-AlbaNeural",
    label: "Catalan",
    provider: "azure",
    language: "ca",
  },
  "cs-CZ": {
    male: "cs-CZ-AntoninNeural",
    female: "cs-CZ-VlastaNeural",
    label: "Czech",
    provider: "azure",
    language: "cs",
  },
  "cy-GB": {
    male: "cy-GB-AledNeural",
    female: "cy-GB-NiaNeural",
    label: "Welsh",
    provider: "azure",
    language: "cy",
  },
  "da-DK": {
    male: "da-DK-JeppeNeural",
    female: "da-DK-ChristelNeural",
    label: "Danish",
    provider: "azure",
    language: "da",
  },
  "el-GR": {
    male: "el-GR-NestorasNeural",
    female: "el-GR-AthinaNeural",
    label: "Greek",
    provider: "azure",
    language: "el",
  },
  "et-EE": {
    male: "et-EE-KertNeural",
    female: "et-EE-AnuNeural",
    label: "Estonian",
    provider: "azure",
    language: "et",
  },
  "eu-ES": {
    male: "eu-ES-AnderNeural",
    female: "eu-ES-AinhoaNeural",
    label: "Basque",
    provider: "azure",
    language: "eu",
  },
  "fa-IR": {
    male: "fa-IR-FaridNeural",
    female: "fa-IR-DilaraNeural",
    label: "Persian",
    provider: "azure",
    language: "fa",
  },
  "fi-FI": {
    male: "fi-FI-HarriNeural",
    female: "fi-FI-NooraNeural",
    label: "Finnish",
    provider: "azure",
    language: "fi",
  },
  "fil-P": {
    male: "fil-PH-AngeloNeural",
    female: "fil-PH-BlessicaNeural",
    label: "Filipino",
    provider: "azure",
    language: "multi",
  },
  "ga-IE": {
    male: "ga-IE-ColmNeural",
    female: "ga-IE-OrlaNeural",
    label: "Irish",
    provider: "azure",
    language: "ga",
  },
  "gl-ES": {
    male: "gl-ES-RoiNeural",
    female: "gl-ES-SabelaNeural",
    label: "Galician",
    provider: "azure",
    language: "gl",
  },
  "he-IL": {
    male: "he-IL-AvriNeural",
    female: "he-IL-HilaNeural",
    label: "Hebrew",
    provider: "azure",
    language: "he",
  },
  "hr-HR": {
    male: "hr-HR-SreckoNeural",
    female: "hr-HR-GabrijelaNeural",
    label: "Croatian",
    provider: "azure",
    language: "hr",
  },
  "hu-HU": {
    male: "hu-HU-TamasNeural",
    female: "hu-HU-NoemiNeural",
    label: "Hungarian",
    provider: "azure",
    language: "hu",
  },
  "hy-AM": {
    male: "hy-AM-HaykNeural",
    female: "hy-AM-AnahitNeural",
    label: "Armenian",
    provider: "azure",
    language: "hy",
  },
  "id-ID": {
    male: "id-ID-ArdiNeural",
    female: "id-ID-GadisNeural",
    label: "Indonesian",
    provider: "azure",
    language: "id",
  },
  "is-IS": {
    male: "is-IS-GunnarNeural",
    female: "is-IS-GudrunNeural",
    label: "Icelandic",
    provider: "azure",
    language: "is",
  },
  "it-IT": {
    male: "it-IT-GiuseppeNeural",
    female: "it-IT-FiammaNeural",
    label: "Italian",
    provider: "azure",
    language: "it",
  },
  "iu-Ca": {
    male: "iu-Latn-CA-TaqqiqNeural",
    female: "iu-Latn-CA-SiqiniqNeural",
    label: "Inuktitut (Canada)",
    provider: "azure",
    language: "multi",
  },
  "iu-La": {
    male: "iu-Latn-CA-TaqqiqNeural",
    female: "iu-Latn-CA-SiqiniqNeural",
    label: "Inuktitut (Latin)",
    provider: "azure",
    language: "multi",
  },
  "ja-JP": {
    male: "ja-JP-MasaruMultilingualNeural",
    female: "ja-JP-AoiNeural",
    label: "Japanese",
    provider: "azure",
    language: "ja",
  },
  "jv-ID": {
    male: "jv-ID-DimasNeural",
    female: "jv-ID-SitiNeural",
    label: "Javanese",
    provider: "azure",
    language: "jv",
  },
  "ka-GE": {
    male: "ka-GE-GiorgiNeural",
    female: "ka-GE-EkaNeural",
    label: "Georgian",
    provider: "azure",
    language: "ka",
  },
  "kk-KZ": {
    male: "kk-KZ-DauletNeural",
    female: "kk-KZ-AigulNeural",
    label: "Kazakh",
    provider: "azure",
    language: "kk",
  },
  "km-KH": {
    male: "km-KH-PisethNeural",
    female: "km-KH-SreymomNeural",
    label: "Khmer",
    provider: "azure",
    language: "km",
  },
  "ko-KR": {
    male: "ko-KR-HyunsuNeural",
    female: "ko-KR-JiMinNeural",
    label: "Korean",
    provider: "azure",
    language: "ko-KR",
  },
  "lo-LA": {
    male: "lo-LA-ChanthavongNeural",
    female: "lo-LA-KeomanyNeural",
    label: "Lao",
    provider: "azure",
    language: "lo",
  },
  "lt-LT": {
    male: "lt-LT-LeonasNeural",
    female: "lt-LT-OnaNeural",
    label: "Lithuanian",
    provider: "azure",
    language: "lt",
  },
  "lv-LV": {
    male: "lv-LV-NilsNeural",
    female: "lv-LV-EveritaNeural",
    label: "Latvian",
    provider: "azure",
    language: "lv",
  },
  "mk-MK": {
    male: "mk-MK-AleksandarNeural",
    female: "mk-MK-MarijaNeural",
    label: "Macedonian",
    provider: "azure",
    language: "mk",
  },
  "mn-MN": {
    male: "mn-MN-BataaNeural",
    female: "mn-MN-YesuiNeural",
    label: "Mongolian",
    provider: "azure",
    language: "mn",
  },
  "ms-MY": {
    male: "ms-MY-OsmanNeural",
    female: "ms-MY-YasminNeural",
    label: "Malay",
    provider: "azure",
    language: "ms",
  },
  "mt-MT": {
    male: "mt-MT-JosephNeural",
    female: "mt-MT-GraceNeural",
    label: "Maltese",
    provider: "azure",
    language: "mt",
  },
  "my-MM": {
    male: "my-MM-ThihaNeural",
    female: "my-MM-NilarNeural",
    label: "Burmese",
    provider: "azure",
    language: "my",
  },
  "nb-NO": {
    male: "nb-NO-FinnNeural",
    female: "nb-NO-PernilleNeural",
    label: "Norwegian",
    provider: "azure",
    language: "no",
  },
  "ne-NP": {
    male: "ne-NP-SagarNeural",
    female: "ne-NP-HemkalaNeural",
    label: "Nepali",
    provider: "azure",
    language: "ne",
  },
  "pl-PL": {
    male: "pl-PL-MarekNeural",
    female: "pl-PL-ZofiaNeural",
    label: "Polish",
    provider: "azure",
    language: "pl",
  },
  "ps-AF": {
    male: "ps-AF-GulNawazNeural",
    female: "ps-AF-LatifaNeural",
    label: "Pashto",
    provider: "azure",
    language: "ps",
  },
  "pt-BR": {
    male: "pt-BR-NicolauNeural",
    female: "pt-BR-ManuelaNeural",
    label: "Brazilian Portuguese",
    provider: "azure",
    language: "pt-BR",
  },
  "pt-PT": {
    male: "pt-PT-DuarteNeural",
    female: "pt-PT-RaquelNeural",
    label: "European Portuguese",
    provider: "azure",
    language: "pt",
  },
  "ro-RO": {
    male: "ro-RO-EmilNeural",
    female: "ro-RO-AlinaNeural",
    label: "Romanian",
    provider: "azure",
    language: "ro",
  },
  "ru-RU": {
    male: "ru-RU-DmitryNeural",
    female: "ru-RU-DariyaNeural",
    label: "Russian",
    provider: "azure",
    language: "ru",
  },
  "si-LK": {
    male: "si-LK-SameeraNeural",
    female: "si-LK-ThiliniNeural",
    label: "Sinhalese",
    provider: "azure",
    language: "si",
  },
  "sk-SK": {
    male: "sk-SK-LukasNeural",
    female: "sk-SK-ViktoriaNeural",
    label: "Slovak",
    provider: "azure",
    language: "sk",
  },
  "sl-SI": {
    male: "sl-SI-RokNeural",
    female: "sl-SI-PetraNeural",
    label: "Slovenian",
    provider: "azure",
    language: "sl",
  },
  "so-SO": {
    male: "so-SO-MuuseNeural",
    female: "so-SO-UbaxNeural",
    label: "Somali",
    provider: "azure",
    language: "so",
  },
  "sq-AL": {
    male: "sq-AL-IlirNeural",
    female: "sq-AL-AnilaNeural",
    label: "Albanian",
    provider: "azure",
    language: "sq",
  },
  "sr-La": {
    male: "sr-Latn-RS-NicholasNeural",
    female: "sr-Latn-RS-SophieNeural",
    label: "Serbian (Latin)",
    provider: "azure",
    language: "sr",
  },
  "sr-RS": {
    male: "sr-RS-NicholasNeural",
    female: "sr-RS-SophieNeural",
    label: "Serbian (Cyrillic)",
    provider: "azure",
    language: "sr",
  },
  "su-ID": {
    male: "su-ID-JajangNeural",
    female: "su-ID-TutiNeural",
    label: "Sundanese",
    provider: "azure",
    language: "su",
  },
  "sv-SE": {
    male: "sv-SE-MattiasNeural",
    female: "sv-SE-HilleviNeural",
    label: "Swedish",
    provider: "azure",
    language: "sv",
  },
  "sw-KE": {
    male: "sw-KE-RafikiNeural",
    female: "sw-KE-ZuriNeural",
    label: "Swahili (Kenya)",
    provider: "azure",
    language: "sw",
  },
  "sw-TZ": {
    male: "sw-TZ-DaudiNeural",
    female: "sw-TZ-RehemaNeural",
    label: "Swahili (Tanzania)",
    provider: "azure",
    language: "sw",
  },
  "ta-LK": {
    male: "ta-LK-KumarNeural",
    female: "ta-LK-SaranyaNeural",
    label: "Tamil (Sri Lanka)",
    provider: "azure",
    language: "ta-LK",
  },
  "ta-MY": {
    male: "ta-MY-SuryaNeural",
    female: "ta-MY-VenbaNeural",
    label: "Tamil (Malaysia)",
    provider: "azure",
    language: "ta-MY",
  },
  "ta-SG": {
    male: "ta-SG-AnbuNeural",
    female: "ta-SG-VenbaNeural",
    label: "Tamil (Singapore)",
    provider: "azure",
    language: "ta-SG",
  },
  "th-TH": {
    male: "th-TH-NiwatNeural",
    female: "th-TH-PremwadeeNeural",
    label: "Thai",
    provider: "azure",
    language: "th-TH",
  },
  "tr-TR": {
    male: "tr-TR-AhmetNeural",
    female: "tr-TR-EmelNeural",
    label: "Turkish",
    provider: "azure",
    language: "tr",
  },
  "uk-UA": {
    male: "uk-UA-OstapNeural",
    female: "uk-UA-PolinaNeural",
    label: "Ukrainian",
    provider: "azure",
    language: "uk",
  },
  "ur-PK": {
    male: "ur-PK-AsadNeural",
    female: "ur-PK-UzmaNeural",
    label: "Urdu (Pakistan)",
    provider: "azure",
    language: "ur",
  },
  "uz-UZ": {
    male: "uz-UZ-SardorNeural",
    female: "uz-UZ-MadinaNeural",
    label: "Uzbek",
    provider: "azure",
    language: "uz",
  },
  "vi-VN": {
    male: "vi-VN-NamMinhNeural",
    female: "vi-VN-HoaiMyNeural",
    label: "Vietnamese",
    provider: "azure",
    language: "vi",
  },
  "wuu-CN": {
    male: "wuu-CN-YunzheNeural",
    female: "wuu-CN-XiaotongNeural",
    label: "Wu Chinese",
    provider: "azure",
    language: "multi",
  },
  "yue-CN": {
    male: "yue-CN-YunSongNeural",
    female: "yue-CN-XiaoMinNeural",
    label: "Cantonese",
    provider: "azure",
    language: "zh-HK",
  },
};

const InterviewBookCall = ({ testHero }) => {
  let [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(false);
  const [isTestEnv, setIsTestEnv] = useState(false);
  const [vacancyData, setVacancyData] = useState(true);
  const [candidateData, setCandidateData] = useState(null);
  const user = useSelector(selectUser);
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef();
  const [volume, setVolume] = useState(0);
  const [transcript, setTranscript] = useState([]);
  const [endinterview, setendInterview] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (testHero) return;
    const token = searchParams.get("token");
    if (!token) return;

    setLoading(true);

    CVService.getCVData(token)
      .then(({ data }) => {
        setCandidateData(data.candidate);
        setVacancyData(data.vacancy);
      })
      .finally(() => setLoading(false));
  }, [searchParams, testHero]);

  const vapi = useRef(new Vapi(process.env.REACT_APP_VAPI_PUBLIC_KEY));

  useEffect(() => {
    if (vapi?.current) {
      vapi.current.on("volume-level", (volume) => {
        setVolume(volume);
      });

      vapi.current.on("message", (message) => {
        console.log(message);
        if (message.type === "transcript") {
          setTranscript((prevTranscript) => {
            const messages = [...prevTranscript];

            if (message.transcriptType === "final") {
              const newText = message.transcript.trim();
              messages.push({
                ...message,
                text: newText,
              });
            }

            return messages;
          });
        }
      });
    }
  }, [vapi]);

  useEffect(() => {
    if (testHero) return;
    const token = searchParams.get("token");
    if (!token) return;

    setLoading(true);

    CVService.getCVData(token)
      .then(({ data }) => {
        setCandidateData(data.candidate);
        setVacancyData(data.vacancy);
      })
      .finally(() => setLoading(false));
  }, [searchParams, testHero]);

  useEffect(() => {
    if (!endinterview) return;
    navigate(
      `/end-interview?token=${searchParams.get("token")}&training=${
        vacancyData.training
      }}`
    );
  }, [endinterview]);

  useEffect(() => {
    console.log(testHero);
    console.log(isTestEnv);
    if (testHero) setIsTestEnv(true);
    if (!testHero && isTestEnv) {
      console.log("stopping");
      vapi?.current?.stop?.();
    }
    return () => {
      console.log("here");
      if (isTestEnv) {
        console.log("stopping");
        vapi?.current?.stop?.();
      }
    };
  }, [testHero, isTestEnv]);

  const myHero = testHero ?? vacancyData?.hero;

  const handleStartInterview = useCallback(async () => {
    videoRef.current.play();

    if (isPlaying) return;
    setIsPlaying(true);

    const voice = voices[myHero?.aiLanguage] ?? voices["en-US"];

    let instructions = `Your name is ${
      myHero?.name
    }, and you are a world class expert recruiter. You are calling a candidate to screen their skills for ${
      vacancyData?.name
    } position. Your interview style must be ${
      myHero?.aiStyle
    }.\n\nMost important requirements for the job:\n${
      vacancyData?.requiredSkills ?? ""
    }\n\nThe call:\nKeep the intro short.\n\nMake sure that throughout the call:\n\nintroduce yourself properly - Name: ${
      myHero?.name
    }, who you are, what company (${
      vacancyData?.companyName || "HireHeroes"
    }) and why you're calling.\nkeep your questions and explanations concise and effective\nyou ask one questions at a time (let them answer first, and do not ask multiple questions at a time)\nask them intelligent questions to qualify them for the role\nbuild conversational rapport\nadjust your use of words to the role / level of education, required for the role\nDo not speak more than 20 seconds in a row unless it is necessary to make something clear\n\nWhen candidate answers your questions, do not make any comments and do not tell them that we are interested in hiring them since that will be decided later, keep your answers short (e.g. "uhuh, I understand") and focus on asking intelligent questions rather than making comments\n\nPrioritize user’s topics, like must-ask questions / requirements given. This can be done in the natural order, as long as the topics are handled.\n\nAt the end of the interview, close like this: "Thank you for participating in this interview. You are free to ask any final questions. Otherwise, have a great day, and we will be in touch soon."`;
    let firstMessage = `Hello ${
      candidateData?.formData?.firstname ?? user?.firstName ?? ""
    }, my name is ${myHero?.name}, your AI recruitment assistant from ${
      vacancyData?.companyName || "HireHeroes"
    }. Thank you for taking your time today. This will be your interview for ${
      vacancyData?.name
    } position you applied earlier. Let me know when you are ready, and we will get started!

    
    `;

    if (!voice.label.includes("English")) {
      try {
        const instructionsTranslate = await PublicService.prompt({
          content: `Please translate the following text to ${voice.label}. In your answer, do not include anything other than the blank translation of the text to ${voice.label}.\n\n${instructions}`,
        });
        const firstMessageTranslate = await PublicService.prompt({
          content: `Please translate the following text to ${voice.label}. In your answer, do not include anything other than the blank translation of the text to ${voice.label}.\n\n${firstMessage}`,
        });

        if (instructionsTranslate.data.response) {
          instructions = instructionsTranslate.data.response;
        }
        if (firstMessageTranslate.data.response) {
          firstMessage = firstMessageTranslate.data.response;
        }
      } catch (e) {
        console.error(e);
      }
    }
    console.log("user", candidateData);
    candidateData.phoneCalls = [];

    const filesResponse = await PublicService.getFiles(candidateData.user_id);

    vapi?.current?.start?.({
      maxDurationSeconds: myHero?.aiMaxDuration * 60,
      transcriber: {
        language: voices[myHero?.aiLanguage].language,
        provider: "deepgram",
      },
      model: {
        provider: "openai",
        model: "gpt-4o",
        fallbackModels: ["gpt-4", "gpt-3.5-turbo"],
        messages: [
          {
            role: "system",
            content: instructions,
          },
        ],
        knowledgeBase: {
          provider: "canonical",
          fileIds: filesResponse.data.files.map((x) => x.vapiId),
        },
      },
      voice: {
        speed: myHero?.aiSpeed,
        provider: voice.provider,
        voiceId: voice[myHero?.aiGender === "male" ? "male" : "female"],
      },

      name: myHero?.name,
      silenceTimeoutSeconds: 60 * 5,

      recordingEnabled: myHero?.aiRecord,
      backgroundSound: "office",
      firstMessage,
      serverUrl: `${
        process.env.REACT_APP_BACKEND_URL.includes("localhost")
          ? "https://taylor-backend-2f1b.vercel.app/api"
          : process.env.REACT_APP_BACKEND_URL
      }/blandHook/webhook?candidate_id=${candidateData?._id}`,
      serverUrlSecret: "hello-world",
      metadata: {
        candidateData,
        vacancyData,
      },
    });

    // Disable fullscreen API
    if (videoRef.current.requestFullscreen) {
      videoRef.current.requestFullscreen = () => {};
    } else if (videoRef.current.webkitRequestFullscreen) {
      videoRef.current.webkitRequestFullscreen = () => {};
    } else if (videoRef.current.mozRequestFullScreen) {
      videoRef.current.mozRequestFullScreen = () => {};
    } else if (videoRef.current.msRequestFullscreen) {
      videoRef.current.msRequestFullscreen = () => {};
    }
  }, [videoRef, isPlaying, myHero, vacancyData, user, candidateData]);

  if (!candidateData && loading && !testHero) return <Skeleton active />;
  if (!candidateData && !loading && !testHero)
    return (
      <div className="m-4">
        <Alert type="error" message="Invalid CV link" />
      </div>
    );

  if (!vacancyData?.enabled)
    return (
      <div className="m-4">
        <Alert type="error" message="This interview has been disabled" />
      </div>
    );

  return (
    <>
      {!testHero && (
        <div
          className={`bg-white h-screen z-40 fixed top-0 bottom-0 left-0 right-0`}
        >
          <Camera
            handleStartInterview={handleStartInterview}
            transcript={transcript}
            vapi={vapi}
            hero={myHero}
            user={candidateData}
            endinterview={endinterview}
            setendInterview={setendInterview}
            training={vacancyData.training}
          />
        </div>
      )}
      <div className={`bg-white ${testHero ? "" : "h-screen"}`}>
        <div className="mb-[55px] mdx:mb-1  flex flex-1 flex-col items-start gap-3  mdx:self-stretch smx:pt-0 pt-12  relative cursor-pointer">
          <div className="flex w-[67%]  mx-auto justify-center rounded-[24px]  mdx:w-full smx:p-1 ">
            <div className=" relative h-[320px] mdx:h-[125px] w-full rounded-lg  flex items-center justify-center p-1  mdx:h-auto">
              <img
                onClick={handleStartInterview}
                src="/dhimages/playbtn.png"
                alt="screenmockup"
                loop
                className={`object-contain  ${
                  isPlaying ? "hidden" : "animate-main"
                }`}
              />

              <video
                //change video! it´s green!!
                src="/dhimages/playbtn.mp4"
                alt="screenmockup"
                playsInline
                loop
                className={`rounded-lg object-contain  ${
                  isPlaying ? "" : "hidden"
                }`}
                style={{
                  transform: `scale(${(1 + volume / 15).toFixed(2)})`,
                }}
                ref={videoRef}
              />
            </div>
          </div>

          <div className="w-full flex justify-center">
            <div
              className="h-8 mt-[-45px] px-3 mt-2 bg-white bg-opacity-10 rounded-[100px]  border border-indigo-500 border-opacity-80 justify-center items-center gap-2 inline-flex"
              style={{
                letterSpacing: "0.2px",
                minWidth: "82px",
                opacity: !isPlaying ? 1 : 0,
              }}
            >
              <div className="text-transparent bg-gradient bg-clip-text text-sm font-bold text-xl leading-normal border border-indigo-500">
                Start your interview
              </div>
            </div>
          </div>
          <div className="w-full flex justify-center">
            <div
              className="h-8 mt-[-20px] px-3 mt-2 bg-white bg-opacity-10 rounded-[100px] justify-center items-center gap-2 inline-flex border border-indigo-500"
              style={{
                letterSpacing: "0.2px",
                minWidth: "82px",
                opacity: !isPlaying ? 1 : 0,
              }}
            >
              <div className="text-transparent bg-gradient bg-clip-text text-sm font-bold text-lg leading-normal">
                Click the microphone
              </div>
            </div>
          </div>
        </div>

        <div className="mt-[200px]"></div>
      </div>
    </>
  );
};

export default InterviewBookCall;
