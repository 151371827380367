export const getBackendUrl = () =>
  process.env.NODE_ENV !== "production"
    ? "http://localhost:5055/api"
    : process.env.REACT_APP_BACKEND_URL;

export const getBackendLinkUrl = () =>
  process.env.NODE_ENV !== "production"
    ? "http://localhost:8080"
    : process.env.REACT_APP_SHORTENER_APP_LINK;

export const getZoomAppUrl = (partner) =>
  process.env.NODE_ENV !== "production"
    ? "http://localhost:3010"
    : `https://meeting.${partner.domain}`;
