import { LoadingOutlined } from "@ant-design/icons";
import { Alert, Divider, Progress, Spin } from "antd";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthService from "../../service/AuthService";

const twoColors = {
  "0%": "#108ee9",
  "100%": "#87d068",
};

const randomNumber = () => Math.floor(Math.random() * 15) + 1;

const informations = [
  "We are developing AI algorithms to assess candidates' soft skills",
  "We are creating personalized interview scripts for every job role",
  "We are integrating natural language processing for seamless conversations",
  "We are training our AI interviewer to understand diverse accents",
  "We are refining our voice recognition technology for accuracy",
  "We are analyzing past interview data to improve question relevance",
  "We are designing intuitive user interfaces for recruiters",
  "We are implementing sentiment analysis to gauge candidate enthusiasm",
  "We are optimizing interview scheduling for efficiency",
  "We are ensuring GDPR compliance in candidate data handling",
  "We are customizing interview experiences to match company values",
  "We are integrating personality assessments into the interview process",
  "We are facilitating remote interviews for global talent acquisition",
  "We are enhancing candidate engagement through interactive features",
  "We are tailoring interview feedback reports for actionable insights",
  "We are automating reference checks to streamline hiring",
  "We are implementing bias detection algorithms for fair evaluations",
  "We are analyzing cultural fit indicators to align with company ethos",
  "We are developing machine learning models for predictive hiring",
  "We are optimizing interview workflows for HR productivity",
  "We are creating AI-powered interview coaching modules for candidates",
  "We are ensuring accessibility features for all candidates",
  "We are integrating video interviewing capabilities for visual cues",
  "We are conducting usability testing for a seamless user experience",
  "We are building integrations with popular ATS platforms",
  "We are optimizing interview scripts for role-specific competencies",
  "We are incorporating diversity metrics into recruitment analytics",
  "We are designing interview simulations to assess real-world skills",
  "We are implementing chatbot assistants for candidate support",
  "We are developing interview gamification features for engagement",
  "We are analyzing cultural alignment through text mining techniques",
  "We are automating candidate screening based on job requirements",
  "We are enhancing interview transcriptions for easy review",
  "We are providing interview analytics dashboards for data-driven decisions",
  "We are developing AI-driven candidate sourcing tools",
  "We are integrating interview feedback loops for continuous improvement",
  "We are ensuring data security measures in candidate interactions",
  "We are optimizing interview length for candidate comfort",
  "We are incorporating emotional intelligence assessments into interviews",
  "We are integrating HRIS systems for seamless onboarding",
  "We are designing interview simulations for scenario-based evaluations",
  "We are optimizing interview question banks for diversity and inclusion",
  "We are developing chatbot avatars for a personalized touch",
  "We are implementing interview scheduling assistants for recruiters",
  "We are automating follow-up communications with candidates",
  "We are analyzing interview response patterns for insight generation",
  "We are developing virtual job fairs for recruitment outreach",
  "We are ensuring GDPR-compliant data storage and processing",
  "We are optimizing AI interviewers for multi-lingual interactions",
  "We are providing ongoing training data updates for AI model accuracy",
];

export default function Onboarding() {
  const [me, setMe] = useState(null);
  const [percent, setPercent] = useState(0);
  const [informationIdx, setInformationIdx] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    AuthService.me().then((data) => setMe(data.data.me));
  }, []);

  useEffect(() => {
    setInterval(
      () => setPercent((p) => Math.min(100, p + randomNumber())),
      3000
    );
  }, []);

  useEffect(() => {
    if (percent === 100) {
      AuthService.updateMe({ onboardAnimation: true, silent: true }).then(
        () => {
          navigate("/auth/subscription");
        }
      );
    }

    setInformationIdx((i) => i + 1);
  }, [percent]);

  return (
    <div className=" flex items-center justify-center">
      <div className="text-center mt-10 ">
        <h3 className="mt-2 text-lg font-semibold text-gray-900 dark:text-gray-400 ">
          Onboarding
        </h3>
        <p className="mt-1 text-md text-gray-500 mt-5">
          We are setting up your customized HireHero.
        </p>

        <div className="mt-6">
          <Progress
            type="dashboard"
            percent={percent}
            strokeColor={twoColors}
          />
        </div>

        <Divider />

        <Alert
          message="Information"
          description={informations[informationIdx % informations.length]}
          type="info"
          showIcon
          icon={
            <Spin
              indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />}
            />
          }
        />
      </div>
    </div>
  );
}
