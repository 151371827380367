import { AutoComplete, Button, Input, Space } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { FaLinkedin } from "react-icons/fa";
import { useSelector } from "react-redux";
import { selectDarkMode } from "../../redux/auth/selectors";
import PublicService from "../../service/PublicService";

function debounce(func, wait) {
  let timeout;

  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

const LinkedinSearch = ({ onChange, value }) => {
  const [searchValue, setSearchValue] = useState("");
  const [results, setResults] = useState([]);
  const [isUsername, setIsUsername] = useState(false);
  const [locked, setLocked] = useState(false);
  const [selectedUrl, setSelectedUrl] = useState(""); // State to keep track of the selected user's URL
  const darkMode = useSelector(selectDarkMode);

  // Debounce searchLinkedIn function
  const debouncedSearchLinkedIn = useCallback(
    debounce((value) => {
      if (value && !isUsername) {
        searchLinkedIn(value);
      }
    }, 1000),
    [isUsername] // Recreate this function only when isUsername changes
  );

  const searchLinkedIn = async (value) => {
    try {
      const response = await PublicService.linkedinPeopleSearch(value, 25);
      setResults(
        response.data.response.map((user) => ({
          value: user.fullName,
          url: user.navigationUrl,
          label: (
            <div>
              {user.fullName} - {user.primarySubtitle}
            </div>
          ),
        }))
      );
    } catch (error) {
      console.error("Error fetching LinkedIn data:", error);
    }
  };

  const handleSearch = (value) => {
    setSearchValue(value);
    debouncedSearchLinkedIn(value);
  };

  const handleSelect = (value, option) => {
    // Set both searchValue and selectedUrl when a user is selected
    setSearchValue(value);
    setSelectedUrl(option.url?.split?.("?miniProfile")?.[0]); // option.url should correspond to the user's navigationUrl
  };

  const handleContinue = () => {
    setLocked((l) => !l);
  };

  const currentUrl =
    (isUsername ? `https://www.linkedin.com/in/${searchValue}` : selectedUrl) ??
    value;

  useEffect(() => {
    const currentUrl = isUsername
      ? `https://www.linkedin.com/in/${searchValue}`
      : selectedUrl;

    if (currentUrl) onChange("linkedInUrl", currentUrl);
  }, [isUsername, searchValue, selectedUrl]);

  const toggleInputType = () => {
    setResults([]); // Clear results when toggling
    setIsUsername(!isUsername);
  };

  if (locked)
    return (
      <div className="form-item">
        <div className="flex justify-between">
          <div className="flex justify-between items-center w-full">
            <label className="text-lg font-semibold">Linkedin (optional)</label>

            <div className="ant-space css-dev-only-do-not-override-gujwkx ant-space-horizontal ant-space-align-center ant-space-gap-row-small ant-space-gap-col-small" />
          </div>
        </div>
        <div />
        <div className=" react-tel-input ">
          <div className="flex gap-3">
            <Space
              className="cursor-pointer"
              onClick={() => {
                window.open(currentUrl, "_blank");
              }}
            >
              <FaLinkedin />/
              {currentUrl?.replace?.("https://www.linkedin.com/in/", "") ?? ""}
            </Space>
            <button
              className="px-2 py-2 text-sm bg-indigo-500 text-white rounded "
              onClick={handleContinue}
              type="primary"
            >
              Edit
            </button>
          </div>
        </div>
      </div>
    );
  return (
    <div className="form-item">
      <div className="flex justify-between">
        <div className="flex justify-between items-center w-full">
          <label className="text-lg font-semibold">Linkedin (optional)</label>
          <div className="ant-space css-dev-only-do-not-override-gujwkx ant-space-horizontal ant-space-align-center ant-space-gap-row-small ant-space-gap-col-small" />
        </div>
      </div>
      <div />
      <div className=" react-tel-input ">
        <div className="flex">
          <AutoComplete
            style={{ flex: 1 }}
            onSearch={handleSearch}
            onSelect={handleSelect}
            options={isUsername ? [] : results}
            value={searchValue}
            placeholder="Enter keywords or username"
          >
            <Input
              className={darkMode ? "darkModeCorrector" : ""}
              suffix={
                <Button type="link" onClick={toggleInputType}>
                  {isUsername ? "Search profiles" : "Or type username"}
                </Button>
              }
              onPressEnter={handleContinue}
            />
          </AutoComplete>
          <button
            className="px-2 py-2 text-sm bg-indigo-500 text-white rounded "
            onClick={handleContinue}
            type="primary"
          >
            Confirm
          </button>
        </div>
      </div>
    </div>
  );
};

export default LinkedinSearch;
