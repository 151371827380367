import React, { useState } from "react";
import { Button } from "./components/Button";
import { Heading } from "./components/Heading";
import { Img } from "./components/Img";

const InviteTeam = ({
  handleSubmit,
  handleSubmitTeam,
  handleAddMember,
  members,
  setMembers,
  loading,
}) => {
  return (
    <div className="flex flex-col w-full items-center gap-[68px] px-14 md:px-5 sm:gap-[34px] mt-3">
      <div className="flex flex-col justify-center gap-3 px-5 md:px-5">
        <Img
          src="/images/members.svg"
          alt="Logo Icon"
          className="mx-auto h-[48px] w-[48px] rounded-[10px] "
        />
        <a href="#" className="md:text-[28px] sm:text-[26px]">
          <Heading
            size="headingxl"
            as="h3"
            className="text-[30px] text-center font-semibold text-[#0f1728]"
          >
            Invite your team
          </Heading>
        </a>
        <Heading
          as="h4"
          className="text-[16px] font-normal text-[#475467] text-center"
        >
          Start collaborating with your team.
        </Heading>
      </div>
      <form className="flex flex-col w-full md:w-[70%] gap-3 h-[80vh] lg:h-[75vh] justify-between">
        {/* <div className="flex flex-col w-[70%]"> */}
        <div>
          <div className="flex flex-col w-full items-start justify-center gap-1.5 my-3">
            <Heading
              size="texts"
              as="h3"
              className="text-[14px] font-medium text-[#344054]"
            >
              Email address
            </Heading>
            {members?.map((member) => (
              <input
                key={member.key}
                value={member.email}
                shape="round"
                type="text"
                placeholder={`you@untitledui.com`}
                className="w-full rounded-lg border border-solid border-[#cfd4dc] px-3.5"
                onChange={(e) => {
                  const updatedMembers = members.map((m) =>
                    m.key === member.key ? { ...m, email: e.target.value } : m
                  );
                  setMembers(updatedMembers);
                }}
              />
            ))}
          </div>
          <div className="flex items-center gap-2 self-stretch">
            <Button
              onClick={handleAddMember}
              className="bg-gradient-to-r from-[#0538ff] to-[#6b56f4] bg-clip-text text-[14px] font-semibold text-transparent"
            >
              + Add another
            </Button>
          </div>
        </div>

        <div className="w-full ">
          {/* <Button
                      className="border border-solid border-black md:min-w-[400px] rounded-lg  px-[33px] font-semibold  shadow-[0_1px_2px_0_#1018280c] sm:px-5 flex w-full my-5"
                      size="2xl"
                      shape="round"
                      variant={null}
                      onClick={handleSubmit}
                    >
                      {loading? "Processing, please wait...": "Invite Later"}
                    </Button> */}

          <Button
            className="continuebutton_border md:min-w-[400px] rounded-lg bg-gradient-to-r from-[#0538ff] to-[#6b56f4] px-[33px] font-semibold text-[#ffffff] shadow-[0_1px_2px_0_#1018280c] sm:px-5 flex w-full my-5"
            size="2xl"
            shape="round"
            variant={null}
            onClick={handleSubmitTeam}
          >
            {loading
              ? "Processing, please wait..."
              : members.filter((x) => !!x.email).length > 0
              ? "Invite and Register"
              : "Skip and Register"}
          </Button>
          <div className="flex justify-between w-full mb-4">
            <div className="h-[8px] w-[32%] rounded bg-[#eaecf0]" />
            <div className="h-[8px] w-[32%] rounded bg-[#eaecf0]" />
            <div className="h-[8px] w-[32%] rounded bg-gradient-to-r from-[#0538ff] to-[#6b56f4]" />
          </div>
        </div>
      </form>
    </div>
  );
};

export default InviteTeam;
