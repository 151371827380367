import { EnvelopeIcon } from "@heroicons/react/24/outline";
import { Checkbox, Input } from "antd";

export default function Support() {
  return (
    <div className="flex flex-col w-full ">
      <div className="relative flex items-center ">
        <div className="flex flex-col w-full  items-start pb-2">
          <h1 className="text-2xl font-bold">Support</h1>
          <p className="text-md font-inter text-[#475467]">
            Describe your request below.
          </p>
        </div>
      </div>
      <form
        className="bg-white dark:bg-gray-900 shadow-sm dark:shadow-gray-400/50  ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2"
        onSubmit={(e) => e.preventDefault()}
      >
        <div className="px-4 py-3 sm:p-8">
          <div className="grid  grid-cols-1 gap-x-6 gap-y-8 sm:grid-cols-6">
            {/* FirstName */}
            <div className="sm:col-span-3">
              <label
                htmlFor="first-name"
                className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-400 "
              >
                First name
              </label>
              <div className="mb-5">
                <input
                  type="text"
                  name="first-name"
                  id="first-name"
                  autoComplete="given-name"
                  placeholder="Taylor"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-gray-400  shadow-sm dark:shadow-gray-400/50  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-900 "
                />
              </div>
            </div>
            {/* LastName */}
            <div className="sm:col-span-3">
              <label
                htmlFor="last-name"
                className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-400 "
              >
                Last name
              </label>
              <div className="mb-5">
                <input
                  type="text"
                  name="last-name"
                  id="last-name"
                  placeholder="Smith"
                  autoComplete="family-name"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-gray-400  shadow-sm dark:shadow-gray-400/50  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-900 "
                />
              </div>
            </div>
          </div>
          {/* Email */}
          <div className="sm:col-span-3">
            <label
              htmlFor="email"
              className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-400 "
            >
              Email
            </label>
            <div className="relative mb-5">
              <div className="absolute inset-y-0 start-0 z-[3] flex items-center ps-3.5 pointer-events-none">
                <EnvelopeIcon width={20} height={20} color="gray" />
              </div>
              <input
                type="text"
                name="email"
                id="email"
                autoComplete="email"
                placeholder="taylorsmith@mail.com"
                className="block w-full rounded-md border-0 ps-10 p-1.5 text-gray-900 dark:text-gray-400  shadow-sm dark:shadow-gray-400/50  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-900 "
              />
            </div>
          </div>
          {/* Phone */}
          <div className="sm:col-span-3">
            <label
              htmlFor="phone"
              className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-400 "
            >
              Phone number
            </label>
            <div className="mb-5">
              <input
                type="text"
                name="phone"
                id="phone"
                autoComplete="phone"
                placeholder="+1 (555) 000-0000"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-gray-400  shadow-sm dark:shadow-gray-400/50  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-900 "
              />
            </div>
          </div>
          {/* Message */}
          <div className="sm:col-span-3">
            <label
              htmlFor="message"
              className="block text-sm font-medium leading-6 text-gray-900 dark:text-gray-400 "
            >
              Message
            </label>
            <div className="mb-2">
              <textarea
                name="message"
                id="message"
                autoComplete="message"
                rows="6"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-gray-400  shadow-sm dark:shadow-gray-400/50  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-900 "
              />
            </div>
          </div>
          <Checkbox>
            You agree to our friendly{" "}
            <a href="/privacy" target="_blank" className="underline">
              privacy policy
            </a>
          </Checkbox>
        </div>
      </form>
      <button
        className="py-2 my-4 text-sm bg-gradient text-white rounded-lg w-full"
        type="primary"
      >
        Send message
      </button>
      <button
        onClick={() => {
          document.dispatchEvent(new CustomEvent("BOOK.MEETING"));
        }}
        className="py-2 mt-1 text-sm bg-transparent text-black rounded-lg w-full border-1 border border-gray-300"
      >
        Book a call instead
      </button>
    </div>
  );
}
