import React from "react";
import PropTypes from "prop-types";

const shapes = {
  round: "rounded-lg",
};
const variants = {
  fill: {
    hireheroes_white: "bg-[#ffffff] text-[#344054]",
    hireheroes_background: "bg-[#f8f8f8]",
  },
  outline: {
    hireheroes_main_gradient_blue_deep_purple_A200:
      "border border-solid hireheroes_main_gradient_blue_deep_purple_A200_border bg-gradient-to-r from-[#0538ff] to-[#6b56f4] shadow-[0_1px_2px_0_#1018280c] text-[#ffffff]",
  },
  gradient: {
    hireheroes_main_gradient_blue_deep_purple_A200: "bg-gradient-to-r from-[#0538ff] to-[#6b56f4] text-[#ffffff]",
  },
};
const sizes = {
  "3xl": "h-[48px] px-3",
  xl: "h-[44px] px-2.5",
  md: "h-[34px] px-2.5 text-[12px]",
  lg: "h-[40px] px-[34px] text-[14px]",
  "2xl": "h-[44px] px-[34px] text-[16px]",
  sm: "h-[28px] px-1.5 text-[14px]",
  xs: "h-[22px] px-1 text-[12px]",
};

const Button = ({
  children,
  className = "",
  leftIcon,
  rightIcon,
  shape,
  variant = "fill",
  size = "xs",
  color = "hireheroes_white",
  ...restProps
}) => {
  return (
    <button
      className={`${className} flex flex-row items-center justify-center text-center cursor-pointer whitespace-nowrap ${shape && shapes[shape]} ${size && sizes[size]} ${variant && variants[variant]?.[color]}`}
      {...restProps}
    >
      {!!leftIcon && leftIcon}
      {children}
      {!!rightIcon && rightIcon}
    </button>
  );
};

Button.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  leftIcon: PropTypes.node,
  rightIcon: PropTypes.node,
  shape: PropTypes.oneOf(["round"]),
  size: PropTypes.oneOf(["3xl", "xl", "md", "lg", "2xl", "sm", "xs"]),
  variant: PropTypes.oneOf(["fill", "outline", "gradient"]),
  color: PropTypes.oneOf([
    "hireheroes_white",
    "hireheroes_background",
    "hireheroes_main_gradient_blue_deep_purple_A200",
  ]),
};

export { Button };
