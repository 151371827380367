import { Heading} from "./Heading";
import {Img } from "./Img";
import React from "react";

export default function UserDetail({
  userDetailsHeading = "Your details",
  userDetailsDescription = "Please provide your personal details",
  ...props
}) {
  return (
    <div {...props} className={`${props.className} flex items-start gap-4 h-full`}>
      <Img src="/images/img_check_circle.png" alt="Profile Image" className="h-[24px] w-[24px] rounded-[50%]" />
      <div className="flex flex-1 flex-col items-start justify-center gap-0.5 self-center">
        <Heading size="headings" as="p" className="text-[14px] font-semibold text-[#344054]">
          {userDetailsHeading}
        </Heading>
        <Heading size="textxs" as="p" className="text-[12px] font-normal text-[#475467]">
          {userDetailsDescription}
        </Heading>
      </div>
    </div>
  );
}
