import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { login } from "../../redux/auth/actions";
import { selectUser } from "../../redux/auth/selectors";
import { store } from "../../redux/store";
import ATSService from "../../service/ATSService";
import AuthService from "../../service/AuthService";
import CrudService from "../../service/CrudService";
import UserDashboard from "./UserDashboard";

const OnboardUser = () => {
  const user = useSelector(selectUser);
  const [campaigns, setCampaigns] = useState([]);
  const [heroes, setHeroes] = useState([]);
  const [initialLoadDone, setInitialLoadDone] = useState(false);
  const [knowledgeInfo, setknowledgeInfo] = useState(false);
  const navigate = useNavigate();

  const userOnboarded =
    campaigns.length > 0 && heroes.length > 0 && knowledgeInfo;

  useEffect(() => {
    if (userOnboarded && !user.onboarded) {
      AuthService.setOnboarded().then(async () => {
        const me = await AuthService.me();
        store.dispatch(login(me.data.me));
        document.dispatchEvent(new CustomEvent("BOOK.MEETING"));
      });
    }
  }, [userOnboarded, user]);

  useEffect(() => {
    if (!user) return;
    // console.log("user", user)
    // Ejecutar ambas llamadas a la API en paralelo
    Promise.all([
      CrudService.search("Hero", 4, 1, { filters: { user_id: user._id } }),
      CrudService.search("Vacancy", 2, 1, {
        filters: { user_id: user._id },
      }),
      CrudService.search("File", 1, 1, {
        filters: { user_id: user._id },
      }),
    ])
      .then(([heroesRes, vacanciesRes, fileRes]) => {
        // console.log(vacanciesRes)
        setHeroes(heroesRes.data.items);
        setknowledgeInfo(fileRes.data.items.length > 0);

        return ATSService.countApplicants(vacanciesRes.data.items);
      })
      .then((newVacancies) => {
        setCampaigns(newVacancies.data);
        setInitialLoadDone(true);
      })
      .catch((err) => {
        console.error("Error fetching data:", err);
      });
  }, [user]);

  // if (true) return(<UserDashboard/>)
  if (!initialLoadDone) return <Skeleton active />;
  if (campaigns?.length > 0 && heroes?.length > 0 && knowledgeInfo)
    return <UserDashboard user={user} campaigns={campaigns} heroes={heroes} />;
  return (
    <div className="flex flex-col w-full ">
      {/* welcome section */}
      <div className="flex flex-col w-full  items-start mb-3">
        <h1 className="text-3xl font-semibold mb-1">
          Hi&nbsp;{user?.firstName}&nbsp;👋
        </h1>
        <p className="text-base">Welcome to HireHeroes!</p>
      </div>
      <div className="flex  min-h-[60vh] flex-col items-center justify-center bg-white-A700  md:p-5 ">
        <img alt="user onboarding" src="/img_onboard_dashboard.png" />
        {/* campaign setup section */}
        <div className="flex flex-col justify-end">
          <p className="text-lg mt-[37px] font-semibold">
            Let's get your first campaigns set up!
          </p>
          {/* company info section */}
          <div className="mt-10 flex flex-col justify-center gap-2.5">
            <div
              className="flex justify-between items-start gap-5 cursor-pointer"
              onClick={async () => {
                const hero = await CrudService.create("Hero", {
                  user_id: user._id,
                });
                if (hero?.data?.result?._id)
                  navigate(`/dashboard/heroedit?id=${hero?.data?.result?._id}`);
              }}
            >
              <h2
                className={`text-base font-normal ${
                  heroes.length > 0 ? "line-through" : ""
                }`}
              >
                Create your first hero
              </h2>
              {heroes.length > 0 ? (
                <CheckCircleIcon className=" h-[20px] w-[20px] text-indigo-500" />
              ) : (
                <CheckCircleIcon className=" h-[20px] w-[20px] text-gray-400" />
              )}
            </div>
            <div
              className="flex justify-between items-start gap-5 cursor-pointer"
              onClick={async () => {
                const vacancy = await ATSService.createVacancy({
                  vacancyTemplate: {
                    name: "",
                    description: "",
                    image: "",
                    keyBenefits: "",
                    requiredSkills: "",
                    shortCode: "",
                    published: false,
                  },
                });
                navigate(
                  `/dashboard/vacancyprepublish?id=${vacancy.data.vacancy._id}`
                );
              }}
            >
              <h2
                className={`text-base font-normal ${
                  campaigns.length > 0 ? "line-through" : ""
                }`}
              >
                Create your first campaign
              </h2>
              {campaigns.length > 0 ? (
                <CheckCircleIcon className=" h-[20px] w-[20px] text-indigo-500" />
              ) : (
                <CheckCircleIcon className=" h-[20px] w-[20px] text-gray-400" />
              )}
            </div>
            <Link
              to="/dashboard/knowledge"
              className="flex justify-between items-start gap-5"
            >
              <h2
                className={`text-base font-normal ${
                  knowledgeInfo ? "line-through" : ""
                }`}
              >
                Upload documents to customize your heroes
              </h2>
              {knowledgeInfo ? (
                <CheckCircleIcon className=" h-[20px] w-[20px] text-indigo-500" />
              ) : (
                <CheckCircleIcon className=" h-[20px] w-[20px] text-gray-400" />
              )}
            </Link>
          </div>
        </div>

        {!user?.onboardingForm && (
          <div className="flex w-full justify-center items-center mt-[24px] gap-2">
            <div className="text-sm font-semibold py-2.5 px-1">Agency? </div>
            <div>
              <Link
                to="/dashboard/agency-onboarding"
                className="bg-gradient text-white dark:bg-gray-600 dark:text-gray-400 rounded-md py-2.5 px-4 text-sm leading-6 font-semibold"
              >
                Click here
              </Link>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default OnboardUser;
