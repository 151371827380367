import { Button, Divider, Modal, Tooltip } from "antd";
import React from "react";
import { GrInfo } from "react-icons/gr";
import { useSelector } from "react-redux";
import { selectDarkMode, selectLoading } from "../../redux/auth/selectors";
import AuthService from "../../service/AuthService";
import SMTPService from "../../service/SMTPService";
import ShowPassword from "./PartnerSettings/ShowPassword";

const SMTPModal = ({
  SMTPModal,
  setSMTPModal,
  handleUpdate = () => {},
  setMe = () => {},
  setOnboardingStatus = () => {},
  smtp,
  setSmtp,
  onFinish = () => {},
}) => {
  const darkMode = useSelector(selectDarkMode);
  const loading = useSelector(selectLoading);

  return (
    <>
      <Modal
        wrapClassName={`${darkMode ? "dark" : ""}`}
        open={!!SMTPModal}
        onCancel={() => setSMTPModal(false)}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        destroyOnClose
      >
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            await handleUpdate();
            const result = await SMTPService.updateSMTP({ smtp });

            if (!result.data?.smtp?.connectionStatus)
              return message.error("Connection was not successful");
            await AuthService.me().then((data) => {
              setMe(data.data.me);
              setOnboardingStatus(data.data.onboardingStatus);
              setSMTPModal(false);
            });
            onFinish();
          }}
        >
          <div className="mt-10 mb-2 flex items-center justify-between gap-3">
            <label>Host</label>
            <input
              type="text"
              required
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-gray-400  shadow-sm dark:shadow-gray-400/50  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-900 "
              value={smtp?.host}
              onChange={(e) => setSmtp((x) => ({ ...x, host: e.target.value }))}
            />
          </div>
          <div className="my-2 flex items-center justify-between gap-3">
            <label>Port</label>
            <input
              type="number"
              required
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-gray-400  shadow-sm dark:shadow-gray-400/50  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-900 "
              value={smtp?.port}
              onChange={(e) => setSmtp((x) => ({ ...x, port: e.target.value }))}
            />
          </div>
          <div className="my-2 flex items-center justify-between gap-3">
            <label>Email</label>
            <input
              type="email"
              required
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-gray-400  shadow-sm dark:shadow-gray-400/50  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-900 "
              value={smtp?.email}
              onChange={(e) =>
                setSmtp((x) => ({ ...x, email: e.target.value }))
              }
            />
          </div>
          <div className="my-2 flex items-center justify-between gap-3">
            <label>Password</label>
            <ShowPassword
              required
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-gray-400  shadow-sm dark:shadow-gray-400/50  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-900 "
              value={smtp?.password}
              onChange={(e) =>
                setSmtp((x) => ({ ...x, password: e.target.value }))
              }
            />
          </div>

          <Divider />

          <div className="flex items-center justify-start gap-3">
            <h2 className="text-lg font-semibold">DKIM Settings</h2>
            <a
              href="https://easydmarc.com/tools/dkim-record-generator"
              target="_blank"
            >
              <Tooltip title="DomainKeys Identified Mail (DKIM) is an email authentication method that helps protect email senders and recipients from spam, spoofing, and phishing. It allows an email receiver to check that an email claimed to have come from a specific domain was indeed authorized by the owner of that domain. Using a DKIM generator, such as the one available at EasyDMARC's DKIM Record Generator (click on the lightbulb icon to navigate there), you can easily create these keys. Fill in the 'Domain' and 'Key Selector' fields with the appropriate information for your domain, then generate and use the provided private key here. Your public key will be part of the DKIM record you add to your domain's DNS settings.">
                <GrInfo />
              </Tooltip>
            </a>
          </div>

          <div className="my-2 flex items-center justify-between gap-3">
            <label>Domain</label>
            <input
              type="text"
              placeholder="your-domain.com"
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-gray-400  shadow-sm dark:shadow-gray-400/50  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-900 "
              value={smtp?.dkimDomain}
              onChange={(e) =>
                setSmtp((x) => ({ ...x, dkimDomain: e.target.value }))
              }
            />
          </div>
          <div className="my-2 flex items-center justify-between gap-3">
            <label>Key Selector</label>
            <input
              type="text"
              placeholder="s2"
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-gray-400  shadow-sm dark:shadow-gray-400/50  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-900 "
              value={smtp?.dkimKeySelector}
              onChange={(e) =>
                setSmtp((x) => ({ ...x, dkimKeySelector: e.target.value }))
              }
            />
          </div>
          <div className="my-2 flex items-center justify-between gap-3">
            <label>Private Key</label>
            <textarea
              rows={4}
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-gray-400  shadow-sm dark:shadow-gray-400/50  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-900 "
              value={smtp?.dkimPrivateKey}
              onChange={(e) =>
                setSmtp((x) => ({ ...x, dkimPrivateKey: e.target.value }))
              }
            />
          </div>

          <div className="flex justify-between">
            <div />
            <Button
              className="px-2 py-1 text-sm bg-indigo-500 text-white rounded"
              htmlType="submit"
              disabled={loading}
              loading={loading}
            >
              Connect
            </Button>
          </div>
        </form>
      </Modal>
    </>
  );
};

export default SMTPModal;
