import React from "react";
import ImportModule from "./ImportModule";
import UserSearch from "./UserSearch";

const LeadAutomations = () => {
  return (
    <>
      <ImportModule />
      <UserSearch />
    </>
  );
};

export default LeadAutomations;
