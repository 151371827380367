import { Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import CrudService from "../../../service/CrudService";
import StatsDashboard from "../StatsDashboard";

const VacancyStats = () => {
  let [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [vacancyId, setVacancyId] = useState(null);
  const [vacancyData, setVacancyData] = useState(null);
  const [back, setBack] = useState("");

  useEffect(() => {
    const id = searchParams.get("id");
    if (!id) return;
    setVacancyId(id);

    CrudService.getSingle("Vacancy", id).then(({ data }) => {
      setVacancyData(data);
    });
  }, [searchParams]);

  useEffect(() => {
    const back = searchParams.get("back");
    if (!back) return;
    setBack(back);
  }, [searchParams]);

  if (!vacancyId) return <Skeleton active />;
  if (!vacancyData) return <Skeleton active />;
  return (
    <>
      <StatsDashboard
        funnelId={vacancyId}
        vacancyData={vacancyData}
        back={back}
      />
    </>
  );
};

export default VacancyStats;
