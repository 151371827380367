import { useSelector } from "react-redux";
import { getPartner } from "../../redux/auth/selectors";
import backgroundImage from "./images/background-auth.jpg";
import { Heading } from "../auth/components/Heading";
import { Img } from "../auth/components/Img";

export function SlimLayout({ children }) {
  const partner = useSelector(getPartner);
  return (
    <>
      <div className="relative flex h-[100vh] justify-center md:px-12 lg:px-0 md:overflow-hidden">
      {partner?.authImage?
        <div className="hidden sm:contents lg:relative lg:block lg:flex-1">
          <img
            className="absolute inset-0 h-full w-full object-cover"
            src={partner?.authImage || backgroundImage}
            alt=""
          />
        </div>:
        <div className="  w-[50vw] flex  flex-col items-start hidden md:block h-[100vh]">
        <div className=" flex  flex-col items-center justify-between gap-10  border border-solid border-[#eaecf0] bg-[#ffffff] md:w-full h-full ">
          <div className="ml-[60px] mr-[74px] mt-36 flex flex-col gap-3 self-stretch md:mx-0">
            <div className="flex">
              <Heading
                size="headingxl"
                as="h1"
                className="mx-8 text-3xl font-semibold tracking-[-0.60px] text-[#0f1728] md:text-[28px] sm:text-[26px]"
              >
                HireHeroes
              </Heading>
            </div>
            <Heading as="h2" className=" mx-8 text-base font-normal leading-[22px] text-[#475467] ">
              Conversational voice driven ai recruitment assistants. Interviews applicants through live interview
              calls, takes care of all admin tasks, have the AI evaluate and shortlist the top pools.
            </Heading>
          </div>
          <div className="  w-[90%] content-center self-end rounded-[10px] md:h-auto">
            <Img
              src="/images/ScreenMockup.png"
              alt="Shadow Image"
              className=" w-full  flex-1 object-cover"
            />
          </div>
        </div>
      </div>}
        <div className="relative z-10 h-full flex flex-col bg-white dark:bg-gray-900 px-4 py-10 shadow-2xl sm:justify-center md:px-28">
          <main className="mx-auto w-full max-w-md sm:px-4 md:w-96 md:max-w-sm md:px-0">
            {children}
          </main>
        </div>
      </div>
    </>
  );
}
