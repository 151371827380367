import { Alert, Skeleton, Space, Spin, message } from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { selectLoading } from "../../redux/auth/selectors";
import PublicService from "../../service/PublicService";
import { Footer } from "../Landing/Footer";

const Finished = ({ reset }) => {
  let [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [vacancyData, setVacancyData] = useState(null);
  const [sentCV, setSentCV] = useState(false);
  const loading = useSelector(selectLoading);

  useEffect(() => {
    const id = searchParams.get("id");
    if (!id) return;
    setVacancyData(null);

    PublicService.getVacancyData(id).then((res) => {
      if (!res.data) return;
      setVacancyData(res.data);
    });
  }, [searchParams]);

  const handleCompleteCV = useCallback(async () => {
    const id = searchParams.get("id");
    if (!id) return;

    const response = await PublicService.generateCandidateToken(
      localStorage?.[`funnelUUID_${id}`]
    );
    if (!response.data?.candidateToken)
      return message.error(
        "The CV page for your application is currently not available"
      );
    setSentCV(true);
    navigate(`/cv?token=${response.data?.candidateToken}`);
  }, [searchParams]);
  const handleRemindLater = useCallback(async () => {
    const id = searchParams.get("id");
    if (!id) return;

    await PublicService.remindCV(localStorage?.[`funnelUUID_${id}`]);
    setSentCV(true);
  }, [searchParams]);

  if (!vacancyData) return <Skeleton active />;
  if (!vacancyData.enabled)
    return (
      <div className="p-5">
        <Alert
          type="info"
          message="The vacancy you are looking for is no longer available."
        />
      </div>
    );

  return (
    <>
      <div className="content">
        <div
          className="w-full border-box pt-4 pl-8 pr-8 pb-4"
          style={{
            background: "center center / cover no-repeat transparent",
          }}
        >
          <div className="mx-auto max-w-md sm:max-w-xl w-full md:max-w-3xl lg:max-w-4xl">
            <div className="transition-wrapper" style={{}}>
              <div className="wrapper break-words text-left flex items-center flex-col responsive">
                <h2 className="font-bold text-3xl">
                  {vacancyData.thankYouHero}
                </h2>
              </div>
            </div>
          </div>
        </div>

        <div
          className="w-full border-box pt-4 pl-6 pr-6 pb-2"
          style={{
            background: "center center / cover no-repeat transparent",
          }}
        >
          <div className="mx-auto max-w-md sm:max-w-xl w-full md:max-w-3xl lg:max-w-4xl">
            <div className="transition-wrapper" style={{}}>
              <div
                className="w-full border-box pb-6"
                style={{
                  background: "center center / cover no-repeat transparent",
                }}
              >
                <div className="mx-auto max-w-md sm:max-w-xl w-full md:max-w-3xl lg:max-w-4xl">
                  <div className="transition-wrapper" style={{}}>
                    <div className="wrapper break-words text-left flex items-center flex-col responsive">
                      <h4>
                        Congratulations on taking the first step towards
                        becoming a {vacancyData.name}!
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="w-full border-box pt-2 pl-8 pr-8 pb-6"
          style={{
            background: "center center / cover no-repeat transparent",
          }}
        >
          <div className="mx-auto max-w-md sm:max-w-xl w-full md:max-w-3xl lg:max-w-4xl">
            <div className="transition-wrapper" style={{}}>
              <div className="wrapper break-words text-left flex items-center flex-col responsive">
                <h4>
                  <div>✅ Application Status: Received </div>
                  <div>🌐 Work Mode: {vacancyData.location}</div>
                  <div>💼 Engagement: {vacancyData.engagementType}</div>
                  <div>📄 Contract: {vacancyData.contractType}</div>
                </h4>
              </div>
            </div>
          </div>
        </div>

        <div
          className="w-full border-box pt-4 pl-6 pr-6 pb-2"
          style={{
            background: "center center / cover no-repeat transparent",
          }}
        >
          <div className="mx-auto max-w-md sm:max-w-xl w-full md:max-w-3xl lg:max-w-4xl">
            <div className="transition-wrapper" style={{}}>
              <div
                className="w-full border-box pb-6"
                style={{
                  background: "center center / cover no-repeat transparent",
                }}
              >
                <div className="mx-auto max-w-md sm:max-w-xl w-full md:max-w-3xl lg:max-w-4xl">
                  <div className="transition-wrapper" style={{}}>
                    <div className="wrapper break-words text-left flex items-center flex-col responsive">
                      <h4>{vacancyData.underReviewInfo}</h4>
                    </div>
                    <div className="mt-2 wrapper break-words text-left flex items-center flex-col responsive">
                      <h4>{vacancyData.nextSteps}</h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mx-auto max-w-md sm:max-w-xl w-full">
          <div className="transition-wrapper" style={{}}>
            {vacancyData?.askForCV && !sentCV ? (
              <Space direction="vertical" className="w-full">
                <button
                  onClick={handleCompleteCV}
                  className="py-4 px-6 rounded-md mx-auto max-w-md block cursor-pointer border-none w-full box-border button transition text-center transform active:scale-90 "
                  type="button"
                  disabled={loading}
                >
                  <div
                    className="w-full border-box "
                    style={{
                      background: "center center / cover no-repeat transparent",
                    }}
                  >
                    <div className="mx-auto max-w-md sm:max-w-xl w-full md:max-w-3xl lg:max-w-4xl">
                      <div className="transition-wrapper" style={{}}>
                        <div className="wrapper break-words text-center p-4 bg-indigo-500 rounded text-white font-bold">
                          {loading ? (
                            <Spin>
                              <h3>Complete your CV now</h3>
                            </Spin>
                          ) : (
                            <h3>Complete your CV now</h3>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </button>

                <button
                  onClick={handleRemindLater}
                  className="py-4 px-6 rounded-md mx-auto max-w-md block cursor-pointer border-none w-full box-border button transition text-center transform active:scale-90 "
                  type="button"
                  disabled={loading}
                >
                  <div
                    className="w-full border-box "
                    style={{
                      background: "center center / cover no-repeat transparent",
                    }}
                  >
                    <div className="mx-auto max-w-md sm:max-w-xl w-full md:max-w-3xl lg:max-w-4xl">
                      <div className="transition-wrapper" style={{}}>
                        <div className="wrapper break-words text-center p-4 bg-transparent border border-indigo-500 rounded text-indigo-500 font-bold">
                          {loading ? (
                            <Spin>
                              <h3>Remind me later about the CV</h3>
                            </Spin>
                          ) : (
                            <h3>Remind me later about the CV</h3>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </button>
              </Space>
            ) : (
              <button
                onClick={reset}
                className="py-4 px-6 rounded-md mx-auto max-w-md block cursor-pointer border-none w-full box-border button transition text-center transform active:scale-90 "
                type="button"
              >
                <div
                  className="w-full border-box "
                  style={{
                    background: "center center / cover no-repeat transparent",
                  }}
                >
                  <div className="mx-auto max-w-md sm:max-w-xl w-full md:max-w-3xl lg:max-w-4xl">
                    <div className="transition-wrapper" style={{}}>
                      <div className="wrapper break-words text-center p-4 bg-indigo-500 rounded text-white font-bold">
                        <h3>Back to job description</h3>
                      </div>
                    </div>
                  </div>
                </div>
              </button>
            )}
          </div>
        </div>

        <div
          className="w-full border-box pt-2 pl-8 pr-8 pb-8"
          style={{
            background: "center center / cover no-repeat transparent",
          }}
        >
          <div className="mx-auto max-w-md sm:max-w-xl w-full md:max-w-3xl lg:max-w-4xl">
            <div className="transition-wrapper" style={{}}>
              <div className="divider divider h-0 leading-none text-black dark:text-gray-400 /85 tracking-wider text-base font-bold border-t uppercase select-none my-5 md:py-6 !border-transparent ">
                &nbsp;
              </div>
            </div>
          </div>
        </div>
        <div
          className="w-full border-box pt-2 pl-8 pr-8 pb-2"
          style={{
            background: "center center / cover no-repeat rgb(245, 245, 245)",
          }}
        >
          <div className="mx-auto max-w-md sm:max-w-xl w-full md:max-w-3xl lg:max-w-4xl">
            <div className="transition-wrapper" style={{}}>
              <div className="divider divider h-0 leading-none text-black dark:text-gray-400 /85 tracking-wider text-base font-bold border-t uppercase select-none my-5 md:py-6 !border-transparent ">
                &nbsp;
              </div>
            </div>
          </div>
        </div>
        <div
          className="w-full border-box pt-8 pl-8 pr-8 pb-6"
          style={{
            background: "center center / cover no-repeat rgb(245, 245, 245)",
          }}
        >
          <div className="mx-auto max-w-md sm:max-w-xl w-full md:max-w-3xl lg:max-w-4xl">
            <div className="transition-wrapper" style={{}}>
              <div className="wrapper break-words text-center responsive">
                <h2>
                  <strong>{vacancyData.testimonialTitle}</strong>
                </h2>
              </div>
            </div>
          </div>
        </div>
        <div
          className="w-full border-box pt-6 pl-6 pr-6 pb-6"
          style={{
            background: "center center / cover no-repeat rgb(245, 245, 245)",
          }}
        >
          <div className="mx-auto max-w-md sm:max-w-xl w-full md:max-w-3xl lg:max-w-4xl">
            <div className="transition-wrapper" style={{}}>
              <div className="grid text-left gap-6 grid-cols-1 md:grid-cols-2">
                {vacancyData.teamTestimonials.map((testimonial) => (
                  <div
                    key={testimonial._id}
                    className="flex box-border w-full align-top items-center flex-col"
                  >
                    <div
                      className="flex-shrink-0 flex items-center justify-center "
                      style={{
                        transform: "translateZ(0)",
                        width: "48px",
                        height: "48px",
                      }}
                    >
                      <div
                        className="w-full h-full bg-no-repeat"
                        style={{ background: "transparent" }}
                      >
                        <div className="mx-auto max-w-md sm:max-w-xl w-full md:max-w-3xl lg:max-w-5xl">
                          <div className="transition-wrapper" style={{}}>
                            <div className="p-0">
                              <div className="imageWrapper overflow-hidden leading-[0] rounded">
                                <div
                                  className="wrapper   "
                                  style={{ width: "100%" }}
                                >
                                  <div
                                    className=" loaded"
                                    style={{
                                      position: "relative",
                                      width: "100%",
                                    }}
                                  >
                                    <div
                                      style={{
                                        position: "relative",
                                        overflow: "hidden",
                                      }}
                                    >
                                      <div
                                        style={{
                                          width: "100%",
                                          paddingBottom: "100%",
                                        }}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="text flex items-center flex-grow break-words w-full mt-3 "
                      style={{ minHeight: "0px", fontSize: "16px" }}
                    >
                      <div
                        className="w-full border-box "
                        style={{
                          background:
                            "center center / cover no-repeat transparent",
                        }}
                      >
                        <div className="mx-auto max-w-md sm:max-w-xl w-full md:max-w-3xl lg:max-w-4xl">
                          <div className="transition-wrapper" style={{}}>
                            <div className="wrapper break-words text-center inheritSize responsive">
                              <h4>
                                <strong>
                                  {testimonial.author},{" "}
                                  {testimonial.authorPosition}
                                </strong>
                              </h4>
                              <h4>{testimonial.testimonial}</h4>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        <div
          className="w-full border-box pt-2 pl-8 pr-8 pb-2"
          style={{
            background: "center center / cover no-repeat rgb(245, 245, 245)",
          }}
        >
          <div className="mx-auto max-w-md sm:max-w-xl w-full md:max-w-3xl lg:max-w-4xl">
            <div className="transition-wrapper" style={{}}>
              <div className="divider divider h-0 leading-none text-black dark:text-gray-400 /85 tracking-wider text-base font-bold border-t uppercase select-none my-5 md:py-6 !border-transparent ">
                &nbsp;
              </div>
            </div>
          </div>
        </div>
        <div
          className="w-full border-box pt-2 pl-8 pr-8 pb-2"
          style={{
            background: "center center / cover no-repeat transparent",
          }}
        >
          <div className="mx-auto max-w-md sm:max-w-xl w-full md:max-w-3xl lg:max-w-4xl">
            <div className="transition-wrapper" style={{}}>
              <div className="divider divider h-0 leading-none text-black dark:text-gray-400 /85 tracking-wider text-base font-bold border-t uppercase select-none my-5 md:py-6 !border-transparent ">
                &nbsp;
              </div>
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Finished;
