import React, { useState } from "react";
import ShowPassword from "../Dashboard/PartnerSettings/ShowPassword";
import { Button } from "./components/Button";
import { Heading } from "./components/Heading";
import { Img } from "./components/Img";

const Password = ({ password, setPassword, step, setStep }) => {
  const [confirmPwd, setConfirmPwd] = useState("");

  const handleContinue = (e) => {
    e.preventDefault();
    if (password === confirmPwd && password.length > 7) {
      setStep(step + 1);
    } else {
      alert(
        "Passwords is too short or passowrd do not match. Please try again"
      );
    }
  };

  return (
    <div className="flex flex-col w-full items-center gap-[68px] px-14 md:px-5 sm:gap-[34px] mt-3">
      <div className="flex flex-col justify-center gap-3 px-5 md:px-5">
        <Img
          src="/images/lock.svg"
          alt="Logo Icon"
          className="mx-auto h-[48px] w-[48px] rounded-[10px] "
        />
        <a href="#" className="md:text-[28px] sm:text-[26px]">
          <Heading
            size="headingxl"
            as="h3"
            className="text-[30px] text-center font-semibold text-[#0f1728]"
          >
            Password
          </Heading>
        </a>
        <Heading
          as="h4"
          className="text-[16px] font-normal text-[#475467] text-center"
        >
          Set your password here.
        </Heading>
      </div>
      <form
        onSubmit={handleContinue}
        className="flex flex-col w-full md:w-[70%]  gap-3 h-[80vh] lg:h-[75vh] justify-between"
      >
        {/* <div className="flex flex-col w-[70%]"> */}
        <div>
          <div className="flex flex-col w-full items-start justify-center gap-1.5 my-3">
            <Heading
              size="texts"
              as="h3"
              className="text-[14px] font-medium text-[#344054]"
            >
              Password*
            </Heading>
            <ShowPassword
              shape="round"
              name="Password"
              placeholder={`********`}
              required
              className="w-full rounded-lg border border-solid border-[#cfd4dc] px-3.5"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <p className="text-[#667085]">Must be at least 8 characters.</p>
          </div>
          <div className="flex flex-col w-full items-start justify-center gap-1.5 my-3">
            <Heading
              size="texts"
              as="h3"
              className="text-[14px] font-medium text-[#344054]"
            >
              Confirm Password*
            </Heading>
            <ShowPassword
              shape="round"
              name="Confirm password"
              required
              placeholder={`********`}
              className="w-full rounded-lg border border-solid border-[#cfd4dc] px-3.5"
              value={confirmPwd}
              onChange={(e) => setConfirmPwd(e.target.value)}
            />
          </div>
        </div>
        <div className="w-full ">
          <Button
            className="continuebutton_border md:min-w-[400px] rounded-lg bg-gradient-to-r from-[#0538ff] to-[#6b56f4] px-[33px] font-semibold text-[#ffffff] shadow-[0_1px_2px_0_#1018280c] sm:px-5 flex w-full my-5"
            size="2xl"
            shape="round"
            variant={null}
            onClick={handleContinue}
          >
            Continue
          </Button>
          <div className="flex justify-between w-full mb-4">
            <div className="h-[8px] w-[32%] rounded bg-[#eaecf0]" />
            <div className="h-[8px] w-[32%] rounded bg-gradient-to-r from-[#0538ff] to-[#6b56f4]" />
            <div className="h-[8px] w-[32%] rounded bg-[#eaecf0]" />
          </div>
        </div>
      </form>
    </div>
  );
};

export default Password;
