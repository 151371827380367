import { Alert, Divider, Skeleton } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import PublicService from "../../service/PublicService";

import MultiStepComponent from "../../components/MultiStepComponent";
import { Footer } from "../Landing/Footer";
import Finished from "./Finished";

const SurveyDetails = () => {
  let [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [surveyData, setSurveyData] = useState(null);
  const [finished, setFinished] = useState(false);
  const [loading, setLoading] = useState(true);
  const [started, setStarted] = useState(false);

  useEffect(() => {
    const token = searchParams.get("token");
    if (!token) return;
    setSurveyData(null);
    setLoading(true);

    PublicService.getSurvey(token, true)
      .then((res) => {
        setSurveyData(res.data.form);
      })
      .finally((e) => {
        setLoading(false);
      });
  }, [searchParams]);

  if (finished) return <Finished />;
  if (!surveyData && loading) return <Skeleton active />;
  if (!surveyData && !loading)
    return (
      <div className="m-4">
        <Alert type="error" message="Invalid survey link" />
      </div>
    );

  if (!started)
    return (
      <>
        <div
          className="w-full border-box pt-10 pl-8 pr-8 pb-4"
          style={{ background: "center center / cover no-repeat transparent" }}
        >
          <div className="mx-auto max-w-md sm:max-w-xl w-full md:max-w-3xl lg:max-w-4xl">
            <div className="transition-wrapper" style={{}}>
              <div className="wrapper break-words text-center responsive font-semibold text-lg">
                <h4>Welcome to our Hiring Process Evaluation!</h4>
              </div>
            </div>
          </div>
        </div>

        <div
          className="w-full border-box pt-4 pl-6 pr-6 pb-4"
          style={{ background: "center center / cover no-repeat transparent" }}
        >
          <div className="mx-auto max-w-md sm:max-w-xl w-full md:max-w-3xl lg:max-w-4xl">
            <div className="transition-wrapper" style={{}}>
              <div
                className="w-full border-box pb-6"
                style={{
                  background: "center center / cover no-repeat transparent",
                }}
              >
                <div className="mx-auto max-w-md sm:max-w-xl w-full md:max-w-3xl lg:max-w-4xl">
                  <div className="transition-wrapper" style={{}}>
                    <div className="wrapper break-words text-center responsive">
                      <h4>
                        Your feedback is crucial to us as we strive to
                        continually enhance our hiring procedures. We value your
                        input and appreciate your time spent providing us with
                        insights. Please assist us in evaluating how we have
                        performed during your hiring journey. Your feedback will
                        enable us to make improvements and ensure a better
                        experience for future candidates. Thank you for being an
                        essential part of our evaluation process.
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mx-auto max-w-md sm:max-w-xl w-full">
          <div className="transition-wrapper" style={{}}>
            <button
              onClick={() => setStarted(true)}
              className="py-4 px-6 rounded-md mx-auto max-w-md block cursor-pointer border-none w-full box-border button transition text-center transform active:scale-90 "
              type="button"
            >
              <div
                className="w-full border-box "
                style={{
                  background: "center center / cover no-repeat transparent",
                }}
              >
                <div className="mx-auto max-w-md sm:max-w-xl w-full md:max-w-3xl lg:max-w-4xl">
                  <div className="transition-wrapper" style={{}}>
                    <div className="wrapper break-words text-center p-4 bg-indigo-500 rounded text-white font-bold">
                      <h3>Start Survey</h3>
                    </div>
                  </div>
                </div>
              </div>
            </button>
          </div>
        </div>
      </>
    );

  return (
    <>
      <MultiStepComponent
        loading={loading}
        displaySteps={true}
        AIEnhancements={false}
        steps={[
          ...(Array.isArray(surveyData?.form)
            ? surveyData?.form
            : typeof surveyData?.form === "object"
            ? Object.values(surveyData?.form)
            : []),
        ]}
        onFinish={async (formData) => {
          const token = searchParams.get("token");
          if (!token) return;
          if (loading) return;

          const KPIs = [];

          const form = surveyData.form;
          for (const step of form) {
            for (const item of step.form) {
              if (item.kpi && typeof formData?.[item.fieldName] === "number")
                KPIs.push({
                  key: item.kpi,
                  submittedValue: formData[item.fieldName],
                });
            }
          }

          setLoading(true);
          PublicService.submitSurvey({
            token,
            formData,
            KPIs,
          })
            .then((res) => {
              if (!res.data) return;
              setFinished(true);
            })
            .finally(() => {
              setLoading(false);
            });
        }}
      />

      <Footer />
    </>
  );
};

export default SurveyDetails;
