import {
  Divider,
  Modal,
  Popconfirm,
  Select,
  Skeleton,
  Space,
  Tooltip,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import { GrInfo } from "react-icons/gr";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import MultiStepComponent from "../../components/MultiStepComponent";
import { countries } from "../../data/constants";
import { selectDarkMode, selectLoading } from "../../redux/auth/selectors";
import AuthService from "../../service/AuthService";
import CalendlyService from "../../service/CalendlyService";
import PartnerService from "../../service/PartnerService";
import ShowPassword from "../Dashboard/PartnerSettings/ShowPassword";
import { mailOptions } from "../Dashboard/Settings";
import FormMultiStep from "../Dashboard/vacancies/FormMultiStep";

function debounce(func, wait) {
  let timeout;

  return function executedFunction(...args) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
}

const UserOnboard = ({ hideWelcome = false }) => {
  const [me, setMe] = useState(null);
  const [smtp, setSmtp] = useState(null);
  const [SMTPModal, setSMTPModal] = useState(false);
  const [onboardingStatus, setOnboardingStatus] = useState(null);
  const [calendlyToken, setCalendlyToken] = useState(null);

  const navigate = useNavigate();
  const loading = useSelector(selectLoading);
  const darkMode = useSelector(selectDarkMode);

  useEffect(() => {
    AuthService.me().then((data) => setMe(data.data.me));
  }, []);
  useEffect(() => {
    AuthService.me().then((data) =>
      setOnboardingStatus(data.data.onboardingStatus)
    );

    CalendlyService.getCurrentToken()
      .then(({ data }) => {
        setCalendlyToken(data);
      })
      .catch(() => setCalendlyToken(null));
  }, []);

  const handleSave = (me) => {
    AuthService.updateMe({
      ...me,
      silent: true,
    });
  };

  const debouncedChange = debounce((value) => handleSave(value), 800);

  const getProps = (fieldKey) => ({
    value: me?.[fieldKey],
    onChange: (e) => {
      setMe((current) => {
        const value = {
          ...current,
          [fieldKey]: e?.target?.value ?? e,
        };

        // debouncedChange(value);

        return value;
      });
    },
  });

  const partnerRegistrationSteps = [
    {
      id: "step2",
      name: "Company Information",
      form: [
        {
          fieldName: "companyWebsite",
          label: "Company Website",
          type: "input",
          placeholder: "https://hireheroes.ai",
          validationRegex: /^http/,
          validationRegexTriggerMin: 6,
          validationWarningText: "URL should start with http:// or https://",
        },
      ],
    },
  ];

  if (!me) return <Skeleton active />;
  return (
    <>
      <div
        className={hideWelcome ? "" : "fixed left-0 top-0 w-[100vw] h-[100vh]"}
        style={
          hideWelcome
            ? {}
            : {
                backgroundSize: "cover",
                backgroundPosition: "center",
                zIndex: -1,
                backgroundImage: `url('/images/screenshots/Dashboard.png')`,
                filter: "blur(10px)",
              }
        }
      />
      <div
        className={
          hideWelcome
            ? ""
            : "relative flex min-h-screen flex-col justify-center overflow-hidden bg-transparent py-12"
        }
      >
        <div
          className={
            hideWelcome
              ? ""
              : "relative bg-white dark:bg-gray-900 px-6 pt-10 pb-9 shadow-xl dark:shadow-gray-400/50   mx-auto w-full max-w-lg rounded-2xl"
          }
        >
          <div
            className={
              hideWelcome
                ? ""
                : "mx-auto flex w-full max-w-md flex-col space-y-2"
            }
          >
            {!hideWelcome && (
              <div className="flex flex-col items-center justify-center text-center">
                <div className="font-semibold text-3xl">
                  <p>Let's get started</p>
                </div>
                <div className="flex flex-row text-md font-medium text-gray-400 mt-5">
                  <p>
                    Welcome to HireHeroes! Let's learn more about your company.
                    Can you add your company website?
                  </p>
                </div>
              </div>
            )}
            <div>
              <FormMultiStep
                loading={loading}
                displayUndoRedo={false}
                wrapperClassName=""
                displaySteps={false}
                steps={partnerRegistrationSteps}
                defaultFormData={me}
                finishText="Next"
                passFormData={(formData) => {
                  if (!formData) return;
                  if (!hideWelcome) return;
                  debouncedChange(formData);
                }}
                onFinish={async (formData) => {
                  await AuthService.updateMe({
                    ...formData,
                    silent: true,
                  });
                  navigate("/dashboard/home");
                }}
                formDataParent={{}}
                onNext={async (formData) => {
                  await AuthService.updateMe({
                    ...formData,
                    silent: true,
                  });
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <Modal
        wrapClassName={`${darkMode ? "dark" : ""}`}
        open={!!SMTPModal}
        onCancel={() => setSMTPModal(false)}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        destroyOnClose
      >
        <form
          onSubmit={async (e) => {
            e.preventDefault();
            const result = await PartnerService.updateSMTP({ smtp });

            if (!result.data?.smtp?.connectionStatus)
              return message.error("Connection was not successful");
            AuthService.me().then((data) => {
              setMe(data.data.me);
              setSMTPModal(false);
            });
          }}
        >
          <div className="mt-10 mb-2 flex items-center justify-between gap-3">
            <label>Host</label>
            <input
              type="text"
              required
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-gray-400  shadow-sm dark:shadow-gray-400/50  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-900 "
              value={smtp?.host}
              onChange={(e) => setSmtp((x) => ({ ...x, host: e.target.value }))}
            />
          </div>
          <div className="my-2 flex items-center justify-between gap-3">
            <label>Port</label>
            <input
              type="number"
              required
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-gray-400  shadow-sm dark:shadow-gray-400/50  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-900 "
              value={smtp?.port}
              onChange={(e) => setSmtp((x) => ({ ...x, port: e.target.value }))}
            />
          </div>
          <div className="my-2 flex items-center justify-between gap-3">
            <label>Email</label>
            <input
              type="email"
              required
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-gray-400  shadow-sm dark:shadow-gray-400/50  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-900 "
              value={smtp?.email}
              onChange={(e) =>
                setSmtp((x) => ({ ...x, email: e.target.value }))
              }
            />
          </div>
          <div className="my-2 flex items-center justify-between gap-3">
            <label>Password</label>
            <ShowPassword
              required
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-gray-400  shadow-sm dark:shadow-gray-400/50  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-900 "
              value={smtp?.password}
              onChange={(e) =>
                setSmtp((x) => ({ ...x, password: e.target.value }))
              }
            />
          </div>

          <Divider />

          <div className="flex items-center justify-start gap-3">
            <h2 className="text-lg font-semibold">DKIM Settings</h2>
            <a
              href="https://easydmarc.com/tools/dkim-record-generator"
              target="_blank"
            >
              <Tooltip title="DomainKeys Identified Mail (DKIM) is an email authentication method that helps protect email senders and recipients from spam, spoofing, and phishing. It allows an email receiver to check that an email claimed to have come from a specific domain was indeed authorized by the owner of that domain. Using a DKIM generator, such as the one available at EasyDMARC's DKIM Record Generator (click on the lightbulb icon to navigate there), you can easily create these keys. Fill in the 'Domain' and 'Key Selector' fields with the appropriate information for your domain, then generate and use the provided private key here. Your public key will be part of the DKIM record you add to your domain's DNS settings.">
                <GrInfo />
              </Tooltip>
            </a>
          </div>

          <div className="my-2 flex items-center justify-between gap-3">
            <label>Domain</label>
            <input
              type="text"
              placeholder="your-domain.com"
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-gray-400  shadow-sm dark:shadow-gray-400/50  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-900 "
              value={smtp?.dkimDomain}
              onChange={(e) =>
                setSmtp((x) => ({ ...x, dkimDomain: e.target.value }))
              }
            />
          </div>
          <div className="my-2 flex items-center justify-between gap-3">
            <label>Key Selector</label>
            <input
              type="text"
              placeholder="s2"
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-gray-400  shadow-sm dark:shadow-gray-400/50  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-900 "
              value={smtp?.dkimKeySelector}
              onChange={(e) =>
                setSmtp((x) => ({ ...x, dkimKeySelector: e.target.value }))
              }
            />
          </div>
          <div className="my-2 flex items-center justify-between gap-3">
            <label>Private Key</label>
            <textarea
              rows={4}
              className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-gray-400  shadow-sm dark:shadow-gray-400/50  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-900 "
              value={smtp?.dkimPrivateKey}
              onChange={(e) =>
                setSmtp((x) => ({ ...x, dkimPrivateKey: e.target.value }))
              }
            />
          </div>

          <div className="flex justify-between">
            <div />

            {me?.smtp?.connectionStatus ? (
              <Popconfirm
                title="When you disconnect your SMTP, your emails will no longer be sent from your inbox."
                onConfirm={async () => {
                  await PartnerService.disconnectSMTP();
                  PartnerService.getPartnerSecretConfig().then((data) => {
                    setMe(data.data.me);
                    AuthService.me().then((data) => {
                      setOnboardingStatus(data.data.onboardingStatus);
                    });
                  });
                }}
              >
                <button className="px-2 py-1 text-sm border border-red-500 text-red rounded">
                  Disconnect
                </button>
              </Popconfirm>
            ) : (
              <button
                className="px-2 py-1 text-sm bg-indigo-500 text-white rounded"
                htmlType="submit"
                disabled={loading}
                loading={loading}
              >
                Connect
              </button>
            )}
          </div>
        </form>
      </Modal>
    </>
  );
};

export default UserOnboard;
