import { Alert, Spin, message } from "antd";
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { STANDARD_MOMENT_FORMAT } from "../../data/constants";
import { selectLoading } from "../../redux/auth/selectors";
import PublicService from "../../service/PublicService";
import { Footer } from "../Landing/Footer";

const AddressPrompt = ({ candidateData, documentData, token, reloadData }) => {
  const loading = useSelector(selectLoading);

  return (
    <>
      <div
        className="w-full border-box pt-5 pl-8 pr-8 pb-2"
        style={{ background: "center center / cover no-repeat transparent" }}
      >
        <div className="mx-auto max-w-md sm:max-w-xl w-full md:max-w-3xl lg:max-w-4xl">
          <div className="transition-wrapper" style={{}}>
            <div className="wrapper break-words text-left responsive font-semibold text-lg">
              <h4>
                Hi {candidateData?.formData?.firstname}! We need your address
                details to continue with the process.
              </h4>
            </div>
          </div>
        </div>
      </div>

      <div
        className="w-full border-box pt-2 pl-8 pr-8 pb-2"
        style={{ background: "center center / cover no-repeat transparent" }}
      >
        <div className="mx-auto max-w-md sm:max-w-xl w-full md:max-w-3xl lg:max-w-4xl">
          <div className="transition-wrapper" style={{}}>
            <div className="wrapper break-words text-left responsive text-md">
              <Alert
                type="info"
                message={`You received the document titled "${
                  documentData?.title ?? ""
                }" on ${moment(documentData?.createdAt).format(
                  STANDARD_MOMENT_FORMAT
                )}. As part of the document, your address information is required. To facilitate the completion and subsequent provision of a signable version of the document, please kindly provide us with your address details below.`}
              />
              <form
                onSubmit={async (e) => {
                  e.preventDefault();
                  const address = e.target[0].value;
                  if (!address) return message.info("Please type your address");

                  await PublicService.publicCandidateUpdate({
                    address,
                    token,
                  });
                  await reloadData();
                }}
              >
                <textarea
                  placeholder="Your physical address"
                  className="mt-1 block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-gray-400  shadow-sm dark:shadow-gray-400/50  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-900 "
                  rows={3}
                />

                <div className="w-full justify-end flex">
                  <button
                    className="mt-2 px-2 py-1 text-sm bg-indigo-500 text-white rounded"
                    type="submit"
                    disabled={loading}
                  >
                    {loading ? <Spin>Submit</Spin> : <>Submit</>}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <br />
      <Footer />
    </>
  );
};

export default AddressPrompt;
