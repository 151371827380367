import { Tooltip } from "antd";
import React from "react";

function StageTabs({ index, stageData, classObj }) {

  const colors= ["#E6EBFF","#B4C3FF","#7C8EFE","#465FC2","#0538FF"]

  return (
    <>
      <Tooltip placement={"top"} title={`${stageData?.title}`}>
        <div
          key={index}
          style={{
            background: 
  
              stageData.color !== "#eeeeee"
                ? `${index < colors.length ? colors[index] : "#0538FF"}`
                : "#eeeeee",
          }}
          className={`${classObj.height} ${classObj.width} flex justify-center items-center text-white ${classObj.rounded}`}
        ></div>
      </Tooltip>
    </>
  );
}

export default StageTabs;
