import React from "react";
import {Heading} from "./components/Heading"
import {Button} from "./components/Button"
import {Img} from "./components/Img"

const Details = ({setFirstName, setLastName, setEmail, email, firstName, lastName, step, setStep})=>{

  const handleContinue = (e) =>{
    e.preventDefault()
    if(firstName && lastName && email){
    setStep(step + 1)}
  }
      
    return(
        <div className="flex flex-col w-full items-center gap-[68px] px-14 md:px-5 sm:gap-[34px] mt-3">
<div className="flex flex-col justify-center gap-3 px-5 md:px-5">
                <Img
                  src="/logo.svg"
                  alt="Logo Icon"
                  className="mx-auto h-[48px] w-[48px] rounded-[10px] "
                />
                <a href="#" className="md:text-[28px] sm:text-[26px]">
                  <Heading size="headingxl" as="h3" className="text-[30px] text-center font-semibold text-[#0f1728]">
                  Welcome to HireHeroes!
                  </Heading>
                </a>
                <Heading as="h4" className="text-[16px] font-normal text-[#475467] text-center">
                Create your account.
                </Heading>
              </div>
                  <form 
                  onSubmit={handleContinue}
                  className="flex flex-col w-full md:w-[70%] gap-3 h-[80vh] lg:h-[75vh] justify-between">
                    {/* <div className="flex flex-col w-[70%]"> */}
                    <div>
                      <div className="flex flex-col w-full items-start justify-center gap-1.5 my-3">
                        <Heading size="texts" as="h3" className="text-[14px] font-medium text-[#344054]">
                          First Name*
                        </Heading>
                        <input
                          shape="round"
                          required
                          type="text"
                          name="First Name Field"
                          placeholder={`Enter your first name`}
                          className="w-full rounded-lg border border-solid border-[#cfd4dc] px-3.5"
                          value={firstName}
                          onChange={(e)=>setFirstName(e.target.value)}
                        />
                      </div>
                      <div className="flex flex-col w-full items-start justify-center gap-1.5 my-3">
                        <Heading size="texts" as="h3" className="text-[14px] font-medium text-[#344054]">
                          Last Name*
                        </Heading>
                        <input
                          shape="round"
                          type="text"
                          required
                          name="Last Name Field"
                          placeholder={`Enter your last name`}
                          className="w-full rounded-lg border border-solid border-[#cfd4dc] px-3.5"
                          value={lastName}
                          onChange={(e)=>setLastName(e.target.value)}
                        />
                      </div>
                      <div className="flex flex-col w-full items-start justify-center gap-1.5 my-3">
                        <Heading size="texts" as="h4" className="text-[14px] font-medium text-[#344054]">
                          Email*
                        </Heading>
                        <input
                          shape="round"
                          type="email"
                          required
                          name="Email Field"
                          placeholder={`Enter your email`}
                          className="w-full rounded-lg border border-solid border-[#cfd4dc] px-3.5"
                          value={email}
                          onChange={(e)=>setEmail(e.target.value)}
                        />
                      </div>
                    </div>
                    {/* <Divider>or</Divider>
                    <TextArea
                      shape="round"
                      name="Upload Area"
                      placeholder={`or drag and drop`}
                      className="flex w-[76%] gap-[30px] rounded-lg !border !border-[#cfd4dc] px-3 text-[#475467] md:py-5"
                    /> */}
                    <div className="w-full ">
                    <Button
                      className="continuebutton_border md:min-w-[400px] rounded-lg bg-gradient-to-r from-[#0538ff] to-[#6b56f4] px-[33px] font-semibold text-[#ffffff] shadow-[0_1px_2px_0_#1018280c] sm:px-5 flex w-full my-5"
                      size="2xl"
                      shape="round"
                      variant={null}
                      onClick={handleContinue}
                    >
                      Continue
                    </Button>
                    <div className="flex justify-between w-full mb-4">
                  <div className="h-[8px] w-[32%] rounded bg-gradient-to-r from-[#0538ff] to-[#6b56f4]" />
                  <div className="h-[8px] w-[32%] rounded bg-[#eaecf0]" />
                  <div className="h-[8px] w-[32%] rounded bg-[#eaecf0]" />
                </div>
                </div>
                  </form>      
                  </div>      

    )
}

export default Details