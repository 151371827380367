import React from "react";

const Terms = () => {
  return (
    <div className="p-9">
      <div className="font-bold text-lg">Terms & Conditions</div>

      <div className="mt-5">Last Updated: April, 2024</div>

      <div>Introduction</div>
      <div>
        Thanks for using our products and services ("Service"). The Service is
        provided by HireHeroes.ai ("HireHeroes", "we", "us", or "our"), located
        at Industrieweg 1a, 1521NA Wormerveer.. By using our Service, you agree
        to these terms. Please read them carefully.
      </div>
      <div>Description of Service</div>
      <div>
        HireHeroes.ai provides you with access to a variety of career
        enhancement tools and services, which may be accessed via our web
        platform. The Service may include communications from HireHeroes, such
        as service announcements and administrative messages. These are
        considered part of your account membership and you may not be able to
        opt-out from receiving them. Any new features or tools released by us
        will also be subject to these Terms.
      </div>
      <div>Access Requirements</div>
      <div>
        To access the Service, you need a reliable internet connection and you
        are responsible for all fees associated with such access, as well as for
        providing all the equipment necessary to connect to the Service.
      </div>
      <div>Use of Service</div>
      <div>
        Account Registration: You must be of legal age to form a binding
        contract in your jurisdiction to register for an account. When you
        register, you agree to provide accurate, current, and complete
        information about yourself. You agree to keep this information
        up-to-date.
      </div>
      <div>
        Permitted Uses: You are allowed to use our Service for personal and
        professional purposes related to seeking employment, managing career
        information, or enhancing professional skills.
      </div>
      <div>
        Prohibited Uses: You may not use our Service to engage in illegal
        activities or to infringe upon the rights of others. You may not use the
        service to transmit harmful or malicious software, or to engage in
        spamming, phishing, or other fraudulent behaviors.
      </div>
      <div>Intellectual Property Rights</div>
      <div>
        Using our Service does not give you ownership of any intellectual
        property rights in our Service or the content you access. You may not
        use content from our Service unless you obtain permission from its owner
        or are otherwise permitted by law.
      </div>
      <div>User Content</div>
      <div>
        You retain all rights to the text, files, images, graphics, or other
        materials you submit to the Service (collectively, "User Content"). By
        uploading User Content, you grant HireHeroes a global, non-exclusive,
        royalty-free license to use, host, store, reproduce, modify, create
        derivative works, communicate, publish, publicly perform, publicly
        display and distribute such content.
      </div>
      <div>Privacy and Data Protection</div>
      <div>
        HireHeroes is committed to protecting your privacy and data. All
        personal data is processed in accordance with EU General Data Protection
        Regulation (GDPR). For detailed information, please refer to our Privacy
        Policy.
      </div>
      <div>Modifications to the Service</div>
      <div>
        We may modify the Service or any portion thereof, at any time without
        prior notice. We may also suspend or discontinue the Service at our sole
        discretion.
      </div>
      <div>Termination</div>
      <div>
        You may terminate your use of the Service at any time, unless agreed
        otherwise. HireHeroes reserves the right to suspend or terminate your
        access to the Service at any time, without notice, for any reason,
        including breach of these Terms.
      </div>
      <div>Limitation of Liability</div>
      <div>
        To the extent permitted by law, HireHeroes shall not be liable for any
        indirect, incidental, special, consequential or punitive damages, or any
        loss of profits or revenues, whether incurred directly or indirectly, or
        any loss of data, use, goodwill, or other intangible losses.
      </div>
      <div>Governing Law and Jurisdiction</div>
      <div>
        These terms shall be governed by and construed in accordance with the
        laws of Dutch Government. You agree to submit to the personal
        jurisdiction of the courts located in Amsterdam, The Netherlands, for
        any legal proceedings that may arise in relation to the Service or these
        Terms.
      </div>
      <div>Changes to Terms</div>
      <div>
        We reserve the right to modify these terms at any time. We will post the
        revised terms on the Service and update the "Last Updated" date to
        reflect the date of the changes. By continuing to use the Service after
        these changes are made, you agree to the revised terms.
      </div>
      <div>Contact Us</div>
      <div>
        If you have any questions about these Terms, please contact us at
        support@HireHeroes.ai.
      </div>
    </div>
  );
};

export default Terms;
