import { Alert, Popconfirm, Skeleton, Spin, message } from "antd";
import jsPDF from "jspdf";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import ReactSignatureCanvas from "react-signature-canvas";
import { selectLoading } from "../../redux/auth/selectors";
import PublicService from "../../service/PublicService";
import { Footer } from "../Landing/Footer";
import AddressPrompt from "./AddressPrompt";

import domtoimage from "dom-to-image";

function blobToBase64(blob) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
}

export const downloadAsPDF = async (elementId, filename = "Contract.pdf") => {
  const element = document.getElementById(elementId);

  if (!element) {
    console.error("Element not found");
    return;
  }

  try {
    const blob = await domtoimage.toBlob(element, {
      width: element.offsetWidth,
      height: element.offsetHeight,
    });
    const imgData = await blobToBase64(blob);

    const pdf = new jsPDF({
      orientation: "p",
      unit: "px",
      format: "a4",
    });

    // Define padding
    const padding = 20; // For example, 20px padding on each side

    const pdfWidth = pdf.internal.pageSize.getWidth() - padding * 2;
    const pdfHeight = pdf.internal.pageSize.getHeight() - padding * 2;

    // Calculate scaled dimensions to maintain aspect ratio
    const scaledWidth = pdfWidth;
    const scaledHeight =
      (element.offsetHeight / element.offsetWidth) * scaledWidth;
    const pageCount = Math.ceil(scaledHeight / pdfHeight);

    for (let i = 0; i < pageCount; i++) {
      if (i !== 0) {
        pdf.addPage();
      }

      // Calculate the y position to start slicing from
      const sliceY = (pdfHeight + padding * 2) * i;

      // Add the image slice to the current page
      pdf.addImage(
        imgData,
        "PNG",
        padding, // x position
        -sliceY, // y position: start at padding, minus the slice offset
        scaledWidth, // width of the image slice
        scaledHeight // total height of the image
      );
    }

    pdf.save(filename);
  } catch (error) {
    console.error("Error converting element to PDF:", error);
  }
};

const ADDRESS_PLACEHOLDER = "@[Candidate's Address](candidateAddress)";

const Offer = () => {
  let [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [documentData, setDocumentData] = useState(null);
  const [candidateData, setCandidateData] = useState(null);
  const [token, setToken] = useState("");
  const sigCanvas = useRef();

  const backendLoading = useSelector(selectLoading);

  const reloadData = async (token) => {
    setLoading(true);

    await PublicService.getDocumentData(token)
      .then(({ data }) => {
        setDocumentData(data?.document);
        setCandidateData(data?.candidate);
      })
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    const token = searchParams.get("token");
    if (!token) return;
    setToken(token);

    reloadData(token);
  }, [searchParams]);

  const getReplacement = (key) => {
    switch (key) {
      case "hiringAuthoritySignature":
        return documentData?.hiringManagerSignature ? (
          <img
            src={documentData.hiringManagerSignature}
            alt="Hirint Authority Signature"
            width={400}
            height={100}
            style={{ border: "1px solid gray" }}
          />
        ) : (
          <></>
        );
      case "candidateSignature":
        return (
          <>
            <div>
              <span>
                {documentData?.candidateSignature ? (
                  <img
                    src={documentData?.candidateSignature}
                    alt="Signature"
                    width={400}
                    height={100}
                    style={{ border: "1px solid gray" }}
                  />
                ) : (
                  <ReactSignatureCanvas
                    penColor="blue"
                    canvasProps={{
                      style: {
                        border: "1px solid gray",
                      },
                      width: 400,
                      height: 100,
                      className: "sigCanvas",
                    }}
                    ref={sigCanvas}
                  />
                )}

                <div className="w-[400px] flex justify-between items-center">
                  <div
                    className={`${
                      documentData?.candidateSignature ? "invisible" : ""
                    } font-semibold`}
                  >
                    Sign here as the candidate
                  </div>
                  <div className=" flex justify-end gap-1">
                    <Popconfirm
                      title="Are you sure to sign?"
                      onConfirm={async () => {
                        if (backendLoading) return;
                        if (sigCanvas?.current?.isEmpty?.())
                          return message.info(
                            "Please ensure all required fields are signed"
                          );
                        const signature =
                          sigCanvas?.current?.toDataURL?.("image/png");

                        await PublicService.publicCandidateUpdate({
                          signature,
                          token,
                        });
                        await reloadData(token);
                      }}
                    >
                      <button
                        className={`${
                          documentData?.candidateSignature ? "invisible" : ""
                        } mt-2 px-2 py-1 text-sm bg-indigo-500 text-white rounded`}
                        loading={backendLoading}
                      >
                        {backendLoading ? <Spin>Confirm</Spin> : "Confirm"}
                      </button>
                    </Popconfirm>
                    <button
                      className={`${
                        documentData?.candidateSignature ? "invisible" : ""
                      } mt-2 px-2 py-1 text-sm text-indigo-500 border border-indigo-500 bg-transparent rounded`}
                      loading={backendLoading}
                      onClick={() => sigCanvas?.current?.clear?.()}
                    >
                      {backendLoading ? <Spin>Clear</Spin> : "Clear"}
                    </button>
                  </div>
                </div>
              </span>
            </div>
          </>
        );
      case "candidateSignatureDate":
        return (
          <span>
            {documentData?.candidateSignatureDate
              ? moment(documentData.candidateSignatureDate).format("DD-MM-YYYY")
              : "To be signed"}
          </span>
        );
      case "hiringAuthoritySignatureDate":
        return (
          <span>
            {documentData?.hiringManagerSignatureDate
              ? moment(documentData.hiringManagerSignatureDate).format(
                  "DD-MM-YYYY"
                )
              : "To be signed"}
          </span>
        );
      default:
        return ``;
    }
  };

  const processText = (text) => {
    const regex =
      /\@\[([^[\]]+)\]\((candidateSignature|hiringAuthoritySignature|candidateSignatureDate|hiringAuthoritySignatureDate)\)/g;
    const parts = [];
    let lastIndex = 0;

    text.replace(regex, (match, p1, p2, offset) => {
      // Add the text before the match
      parts.push(text.slice(lastIndex, offset).replace(/\n/g, "<br>"));
      // Add the replacement for the match
      parts.push(getReplacement(p2));
      lastIndex = offset + match.length;
      return match; // This return is not used, but replace expects a return
    });

    // Add any remaining text after the last match
    if (lastIndex < text.length) {
      parts.push(text.slice(lastIndex).replace(/\n/g, "<br>"));
    }

    return parts.map((part, index) => (
      <React.Fragment key={index}>
        {typeof part === "string" ? (
          <span
            dangerouslySetInnerHTML={{
              __html: part,
            }}
          />
        ) : (
          part
        )}
      </React.Fragment>
    ));
  };

  if (!documentData && loading) return <Skeleton active />;
  if (!documentData && !loading)
    return (
      <div className="m-4">
        <Alert type="error" message="Invalid document link" />
      </div>
    );

  if (
    documentData?.body?.includes?.(ADDRESS_PLACEHOLDER) &&
    !candidateData?.address
  )
    return (
      <AddressPrompt
        candidateData={candidateData}
        documentData={documentData}
        token={token}
        reloadData={async () => {
          if (token) await reloadData(token);
        }}
      />
    );

  return (
    <>
      <div className="flex flex-col items-center">
        <div className="w-full border-box pt-5 pb-2 container">
          <div className="wrapper break-words text-left responsive font-semibold text-lg">
            <h4>Document: {documentData?.title ?? ""}</h4>
          </div>
        </div>

        <div className="container">
          <div id="contract-section">
            {documentData?.body &&
              processText(
                documentData.body.replace(
                  ADDRESS_PLACEHOLDER,
                  candidateData?.address
                )
              )}
          </div>

          <button
            className="mt-5 rounded-md max-w-md block cursor-pointer border-none w-full box-border button transition text-center transform active:scale-90 "
            type="button"
            onClick={async () => {
              setLoading(true);
              try {
                await downloadAsPDF(
                  "contract-section",
                  `${documentData?.title ?? "Contract"}.pdf`
                );
              } catch (e) {
              } finally {
                setLoading(false);
              }
            }}
          >
            <div className="w-full border-box ">
              <div className="wrapper break-words text-center p-4 bg-indigo-500 rounded text-white font-bold">
                {loading ? (
                  <Spin>
                    <h3>Download as PDF</h3>
                  </Spin>
                ) : (
                  <h3>Download as PDF</h3>
                )}
              </div>
            </div>
          </button>
        </div>
      </div>
      <br />
      <Footer />
    </>
  );
};

export default Offer;
