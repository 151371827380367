import { Button, Card, Col, Row, Select, Space, Spin, Statistic } from "antd";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { MdArrowBackIos, MdRefresh } from "react-icons/md";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  CartesianGrid,
  Cell,
  Legend,
  Line,
  LineChart,
  Pie,
  PieChart,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { login } from "../../redux/auth/actions";
import { selectUser } from "../../redux/auth/selectors";
import { store } from "../../redux/store";
import AuthService from "../../service/AuthService";
import CrudService from "../../service/CrudService";
import StatsService from "../../service/StatsService";
import OnboardUser from "./OnboardUser";
import UserDashboard from "./UserDashboard";

const { Option } = Select;

const segmentDetails = {
  H: { segmentNum: 8, timeframeName: "hour" },
  D: { segmentNum: 7, timeframeName: "day" },
  W: { segmentNum: 4, timeframeName: "week" },
  M: { segmentNum: 6, timeframeName: "month" },
  A: { segmentNum: 5, timeframeName: "year" },
};

const PieChartComponent = ({ title, data }) => {
  const COLORS = ["#0088FE", "#00C49F", "#FFBB28", "#FF8042", "#AF19FF"];
  return (
    <div>
      <PieChart width={150} height={150}>
        <Pie
          data={data}
          dataKey="count"
          nameKey="_id"
          outerRadius={40}
          cx={100}
          fill="#8884d8"
          label
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        <Tooltip formatter={(value, name) => [value, name]} />
        <Legend />
      </PieChart>
    </div>
  );
};

const StatsDashboard = ({ funnelId, vacancyData, back }) => {
  const [mainStats, setMainStats] = useState({});
  const [segmentedStats, setSegmentedStats] = useState([]);
  const [KPIs, setKPIs] = useState([]);
  const [timeToApply, setTimeToApply] = useState({});
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [loading4, setLoading4] = useState(false);
  const [timeframe, setTimeframe] = useState("D");
  const navigate = useNavigate();
  const user = useSelector(selectUser);
  const [campaigns, setCampaigns] = useState([]);
  const [heroes, setHeroes] = useState([]);

  useEffect(() => {
    CrudService.search("Hero", 1, 1, { filters: { user_id: user._id } }).then(
      (res) => {
        setHeroes(res.data.items);
      }
    );
    CrudService.search("Vacancy", 1, 1, {
      filters: { user_id: user._id },
    }).then((res) => {
      setCampaigns(res.data.items);
    });
  }, [user]);

  const isLoading = useMemo(
    () => loading1 || loading2 || loading3,
    [loading1, loading2, loading3]
  );

  const chartData = [...segmentedStats].reverse().map((segment) => ({
    name: `${segment.idx === 1 ? "This" : segment.idx - 1} ${
      segmentDetails[timeframe].timeframeName
    }${segment.idx <= 2 ? "" : "s"}${segment.idx === 1 ? "" : " ago"}`,
    Clicks: segment.clicks,
    Conversions: segment.conversions,
    Applicants: segment.applicants,
    Qualified: segment.qualified,
    Meetings: segment.meetings,
    Hires: segment.hires,
  }));

  const loadData = useCallback(() => {
    setLoading1(true);
    setLoading2(true);
    setLoading3(true);
    setLoading4(true);

    StatsService.getNumbers(funnelId ?? "")
      .then(({ data }) => {
        setMainStats(data);
      })
      .finally(() => {
        setLoading1(false);
      });
    StatsService.getTimeToApply(funnelId ?? "")
      .then(({ data }) => {
        setTimeToApply(data);
      })
      .finally(() => {
        setLoading2(false);
      });
    StatsService.getSegmentedNumbers(funnelId ?? "", timeframe)
      .then(({ data }) => {
        setSegmentedStats(data);
      })
      .finally(() => {
        setLoading3(false);
      });

    StatsService.getSurveys(funnelId ?? "")
      .then(({ data }) => {
        setKPIs(data.KPIs);
      })
      .finally(() => {
        setLoading4(false);
      });
  }, [timeframe, funnelId]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const utmList = [
    {
      name: "utmCampaign",
      title: "UTM Campaign",
      data: mainStats.utmCampaignAggregation,
    },
    {
      name: "utmSource",
      title: "UTM Source",
      data: mainStats.utmSourceAggregation,
    },
    {
      name: "utmMedium",
      title: "UTM Medium",
      data: mainStats.utmMediumAggregation,
    },
    {
      name: "utmContent",
      title: "UTM Content",
      data: mainStats.utmContentAggregation,
    },
    {
      name: "utmTerm",
      title: "UTM Term",
      data: mainStats.utmTermAggregation,
    },
    {
      name: "salesforceUUID",
      title: "Salesforce UUID",
      data: mainStats.salesforceUuidAggregation,
    },
  ];

  const userOnboarded =
    campaigns.length > 0 &&
    heroes.length > 0 &&
    user?.knowledge &&
    Object.keys(user?.knowledge)?.some?.(
      (key) => user?.knowledge?.[key]?.length > 0
    );

  useEffect(() => {
    if (userOnboarded && !user.onboarded) {
      AuthService.setOnboarded().then(async () => {
        const me = await AuthService.me();
        store.dispatch(login(me.data.me));
      });
    }
  }, [userOnboarded, user]);

  /////
  if (!vacancyData && !user.onboarded) return <OnboardUser />;
  return (
    <>
      <UserDashboard user={user} campaigns={campaigns} heroes={heroes} />
    </>
  );
};

export default StatsDashboard;
