import { CloudArrowUpIcon, EnvelopeIcon } from "@heroicons/react/24/outline";
import { UserCircleIcon } from "@heroicons/react/24/solid";
import {
  Select as AntdSelect,
  Button,
  Checkbox,
  ColorPicker,
  Divider,
  Modal,
  Popconfirm,
  Progress,
  Skeleton,
  Space,
  Switch,
  Tabs,
  Tooltip,
  message,
} from "antd";
import { useCallback, useEffect, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import { GrInfo } from "react-icons/gr";
import { Mention, MentionsInput } from "react-mentions";
import PhoneInput from "react-phone-input-2";
import { useSelector } from "react-redux";
import Select from "../../components/Select";
import {
  DEFAULT_CANDIDATE_MESSAGE_VARIABLES,
  ZOOM_ENABLED,
  countries,
} from "../../data/constants";
import {
  getPartner,
  selectDarkMode,
  selectLoading,
} from "../../redux/auth/selectors";
import AuthService from "../../service/AuthService";
import CalendlyService from "../../service/CalendlyService";
import SMTPService from "../../service/SMTPService";
import UploadService from "../../service/UploadService";
import ZoomService from "../../service/ZoomService";
import classNamesBody from "./Message/body.module.css";
import classNames from "./Message/example.module.css";
import ShowPassword from "./PartnerSettings/ShowPassword";
import SMTPModalComponent from "./SMTPModal";

export const JOB_PORTAL_ACTIVATED = false;
export const mailOptions = [
  { value: "index", label: "Default" },
  { value: "blank", label: "Clean" },
];

function getColorFun(r, g, b) {
  return (
    "#" +
    ((1 << 24) + (Math.ceil(r) << 16) + (Math.ceil(g) << 8) + Math.ceil(b))
      .toString(16)
      .slice(1)
  );
}

export default function UserSettings() {
  const [me, setMe] = useState(null);
  const [softValue, setSoftValue] = useState(null);
  const [CVEditModal, setCVEditModal] = useState(null);
  const [onboardingStatus, setOnboardingStatus] = useState(null);
  const [calendlyToken, setCalendlyToken] = useState(null);
  const [zoomToken, setZoomToken] = useState(null);
  const [SMTPModal, setSMTPModal] = useState(false);
  const [changeEmailModal, setChangeEmailModal] = useState(false);
  const [smtp, setSmtp] = useState(null);
  const [eventTypes, setEventTypes] = useState(null);
  const loading = useSelector(selectLoading);
  const partner = useSelector(getPartner);
  const darkMode = useSelector(selectDarkMode);

  const fileInput = useRef(null);
  const fileInput31 = useRef(null);
  const fileInput32 = useRef(null);
  const fileInput33 = useRef(null);

  useEffect(() => {
    fileInput.current = document.getElementById("fileInput");
    if (fileInput.current)
      fileInput.current.addEventListener("change", async () => {
        const selectedFile = fileInput.current.files[0]; // Get the selected file
        if (selectedFile) {
          const result = await UploadService.upload(selectedFile, 1);
          await AuthService.updateMe({
            avatar: result.data.secure_url,
          });
          const res = await AuthService.me();
          setMe(res.data.me);
          setOnboardingStatus(res.data.onboardingStatus);

          // You can perform further actions with the selected file here
        } else {
          console.log("No file selected.");
        }
      });
  }, []);

  useEffect(() => {
    fileInput31.current = document.getElementById("fileInput31");
    if (fileInput31.current)
      fileInput31.current.addEventListener("change", async () => {
        const selectedFile = fileInput31.current.files[0]; // Get the selected file
        if (selectedFile) {
          const result = await UploadService.upload(selectedFile, 1);
          await AuthService.updateMe({
            jobPortalHeroImage: result.data.secure_url,
          });
          const res = await AuthService.me();
          setMe(res.data.me);
          setOnboardingStatus(res.data.onboardingStatus);

          // You can perform further actions with the selected file here
        } else {
          console.log("No file selected.");
        }
      });
  }, []);
  useEffect(() => {
    fileInput32.current = document.getElementById("fileInput32");
    if (fileInput32.current)
      fileInput32.current.addEventListener("change", async () => {
        const selectedFile = fileInput32.current.files[0]; // Get the selected file
        if (selectedFile) {
          const result = await UploadService.upload(selectedFile, 1);
          await AuthService.updateMe({
            jobPortalJobsSectionImage: result.data.secure_url,
          });
          const res = await AuthService.me();
          setMe(res.data.me);
          setOnboardingStatus(res.data.onboardingStatus);

          // You can perform further actions with the selected file here
        } else {
          console.log("No file selected.");
        }
      });
  }, []);
  useEffect(() => {
    fileInput33.current = document.getElementById("fileInput33");
    if (fileInput33.current)
      fileInput33.current.addEventListener("change", async () => {
        const selectedFile = fileInput33.current.files[0]; // Get the selected file
        if (selectedFile) {
          const result = await UploadService.upload(selectedFile, 1);
          await AuthService.updateMe({
            logo: result.data.secure_url,
          });
          const res = await AuthService.me();
          setMe(res.data.me);
          setOnboardingStatus(res.data.onboardingStatus);

          // You can perform further actions with the selected file here
        } else {
          console.log("No file selected.");
        }
      });
  }, []);

  const handleUpdate = useCallback(async () => {
    await AuthService.updateMe(softValue);
    const res = await AuthService.me();
    setMe(res.data.me);
    setOnboardingStatus(res.data.onboardingStatus);
    document.dispatchEvent(new CustomEvent("REFRESH.PROFILE"));

    if (
      res.data.onboardingStatus?.profileCompletion === 100 &&
      localStorage.lastVisit
    )
      window.location.href = localStorage.lastVisit;
  }, [softValue]);

  useEffect(() => {
    setSoftValue(me);
    setSmtp(me?.smtp ?? null);
  }, [me]);

  useEffect(() => {
    if (!partner?.calendlyclientId) return;
    CalendlyService.getEventTypes().then(({ data }) => {
      setEventTypes(data.eventTypes);
    });
  }, [partner]);

  useEffect(() => {
    AuthService.me().then((data) => {
      setMe(data.data.me);
      setOnboardingStatus(data.data.onboardingStatus);
    });
  }, []);

  useEffect(() => {
    if (partner?.calendlyclientId)
      CalendlyService.getCurrentToken()
        .then(({ data }) => {
          setCalendlyToken(data);
        })
        .catch(() => setCalendlyToken(null));

    if (ZOOM_ENABLED && partner?.zoomClientId)
      ZoomService.getCurrentToken()
        .then(({ data }) => {
          setZoomToken(data);
        })
        .catch(() => setZoomToken(null));
  }, []);

  const getProps = (fieldKey) => ({
    value: softValue?.[fieldKey],
    onChange: (e) =>
      setSoftValue((current) => ({
        ...current,
        [fieldKey]: e?.target?.value ?? e,
      })),
  });

  const items = [
    {
      key: "1",
      label: "Profile Settings",
      children: <Profile />,
    },
    {
      key: "2",
      label: "Password",
      children: <Password />,
    },
  ];

  if (!softValue) return <Skeleton />;
  return (
    <div className="flex flex-col w-full ">
      <div className="relative flex items-center ">
        <div className="flex flex-col w-full  items-start pb-2">
          <h1 className="text-2xl font-bold">Settings</h1>
          <p className="text-md font-inter text-[#475467]">
            Manage your account here.
          </p>
        </div>
      </div>
      <Tabs defaultActiveKey="1" items={items} />
    </div>
  );
}

const Profile = () => {
  const { getRootProps, getInputProps } = useDropzone();

  return (
    <div>
      <form
        className="bg-white dark:bg-gray-900 shadow-sm dark:shadow-gray-400/50  ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2 min-h-[60vh]"
        onSubmit={(e) => e.preventDefault()}
      >
        <div className="px-4 py-1 sm:p-4">
          <div className="flex flex-col w-full items-start">
            <h1 className="text-lg font-bold">Profile Settings</h1>
            <p className="text-xs font-inter text-[#475467]">
              Update your photo and personal details here.
            </p>
          </div>

          <Divider className="my-3" />
          {/* FirstName */}
          <div className="flex flex-column justify-between mb-3">
            <label
              htmlFor="first-name"
              className=" text-sm font-medium leading-6 text-gray-900 dark:text-gray-400 "
            >
              First name
            </label>
            <input
              type="text"
              name="first-name"
              id="first-name"
              autoComplete="given-name"
              placeholder="Taylor"
              className="block w-[70%] rounded-md border-0 py-1.5 text-gray-900 dark:text-gray-400  shadow-sm dark:shadow-gray-400/50  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-900 "
            />
          </div>
          {/* LastName */}
          <div className="flex flex-column justify-between">
            <label
              htmlFor="last-name"
              className="text-sm font-medium leading-6 text-gray-900 dark:text-gray-400 "
            >
              Last name
            </label>
            <input
              type="text"
              name="last-name"
              id="last-name"
              placeholder="Smith"
              autoComplete="family-name"
              className="block w-[70%] rounded-md border-0 py-1.5 text-gray-900 dark:text-gray-400  shadow-sm dark:shadow-gray-400/50  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-900 "
            />
          </div>
          <Divider />
          {/* Email */}
          <div className="flex flex-column justify-between mb-3">
            <label
              htmlFor="email"
              className="text-sm font-medium leading-6 text-gray-900 dark:text-gray-400 "
            >
              Email
            </label>
            <div className="relative w-[70%]">
              <div className="absolute inset-y-0 start-0 z-[3] flex items-center ps-3.5 pointer-events-none">
                <EnvelopeIcon width={20} height={20} color="gray" />
              </div>
              <input
                type="text"
                name="email"
                id="email"
                autoComplete="email"
                placeholder="taylorsmith@mail.com"
                className="block w-full rounded-md border-0 ps-10 p-1.5 text-gray-900 dark:text-gray-400  shadow-sm dark:shadow-gray-400/50  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-900 "
              />
            </div>
          </div>
          {/* Phone */}
          <div className="flex flex-column justify-between">
            <label
              htmlFor="phone"
              className=" text-sm font-medium leading-6 text-gray-900 dark:text-gray-400 "
            >
              Phone number
            </label>
            <div className="w-[70%]">
              <PhoneInput
                country={"us"}
                name="phone"
                id="phone"
                autoComplete="phone"
                placeholder="+1 (555) 000-0000"
                inputStyle={{
                  width: "100%",
                  display: "block",
                  borderRadius: "0.375rem", // rounded-md
                  // border: 'none',
                  paddingTop: "0.3rem", // py-1.5
                  paddingBottom: "0.3rem",
                  color: "#1f2937", // text-gray-900
                  boxShadow:
                    "0 1px 2px rgba(0, 0, 0, 0.05), 0 1px 3px rgba(0, 0, 0, 0.1)", // shadow-sm (default light shadow)
                  border: "solid",
                  borderWidth: "1px",
                  borderColor: "#d1d5db", // ring-gray-300
                  ring: "1px",
                  ringInset: "inset",
                  placeholderColor: "#9ca3af", // placeholder:text-gray-400
                  outline: "none", // removing the default outline
                  transition: "ring 0.2s ease-in-out", // for smooth focus transition
                  fontSize: "0.875rem", // sm:text-sm
                  lineHeight: "1rem", // sm:leading-6
                  justifySelf: "end",
                }}
              />
            </div>
          </div>
          <Divider />
          {/* Message */}
          <div className="flex flex-column justify-between mb-3">
            <label
              htmlFor="language"
              className="text-sm font-medium leading-6 text-gray-900 dark:text-gray-400 "
            >
              Language
            </label>
            <select
              name="language"
              id="language"
              autoComplete="language"
              className="block w-[70%] rounded-md border-0 py-1.5 text-gray-900 dark:text-gray-400  shadow-sm dark:shadow-gray-400/50  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-900 "
            />
          </div>
          <Divider />
          {/* Photo */}
          <div
            {...getRootProps()}
            className=" rounded-md border-0 py-3 text-gray-900 dark:text-gray-400  shadow-sm dark:shadow-gray-400/50  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-900 flex flex-col items-center justify-center "
          >
            <input {...getInputProps()} />
            <div className=" p-1 rounded-lg border border-1 border-[#D0D5DD] inline-flex items-center justify-center">
              <CloudArrowUpIcon width={20} height={20} />
            </div>
            <p className="text-center">
              <span className="text-semibold bg-gradient !text-transparent bg-clip-text">
                Click to upload{" "}
              </span>
              or drag and drop
            </p>
            <p className="text-center">SVG, PNG, JPG or GIF (max. 800x400px)</p>
            <img></img>
          </div>
        </div>
      </form>
      <div className="w-full flex justify-end gap-6 mt-4">
        <button
          className="py-2 text-sm bg-white border border-1 border-[#D0D5DD] text-[#344054]  rounded-lg w-full"
          //   onClick={() => {
          //   }
        >
          Cancel
        </button>
        <button
          className="py-2 text-sm bg-gradient text-white rounded-lg w-full"
          //   onClick={handleNext}
          type="primary"
        >
          Save
        </button>
      </div>
    </div>
  );
};

const Password = () => {
  return (
    <div>
      <form
        className="bg-white dark:bg-gray-900 shadow-sm dark:shadow-gray-400/50  ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2 min-h-[60vh]"
        onSubmit={(e) => e.preventDefault()}
      >
        <div className="px-4 py-1 sm:p-4">
          <div className="flex flex-col w-full items-start">
            <h1 className="text-lg font-bold">Password</h1>
            <p className="text-xs font-inter text-[#475467]">
              Please enter your current password to change your password.
            </p>
          </div>

          <Divider className="my-3" />
          {/* Password */}
          <div className="flex flex-column justify-between">
            <label
              htmlFor="current-password"
              className=" text-sm font-medium leading-6 text-gray-900 dark:text-gray-400 "
            >
              Current password
            </label>
            <ShowPassword
              name="current-password"
              id="current-password"
              autoComplete="current-password"
              placeholder="••••••••"
              className="block w-[70%] rounded-md border-0 py-1.5 text-gray-900 dark:text-gray-400  shadow-sm dark:shadow-gray-400/50  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-900 "
            />
          </div>
          <Divider />
          {/* New password */}
          <div className="flex flex-column justify-between mb-3">
            <label
              htmlFor="new-password"
              className="text-sm font-medium leading-6 text-gray-900 dark:text-gray-400 "
            >
              New password
            </label>
            <div className="w-[70%]">
              <ShowPassword
                name="new-password"
                id="new-password"
                autoComplete="new-password"
                placeholder="••••••••"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 dark:text-gray-400  shadow-sm dark:shadow-gray-400/50  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-900 "
              />
              <p class="mt-1 text-sm text-[#475467]">
                Your new password must be more than 8 characters.
              </p>
            </div>
          </div>
          {/* Confirm password */}
          <div className="flex flex-column justify-between">
            <label
              htmlFor="confirm-password"
              className=" text-sm font-medium leading-6 text-gray-900 dark:text-gray-400 "
            >
              Confirm new password
            </label>
            <ShowPassword
              name="confirm-password"
              id="confirm-password"
              autoComplete="confirm-password"
              placeholder="••••••••"
              className="block w-[70%] rounded-md border-0 py-1.5 text-gray-900 dark:text-gray-400  shadow-sm dark:shadow-gray-400/50  ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 dark:bg-gray-900 "
            />
          </div>
        </div>
      </form>
      <div className="w-full flex justify-end gap-6 mt-4">
        <button
          className="py-2 text-sm bg-white border border-1 border-[#D0D5DD] text-[#344054]  rounded-lg w-full"
          //   onClick={() => {
          //   }
        >
          Cancel
        </button>
        <button
          className="py-2 text-sm bg-gradient text-white rounded-lg w-full"
          //   onClick={handleNext}
          type="primary"
        >
          Save
        </button>
      </div>
    </div>
  );
};
