import { EyeIcon } from "@heroicons/react/24/outline";
import { Button } from "../auth/components/Button";
import { Heading } from "../auth/components/Heading";
import { Img } from "../auth/components/Img";
import { Input } from "../auth/components/Input";
import { Text } from "../auth/components/Text";
//import { CloseSVG } from "../../components/Input/close.jsx";
//import Sidebar1 from "../../components/Sidebar1";
import {
  BriefcaseIcon,
  EnvelopeIcon,
  InformationCircleIcon,
  PhoneIcon,
} from "@heroicons/react/24/outline";
import { Avatar, Modal } from "antd";
import React, { Suspense, useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { selectDarkMode, selectLoading } from "../../redux/auth/selectors";
import ATSService from "../../service/ATSService";
import CrudService from "../../service/CrudService";
import UserService from "../../service/UserService";
import HeroCard from "./HeroCard";

export default function UserDashboard({ user, campaigns, heroes }) {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [lastScroll, setLastScroll] = useState(0);
  const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
  const [inviteData, setInviteData] = useState({
    email: "",
    firstName: "",
    lastName: "",
    phone: "",
  });
  const backendLoading = useSelector(selectLoading);

  const handleInviteUser = async (e) => {
    e.preventDefault();
    await UserService.inviteUser(inviteData);
    setIsInviteModalOpen(false);
    setInviteData({
      email: "",
      firstName: "",
      lastName: "",
      phone: "",
    });
  };

  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true);
      try {
        const response = await UserService.searchUsers(user);
        // Limit the array to 2 members
        const limitedUsers = response.data.result.slice(0, 2);
        setUsers(limitedUsers);
        console.log(limitedUsers);
      } catch (error) {
        console.error("Error fetching users:", error);
      } finally {
        setLoading(false);
      }
    };

    if (user._id) {
      fetchUsers();
    }
  }, [user._id]);

  return (
    <>
      <div className="w-full">
        <div className="flex flex-col gap-6 py-3 mx-2">
          <div className="mr-2 flex items-start justify-between gap-5 md:mr-0">
            <div className="flex flex-1 flex-col items-start justify-center gap-1 self-center">
              <Heading
                size="headingxl"
                as="h1"
                className="text-[30px] font-semibold text-[#0f1728] md:text-[28px] sm:text-[26px]"
              >
                Hi&nbsp;{user?.firstName}&nbsp;👋
              </Heading>
              <Heading
                as="h2"
                className="text-[16px] font-normal text-[#344054]"
              >
                Welcome to HireHeroes!
              </Heading>
            </div>
            <div className="flex items-center gap-4">
              <Heading
                size="headings"
                as="h3"
                className="mb-2 self-end text-[14px] font-semibold text-[#344054]"
              >
                Agency?
              </Heading>
              <Link
                to="/dashboard/agency-onboarding"
                className="bg-gradient text-white dark:bg-gray-600 dark:text-gray-400 rounded-md py-2.5 px-4 text-sm leading-6 font-semibold"
              >
                Click Here
              </Link>
            </div>
          </div>
          <div className="mr-2 flex flex-col gap-8 md:mr-0">
            <div className="flex flex-col gap-6">
              {/* Heroes Section */}
              <div className="flex items-start justify-center gap-2.5">
                <div className="flex flex-1 flex-col items-start justify-center gap-2.5 self-center">
                  <Heading
                    size="headinglg"
                    as="h4"
                    className="text-[18px] font-semibold text-[#0f1728]"
                  >
                    Heroes
                  </Heading>
                  <Heading
                    size="texts"
                    as="h5"
                    className="text-[14px] font-normal text-[#344054]"
                  >
                    Your digital recruitment team.
                  </Heading>
                </div>
                <a
                  href="/dashboard/heroes"
                  className="bg-gradient-to-r from-[#0538ff] to-[#6b56f4] bg-clip-text"
                >
                  <Heading
                    size="texts"
                    as="h6"
                    className="text-[14px] font-medium capitalize text-transparent"
                  >
                    View All
                  </Heading>
                </a>
              </div>
              <div className="flex gap-5 ">
                <Suspense fallback={<div>Loading feed...</div>}>
                  {heroes.map((d, index) => (
                    <HeroCard {...d} key={"sectionHeader" + index} />
                  ))}
                </Suspense>
              </div>
            </div>

            <div className="flex gap-8 flex-col md:flex-row">
              {/* Campaign Section */}
              <div
                className={`flex w-full flex-col gap-6 md:self-stretch
                    ${
                      user.role === "team-member"
                        ? "w-full "
                        : "w-full md:w-[66%] "
                    }`}
              >
                <div className="flex flex-wrap items-center justify-between gap-5">
                  <Heading
                    size="heading-lg"
                    as="h6"
                    className="self-end text-[18px] font-semibold capitalize text-[#0f1728]"
                  >
                    Campaigns
                  </Heading>
                  <a
                    href="/dashboard/vacancy"
                    className="bg-gradient-to-r from-[#0538ff] to-[#6b56f4] bg-clip-text"
                  >
                    <Heading
                      size="texts"
                      as="p"
                      className="text-[14px] font-medium capitalize text-transparent"
                    >
                      View All
                    </Heading>
                  </a>
                </div>
                <div
                  className={`
    flex flex-col w-full
    ${
      user.role === "team-member"
        ? "md:flex-row md:flex-wrap md:justify-between md:gap-6"
        : "gap-6 md:self-stretch"
    }
  `}
                >
                  {campaigns.map((data) => (
                    <div
                      key={data._id}
                      className="flex flex-1 flex-col gap-3 rounded-[12px] border border-solid border-[#eaecf0] bg-[#ffffff] p-6 sm:p-5"
                    >
                      <div className="flex flex-col gap-3">
                        <div className="flex">
                          <Heading
                            size="headingmd"
                            as="h6"
                            className="text-[16px] font-semibold text-[#344054]"
                          >
                            {data.name}
                          </Heading>
                        </div>
                        <div className="mr-[22px] flex justify-center gap-3 md:mr-0 flex-col md:flex-row">
                          <Button
                            shape="round"
                            leftIcon={
                              <Img
                                src="/dashboard/greendot.png"
                                alt="User"
                                className="my-1 h-[8px] w-[8px]"
                              />
                            }
                            className="flex-1 gap-1 rounded-md border border-solid border-[#cfd4dc] px-[3px] font-medium md:self-stretch"
                          >
                            Total applicants ({data.numberApplicants})
                          </Button>
                          <Button
                            shape="round"
                            leftIcon={
                              <Img
                                src="/dashboard/yellowdot.png"
                                alt="Close"
                                className="my-1 h-[8px] w-[8px]"
                              />
                            }
                            className="flex-1 gap-1 rounded-md border border-solid border-[#cfd4dc] px-[5px] font-medium md:self-stretch"
                          >
                            Total interviewed ({data.interviewedApplicants})
                          </Button>
                          <div className="flex flex-1 items-center justify-center gap-1 rounded-md border border-solid border-[#cfd4dc] bg-[#ffffff] md:self-stretch">
                            <Img
                              src="/dashboard/purpledot.png"
                              alt="Close Badge"
                              className="h-[8px] w-[8px]"
                            />
                            <Heading
                              size="textxs"
                              as="p"
                              className="text-[12px] font-medium text-[#344054]"
                            >
                              Total shortlisted ({data.shortlistedApplicants})
                            </Heading>
                            <EyeIcon className="h-[16px] w-[16px]" />
                          </div>
                          {/* <Button
                              shape="round"
                              leftIcon={
                                <Img src="/dashboard/bluedot.png" alt="Close" className="my-1 h-[8px] w-[8px]" />
                              }
                              className="min-w-[132px] gap-1 rounded-md border border-solid border-[#cfd4dc] px-[3px] font-medium"
                            >
                              Planned today (13)
                            </Button> */}
                        </div>
                      </div>
                      <div className="hidden md:block flex flex-col items-start gap-1 rounded-lg border border-solid border-[#eaecf0] bg-[#ffffff] px-3 py-2.5">
                        <div className="flex items-center justify-between gap-5 self-stretch">
                          <Heading
                            size="headings"
                            as="p"
                            className="text-[14px] font-semibold text-[#344054]"
                          >
                            Description
                          </Heading>
                          {/* <Img src="images/img_minus_circle.svg" alt="Minus Image" className="h-[16px] w-[16px]" /> */}
                        </div>
                        <Text
                          as="p"
                          className="text-[14px] font-normal text-[#344054]"
                        >
                          {data.description.length > 60
                            ? data.description.slice(0, 61) + "..."
                            : data.description}
                        </Text>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              {/* Team Section */}
              {user.role !== "team-member" && (
                <div className="flex w-full md:w-[34%] flex-col gap-6 ">
                  <div className="flex flex-wrap items-center justify-between gap-5">
                    <Heading
                      size="headinglg"
                      as="h6"
                      className="text-[18px] font-semibold capitalize text-[#0f1728]"
                    >
                      Team
                    </Heading>
                    <a
                      href="#"
                      className="bg-gradient-to-r from-[#0538ff] to-[#6b56f4] bg-clip-text"
                    >
                      <Heading
                        size="texts"
                        as="p"
                        className="text-[14px] font-medium capitalize text-transparent"
                      >
                        View All
                      </Heading>
                    </a>
                  </div>
                  <div className="flex flex-col gap-6 rounded-[12px] border border-solid border-[#eaecf0] bg-[#ffffff] p-6 sm:p-5">
                    {loading ? (
                      <p>Loading team members...</p>
                    ) : (
                      users.map((member, index) => (
                        <div className="flex flex-col gap-6">
                          <div className="flex flex-col gap-4">
                            <div className="flex items-center justify-center">
                              <div className="flex flex-1 items-center gap-3">
                                {member.avatar ? (
                                  <Img
                                    src={member.avatar}
                                    alt="Avatar Image"
                                    className="h-[40px] w-[40px] rounded-[20px] object-cover"
                                  />
                                ) : (
                                  <Avatar className="h-[40px] w-[40px] rounded-[20px] object-cover">
                                    {member.firstName[0]}
                                    {member.lastName[0]}
                                  </Avatar>
                                )}
                                <Heading
                                  size="texts"
                                  as="p"
                                  className="text-[14px] font-medium text-[#344054]"
                                >
                                  {member.firstName} {member.lastName}
                                </Heading>
                              </div>
                              <InformationCircleIcon className="h-[16px] w-[16px]" />
                            </div>
                            <div className="flex flex-col gap-3.5">
                              <div className="flex justify-center">
                                <div className="flex flex-1 items-center gap-1.5">
                                  <EnvelopeIcon className="h-[14px] w-[14px]" />
                                  <Heading
                                    size="textxs"
                                    as="p"
                                    className="text-[12px] font-medium text-[#667084]"
                                  >
                                    Mail
                                  </Heading>
                                </div>
                                <Heading
                                  size="textxs"
                                  as="p"
                                  className="text-[12px] font-medium text-[#0f1728]"
                                >
                                  {member.email}
                                </Heading>
                              </div>
                              <div className="flex justify-center">
                                <div className="flex flex-1 items-center gap-1.5">
                                  <PhoneIcon className="h-[14px] w-[14px]" />
                                  <Heading
                                    size="textxs"
                                    as="p"
                                    className="text-[12px] font-medium text-[#667084]"
                                  >
                                    Phone Number
                                  </Heading>
                                </div>
                                <Heading
                                  size="textxs"
                                  as="p"
                                  className="text-[12px] font-medium uppercase text-[#0f1728]"
                                >
                                  {member.phone}
                                </Heading>
                              </div>
                              <div className="flex justify-center">
                                <div className="flex flex-1 items-center gap-1.5">
                                  <BriefcaseIcon className="h-[14px] w-[14px]" />
                                  <Heading
                                    size="textxs"
                                    as="p"
                                    className="text-[12px] font-medium text-[#667084]"
                                  >
                                    Position
                                  </Heading>
                                </div>
                                <Heading
                                  size="textxs"
                                  as="p"
                                  className="text-[12px] font-medium text-[#0f1728]"
                                >
                                  {member.role}
                                </Heading>
                              </div>
                            </div>
                          </div>
                          {index < users.length - 1 && (
                            <div className="h-px bg-[#eaecf0]" />
                          )}
                        </div>
                      ))
                    )}
                    <div className="flex flex-col gap-6">
                      <div className="h-px bg-[#eaecf0]" />
                      <Button
                        size="lg"
                        variant="gradient"
                        shape="round"
                        color="hireheroes_main_gradient_blue_deep_purple_A200"
                        className="gap-2 self-stretch rounded-lg px-[34px] font-semibold sm:px-5"
                        onClick={() => {
                          setLastScroll(window.scrollY);
                          setIsInviteModalOpen(true);
                        }}
                      >
                        + Invite a Team Member
                      </Button>
                      <Modal
                        open={isInviteModalOpen}
                        onCancel={() => setIsInviteModalOpen(false)}
                        okButtonProps={{ style: { display: "none" } }}
                        cancelButtonProps={{ style: { display: "none" } }}
                        destroyOnClose={true}
                        title="Invite Team Member"
                        afterOpenChange={(e) => {
                          if (!e) window.scrollTo(0, lastScroll);
                        }}
                      >
                        <div className="mb-2 mt-5">
                          <input
                            type="email"
                            className="w-full mt-2 dark:bg-gray-900 rounded-md"
                            placeholder="Email"
                            value={inviteData.email}
                            onChange={(e) =>
                              setInviteData({
                                ...inviteData,
                                email: e.target.value,
                              })
                            }
                          />
                          <input
                            type="text"
                            className="w-full mt-2 dark:bg-gray-900 rounded-md"
                            placeholder="Firstname"
                            value={inviteData.firstName}
                            onChange={(e) =>
                              setInviteData({
                                ...inviteData,
                                firstName: e.target.value,
                              })
                            }
                          />
                          <input
                            type="text"
                            className="w-full mt-2 dark:bg-gray-900 rounded-md"
                            placeholder="Lastname"
                            value={inviteData.lastName}
                            onChange={(e) =>
                              setInviteData({
                                ...inviteData,
                                lastName: e.target.value,
                              })
                            }
                          />
                          <PhoneInput
                            placeholder={"Phone"}
                            inputStyle={{ width: "100%", height: "40px" }}
                            defaultCountry="US"
                            className="w-full mt-2 rounded-md"
                            inputClass="dark:!bg-gray-900"
                            dropdownClass="dark:!text-black"
                            buttonClass="dark:!bg-gray-900"
                            value={inviteData.phone}
                            onChange={(e) =>
                              setInviteData({ ...inviteData, phone: e })
                            }
                          />
                        </div>
                        <div className="w-full justify-end flex mt-2">
                          <Button
                            size="lg"
                            variant="gradient"
                            shape="round"
                            color="hireheroes_main_gradient_blue_deep_purple_A200"
                            className="gap-2 self-stretch rounded-lg px-[34px] font-semibold sm:px-5"
                            onClick={handleInviteUser}
                            loading={backendLoading}
                          >
                            Send Invite
                          </Button>
                        </div>
                      </Modal>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
