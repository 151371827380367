import axios from "axios";
import { getBackendUrl } from "./getBackendUrl";
import { middleField } from "./middlefield";

class PublicService {
  constructor(baseURL) {
    this.baseURL = baseURL;
    this.api = axios.create({
      baseURL,
    });
    middleField(this.api);
  }

  getPartnerConfig() {
    return this.api.post("/getPartnerConfig", { origin: window.location.host });
  }
  signupPartner(data) {
    return this.api.post("/signupPartner", {
      domain: window.location.host,
      ...data,
    });
  }
  getVacancyData(id) {
    return this.api.get(`/getVacancyData?id=${id}`);
  }

  getVacancySubmissionData(id) {
    return this.api.get(`/getVacancySubmissionData?id=${id}`);
  }

  prompt(data) {
    return this.api.post(`/prompt`, data);
  }
  createVacancySubmission(data) {
    return this.api.post("/createVacancySubmission", {
      domain: window.location.host,
      ...data,
      baseURL: window.location.origin,
    });
  }
  clickFunnel(data) {
    return this.api.post("/clickFunnel", {
      domain: window.location.host,
      ...data,
    });
  }
  ctaClick(data) {
    return this.api.put("/ctaClick", {
      domain: window.location.host,
      ...data,
    });
  }

  getSurvey(token, checkExpired) {
    return this.api.get(
      `/getSurvey?token=${token}&checkExpired=${checkExpired}`
    );
  }
  getRecruiterData(id, candidateId) {
    return this.api.get(
      `/getRecruiterData?id=${id}&candidateId=${candidateId}`
    );
  }
  linkedinPeopleSearch(keywords, count) {
    return this.api.post(`/linkedinPeopleSearch`, { keywords, count });
  }
  getPDFText(url) {
    return this.api.post(`/getPDFText`, { url });
  }
  queryJobsOfRecruiter({ page = 1, limit = 10, recruiterId, text }) {
    return this.api.post(`/queryJobsOfRecruiter`, {
      page,
      limit,
      recruiterId,
      text,
    });
  }
  queryJobs({ page = 1, limit = 10, text }) {
    return this.api.post(`/queryJobs`, {
      page,
      limit,
      text,
    });
  }
  generateCandidateToken(click_id) {
    return this.api.post(`/generateCandidateToken`, { click_id });
  }
  remindCV(click_id) {
    return this.api.post(`/remindCV`, { click_id });
  }
  getDocumentData(k) {
    return this.api.get(`/getDocumentData?token=${k}`);
  }
  publicCandidateUpdate(data) {
    return this.api.post(`/publicCandidateUpdate`, data);
  }
  getDocumentDataHiringManager(k) {
    return this.api.get(`/getDocumentDataHiringManager?token=${k}`);
  }
  publicHiringManagerUpdate(data) {
    return this.api.post(`/publicHiringManagerUpdate`, data);
  }
  applyEarlyAdapter(data) {
    return this.api.post(`/applyEarlyAdapter`, data);
  }
  scheduleInterview({ token, start_time, end_time }) {
    return this.api.post(`/scheduleInterview`, {
      token,
      baseURL: window.location.origin,
      start_time,
      end_time,
    });
  }
  submitSurvey(data) {
    return this.api.post("/submitSurvey", {
      domain: window.location.host,
      ...data,
    });
  }
  startCall(token) {
    return this.api.get(`/startCall?token=${token}`);
  }
  getDemo(uuid) {
    if (!uuid) return;
    return this.api.get(`/getDemo?uuid=${uuid}`);
  }
  addVideoInterview(candidateId, alternativeUrl) {
    return this.api.post(`/addVideoInterview`, { candidateId, alternativeUrl });
  }

  getVacancyCallAverage(id) {
    return this.api.get(`/getVacancyCallAverage?id=${id}`);
  }
  getVacancyCallAverageEmotional(id) {
    return this.api.get(`/getVacancyCallAverageEmotional?id=${id}`);
  }
  getFiles(user_id) {
    return this.api.get(`/getFiles?user_id=${user_id}`);
  }
  getVacancyCallAverageCultural(id) {
    return this.api.get(`/getVacancyCallAverageCultural?id=${id}`);
  }
}

export default new PublicService(`${getBackendUrl()}/public`);
