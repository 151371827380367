import Vapi from "@vapi-ai/web";
import { Alert, Button, Skeleton } from "antd";
import jwtDecode from "jwt-decode";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { AiOutlineFall } from "react-icons/ai";
import { BsStars } from "react-icons/bs";
import { useNavigate, useSearchParams } from "react-router-dom";
import CronService from "../service/CronService";
import PublicService from "../service/PublicService";
import MyRadarChart from "./Dashboard/vacancies/DetailsModal/components/RadarChart";
import SkillsTabs from "./Dashboard/vacancies/DetailsModal/components/SkillsTabs";
import LoadingResults from "./LoadingResult";

const MAX_STRING_LENGTH = 20;
const FinishInterview = () => {
  let [searchParams] = useSearchParams();
  const [vacancyData, setVacancyData] = useState({});
  const [loading, setLoading] = useState(false);
  const [candidate, setCandidate] = useState({});
  const [lastCall, setLastCall] = useState({});
  const [vacancy, setVacancy] = useState({});
  const [averageSkills, setAverageSkills] = useState(null);
  const [averageCultural, setAverageCultural] = useState(null);
  const [averageEmotional, setAverageEmotional] = useState(null);
  const [dataReady, setDataReady] = useState(false);
  const [error, setError] = useState(null);
  const maxRetries = 10;
  let retryCount = 0;

  const navigate = useNavigate();

  useEffect(() => {
    const token = searchParams.get("token");
    const training = searchParams.get("training");
    const tokenData = jwtDecode(token);
    setVacancyData({ candidateId: tokenData.candidateId, training: training });
    if (training) {
      CronService.vacancyChecker();
    }
  }, []);

  const [activeItem, setActiveItem] = useState("Skills");

  const [candidateData, setCandidateData] = useState({
    skills: [],
    explanation: "",
    score: 0,
    labels: [],
    dataPoints: [],
  });

  const [culturalData, setCulturalData] = useState({
    skills: [],
    explanation: "",
    labels: [],
    dataPoints: [],
  });

  const [emotionalData, setEmotionalData] = useState({
    skills: [],
    explanation: "",
    labels: [],
    dataPoints: [],
  });

  useEffect(() => {
    if (!vacancyData.training || !vacancyData.candidateId) return;
    setLoading(true);
    const fetchData = () => {
      PublicService.getVacancySubmissionData(vacancyData.candidateId)
        .then(({ data }) => {
          if (data) {
            setCandidate(data);
            const lastPhoneCall = data.phoneCalls[data.phoneCalls.length - 1];
            setLastCall(lastPhoneCall);

            if (!lastPhoneCall?.aiValidation) {
              setTimeout(() => {
                retryCount++;
                if (retryCount < maxRetries) {
                  fetchData();
                } else {
                  setError("Couldn´t load analysis data");
                  setLoading(false);
                }
              }, 20000);
            } else {
              Promise.all([
                PublicService.getVacancyCallAverage(data.VacancyId),
                PublicService.getVacancyCallAverageCultural(data.VacancyId),
                PublicService.getVacancyCallAverageEmotional(data.VacancyId),
                PublicService.getVacancyData(data.VacancyId),
              ]).then(([skillsRes, culturalRes, emotionalRes, vacancyRes]) => {
                setAverageSkills(skillsRes.data);
                setAverageCultural(culturalRes.data);
                setAverageEmotional(emotionalRes.data);
                setVacancy(vacancyRes.data);
                setLoading(false);
              });
            }
          } else {
            setCandidate(false);
            setLoading(false);
          }
        })
        .catch(() => {
          setError("Ocurrió un error al obtener los datos.");
          setLoading(false);
        });
    };

    // fetchData();
  }, [vacancyData.candidateId]);

  useEffect(() => {
    if (!vacancyData.training) return;
    if (!averageSkills || !averageCultural || !averageEmotional) return;
    setDataReady(true);
  }, [averageSkills, averageCultural, averageEmotional]);

  useEffect(() => {
    if (!vacancyData.training) return;
    setCandidateData({
      skills: [],
      explanation: "",
      score: 0,
      labels: [],
      dataPoints: [],
    });
    if (!lastCall?.aiValidation) return;

    const skills = Object.keys(lastCall.aiValidation)
      .filter(
        (key) =>
          key !== "score" && typeof lastCall.aiValidation[key] === "number"
      )
      .map((key) => ({
        skill:
          key.length > MAX_STRING_LENGTH
            ? key.substring(0, MAX_STRING_LENGTH) + "..."
            : key,
        value: lastCall.aiValidation[key],
        fullSkill: key,
      }));
    console.log("avskills", averageSkills);
    setCandidateData((prevData) => ({
      ...prevData,
      skills: skills,
      explanation: lastCall.aiValidation.explanation ?? "",
      score: lastCall.aiValidation.score ?? 0,
      labels: skills.map((s) => s.skill),
      dataPoints: skills.map((s) => s.value),
      averagePoints: averageSkills
        ? Object.keys(averageSkills).map((s) => averageSkills[s])
        : [],
    }));
  }, [lastCall]);

  useEffect(() => {
    if (!vacancyData.training) return;
    setEmotionalData({
      skills: [],
      explanation: "",
      labels: [],
      dataPoints: [],
    });
    if (!lastCall?.aiValidationEmotional) return;

    const skills = Object.keys(lastCall.aiValidationEmotional)
      .filter(
        (key) =>
          key !== "score" &&
          typeof lastCall.aiValidationEmotional[key] === "number"
      )
      .map((key) => ({
        skill:
          key.length > MAX_STRING_LENGTH
            ? key.substring(0, MAX_STRING_LENGTH) + "..."
            : key,
        value: lastCall.aiValidationEmotional[key],
        fullSkill: key,
      }));

    setEmotionalData((prevData) => ({
      ...prevData,
      skills: skills,
      explanation: lastCall.aiValidationEmotional.explanation ?? "",
      labels: skills.map((s) => s.skill),
      dataPoints: skills.map((s) => s.value),
      averagePoints: averageEmotional
        ? Object.keys(averageEmotional).map((s) => averageEmotional[s])
        : [],
    }));
  }, [lastCall]);

  useEffect(() => {
    if (!vacancyData.training) return;
    setCulturalData({
      skills: [],
      explanation: "",
      labels: [],
      dataPoints: [],
    });
    if (!lastCall?.aiValidationCultural) return;

    const skills = Object.keys(lastCall.aiValidationCultural)
      .filter(
        (key) =>
          key !== "score" &&
          typeof lastCall.aiValidationCultural[key] === "number"
      )
      .map((key) => ({
        skill:
          key.length > MAX_STRING_LENGTH
            ? key.substring(0, MAX_STRING_LENGTH) + "..."
            : key,
        value: lastCall.aiValidationCultural[key],
        fullSkill: key,
      }));

    setCulturalData((prevData) => ({
      ...prevData,
      skills: skills,
      explanation: lastCall.aiValidationCultural.explanation ?? "",
      labels: skills.map((s) => s.skill),
      dataPoints: skills.map((s) => s.value),
      averagePoints: averageCultural
        ? Object.keys(averageCultural).map((s) => averageCultural[s])
        : [],
    }));
  }, [lastCall]);

  const getChartData = (active) => {
    let data = "";
    if (active == "Skills") {
      data = candidateData;
    }
    if (active == "Emotional") {
      data = emotionalData;
    }
    if (active == "Cultural") {
      data = culturalData;
    }
    console.log("data", data);
    let chartData = data.labels.map((label, index) => ({
      subject: label,
      candidate: data.dataPoints[index],
      average: data.averagePoints[index],
    }));

    return chartData;
  };

  const handleRedo = () => {
    setVacancyData({});
    navigate(
      `/interview-call?token=${searchParams.get("token")}&training=true`
    );
  };

  if (!vacancyData) return <Skeleton active />;
  // if (!vacancyData.training) {
  return (
    <div className="h-screen flex flex-col items-center justify-center bg-gray-100">
      <h1 className="text-4xl font-bold mb-4">Thanks!</h1>
      <p className="text-xl">The interview has finished</p>
    </div>
  );
  // }

  //   return (
  //     <div className="h-screen flex flex-col items-center justify-center bg-gray-100">
  //         {loading && !dataReady ?<LoadingResults loading={loading}/> :
  //         <div className="h-screen w-screen overflow-hidden bg-gray-50 flex flex-col">
  //         <header className="bg-gradient text-transparent bg-clip-text p-4 text-center">
  //           <h1 className="text-3xl font-bold mb-2">TRAINING INTERVIEW ANALYSIS RESULTS</h1>
  //         </header>

  //         <div className="flex-grow overflow-auto p-4">
  //           <div className="container mx-auto flex flex-col lg:flex-row gap-4 h-full">
  //             <div className="w-full lg:w-1/2 bg-white rounded-lg shadow-md p-4 overflow-auto">
  //               <h2 className="text-2xl font-bold text-gray-800 mb-4 pb-2 border-b">OVERVIEW</h2>
  //               <div className="space-y-4">
  //                 <section>
  //                   <h3 className="text-xl font-bold text-gray-700 mb-2">SUMMARY</h3>
  //                   <div className="space-y-4">
  //                     <div>
  //                       <div className="flex items-center gap-2 mb-2">
  //                         <BsStars className="text-green-500 text-xl" />
  //                         <span className="text-gray-600 font-semibold text-sm">CANDIDATE HIGHLIGHT</span>
  //                       </div>
  //                       <ul className="list-disc list-inside space-y-1 pl-4">
  //                         {lastCall?.aiValidation?.highlight?.map((ele, index) => (
  //                           <li key={index} className="text-gray-700 capitalize text-sm">{ele}</li>
  //                         ))}
  //                       </ul>
  //                     </div>
  //                     <div>
  //                       <div className="flex items-center gap-2 mb-2">
  //                         <AiOutlineFall className="text-red-500 text-xl" />
  //                         <span className="text-gray-600 font-semibold text-sm">CANDIDATE LOWLIGHT</span>
  //                       </div>
  //                       <ul className="list-disc list-inside space-y-1 pl-4">
  //                         {lastCall?.aiValidation?.lowlight?.map((ele, index) => (
  //                           <li key={index} className="text-gray-700 capitalize text-sm">{ele}</li>
  //                         ))}
  //                       </ul>
  //                     </div>
  //                   </div>
  //                 </section>
  //                 <section>
  //                   <h3 className="text-xl font-bold text-gray-700 mb-2">TRANSCRIPT</h3>
  //                   <div
  //                     className="text-sm text-gray-600 space-y-2 max-h-40 overflow-y-auto pr-2"
  //                     dangerouslySetInnerHTML={{
  //                       __html: lastCall?.transcript?.replace(/\n/g, "<br>").replace(/(User:|AI:)/g, "<strong class='text-gray-800'>$1</strong>") ?? "",
  //                     }}
  //                   />
  //                 </section>
  //               </div>
  //             </div>
  //             <div className="w-full lg:w-1/2 bg-white rounded-lg shadow-md p-4 overflow-auto">
  //               <h2 className="text-2xl font-bold text-gray-800 mb-4 pb-2 border-b">SCORE CARD</h2>
  //               <div className="grid md:grid-cols-2 gap-4">
  //                 <section>
  //                   <h3 className="text-xl font-bold text-gray-700 mb-2">APPLICANT ANALYSES</h3>
  //                   <div className="bg-gray-100 p-2 rounded-lg flex justify-center items-center">
  //                     <MyRadarChart data={getChartData(activeItem)} />
  //                   </div>
  //                 </section>
  //                 <section>
  //                   <h3 className="text-xl font-bold text-gray-700 mb-2">SKILLS EVALUATION</h3>
  //                   <SkillsTabs data={getChartData(activeItem)} lastCall={lastCall} activeItem={activeItem} setActiveItem={setActiveItem} />
  //                 </section>
  //               </div>
  //             </div>
  //           </div>
  //         </div>

  //         <footer className="bg-gray-100 p-4 flex justify-center">
  //           <Button type="primary" onClick={()=>handleRedo()} >
  //             Redo Interview
  //           </Button>
  //         </footer>
  //       </div>
  // }
  //     </div>
  //   );
};

export default FinishInterview;
