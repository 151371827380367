import { UserAddOutlined, WechatWorkOutlined } from "@ant-design/icons";
import {
  Alert,
  Divider,
  Input,
  Modal,
  Popconfirm,
  Select,
  Skeleton,
  Space,
  Spin,
  Table,
  Tabs,
  Tag,
  Timeline,
  Button
} from "antd";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { AiOutlineFall, AiOutlineMail, AiOutlineSend } from "react-icons/ai";
import { BiCalendarAlt, BiMapPin, BiTimeFive } from "react-icons/bi";
import { BsStars, BsTelephone } from "react-icons/bs";
import { FaAngleDown, FaExternalLinkAlt } from "react-icons/fa";
import { MdAttachFile, MdDelete } from "react-icons/md";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import MultiStepComponent from "../../../../components/MultiStepComponent";
import {
  STANDARD_MOMENT_FORMAT,
  eeoForm,
  personalDataCollection,
} from "../../../../data/constants";
import { getPartner, selectLoading } from "../../../../redux/auth/selectors";
import ATSService from "../../../../service/ATSService";
import CrudService from "../../../../service/CrudService";
import PublicService from "../../../../service/PublicService";
import UploadService from "../../../../service/UploadService";
import CVTemplate from "../../../CandidateCV/CVTemplate";
import CallStatsComponent from "./CallStatsComponent";
import CandidateAvatarInfo from "./components/CandidateAvatarInfo";
import DetailHeader from "./components/DetailHeader";
import OverviewTabs from "./components/OverviewTabs";
import StageTabs from "./components/StageTabs";
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ChatBubbleLeftRightIcon,
  ChevronDoubleRightIcon,
  EnvelopeIcon,
  PlusCircleIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import Overview from "./components/Overview";
import ScoreCard from "./components/ScoreCard";
import CandidateNote from "../CandidateNote";
import CandidateRejectBox from "../../Message/CandidateRejectBox";
import MoveCandidate from "./components/MoveCandidate";



const { TabPane } = Tabs;

function convertLinksToHTML(text) {
  var urlWithTitleRegex = /\{([^}]+)\}\[([^\]]+)\]/g;

  var replacedText = text.replace(
    urlWithTitleRegex,
    function (match, url, title) {
      // Use the title as the link text
      return '<a href="' + url + '" target="_blank">' + title + "</a>";
    }
  );

  var urlRegex =
    /(?<!href="|href='|">)(https?:\/\/[^\s\n<]+)(?=[,.!?;]*($|\s|<))/g;

  replacedText = replacedText.replace(urlRegex, function (url) {
    return '<a href="' + url + '" target="_blank">' + url + "</a>";
  });

  return replacedText;
}

const getDocumentStatus = (document) => {
  if (!document?.candidateSignature) return "Pending";
  if (
    !document?.body?.includes?.(
      "@[Hiring Authority Signature](hiringAuthoritySignature)"
    )
  )
    return "Completed";
  if (document?.hiringManagerSignature) return "Completed";
  return "Candidate Signed";
};
const getDocumentColor = (document) => {
  if (!document?.candidateSignature) return "orange";
  if (
    !document?.body?.includes?.(
      "@[Hiring Authority Signature](hiringAuthoritySignature)"
    )
  )
    return "green";
  if (document?.hiringManagerSignature) return "green";
  return "blue";
};

function downloadAndShowPDF(pdfUrl, setLocalPdfUrl) {
  fetch(pdfUrl)
    .then((response) => response.blob())
    .then((blob) => {
      const localUrl = URL.createObjectURL(blob);
      setLocalPdfUrl(localUrl); // Assuming you have a state setter for the local PDF URL
    })
    .catch((error) => console.error("Error downloading the PDF:", error));
}

const LOG_LOAD_PAGINATION = 25;

const DetailsModal = ({ candidateId, vacancyInfo, onEmail, reloadStages, setDetailsModal }) => {
  const partner = useSelector(getPartner);

  const [AILoading, setAILoading] = useState(false);
  const [candidate, setCandidate] = useState(null);
  const [vacancyData, setVacancyData] = useState(null);
  const [candidateTimeline, setCandidateTimeline] = useState([]);
  const [candidateTimelineTotal, setCandidateTimelineTotal] = useState(0);
  const [currentLogsPage, setCurrentLogsPage] = useState(1);
  const [CV, setCV] = useState(null);
  const [interviewScripts, setInterviewScripts] = useState([]);
  const [candidateDocuments, setCandidateDocuments] = useState([]);
  const [surveySubmission, setSurveySubmission] = useState(null);
  const [alternativePDFLocalURL, setAlternativePDFLocalURL] = useState(null);
  const [AIInstructions, setAIInstructions] = useState("");
  // const [activeTab, setActiveTab] = useState("phoneCalls");
  const [activeTab, setActiveTab] = useState("overview");
  const [callDetailModal, setCallDetailModal] = useState(null);
  const [averageSkills, setAverageSkills] = useState(null);
  const [averageCultural, setAverageCultural] = useState(null);
  const [averageEmotional, setAverageEmotional] = useState(null);
  const socket = useRef(null);
  const socketPing = useRef(null);
  const loading = useSelector(selectLoading);
  const [stages, setStages] = useState([]);
  const fileInput = useRef(null);
  const [lastCall, setLastCall] = useState({});
  const [rejectCandidate, setRejectCandidate] = useState(null)
  const [moveCandidate, setMoveCandidate] = useState(null)

  function getNextObject(array, id) {
    console.log(array)
    console.log(id)
    const index = array.findIndex(obj => obj.id === id); 
    if (index === -1 || array.length === 0) {
      return null; 
    }

    if (index === array.length - 1) {
      return array[0].id;
    }
  
    return array[index + 1].id;
  }
  function getPrevObject(array, id) {
    const index = array.findIndex(obj => obj.id === id); 
    if (index === -1 || array.length === 0) {
      return null; 
    }

    if (index === 0) {
      return array[array.length - 1].id;
    }
  
    return array[index - 1].id;
  }


  useEffect(() => {
    if (!candidateId) return;
    
    fileInput.current = document.getElementById("fileInput34");
    if (fileInput.current)
      fileInput.current.addEventListener("change", async () => {
        const selectedFile = fileInput.current.files[0]; // Get the selected file
        if (selectedFile) {
          const result = await UploadService.upload(selectedFile, 5);

          await ATSService.submitCV(candidateId, result.data.secure_url);

          CrudService.search("CV", 1, 1, {
            filters: { candidate: candidateId, submitted: true },
          }).then(({ data }) => {
            if (data.items?.[0]) setCV(data.items?.[0]);
            else setCV(false);
          });
          fileInput.current.files[0] = "";
        } else {
          console.log("No file selected.");
        }
      });
  }, [candidateId]);

  const handleTabChange = (key) => {
    setActiveTab(key);
  };

  useEffect(() => {
    if (CV?.alternativeUrl)
      downloadAndShowPDF(alternativePDFLocalURL, setAlternativePDFLocalURL);
  }, [CV]);

  useEffect(() => {
    if (!candidateId) return;

    CrudService.search("VacancySubmission", 1, 1, {
      filters: { _id: candidateId },
      populate: {
        path: "emailSent.user_id",
        select: "firstName lastName email",
      },
      populate2: {
        path: "smsSent.user_id",
        select: "firstName lastName email",
      },
    }).then(({ data }) => {
      if (data.items?.[0]) {
        setCandidate(data.items[0]);
        setLastCall(
          data.items[0]?.phoneCalls?.sort(
            (a, b) => new Date(b.end_at) - new Date(a.end_at)
          )[data.items[0].phoneCalls.length - 1]
        );
        // console.log(data.items[0]);
        // onGetCandidateInfo(data?.items[0]);
        ATSService.getVacancyCallAverage(data.items[0].VacancyId).then(
          (res) => {
            setAverageSkills(res.data);
          }
        );
        ATSService.getVacancyCallAverageCultural(data.items[0].VacancyId).then(
          (res) => {
            setAverageEmotional(res.data);
          }
        );
        ATSService.getVacancyCallAverageEmotional(data.items[0].VacancyId).then(
          (res) => {
            setAverageCultural(res.data);
          }
        );
        CrudService.search("Vacancy", 1, 1, {
          filters: { _id: data.items?.[0].VacancyId },
        }).then(({ data }) => {
      
          if (data.items?.[0]) setVacancyData(data.items?.[0]);
        });
      } else setCandidate(false);
    });

    CrudService.search("CV", 1, 1, {
      filters: { candidate: candidateId, submitted: true },
    }).then(({ data }) => {
      if (data.items?.[0]) setCV(data.items?.[0]);
      else setCV(false);
    });

    CrudService.search("SurveySubmission", 1, 1, {
      filters: { VacancySubmissionId: candidateId },
    }).then(({ data }) => {
      if (data.items?.[0]) setSurveySubmission(data.items?.[0]);
      else setSurveySubmission(false);
    });

    CrudService.search("InterviewScript", 1000, 1, {
      filters: { candidate: candidateId },
    }).then(({ data }) => {
      setInterviewScripts(data.items);
    });

    CrudService.search("CandidateLogs", LOG_LOAD_PAGINATION, 1, {
      filters: { candidate: candidateId },
      sort: { createdAt: -1 },
      populate: "user_id",
    }).then(({ data }) => {
      setCandidateTimeline(data.items);
      setCandidateTimelineTotal(data.total);
    });

    ATSService.getCandidateDocuments(candidateId).then(({ data }) =>
      setCandidateDocuments(data)
    );
  }, [candidateId]);

  // useEffect(()=>{
  //   let _sortedStages=sortingStages(candidate.formData.email);
  //   setStages(_sortedStages);
  // },[candidateId]);

  const onLogsLoadMore = () => {
    CrudService.search(
      "CandidateLogs",
      LOG_LOAD_PAGINATION,
      currentLogsPage + 1,
      {
        filters: { candidate: candidateId },
        sort: { createdAt: -1 },
        populate: "user_id",
      }
    ).then(({ data }) => {
      setCandidateTimeline((a) => [...a, ...data.items]);
      setCandidateTimelineTotal(data.total);
    });
    setCurrentLogsPage((a) => a + 1);
  };

  const columnsPhoneCall = [
    {
      title: "Timestamp",
      key: "call.createdAt",
      dataIndex: "call.createdAt",
      render: (_, record) => (
        <>{moment(record?.call?.createdAt).format(STANDARD_MOMENT_FORMAT)}</>
      ),
    },
    {
      title: "Recording",
      key: "recordingUrl",
      dataIndex: "recordingUrl",
      render: (url) => <>{url && <audio src={url} controls />}</>,
    },
    {
      title: "Details",
      key: "details",
      dataIndex: "details",
      render: (_, record) => (
        <Button type="primary" onClick={() => setCallDetailModal(record)}>
          Details
        </Button>
      ),
    },
  ];

  const requestInterview = () => {
    if (AILoading) return;
    if (candidate === null) return;
    if (CV === null) return;
    if (surveySubmission === null) return;

    socket.current = new WebSocket(
      `wss://booklified-chat-socket.herokuapp.com`
    );

    socket.current.addEventListener("open", async () => {
      socketPing.current = setInterval(
        () => socket.current.send(JSON.stringify({ id: "PING" })),
        30000
      );

      const content = `Hello, I am in the process of interviewing a candidate and require your expertise in recruitment to enhance the interview structure and content. Please provide me with the following:

A breakdown of the interview stages, including:
- The name of each step.
- A concise description for each step.
- The recommended duration for each step.
- A curated list of high-quality interview questions that are:
-- Tailored specifically to the job position in question.
-- Based on the candidate's application details.
-- Reflective of the candidate's CV and experiences.
-- Explanation of the intent behind each question and the anticipated response from a suitable candidate.

Please ensure that the questions are designed to elicit responses that demonstrate the candidate's suitability for the role, their technical competencies, problem-solving abilities, and cultural fit. The goal is to create an interview experience that is both thorough and insightful, allowing for an accurate assessment of the candidate's potential for the position.

Here is the candidate data:
${JSON.stringify(candidate)}

Here is the CV data:
${
  CV?.alternativeUrl
    ? (await PublicService.getPDFText(CV.alternativeUrl))?.data?.text?.slice?.(
        0,
        600
      )
    : JSON.stringify(CV)
}


${AIInstructions}

It is imperative that your reply contains nothing beyond the required interview script. Don't start your response by answering to me. Instead get right into the script. Do not add anything else into your answer.
`;

      setAILoading(true);
      socket.current.send(
        JSON.stringify({
          id: "OPEN_AI_PROMPT",
          payload: {
            content,
            model: "gpt-3.5-turbo-16k",
            partner: partner._id,
          },
        })
      );
    });

    socket.current.addEventListener("message", async (event) => {
      const message = JSON.parse(event.data);
      const response = message.payload?.response;

      await CrudService.create("InterviewScript", {
        message: response,
        candidate: candidateId,
      });
      await CrudService.search("InterviewScript", 1000, 1, {
        filters: { candidate: candidateId },
      }).then(({ data }) => {
        setInterviewScripts(data.items);
      });
      setAILoading(false);
      if (socketPing.current) clearInterval(socketPing.current);
    });
  };

  if (candidate === null) return <Skeleton active />;
  if (CV === null) return <Skeleton active />;
  if (surveySubmission === null) return <Skeleton active />;

  const dateConversion = (_date) => {
    if (_date == undefined || _date == null) return;
    let date = new Date(_date); // Note: JavaScript months are zero-based, so February is represented by 1
    let formattedDate = new Intl.DateTimeFormat("en-US", {
      month: "short",
      day: "2-digit",
      year: "numeric",
    }).format(date);
    return formattedDate;
  };

  const sortingStages = (emailToFind) => {
    let updatedStages = vacancyInfo.stages.map((stage) => ({
      ...stage,
      color: "",
      atStage: "",
    }));
    let foundIndex = -1;

    vacancyInfo.stages.forEach((stage, index) => {
      let stageColor = stage.color;

      // Check if the candidate exists in this stage
      const candidate = stage.cards.find((card) => card.email === emailToFind);
      if (candidate) {
        foundIndex = index; // Update the index where the candidate is found
        // Set the color for the current stage and previous stages where the candidate is found to purple
        for (let i = foundIndex; i >= 0; i--) {
          if (updatedStages[i]) {
            updatedStages[i].color = updatedStages[i]?.title;
          }
        }

        // Set the color for the next stages to black
        for (let i = foundIndex + 1; i < updatedStages.length; i++) {
          updatedStages[i].color = "#eeeeee";
        }

        // Set atStage property for the current stage
        updatedStages[index].atStage = stage.title;
      }
    });
    return updatedStages;
  };
  let _stages = sortingStages(candidate.formData.email);

  const getLastStage = () => {
    let __stages = [];
    _stages.forEach((ele) => {
      if (ele.atStage !== "") __stages.push(ele);
    });
    return __stages[__stages.length - 1]?.atStage;
  };

  return (
    <>
      {/* <div className="dark:bg-gray-400"> */}

      {/* <h2 className="text-lg font-bold mb-1">Candidate Profile View</h2>
      <Divider /> */}
      <DetailHeader
        candidateInfo={candidate}
        _stages={_stages}
        onEmail={onEmail}
        reloadStages={reloadStages}
      />
      <div className="flex gap-2 mt-2 mb-5 items-center">
        <EnvelopeIcon width={20} />
        <h3 className="text-md font-semibold  whitespace-nowrap">
          {candidate?.formData?.email}
        </h3>

        {/* <Select
          mode="tags"
          className="w-full mb-1"
          size="small"
          placeholder="Tags"
          value={candidate?.tags}
          onChange={async (e) => {
            setCandidate((x) => ({
              ...x,
              tags: e,
            }));
            await CrudService.update("VacancySubmission", candidate._id, {
              tags: e,
              searchIndex: `${candidate?.searchIndex ?? ""} ${e}`,
            });
          }}
        /> */}
      </div>
      {/* </div> */}

      {candidate?.rejected && (
        <Alert
          type="error"
          message={`This candidate has been marked for rejection. Provided reason: ${
            candidate?.rejectionReason ?? ""
          }`}
        />
      )}

      <Tabs activeKey={activeTab} onChange={handleTabChange}>
        <Tabs.TabPane key="overview" tab={"Overview"}>
          <div className="flex flex-col gap-5 ">
            <Overview lastCall={lastCall} currentStage={getLastStage()}/>
            
            {/* <OverviewTabs classObj={"gap-5"}>
              <h4 style={{ fontSize: "15px", fontWeight: "400" }}>
                Applied for:
              </h4>
              <h2 style={{ fontSize: "20px", fontWeight: "600" }}>
                {vacancyInfo?.name}
              </h2>
              <h4 style={{ fontSize: "15px", fontWeight: "400" }}>Test:</h4>
              <div className="h-[5vh] w-[100%] flex gap-2">
                {_stages.map((_stageData, index) => {
                  return (
                    <StageTabs
                      index={index}
                      stageData={_stageData}
                      classObj={{
                        height: "h-[3vh]",
                        width: "w-[120px]",
                        rounded: "rounded-[5px]",
                      }}
                    />
                  );
                })}
              </div>
            </OverviewTabs> */}
            {/* <div className="h-[20vh] w-[100%] border border-black rounded-lg flex flex-col gap-5 items-start p-2">
            
            </div> */}
            {/* <OverviewTabs classObj={"gap-5 justify-center"}>
              <div
                style={{ fontSize: "16px" }}
                className="flex gap-2 justify-start items-center"
              >
                <AiOutlineMail /> <span>{candidate?.formData?.email}</span>
              </div>
              <div
                style={{ fontSize: "16px" }}
                className="flex gap-2 justify-start items-center"
              >
                <BsTelephone /> <span>{candidate?.formData?.phone}</span>
              </div>
              <div
                style={{ fontSize: "16px" }}
                className="flex gap-2 justify-start items-center"
              >
                <BiMapPin /> <span>London,Uk</span>
              </div>
            </OverviewTabs> */}

            {/* <OverviewTabs classObj={"gap-5 justify-center overflow-y-auto"}>
              <div className=" shadow sm:rounded-l">
                <div className="">
                  <h3 className="text-lg leading-6 font-medium text-gray-900">
                    Interview Notes
                  </h3>
                </div>
                <div className="border-t border-gray-200">
                  <dl>
                    <div className="">
                      <dt className="text-sm font-medium text-gray-500">
                        Score
                      </dt>
                      <CallStatsComponent
                        callData={lastCall}
                        averageSkills={averageSkills}
                      />
                    </div>
                  </dl>
                </div>
              </div>
            </OverviewTabs> */}

            {/* <OverviewTabs classObj={"gap-5 justify-center overflow-y-auto"}>
              <div className="h-[100%] w-[100%] flex flex-col gap-2 p-2">
                <div className="flex gap-2 items-center">
                  {" "}
                  <BsStars
                    color="#FBB623"
                    fontSize={"25px"}
                    style={{ fontWeight: "bolder" }}
                  />{" "}
                  <span className="text-gray-500">Candidate Highligth</span>
                </div>
                <ol>
                  {lastCall?.aiValidation?.highlight?.map?.((ele, index) => (
                    <li className="flex gap-2 justify-start items-center font-bold capitalize">
                      <div
                        style={{
                          height: "5px",
                          width: "5px",
                          background: "black",
                          borderRadius: "50%",
                        }}
                      ></div>{" "}
                      {ele}
                    </li>
                  ))}
                </ol>
                <div className="flex gap-2 items-center">
                  {" "}
                  <AiOutlineFall
                    color="#F46652"
                    fontSize={"30px"}
                    style={{ fontWeight: "bolder" }}
                  />{" "}
                  <span className="text-gray-500">Cadidate Lowlight</span>
                </div>
                <ol>
                  {lastCall?.aiValidation?.lowlight?.map?.((ele, index) => (
                    <li className="flex gap-2 justify-start items-center font-bold capitalize">
                      <div
                        style={{
                          height: "5px",
                          width: "5px",
                          background: "black",
                          borderRadius: "50%",
                        }}
                      ></div>{" "}
                      {ele}
                    </li>
                  ))}
                </ol>
                <h1 className="text-gray-500">Candidate Skills</h1>
                <div className="flex flex-wrap gap-3">
                  {lastCall?.aiValidation?.skills?.map?.((ele) => (
                    <div className="h-[30px] w-min-[100px] flex justify-center items-center border capitalize p-2 border-black rounded-lg bg-gray-200 font-bold ">
                      {ele}
                    </div>
                  ))}
                </div>
              </div>
            </OverviewTabs> */}

            {/* <OverviewTabs classObj={"gap-2"}>
              <span style={{ fontSize: "15px" }}>Schedule</span>
              <div className="h-[80%] w-[95%] border border-l-[10px] border-r-[1px] border-t-[1px] border-b-[1px] border-l-purple-600 border-black rounded-lg">
                <div className="h-[100%] w-[100%] p-3 flex flex-col items-start gap-2">
                  <span style={{ fontWeight: "600", fontSize: "15px" }}>
                    Interview with{" "}
                    {`${candidate.formData.firstname} ${candidate.formData.lastname}`}
                  </span>
                  <div
                    style={{ fontSize: "16px" }}
                    className="flex gap-2 justify-start items-center"
                  >
                    <BiCalendarAlt />{" "}
                    <span>
                      {candidate?.interviewMeetingTimestamp !== undefined &&
                      candidate?.interviewMeetingTimestamp !== null &&
                      candidate?.interviewMeetingTimestamp !== ""
                        ? dateConversion(candidate?.interviewMeetingTimestamp)
                        : "N/A"}
                    </span>
                  </div>
                  <div
                    style={{ fontSize: "16px" }}
                    className="flex gap-2 justify-start items-center"
                  >
                    <BiTimeFive />{" "}
                    <span>
                      {candidate?.interviewMeetingTimestamp !== undefined &&
                      candidate?.interviewMeetingTimestamp !== null &&
                      candidate?.interviewMeetingTimestamp !== ""
                        ? new Date(
                            candidate?.interviewMeetingTimestamp
                          ).toLocaleTimeString()
                        : "N/A"}
                    </span>
                  </div>
                </div>
              </div>
            </OverviewTabs> */}
            {/* <div className="h-[20vh] w-[100%] p-2 border border-black rounded-lg flex flex-col items-start gap-2">
            </div> */}
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Score Card" key={"scorecard"}>
          <ScoreCard callData={lastCall} lastCall={lastCall} averageSkills={averageSkills} averageCultural={averageCultural} averageEmotional={averageEmotional} currentStage={getLastStage()} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Resume" key={"cv"}>
          {CV?.alternativeUrl ? (
            <div>
              <div className="flex justify-end mb-2">
                <FaExternalLinkAlt
                  onClick={() => {
                    window.open(CV?.alternativeUrl);
                  }}
                  size={20}
                  className="cursor-pointer"
                />
              </div>
              {CV?.alternativeUrl && (
                <iframe src={CV?.alternativeUrl} width="100%" height="600px" />
              )}
            </div>
          ) : CV ? (
            <CVTemplate CVData={{ cv: CV, candidate }} />
          ) : (
            <>
              <Alert type="info" message="Candidate has not submitted the CV" className="mb-3"/>

              <Button
                  type="primary"
                  className="flex justify-center w-full"
                onClick={() => {
                  if (loading) return;
                  fileInput?.current?.click?.();
                }}
              >
                      <div className="wrapper break-words text-center p-4 rounded text-white font-bold">
                        {loading ? (
                          <Spin>
                            <h3>Upload manually</h3>
                          </Spin>
                        ) : (
                          <h3>Upload manually</h3>
                        )}
                </div>
              </Button>
            </>
          )}
        </Tabs.TabPane>
        <Tabs.TabPane tab="Notes" key={"notes"}>
          <CandidateNote candidateId={candidateId} showHeadings={false} />
        </Tabs.TabPane>
        <TabPane tab="Interview Results" key="phoneCalls">
          {candidate?.phoneCalls?.length > 0 ? (
            <>
              <Table
                rowKey="call_id"
                columns={columnsPhoneCall}
                dataSource={candidate.phoneCalls.sort(
                  (a, b) => new Date(b.end_at) - new Date(a.end_at)
                )}
                loading={loading}
              />

              <Modal
                open={callDetailModal}
                onCancel={() => setCallDetailModal(false)}
                footer={[]}
              >
                <div className=" shadow sm:rounded-l overflow-auto">
                  <div className="">
                    <h3 className="text-lg leading-6 font-medium text-gray-900">
                      Interview Notes
                    </h3>
                  </div>
                  <div className="border-t border-gray-200">
                    <dl>
                      <div className="">
                        <dt className="text-sm font-medium text-gray-500">
                          Score
                        </dt>
                        <CallStatsComponent
                          callData={callDetailModal}
                          averageSkills={averageSkills}
                        />
                      </div>

                      <div className="px-4 py-5 sm:px-6">
                        <dt className="text-sm font-medium text-gray-500">
                          Summary
                        </dt>
                        <dd
                          className="mt-1 text-sm text-gray-900"
                          dangerouslySetInnerHTML={{
                            __html:
                              callDetailModal?.summary?.replace?.(
                                /\n/g,
                                "<br>"
                              ) ?? "",
                          }}
                        />
                      </div>
                      <div className="">
                        <dt className="text-sm font-medium text-gray-500">
                          Transcript
                        </dt>
                        <dd
                          className="mt-1 text-sm text-gray-900 space-y-4"
                          dangerouslySetInnerHTML={{
                            __html:
                              callDetailModal?.transcript?.replace?.(
                                /\n/g,
                                "<br>"
                              ) ?? "",
                          }}
                        />
                      </div>
                    </dl>
                  </div>
                </div>
              </Modal>
            </>
          ) : (
            <Alert
              type="info"
              message="Candidate has not been interviewed yet"
            />
          )}
        </TabPane>
        {/* <TabPane tab="Email History" key="emailSent">
          {candidate?.emailSent?.length > 0 ? (
            <>
              <div className="flex flex-col space-y-4">
                {candidate.emailSent
                  .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
                  .map((sms) => (
                    <div key={sms._id}>
                      <div
                        className="bg-indigo-500 font-bold text-white py-2 px-4 rounded-t-lg mt-2"
                        dangerouslySetInnerHTML={{
                          __html: sms.subject?.replace?.(/\n/g, "<br>"),
                        }}
                      ></div>
                      <div
                        className="bg-indigo-500 text-white py-2 px-4 rounded-b-lg "
                        dangerouslySetInnerHTML={{
                          __html: convertLinksToHTML(sms.body)?.replace?.(
                            /\n/g,
                            "<br>"
                          ),
                        }}
                      ></div>
                      <div className="text-right text-xs text-gray-400">
                        <div className="text-right text-xs text-gray-400">
                          {sms?.user_id?.email ? (
                            <>
                              {sms?.user_id?.firstName ?? ""}{" "}
                              {sms?.user_id?.lastName ?? ""} (
                              {sms?.user_id?.email ?? ""})
                            </>
                          ) : (
                            "System"
                          )}
                        </div>
                      </div>
                      <div className="text-right text-xs text-gray-400 mt-1">
                        {moment(sms.timestamp).format("DD-MM-YYYY HH:mm")}
                      </div>
                    </div>
                  ))}
              </div>
            </>
          ) : (
            <Alert
              type="info"
              message="Candidate did not receive any emails from you"
            />
          )}
        </TabPane> */}
        {/* <TabPane tab="SMS History" key="smsSent">
          <div className="h-[60vh] w-[100%] p-2 border border-black flex flex-col gap-5 overflow-y-auto">
            {candidate?.smsSent?.length > 0 ? (
              <> */}
                {/* <div className="flex flex-col space-y-4">
                  {candidate.smsSent
                    .sort(
                      (a, b) => new Date(b.timestamp) - new Date(a.timestamp)
                    )
                    .map((sms) => (
                      <div key={sms._id}>
                        <div
                          className="bg-indigo-500 text-white py-2 px-4 rounded-lg mt-2"
                          dangerouslySetInnerHTML={{
                            __html: sms.text?.replace?.(/\n/g, "<br>"),
                          }}
                        ></div>
                        <div className="text-right text-xs text-gray-400">
                          {sms?.user_id?.email ? (
                            <>
                              {sms?.user_id?.firstName ?? ""}{" "}
                              {sms?.user_id?.lastName ?? ""} (
                              {sms?.user_id?.email ?? ""})
                            </>
                          ) : (
                            "System"
                          )}
                        </div>
                        <div className="text-right text-xs text-gray-400 mt-1">
                          {moment(sms.timestamp).format("DD-MM-YYYY HH:mm")}
                        </div>
                      </div>
                    ))}
                </div> */}
              {/* </>
            ) : (
              <Alert
                type="info"
                message="Candidate did not receive any SMS messages from you"
              />
            )}
           <div className="h-max-[10vh] w-[60%] self-start flex items-start gap-1">
              <div
                className={"h-[100%] w-[10%] flex justify-center items-start"}
              >
                <div className="h-[50px] w-[50px] border boorder-black rounded-full">
                  <img
                    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSQtmJihq_VAgrb4BrSPeSM2v6HXpDiU6XlVWRXIUJoHQ&s"
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "contain",
                    }}
                  />
                </div>
              </div>

              <div className={"h-[100%] w-[90%] flex flex-col gap-5"}>
                <div className="h-[5%] w-[100%] flex justify-between">
                  <span style={{ fontSize: "15px", fontWeight: "600" }}>
                    Ali Khan
                  </span>
                  <span>Friday 2:30 pm</span>
                </div>
               <div
                  className="h-[95%] w-[100%] bg-gray-200 rounded-md p-5"
                   dangerouslySetInnerHTML={{
                    __html: sms.text?.replace?.(/\n/g, "<br>"),
                  }}
                >
                  hi there how are you ? 
                  Lorem Ipsum is simply dummy text of the printing .
                </div> 
              </div>
            </div>  */}

        {/* {
  candidate.smsSent.map((_sms,_index)=>(
            <div key={_index} className="h-max-[10vh] w-[60%] self-end flex justify-end items-start gap-1">

              <div className={"h-[100%] w-[90%] flex flex-col gap-5"}>
                <div className="h-[5%] w-[100%] flex justify-between">
                  <span style={{ fontSize: "15px", fontWeight: "600" }}>
                    You
                  </span>
                  <span>Friday 2:30 pm</span>
                </div>
                <div
                  className="h-[95%] w-[100%] bg-[#3281FE] text-white rounded-md p-5"
                   dangerouslySetInnerHTML={{
                    __html: _sms.text?.replace?.(/\n/g, "<br>"),
                  }}
                >
                  
                </div>
              </div>
            </div>

  ))
}  */}

         {/* <div className="h-[7vh] w-[100%] border border-black-700 gap-2 pl-1 pr-1 flex justify-center items-center">
            <div className="h-[100%] w-[80%] flex justify-center items-center">
              <Input placeholder="Start typing the message..." />
            </div>
            <div className="h-[100%] w-[20%] flex justify-start items-center gap-2">
              <button className="h-[70%] w-[35%] flex justify-center items-center rounded-md border border-black bg-[#3281FE] text-white">
                {" "}
                <MdAttachFile />{" "}
              </button>
              <button className="h-[70%] w-[35%] flex justify-center items-center rounded-md border border-black bg-[#3281FE] text-white">
                <AiOutlineSend />
              </button>
            </div>
          </div> 
          </div> 
        </TabPane>  */}

        <TabPane tab="Edit Contact" key="EditContact">
          {candidate?.formData && (
            <MultiStepComponent
              steps={[
                {
                  id: "contact",
                  name: "Contact Information",
                  form: [...personalDataCollection],
                },
              ]}
              defaultFormData={
                candidate?.formData
                  ? {
                      ...candidate?.formData,
                      phone: `${candidate?.formData?.phone}` ?? "",
                    }
                  : []
              }
              loading={loading}
              displaySteps={false}
              finishText="Save"
              wrapperClassName="px-5"
              onFinish={async (formData) => {
                await ATSService.updateCandidateDetails(candidateId, formData);
                await CrudService.search(
                  "CandidateLogs",
                  LOG_LOAD_PAGINATION,
                  1,
                  {
                    filters: { candidate: candidateId },
                    sort: { createdAt: -1 },
                    populate: "user_id",
                  }
                ).then(({ data }) => {
                  setCandidateTimeline(data.items);
                  setCandidateTimelineTotal(data.total);
                  setCurrentLogsPage(1);
                });
                await CrudService.search("VacancySubmission", 1, 1, {
                  filters: { _id: candidateId },
                }).then(({ data }) => {
                  if (data.items?.[0]) setCandidate(data.items?.[0]);
                  else setCandidate(false);
                });
              }}
            />
          )}
        </TabPane> 
        {/* <TabPane tab="Application Form" key="ApplicationForm">
          {candidate?.formData && candidate?.form ? (
            <MultiStepComponent
              displaySteps={true}
              steps={[
                ...(Array.isArray(candidate.form)
                  ? candidate.form
                  : typeof candidate.form === "object"
                  ? Object.values(candidate.form)
                  : []),
                vacancyData?.eeodc
                  ? {
                      id: "eeodc",
                      name: "EEODC",
                      form: [
                        {
                          type: "custom",
                          CustomInputComponent: () => (
                            <>
                              <h2 className="font-bold text-lg">
                                EEO Data Collection
                              </h2>
                              <Alert
                                type="info"
                                message="The following questions are part of our commitment to ensuring equal opportunities. Participation in this data collection is entirely voluntary, and all questions are optional. Your responses will not influence any individual hiring decisions. Instead, the data collected will be aggregated across multiple participants. This process is designed exclusively for our internal use, to monitor our progress towards achieving specific goals related to ensuring equal employment opportunities. Should you prefer not to respond to any query, please feel free to proceed to the next section of the form."
                              />
                            </>
                          ),
                        },
                        ...eeoForm,
                      ],
                    }
                  : null,
                {
                  id: "contact",
                  name: "Contact Information",
                  form: [...personalDataCollection],
                },
              ].filter((a) => !!a)}
              defaultFormData={candidate?.formData ? candidate?.formData : []}
              readOnly
            />
          ) : (
            <Alert
              type="info"
              message="Candidate has not submitted the application form"
            />
          )}
        </TabPane> */}

        {/* <TabPane tab="CV" key="CV">
          {CV?.alternativeUrl ? (
            <div>
              <div className="flex justify-end mb-2">
                <FaExternalLinkAlt
                  onClick={() => {
                    window.open(CV?.alternativeUrl);
                  }}
                  size={20}
                  className="cursor-pointer"
                />
              </div>
              {CV?.alternativeUrl && (
                <iframe src={CV?.alternativeUrl} width="100%" height="600px" />
              )}
            </div>
          ) : CV ? (
            <CVTemplate CVData={{ cv: CV, candidate }} />
          ) : (
            <>
              <Alert type="info" message="Candidate has not submitted the CV" />

              <button
                className="py-2 px-3 rounded-md mx-auto max-w-md block cursor-pointer border-none w-full box-border button transition text-center transform active:scale-90 "
                type="button"
                onClick={() => {
                  if (loading) return;
                  fileInput?.current?.click?.();
                }}
              >
                <div
                  className="w-full border-box "
                  style={{
                    background: "center center / cover no-repeat transparent",
                  }}
                >
                  <div className="mx-auto max-w-md sm:max-w-xl w-full md:max-w-3xl lg:max-w-4xl">
                    <div className="transition-wrapper" style={{}}>
                      <div className="wrapper break-words text-center p-4 bg-indigo-500 rounded text-white font-bold">
                        {loading ? (
                          <Spin>
                            <h3>Upload manually</h3>
                          </Spin>
                        ) : (
                          <h3>Upload manually</h3>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </button>
            </>
          )}
        </TabPane>

        {/* <TabPane tab="Tracking" key="Tracking">
          <div className="p-4">
            <table className="w-full border-collapse">
              <tbody>
                {Object.entries(candidate?.tracking ?? {})
                  .filter((e) => e?.[0] !== "_id")
                  .map(([key, value]) => (
                    <tr
                      key={key}
                      className="border-t border-gray-200 dark:border-gray-600 "
                    >
                      <td className="py-2 pr-4 font-semibold">{key}:</td>
                      <td className="py-2">{value}</td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </TabPane> */}

        {/* <TabPane tab="Logs" key="Logs">
          {candidateTimeline.length > 0 ? (
            <div>
              <div>
                <Timeline
                  items={candidateTimeline.map((t) => ({
                    color: t.color,
                    children: (
                      <>
                        <div>{t.message ?? ""}</div>
                        <div className="text-sm">
                          Performed by: {t.user_id?.firstName ?? ""}{" "}
                          {t.user_id?.lastName ?? ""} ({t.user_id?.email ?? ""})
                        </div>
                        <div className="text-xs">
                          {moment(t.createdAt).format(STANDARD_MOMENT_FORMAT)}
                        </div>
                      </>
                    ),
                  }))}
                />
              </div> */}

        {/* {candidateTimelineTotal >=
                LOG_LOAD_PAGINATION * currentLogsPage && (
                <div className="flex justify-center mt-5">
                  <Button loading={loading} onClick={onLogsLoadMore}>
                    Load more
                  </Button>
                </div>
              )}
            </div>
          ) : (
            <Alert type="info" message="Nothing to show" />
          )}
        </TabPane> */}
      </Tabs>
      <div className="sticky bottom-0 py-3 bg-white"> 
      <div className="mb-4 flex gap-5 ">
        {/* <button
          className="flex h-[40px] w-full flex-row items-center justify-center gap-2 rounded-lg border border-solid border-hireheroes-gray-300  px-[33px] text-center text-[14px] font-semibold text-gray-700 sm:px-5"
        > <ChatBubbleLeftRightIcon width={20} />
          Contact
        </button> */}
        <button
          className="flex h-[40px] w-full flex-row items-center justify-center gap-2 rounded-lg border border-solid border-hireheroes-gray-300  px-[33px] text-center text-[14px] font-semibold text-gray-700 sm:px-5"
        ><PlusCircleIcon width={20} />
          Invite
        </button>
        <button
          className="flex h-[40px] w-full flex-row items-center justify-center gap-2 rounded-lg border border-solid border-hireheroes-gray-300  px-[33px] text-center text-[14px] font-semibold text-gray-700 sm:px-5"
          onClick={()=>setMoveCandidate(true)}
        ><ChevronDoubleRightIcon width={20}/>
          Move
        </button>
        <button
          className="flex h-[40px] w-full flex-row items-center justify-center gap-2 rounded-lg border border-solid border-hireheroes-gray-300  px-[33px] text-center text-[14px] font-semibold text-gray-700 sm:px-5"
          onClick={()=>setRejectCandidate(true)}
          disabled={candidate.rejected}
        ><XCircleIcon width={20} />
          Reject
        </button>
      </div>
      <Divider className="my-0" />
      <div className="flex flex-col gap-5">
        <div className=" bg-hireheroes-gray-200" />
        <div className="flex gap-5">
          <button 
           className="flex h-[40px] w-full flex-row items-center justify-center gap-2 rounded-lg border border-solid border-hireheroes-gray-300 bg-hireheroes-white px-[33px] text-center text-[14px] font-semibold text-gray-700 sm:px-5"
          onClick={()=>{
            setDetailsModal(getPrevObject(vacancyInfo.stages.filter((stage) =>(stage.id === candidate.stageId))[0].cards, candidateId))}}>
            <ArrowLeftIcon width={20} />
            Previous candidate
          </button>
          <button
           className="flex h-[40px] w-full flex-row items-center justify-center gap-2 rounded-lg border border-solid border-hireheroes-gray-300 bg-hireheroes-white px-[33px] text-center text-[14px] font-semibold text-gray-700 sm:px-5"
          onClick={()=>{setDetailsModal(getNextObject(vacancyInfo.stages.filter((stage) =>(stage.id === candidate.stageId))[0].cards, candidateId))}}>
          
            Next candidate
            <ArrowRightIcon width={20} />
          </button>
        </div>
      </div>
      </div>
      <Modal
        open={!!rejectCandidate}
        onCancel={() => setRejectCandidate(null)}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        destroyOnClose
        // afterOpenChange={(e) => {
        //   if (!e) window.scrollTo(0, lastScroll);
        // }}
      >
        <CandidateRejectBox
          candidateId={candidateId}
          setRejectCandidate={setRejectCandidate}
          onSend={() => {
            setRejectCandidate(null);
            reloadStages({ noLoadingDisplay: true });
          }}
        />
      </Modal>

      <Modal
        open={!!moveCandidate}
        onCancel={() => setMoveCandidate(null)}
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        destroyOnClose
        // afterOpenChange={(e) => {
        //   if (!e) window.scrollTo(0, lastScroll);
        // }}
      >
        <MoveCandidate
          candidateId={candidateId}
          stages={vacancyInfo.stages}
          currentStage={getLastStage()}
          setMoveCandidate={setMoveCandidate}
          reloadStages={reloadStages}
        />
      </Modal>
      {/* </div> */}
    </>
  );
};

// const DetailHeader = ({ candidateInfo, _stages }) => {

// };

export default DetailsModal;
