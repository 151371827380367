import React from "react";

const sizes = {
  body_3_regular: "text-[14px] font-normal not-italic",
  textxl: "text-[18px] font-normal not-italic",
};

const Text = ({ children, className = "", as, size = "body_3_regular", ...restProps }) => {
  const Component = as || "p";

  return (
    <Component className={`text-[#667084] font-['Inter'] ${className} ${sizes[size]}`} {...restProps}>
      {children}
    </Component>
  );
};

export { Text };
