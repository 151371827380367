import { Modal, Pagination, Tag, message } from "antd";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { FaCopy } from "react-icons/fa";
import { useSelector } from "react-redux";
import { selectDarkMode, selectLoading } from "../../../redux/auth/selectors";
import PartnerService from "../../../service/PartnerService";
import DisplayData from "./DisplayData";

const PAGE_SIZE = 10;

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const Growth = () => {
  const [partners, setPartners] = useState([]);
  const [selected, setSelected] = useState(null);
  const [lastScroll, setLastScroll] = useState(0);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: PAGE_SIZE,
  });
  const loading = useSelector(selectLoading);
  const darkMode = useSelector(selectDarkMode);

  const loadPartners = (pagination) => {
    PartnerService.queryGrowthRequests(
      pagination.current,
      pagination.pageSize
    ).then(({ data }) => {
      setPartners(data.growthRequests);
      setPagination((prevPagination) => ({
        ...prevPagination,
        current: data.page + 1,
        total: data.total,
      }));
    });
  };

  useEffect(() => {
    loadPartners({
      current: 1,
      pageSize: PAGE_SIZE,
    });
  }, []);

  const handlePageChange = (page, pageSize) => {
    setPagination({ ...pagination, current: page, pageSize });
    loadPartners({ ...pagination, current: page, pageSize });
  };

  return (
    <>
      <h2 className="px-4 text-base font-semibold leading-7 text-black dark:text-gray-400  sm:px-6 lg:px-8">
        Network
      </h2>
      <table className="mt-6 w-full whitespace-nowrap text-left mb-5">
        <colgroup>
          <col className="w-full sm:w-4/12" />
          <col className="lg:w-4/12" />
          <col className="lg:w-2/12" />
          <col className="lg:w-1/12" />
          <col className="lg:w-1/12" />
        </colgroup>
        <thead className="border-b border-white/10 text-sm leading-6 text-black dark:text-gray-400 ">
          <tr>
            <th
              scope="col"
              className="py-2 pl-4 pr-8 font-semibold sm:pl-6 lg:pl-8"
            >
              Partner
            </th>
            <th
              scope="col"
              className="hidden py-2 pl-0 pr-8 font-semibold sm:table-cell"
            >
              email
            </th>
            <th
              scope="col"
              className="py-2 pl-0 pr-4 text-right font-semibold sm:pr-8 sm:text-left lg:pr-20"
            >
              Joined
            </th>
            <th
              scope="col"
              className="hidden py-2 pl-0 pr-4 text-right font-semibold sm:table-cell sm:pr-6 lg:pr-8"
            >
              View
            </th>
          </tr>
        </thead>
        <tbody className="divide-y divide-white/5">
          {partners.map((item, i) => (
            <tr key={i}>
              <td className="py-4 pl-4 pr-8 sm:pl-6 lg:pl-8">
                <div className="flex items-center gap-x-4">
                  <img
                    src={item.partner?.logo}
                    alt=""
                    className="h-8 w-8 rounded-full bg-gray-800"
                  />
                  <div className="truncate text-sm font-medium leading-6 text-black dark:text-gray-400 ">
                    {item.partner?.owner?.firstName}{" "}
                    {item.partner?.owner?.lastName}
                  </div>

                  {item.createdAt &&
                    moment(item.createdAt).isSameOrAfter(
                      moment().subtract(7, "days")
                    ) && (
                      <div>
                        <Tag color="green">New</Tag>
                      </div>
                    )}
                </div>
              </td>
              <td className="hidden py-4 pl-0 pr-4 sm:table-cell sm:pr-8">
                <div className="flex gap-x-3">
                  <div className="font-mono text-sm leading-6 text-gray-400">
                    {item.partner?.owner?.email}
                  </div>
                  <span
                    className="inline-flex items-center cursor-pointer rounded-md bg-gray-400/10 px-2 py-1 text-xs font-medium text-gray-400 ring-1 ring-inset ring-gray-400/20"
                    onClick={() => {
                      navigator.clipboard.writeText(item.partner?.email);
                      message.success("Copied to clipboard");
                    }}
                  >
                    <FaCopy />
                  </span>
                </div>
              </td>
              <td className="py-4 pl-0 pr-4 text-sm leading-6 sm:pr-8 lg:pr-20">
                <div className="flex items-center justify-end gap-x-2 sm:justify-start">
                  <div className="hidden text-black dark:text-gray-400  sm:block">
                    {moment(item.createdAt).format("DD-MM-YYYY")}
                  </div>
                </div>
              </td>
              <td className="hidden py-4 pl-0 pr-4 text-right text-sm leading-6 text-gray-400 sm:table-cell sm:pr-6 lg:pr-8">
                <button
                  className="px-2 py-1 text-sm bg-white-500 text-indigo-500 border border-indigo-500 rounded"
                  onClick={() => {
                    setLastScroll(window.scrollY);
                    setSelected(item);
                  }}
                >
                  Details
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <div className="w-full flex justify-end">
        <Pagination
          current={pagination.current}
          pageSize={pagination.pageSize}
          total={pagination.total}
          onChange={handlePageChange}
          loading={loading}
        />
      </div>

      <Modal
        wrapClassName={`${darkMode ? "dark" : ""}`}
        open={!!selected}
        onCancel={() => setSelected(null)}
        destroyOnClose
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        afterOpenChange={(e) => {
          if (!e) window.scrollTo(0, lastScroll);
        }}
      >
        <h1 className="font-bold">
          {selected?.partner?.owner?.firstName}{" "}
          {selected?.partner?.owner?.lastName}
        </h1>
        <DisplayData data={selected} />
      </Modal>
    </>
  );
};

export default Growth;
