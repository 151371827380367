import { message } from "antd";
import axios from "axios";

function bytesToMB(bytes) {
  return bytes / (1024 * 1024);
}

class ImageUploadService {
  constructor() {
    this.baseURL = `https://api.cloudinary.com/v1_1/${localStorage?.cloudinaryCloudName}/auto/upload`;
    this.api = axios.create({
      baseURL: this.baseURL,
    });
  }

  upload(file, maxMB) {
    if (!localStorage?.cloudinaryCloudName)
      return message.error("Uploads are disabled");
    if (!localStorage?.cloudinaryPreset)
      return message.error("Uploads are disabled");
    if (!localStorage?.cloudinaryAPIKey)
      return message.error("Uploads are disabled");

    if (bytesToMB(file.size) > maxMB)
      return message.info(`Maximum size: ${maxMB}MB`);
    const formData = new FormData();
    formData.append("file", file);
    formData.append("upload_preset", localStorage?.cloudinaryPreset);
    formData.append("api_key", localStorage?.cloudinaryAPIKey);

    return this.api.post("", formData);
  }
}

export default new ImageUploadService();
