import axios from "axios";
import { getBackendUrl } from "./getBackendUrl";
import { middleField } from "./middlefield";

class AdminLeadsService {
  constructor(baseURL) {
    this.baseURL = baseURL;
    this.api = axios.create({
      baseURL,
    });
    middleField(this.api);
  }

  insertLeads({ leads }) {
    return this.api.post("/insertLeads", { leads });
  }
  manuallyTransferLeads(data) {
    return this.api.post("/manuallyTransferLeads", data);
  }
}

export default new AdminLeadsService(`${getBackendUrl()}/adminLeads`);
