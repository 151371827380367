import React from "react";
import { Button } from "../auth/components/Button";
import { ChipView } from "../auth/components/ChipView";
import { Heading } from "../auth/components/Heading";
import { Img } from "../auth/components/Img";

export default function HeroCard({ ...props }) {
  const [chipOptions, setChipOptions] = React.useState(() => [
    // { value: 1, label: `AC` },
    // { value: 2, label: `TI` },
    // { value: 3, label: `PT` },
  ]);

  const [selectedChipOptions, setSelectedChipOptions] = React.useState([]);

  return (
    <div
      {...props}
      className={`${props.className} flex flex-col items-center justify-center w-[24%]  py-1.5 border-[#eaecf0] border border-solid bg-[#ffffff] rounded-[12px]`}
    >
      <div className="mt-1 flex flex-col items-center self-stretch">
        {/* <div className="mx-6 flex items-start justify-end gap-3 self-stretch">
          {badgeText && <Heading
            size="textxs"
            as="p"
            className="rounded-[10px] border border-solid border-[#ffb93a] bg-[#fff9eb] py-0.5 pl-2 text-[12px] font-medium text-[#b54707]"
          >
            {badgeText}
          </Heading>}
        </div> */}
        <div className="mx-6 flex items-start justify-center gap-3 self-stretch">
          <Img
            src={props.aiImage}
            alt="Top Image"
            className="mt-0 h-[130px] w-[130px] self-end rounded-full object-cover"
          />
        </div>
        <Button
          size="sm"
          leftIcon={
            <Img
              src={`/dashboard/flags/${props.aiLanguage}.png`}
              alt="United States"
              className="mb-0.5 h-[16px] w-[16px]"
            />
          }
          className="mt-[18px] min-w-[80px] gap-1.5 rounded-[14px] border border-solid border-[#b4c3ff] px-[5px] font-semibold"
        >
          {props.name}
        </Button>
        <div className="mt-2 flex items-start justify-center self-stretch">
          <Heading
            size="textxs"
            as="p"
            className="mt-2.5 text-[12px] font-medium text-[#344054]"
          >
            Interview style: <span className="capitalize">{props.aiStyle}</span>
          </Heading>
        </div>
        <ChipView
          options={chipOptions}
          setOptions={setChipOptions}
          values={selectedChipOptions}
          setValues={setSelectedChipOptions}
          className="mx-6 my-2 flex flex-wrap gap-3 justify-center"
        >
          {(option) => (
            <React.Fragment key={option.index}>
              {option.isSelected ? (
                <div
                  onClick={option.toggle}
                  className="flex h-[22px] min-w-[60px] cursor-pointer flex-row items-center justify-center whitespace-pre-wrap rounded-[10px] border border-solid border-[#f8f9fb99] bg-[#f8f9fb] px-4 text-center text-[12px] font-medium text-[#344054]"
                >
                  <span>{option.label}</span>
                </div>
              ) : (
                <div
                  onClick={option.toggle}
                  className="flex h-[22px] min-w-[60px] cursor-pointer flex-row items-center justify-center rounded-[10px] border border-solid border-[#eaecf0] bg-[#f8f9fb] px-4 text-center text-[12px] font-medium text-[#344054]"
                >
                  <span>{option.label}</span>
                </div>
              )}
            </React.Fragment>
          )}
        </ChipView>
      </div>
    </div>
  );
}
