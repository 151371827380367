import { EditOutlined } from "@ant-design/icons";
import { Typography } from "antd";
import { useState } from "react";

function ColumnTitle({ allowRenameColumn, onClick,   children: title }) {
  return allowRenameColumn ? (
    <span style={{ cursor: "pointer" }} onClick={onClick}>
      {title}
    </span>
  ) : (
    <span>{title}</span>
  );
}

function useRenameMode(state) {
  const [renameMode, setRenameMode] = useState(state);

  function toggleRenameMode() {
    setRenameMode(!renameMode);
  }

  return [renameMode, toggleRenameMode];
}

export default function ({
  children: column,
  allowRemoveColumn,
  onColumnRemove,
  allowRenameColumn,
  onColumnRename,
  index,
}) {
  const [renameMode, toggleRenameMode] = useRenameMode(false);
  const [titleInput, setTitleInput] = useState("");

  function handleRenameColumn(event) {
    event.preventDefault();

    onColumnRename(column, titleInput);
    toggleRenameMode();
  }

  function handleRenameMode() {
    setTitleInput(column.title);
    toggleRenameMode();
  }

const colors= ["#E6EBFF","#B4C3FF","#7C8EFE","#465FC2","#0538FF"]

  return (
    <div
      className="react-kanban-column-header bg-white dark:bg-gray-700"
      style={{
        // background: "white",
        borderTop: `10px solid ${index < colors.length ? colors[index] : "#0538FF"}`,
        height: "100%",
        width: "100%",
        borderTopLeftRadius: "10px",
        borderTopRightRadius: "10px",
        display: "flex",
        justifyContent: "starts",
        alignItems: "center",
        // border:'1px solid black'
      }}
    >
      {renameMode ? (
        <form onSubmit={handleRenameColumn}>
          <span>
            <input
              type="text"
              value={titleInput}
              className="dark:bg-gray-900"
              onChange={({ target: { value } }) => setTitleInput(value)}
              autoFocus
            />
          </span>
          <span>
            <button
              className="react-kanban-column-header__button"
              type="submit"
            >
              Rename
            </button>
            <button
              className="react-kanban-column-header__button"
              type="button"
              onClick={handleRenameMode}
            >
              Cancel
            </button>
          </span>
        </form>
      ) : (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "0 10px",
              alignItems: "flex-end",
              height: "100%",
              width: "100%",
            }}
          >
            <div style={{ alignSelf: "flex-end"}}>
              <ColumnTitle
                allowRenameColumn={allowRenameColumn}
                // onClick={handleRenameMode}
              >
                <Typography.Paragraph
                  // editable={
                  //   column.id === "UNCATEGORIZED"
                  //     ? null
                  //     : {
                  //         onChange: (e) => onColumnRename(column.id, e),
                  //         text: column.title,
                  //       }
                  // }
                >
                  <div
                    style={{
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                      maxWidth: 150,
                    }}
                  >
                    {column.title} ({column?.cards?.length})
                  </div>
                </Typography.Paragraph>
              </ColumnTitle>
            </div>
            {/* <span className="self-center pb-2">{column?.cards?.length}</span> */}
          </div>
          {allowRemoveColumn && (
            <span onClick={() => onColumnRemove(column)}>×</span>
          )}
        </>
      )}
    </div>
  );
}
