import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { getPartner } from "../../redux/auth/selectors";
import { Container } from "../Landing/Container";
import { Footer } from "../Landing/Footer";
import { Header } from "../Landing/Header";

const Disclaimer = () => {
  const partner = useSelector(getPartner);
  const location = useLocation();

  useEffect(() => {
    var js,
      tjs = document.getElementsByTagName("script")[0];
    if (partner.termlyDisclaimerDataID) {
      js =
        document.getElementById("termly-jssdk") ??
        document.createElement("script");
      js.id = "termly-jssdk";
      js.src = "https://app.termly.io/embed-policy.min.js";
      tjs.parentNode.insertBefore(js, tjs);

      return () => {
        if (js) tjs.parentNode.removeChild(js);
      };
    }
  }, [partner, location]);

  return (
    <>
      <Header />

      {partner?.termlyDisclaimerDataID ? (
        <div name="termly-embed" data-id={partner.termlyDisclaimerDataID}></div>
      ) : (
        <main>
          <Container className="pb-16 pt-20 text-left lg:pt-32">
            <div
              dangerouslySetInnerHTML={{
                __html: partner?.DisclaimerText,
              }}
            />
          </Container>
        </main>
      )}

      <Footer />
    </>
  );
};

export default Disclaimer;
