import { Skeleton, message } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { FaCopy, FaDesktop, FaGlobe } from "react-icons/fa";
import { useSelector } from "react-redux";
import { selectUser } from "../../../redux/auth/selectors";
import ZoomService from "../../../service/ZoomService";
import { getZoomAppUrl } from "../../../service/getBackendUrl";
import ConnectZoom from "./ConnectZoom";

const MyInterviews = () => {
  const user = useSelector(selectUser);
  const [interviews, setInterviews] = useState([]);

  useEffect(() => {
    if (!user?.connectZoom) return;

    ZoomService.getMeetings().then(({ data }) => {
      setInterviews(data);
    });
  }, [user]);

  const getWebAppUrl = (partner, candidateId) =>
    partner && candidateId ? `${getZoomAppUrl(partner)}?id=${candidateId}` : "";
  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    message.success("Copied to clipboard!");
  };

  if (!user) return <Skeleton active />;
  if (user && !user?.connectZoom) return <ConnectZoom />;

  return (
    <>
      <div className="p-4">
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-4">
          {interviews.map((interview) => (
            <div
              key={interview.id}
              className="bg-white dark:bg-gray-900 shadow-lg dark:shadow-gray-400/50 hover:shadow-gray-600/50  rounded-lg p-4 flex items-start justify-between min-w-[300px]"
            >
              <div className="">
                <h2 className="font-bold text-xl mb-2 max-w-[175px] overflow-hidden truncate">
                  {interview.topic}
                </h2>
                <p className="max-w-[175px] overflow-hidden truncate">
                  {moment
                    .utc(interview.start_time)
                    .format("hh:mm A', YYYY-MM-DD")}
                </p>
                <p className="max-w-[175px] overflow-hidden truncate">
                  Duration: {interview.duration} minutes
                </p>
              </div>
              <div className="flex justify-end gap-3 mt-4">
                <button
                  onClick={() => window.open(interview.join_url)}
                  className="text-blue-500 hover:text-blue-700"
                >
                  <FaDesktop className="text-2xl" />
                </button>
                {interview.candidateId && (
                  <button
                    onClick={() =>
                      window.open(
                        getWebAppUrl(user.partner, interview.candidateId)
                      )
                    }
                    className="text-green-500 hover:text-green-700"
                  >
                    <FaGlobe className="text-2xl" />
                  </button>
                )}
                <button
                  onClick={() =>
                    copyToClipboard(
                      interview.candidateId
                        ? getWebAppUrl(user.partner, interview.candidateId)
                        : interview.join_url
                    )
                  }
                  className="text-gray-500 hover:text-gray-700"
                >
                  <FaCopy className="text-2xl" />
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default MyInterviews;
