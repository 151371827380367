import axios from "axios";
import { getBackendLinkUrl } from "./getBackendUrl";
import { middleField } from "./middlefield";

class LinkService {
  constructor(baseURL) {
    this.baseURL = baseURL;
    this.api = axios.create({
      baseURL,
    });
    middleField(this.api, true);
  }
  // Generate a short link
  shortLink(longUrl) {
    return this.api.post("/shortlink", { longUrl });
  }
  updateShortLink(longUrl, smallCode) {
    return this.api.put("/shortlink", { longUrl, smallCode });
  }

  // Redirect based on short code
  getLongLink(smallCode) {
    return this.api.get(`/${smallCode}`);
  }
}

export default new LinkService(getBackendLinkUrl());
