import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import "./style/index.scss";

import { ConfigProvider, theme } from "antd";
import { useSelector } from "react-redux";
import RouteInit from "./RouteInit";
import { brandColor } from "./data/constants";
import { getPartner, selectDarkMode } from "./redux/auth/selectors";

const AntdConfig = () => {
  const partner = useSelector(getPartner);
  const darkMode = useSelector(selectDarkMode);
  const { defaultAlgorithm, darkAlgorithm } = theme;

  return (
    <>
      <ConfigProvider
        theme={{
          token: { colorPrimary: partner?.themeColor ?? brandColor },
          algorithm: darkMode ? darkAlgorithm : defaultAlgorithm,
        }} 
        
      >
        <Router>
          <RouteInit />
        </Router>
      </ConfigProvider>
    </>
  );
};

export default AntdConfig;
