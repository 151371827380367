import React, { useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import AuthService from "./service/AuthService";

const EmailVerify = () => {
  let [searchParams] = useSearchParams();
  const navigate = useNavigate();

  useEffect(() => {
    const token = searchParams.get("token");
    if (!token) return;

    AuthService.updateEmailConfirm({ token })
      .then(() => {
        navigate("/dashboard/settings");
      })
      .catch(() => navigate("/dashboard/settings"));
  }, [searchParams, navigate]);
  return <></>;
};

export default EmailVerify;
